import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SellerService } from '../Services/Seller/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import * as jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
declare var jsPDF: any;
import html2canvas from 'html2canvas'; 

interface Food {
  value: string;
  viewValue: string;
}
export interface PeriodicElement {
  applicationSeqNo:any;
      fullname:any;
      sclass:any;
      departmentname:any;
      specialization:any;
      branch:any;
      bhawan:any;
      roomno:any;
      category:any;
      dob:any;
}

@Component({
  selector: 'app-exexistingstudentpayment',
  templateUrl: './exexistingstudentpayment.component.html',
  styleUrls: ['./exexistingstudentpayment.component.scss']
})
export class ExexistingstudentpaymentComponent implements OnInit {
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;
  @ViewChild('pdfasTableHDFCChallan', {static: false}) pdfasTableHDFCChallan: ElementRef;
  @ViewChild('pdfasTableHDFC', {static: false}) pdfasTableHDFC: ElementRef;
  @ViewChild('htmlData', {static: false}) htmlData:ElementRef;
  @ViewChild('form', {static: false}) form: ElementRef;

  favoriteSeason: string;
  seasons: string[] = ['DOSW(D/ND)', 'Fees', 'Scholarship', 'ACAD'];
  accessCode: any;
  merchant_id:any;
  encRequest : any;
  encdata:any;
  merchant_code:any;
  currency: string;
  CATDetailList: any;
  submitted: boolean;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  IsresponseSBI: boolean;
  IsAmountButton:boolean;
  IsAmountStatus:boolean=false;
  IsHDFCChallan:boolean=false;
  IsSure:boolean=false;
  ApplicationSeq_No:any;
  CATRegistrationNos:any;
  applicationSeqNo:any = {};
  MBAFeesForm:FormGroup;
  StatusOfFeesForm:FormGroup;
  Mobileform:FormGroup;
  UpdateSellerForm:FormGroup;
  questionTypedeatils: any;
  updateSellerform:FormGroup;
  AmountStatus:any;
  IsHDFCChallanOpen:boolean=false;
  IsHDFCcashDD:boolean=false;
  foods: Food[] = [
    {value: 'a', viewValue: '(a)'},
    {value: 'b', viewValue: '(b)'},
    {value: 'c', viewValue: '(c)'},
    {value: 'd', viewValue: '(d)'},
    {value: 'e', viewValue: '(e)'},
  ];
  CATRegistrationNo: any;
  ApplicantName: any;
  IsresponseHDFC: boolean=false;
  isformstarstop:boolean;
  enrollmentNo: any;
  trans_date: any;
  StatusMessage: any;
  Semester: any;
  Enrollmentno: any;
  users: any = {};
  virtualaccountno: any;
  totalamount: any;
  IFSCCode: string;
  BeneficiaryBank: any;
  BankBranch: string;
  RemitterName: any;
  department: any;
  stMob: any;
  class: any;
  TaskDbList: any[] = [];
  Name: any[];
  Date: any[];
  description: any[];
  option: any[];
  IsStop: boolean;
  enrollmentno: any;
  IsStudentCompline: boolean=false;
  StudentComplineForm: FormGroup;
  date: Date;
  EndDate: any;
  constructor(public datepipe: DatePipe,private spinner: NgxSpinnerService,private formbuilder:FormBuilder,private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  { 
    this.route.queryParams.subscribe(params => {
      this.enrollmentNo=params['enrollment_no']
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
      this.ApplicationSeq_No=params['ApplicationSeq_No'];
      this.ApplicantName=params['ApplicantName'];
      this.CATRegistrationNo=params['RegistrationNo'];
      this.trans_date=params['trans_date'];
      this.StatusMessage=params['StatusMessage'];
      this.Semester=params['Semester'];
      this.Enrollmentno=params['Enrollmentno']
  });

  this.updateSellerform=this.formbuilder.group(
    {
      enrollmentno:[''],
      name:[''],
      course:[''],
      department:[''],
      category:[''],
      status_Year:[''],
      d_ND:[''],
      insituteFee:[''],
      messFee:[''],
      otherFee:[''],
      totalFees:[''],
      stName:[''],
      bankName:[''],
      stMediFee:[''],
      stMob:[''],
      stEmail:[''],
    })
    this.MBAFeesForm = this.formBuilder.group({
      Enrollmentno:[``, Validators.required],
      Name:[``, Validators.required],
      Course:[``,Validators.required],
      Class:[``,Validators.required],
      Department:[``,Validators.required],
      Category:[``,Validators.required],
      Stu_Type:[``,Validators.required],
      D_ND:[``,Validators.required],
      Semester:[``,Validators.required],
      InsituteFee:[``,Validators.required],
      MessFee:[``,Validators.required],
      OtherFee:[``,Validators.required],
      TotalFees:[``,Validators.required],
      stMob:['',Validators.required],
      stEmail:['',Validators.required],
      StName:['',Validators.required],
      stMediFee:['',Validators.required],
      accommodation_Chag:[''],
      electricity_Chag:[''],
      messEsttb_Chag:[''],
      semesterCharg:[''],
      id:['',Validators.required],
      amountStatus:[``],
      currency:[''],
      encdata:[''],
      merchant_code:[''],
   });
   this.StatusOfFeesForm=this.formBuilder.group({
    Enrollmentno:[``, Validators.required],
   });
   this.StudentComplineForm=this.formBuilder.group({
    Enrollmentno:[``, Validators.required],
    Name:[``, Validators.required],
    IITRMailId:[``, Validators.required],
    MobileNo:[``, Validators.required],
    IssueType:[``, Validators.required],
    comment:[``, Validators.required]
   })
   this.Mobileform=this.formBuilder.group({
    Enrollmentno:[``, Validators.required],
   });
  }

  ngOnInit() {
    this.accessCode = 'AVKR88GJ13AH16RKHA';
  this.merchant_id='234485';
  this.currency='INR';
   this.merchant_code='IIT_RK';
   this.myFunction();
    //this.Email= sessionStorage.getItem('Email');
  //this.firstDropDownChanged(this.enrollmentNo);
this.IsresponseSBI=false;
    const data =
          {
            'tracking_id' : this.tracking_id,
            'bank_ref_no' : this.bank_ref_no,
            'order_status' : this.order_status,
            'amount' : this.amount,
            'billing_name' :this.billing_name,
            'merchant_param2':this.merchant_param2,
            'delivery_address':this.delivery_address,
            'sbi_ref_no':this.sbi_ref_no,
            'iitr_ref_no':this.iitr_ref_no,
            'status':this.status,
            'desc':this.desc,
            'Transactiontime_date': this.Transactiontime_date,
            'RegistrationNo':this.CATRegistrationNo,
            'trans_date':this.trans_date,
            'StatusMessage':this.StatusMessage,
            'Semester':this.Semester,
            'Enrollmentno':this.Enrollmentno,
          }
          if(this.status=="Success")
          {
            this.IsresponseSBI = true;
           // this.downloadPDF();
          }
           else if(this.status=="Failure") 
          {
            this.IsresponseSBI = true;
          }
          else if(this.status=="Aborted")
        {
          this.IsresponseSBI = true;
        }

        if(this.order_status=="Success")
        {
          this.IsresponseHDFC = true;
        }
         else if(this.order_status=="Failure") 
        {
          this.IsresponseHDFC = true;
        }
        else if(this.order_status=="Aborted")
      {
        this.IsresponseHDFC = true;
      }
      else if(this.order_status=="Awaited")
      {
        this.IsresponseHDFC=true;
      }
this.MBAFeesForm.reset();
    this.MBAFeesForm = this.formBuilder.group({
      Enrollmentno:[``, Validators.required],
        Name:[``,Validators.required],
        Course:[``,Validators.required],
        Class:[``,Validators.required],
        Department:[``,Validators.required],
        Category:[``,Validators.required],
        Stu_Type:[``,Validators.required],
        D_ND:[``,Validators.required],
        Semester:[``,Validators.required],
        InsituteFee:[``,Validators.required],
        MessFee:[``,Validators.required],
        OtherFee:[``,Validators.required],
        TotalFees:[``,Validators.required],
        stMob:['',Validators.required],
        stEmail:['',Validators.required],
        StName:['',Validators.required],
        stReg:['',Validators.required],
        stMediFee:['',Validators.required],
        id:['',Validators.required],
        accommodation_Chag:[''],
        electricity_Chag:[''],
        messEsttb_Chag:[''],
        semesterCharg:[''],
        amountStatus:[``],
        currency:[''],
        encdata:[''],
        merchant_code:[''],
    });
    this.sellerService.GetCATNOs().subscribe((data: any) => {
      this.questionTypedeatils = data.data.data;
    });
    this.sellerService.GetFormdata().subscribe((data: any) => {
      this.TaskDbList = data.data.data;
      this.Name= this.TaskDbList[0].name;
      this.Date= this.TaskDbList[0].concurrencyStamp;
      this.description= this.TaskDbList[0].normalizedName;
      this.option= this.TaskDbList[0].options;
      if(this.TaskDbList[0].options=="stop")
      {
        this.isformstarstop=false;
        this.IsStop=true;
      }
      else
      {
        this.isformstarstop=true;
        this.IsStop=false;;
      }
    })
  }
  myFunction(){
    this.date=new Date();
    let latest_date =this.datepipe.transform(this.date, 'yyyy-MM-dd');
   }
  start()
  {
    this.ngOnInit();
  }
  clickmobiledata(val: any) 
  {
 var data= this.MBAFeesForm.controls.Enrollmentno.value;
 this.firstDropDownChanged(data);
  }
  firstDropDownChanged(val: any) {
    console.log(val);
    if (val == "Choose Your CAT Registration No.") {
      this.ngOnInit();
    }
    // this.ngOnInit();
      this.sellerService.getCatDeatilasofExixtingStudent(val).subscribe((data: any) => {
        this.CATDetailList = data.data.data;
        if(data.data.message != null)
        {
        this.toastr.show(data.data.message);
      }
      if(this.CATDetailList != null)
      {
        this.AmountStatus=this.CATDetailList.amountStatus;
        if(this.AmountStatus!='Success')
        {
         this. IsAmountButton= true;
        }
      }
        console.log("AmountList",this.AmountStatus);
        this.MBAFeesForm.controls["id"].patchValue(this.CATDetailList.id);
        this.MBAFeesForm.controls["Enrollmentno"].patchValue(this.CATDetailList.enrollmentno);
            this.MBAFeesForm.controls["Name"].patchValue(this.CATDetailList.name);
        this.MBAFeesForm.controls["Course"].patchValue(this.CATDetailList.course);
        this.MBAFeesForm.controls["Class"].patchValue(this.CATDetailList.class);
        this.MBAFeesForm.controls["Department"].patchValue(this.CATDetailList.department);
        this.MBAFeesForm.controls["Category"].patchValue(this.CATDetailList.category);
        this.MBAFeesForm.controls["Stu_Type"].patchValue(this.CATDetailList.stu_Type);
        this.MBAFeesForm.controls["D_ND"].patchValue(this.CATDetailList.d_ND);
        this.MBAFeesForm.controls["Semester"].patchValue(this.CATDetailList.semester);
        this.MBAFeesForm.controls["InsituteFee"].patchValue(this.CATDetailList.insituteFee);
        this.MBAFeesForm.controls["MessFee"].patchValue(this.CATDetailList.messFee);
        this.MBAFeesForm.controls["OtherFee"].patchValue(this.CATDetailList.otherFee);
        console.log("CATDetailList",this.CATDetailList);
         this.MBAFeesForm.controls["TotalFees"].patchValue(this.CATDetailList.totalFees);
         this.MBAFeesForm.controls["stEmail"].patchValue(this.CATDetailList.stEmail);     
         this.MBAFeesForm.controls["amountStatus"].patchValue(this.CATDetailList.amountStatus);   
         this.MBAFeesForm.controls["stMediFee"].patchValue(this.CATDetailList.stMediFee);   
         this.MBAFeesForm.controls["stMob"].patchValue(this.CATDetailList.stMob);   
         this.MBAFeesForm.controls["StName"].patchValue(this.CATDetailList.stName);   
         this.MBAFeesForm.controls["stReg"].patchValue(this.CATDetailList.stReg);
         this.MBAFeesForm.controls["accommodation_Chag"].patchValue(this.CATDetailList.accommodation_Chag);
         this.MBAFeesForm.controls["electricity_Chag"].patchValue(this.CATDetailList.electricity_Chag);
         this.MBAFeesForm.controls["messEsttb_Chag"].patchValue(this.CATDetailList.messEsttb_Chag);
         this.MBAFeesForm.controls["semesterCharg"].patchValue(this.CATDetailList.semesterCharg);
         
      })
  }
  getDataForMBA(seller: any)
  {

  if (seller == "Choose Your CAT Registration No.") {
    this.ngOnInit();
  }
    this.sellerService.getCatDeatilaAccReGNo(seller).subscribe((data: any) => {
      this.CATDetailList = data.data;
      
    })
  }
 
  public downloadPDF11() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTable = this.pdfasTable.nativeElement;
    doc.fromHTML(pdfasTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceiptofNewEntrants_Fee.pdf');
  }
  
  public downloadPDFHDFCChallan() {
    var data = document.getElementById('pdfasTableHDFCChallan');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 350;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('PaymentReceiptofHDFCChallan_Fee.pdf'); // Generated PDF  
    }); 


//     var margin = {
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0
//     };
//     const doc = new jsPDF();
//     const specialElementHandlers = {
//       '#editor': function (element, renderer) {
//         return true;
//       }
//     };
//     const pdfasTableHDFCChallan = this.pdfasTableHDFCChallan.nativeElement;
//     doc.fromHTML(pdfasTableHDFCChallan.innerHTML, 15, 15, {
//       width: 190,
//       'elementHandlers': specialElementHandlers
//     },
// 	function(bla){doc.save('PaymentReceiptofHDFCChallan_Fee.pdf');},
// margin);
    
  }

  // public downloadPDFHDFCChallanfgf () {
  //   const doc = new jsPDF();

  //   const specialElementHandlers = {
  //     '#editor': function (element, renderer) {
  //       return true;
  //     }
  //   };

  //   const content = this.pdfasTableHDFCChallan.nativeElement;

  //   doc.fromHTML(content.innerHTML, 0, 0, {
  //     'width': 100, // max width of content on PDF
  //     'elementHandlers': specialElementHandlers
  //   },
  //     function (bla) { doc.save('saveInCallback.pdf'); },
  //     0);
  // }
  public downloadPDFHDFC() {
//     var margin = {
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0
//     };
//     const doc = new jsPDF();
//     const specialElementHandlers = {
//       '#editor': function (element, renderer) {
//         return true;
//       }
//     };
//     const pdfasTableHDFC = this.pdfasTableHDFC.nativeElement;
//     doc.fromHTML(pdfasTableHDFC.innerHTML, 15, 15, {
//       width: 190,
//       'elementHandlers': specialElementHandlers
//     },
// 	function(bla){doc.save('PaymentReceiptofJEE_Fee.pdf');},
// margin);
var data = document.getElementById('pdfasTableHDFC');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  

      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('PaymentReceiptofIITR_Fee.pdf'); // Generated PDF  
    });  
    
  }
  public downloadPDF() {

    var data = document.getElementById('pdfasTable');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  

      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('PaymentReceiptofIITR_Fee.pdf'); // Generated PDF  
    }); 
  }
  public downloadPDFHDFC11() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTableHDFC = this.pdfasTableHDFC.nativeElement;
    doc.fromHTML(pdfasTableHDFC.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceiptofNewEntrants_Fee.pdf');
  }

  onPrint(){
    window.print();
}
GobackCompline()
{
  this.IsStudentCompline=false;
}
StudentCompline()
{
  this.IsStudentCompline=true;
}
  PayFees()
  {
    this.submitted = true;
      let parameter = "iitr_ref_no=34344333|amount=1";
      this.sellerService.ChecksumOfExistingStudentsPay(this.MBAFeesForm.value).subscribe((data:any)=>{
     console.log('sbiencdata',data.data.data)
     this.encdata=data.data.data;
    //  console.log(this.merchant_code,this.encdata);
     if(data.data.data.amountStatus!="Success")
     {
     setTimeout(() => {
      this.formsbi.nativeElement.submit()
    }, 1000);
    }
    else
    {
      this.toastr.info(data.data.message)
    }
    },
    error => {
      console.log(error)
      }
    );
  }


  PayFeesHDFC()
  {
    this.sellerService.ResponseHandlerOfHDFCExistingStudents(this.MBAFeesForm.value).subscribe((data:any)=>{
    console.log('---------------------', data.data.data)
    this.encRequest = data.data.data; 
    console.log(this.accessCode,this.encRequest);
     if(data.data.data.amountStatus!="Success")
     {
    setTimeout(() => {
      this.form.nativeElement.submit()
    }, 1000);
  }
  else
  {
    this.toastr.info(data.data.message)
  }
    }, error => {
    console.log(error)
    }
    );
  }
  checkamountstatus()
  {
    this.IsAmountStatus=true;
  }
CheckhdfcChallan()
{
  
  this.IsHDFCChallan=true;
  this.users= this.CATDetailList;
}
  Reset()
  {
    this.MBAFeesForm.reset();
  }
  Goback() {
    $('.abc').removeClass('addremovecss');
     window.location.href='https://iitrpaydemo.azurewebsites.net/ExistingStudents';
    this.IsresponseSBI=false;
    this.IsresponseHDFC=false;
  }
  StatusGoback()
  {
    this.IsAmountStatus=false;
  }
  HDFCGoBack()
  {
    this.IsHDFCChallan=false;
  }
  btnClick()
  {
      this.router.navigate(['https://www.iitr.ac.in/Main/pages/jee2020.html']);
  
  }

  HDFCChallan()
  {
  this.IsSure=true
  }
  
  submitcompline()
  {
    this.spinner.show();
    this.sellerService.StudentCompline(this.StudentComplineForm.value).subscribe((data:any)=>{
      this.spinner.hide();
      this.IsStudentCompline=false;
      this.toastr.success(data.message);
    }, error => {
      console.log(error)
      });
  }

  HDFCChallanyes()
  {
    this.spinner.show();
    this.IsSure=false;
    this.sellerService.HDFCChallanVerifcation(this.MBAFeesForm.value).subscribe((data:any)=>{
    this.spinner.hide();
    console.log('---------------------', data.data.data)
    this.IsHDFCChallanOpen=true;
    this.virtualaccountno= data.data.message.toUpperCase();
    this.RemitterName=data.data.data.name;
    this.totalamount=data.data.data.totalFees;
    this.IFSCCode="HDFC0004989";
    this.BeneficiaryBank="HDFC BANK LTD";
    this.BankBranch="Kanjurmarg Branch, Mumbai";
    this.department=data.data.data.department;
    this.stMob=data.data.data.stMob;
    this.class=data.data.data.class;
    this.enrollmentno=data.data.data.enrollmentno;
    let latest_date =(data.data.data.enddate);
    let str = latest_date.split(" ")
   // this.datepipe.transform(data.data.data.enddate, 'yyyy-MM-dd');
    this.EndDate= str[0];
    

    }, error => {
    console.log(error)
    }
    );
  }

//   HDFCChallanCashDD()
//   {
//   this.IsHDFCcashDD=true;
//   this.users= this.CATDetailList;
// }
SureClose()
{
  this.IsSure=false;
}
IsStopclose()
{
  this.IsStop=false;
}

public openPDF():void {
  let DATA = this.htmlData.nativeElement;
  let doc = new jsPDF('p','pt', 'a4');
  doc.fromHTML(DATA.innerHTML,15,15);
  doc.output('dataurlnewwindow');
}

public downloadPDFF():void {
  let DATA = this.htmlData.nativeElement;
  let doc = new jsPDF('p','pt', 'a4');

  let handleElement = {
    '#editor':function(element,renderer){
      return true;
    }
  };
  doc.fromHTML(DATA.innerHTML,15,15,{
    'width': 200,
    'elementHandlers': handleElement
  });
  doc.save('angular-demo.pdf');
}
  }


import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SellerService } from '../Services/Seller/seller.service';
//import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';  
import { DatePipe } from '@angular/common';
declare var jsPDF: any;

@Component({
  selector: 'app-genratereceipt',
  templateUrl: './genratereceipt.component.html',
  styleUrls: ['./genratereceipt.component.scss']
})
export class GenratereceiptComponent implements OnInit {
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('form', {static: false}) form: ElementRef;
  ThroughList: string[] = ['Cash','Cheque', 'Draft', 'NEFT'];
  GenrateReceiptForm:FormGroup;
  GenrateReceiptFormSubmit:boolean=false;
  constructor(private datePipe: DatePipe,private http: HttpClient,private sellerService:SellerService, private toastr: ToastrService,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService) 
  {
    this.GenrateReceiptForm=this.formBuilder.group({
      PanNo:[``, Validators.required],
      BookNo:[``, Validators.required],
      ReceiptNo:[``, Validators.required],
      Datee:[``, Validators.required],
      ReceivedFrom:[``, Validators.required],
      Asumofwords:[``, Validators.required],
      through:[``, Validators.required],
      No:[``, Validators.required],
      dated:[``, Validators.required],
      amount:[``, Validators.required],
     });
   }

  ngOnInit() {
  }
  SubmitData()
  {
    this.spinner.show();
    const data =
    {
      'PanNo' : this.GenrateReceiptForm.controls['PanNo'].value,
      'BookNo':this.GenrateReceiptForm.controls['BookNo'].value,
      'ReceiptNo' : this.GenrateReceiptForm.controls['ReceiptNo'].value,
      'Datee' : this.GenrateReceiptForm.controls['Datee'].value,
      'ReceivedFrom' :this.GenrateReceiptForm.controls['ReceivedFrom'].value,
      'Asumofwords':this.GenrateReceiptForm.controls['Asumofwords'].value,
      'through':this.GenrateReceiptForm.controls['through'].value,
      'No' :this.GenrateReceiptForm.controls['No'].value,
      'dated':this.GenrateReceiptForm.controls['dated'].value,
      'amount':this.GenrateReceiptForm.controls['amount'].value,
    }
    this.sellerService.SaveGenerateReceiptdata(data).subscribe((data:any)=>{
      this.spinner.hide();
      //this.IsStudentCompline=false;
      this.toastr.success(data.message);
    }, error => {
      console.log(error)
      });
    this.GenrateReceiptForm.reset();
    // this.toastr.success("Data submit sccessfully ");
  
  }
}

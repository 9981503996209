import { Component, ɵɵresolveBody } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'

@Component({
  selector: 'app-ebay-order-list',
  templateUrl: './ebay-order-list.component.html',
  styleUrls: ['./ebay-order-list.component.scss']
})
export class EbayOrderListComponent {
  constructor(private _http:HttpClient) { }

  ngOnInit() {
    // var myXMLText4 = '<?xml version="1.0" encoding="utf-8"?><GetOrdersRequest xmlns="urn:ebay:apis:eBLBaseComponents"><RequesterCredentials><eBayAuthToken>AgAAAA**AQAAAA**aAAAAA**US0QWQ**nY+sHZ2PrBmdj6wVnY+sEZ2PrA2dj6AHmIGkDpCCpgmdj6x9nY+seQ**BrgDAA**AAMAAA**6UEFDwEXSEiIBWoV24YN4eGmHX6kxAeSM9tMaZjugHxZA5jwdvFoK2iArDnvSfajXs+qikQPTOrT2xlcLSLzxx9yeLOb7INULtH7hsdoqzxt469EJAdwa/HBfFogNIhB2N6mEi80noW+rrlwYuof4aMcSkw2omVoqxeu0jwCRU4E8pq1+C+Uq/MHR/r9PPQf1OK/JEsCJH75JTNm8ohxEiJlthxpOpUWIZlTTgnfD63CJXK4Gv/23QnkVVFcrU/dGpNE0RN6nnTAWz2z9ZS6e0c9JpD8HHl17gJzmpCh6GS4RUG7sivOuHexVykQb8MDemawd7BBmGLp322QV2DUui0TNuDhKMTLXf/uQQhhpOtPR5G5KH83M2nUhngj/J3cMxng/Cx8l7tVRMDpFOK5c24G3RkD0GBv09pXaKD1V0OhmBkx0EnPsf787L9bf1cM6rtEbCohuwrPvrNrlirHdTt6thoFTCJ8oYQRbboHCToUu06S08z6UGBD1pLG+WUdvoWDgpXHZfFGGVvBfmS8gpehk61WT1WmmZOrk/TLoDsNFZ8BfhVu/HIWbqjFChiZ1fvsRESI+WSHGXyrRy+Wbj0Vk/UUdiL5VnnFNtN5lg1wp38C30TwslR7zWPWstgI355mgsRwSM64yfSEOnd30rHoH/D4XBqZY+ZN5OViEbagBKtwz5qFS+C3AMJ0rqPN9kroVkcP3Z7bO9gdWF0RA8e8dXJTXad4OhiRJ//OAkaSr29DMg0tI/HB5oHmic+x</eBayAuthToken></RequesterCredentials><ErrorLanguage>en_US</ErrorLanguage><WarningLevel>High</WarningLevel><CreateTimeFrom>2019-07-027T20:34:44.000Z</CreateTimeFrom><CreateTimeTo>2019-08-15T20:34:44.000Z</CreateTimeTo><OrderRole>Seller</OrderRole><OrderStatus>Active</OrderStatus></GetOrdersRequest>'
    var myXMLText1 = '<?xml version="1.0" encoding="utf-8"?><FetchTokenRequest xmlns="urn:ebay:apis:eBLBaseComponents"><SessionID>iy4EAA**d2ecdc4b15b0a471e5549d44fffffa81</SessionID> </FetchTokenRequest>';
    var url="https://api.sandbox.ebay.com/ws/api.dll";
    const HttpOptions = {
      headers: new HttpHeaders({
        "ContentType": "text/xml",
					"X-EBAY-API-DEV-NAME": "cd798a05-9fde-4e8f-a4a0-1c625028da2b",
					"X-EBAY-API-APP-NAME": "NOC365Lt-AfterCar-SBX-fd8c7c828-258ec2de",
					"X-EBAY-API-CERT-NAME": "SBX-d8c7c828a648-8318-4bd4-b192-2cc6",
					"X-EBAY-API-SITEID": "0",
          "X-EBAY-API-CALL-NAME": "FetchToken",
          "X-EBAY-API-COMPATIBILITY-LEVEL": "757",
          "Access-Control-Allow-Origin":"*",
          "Access-Control-Allow-Credentials":"true",
          "Access-Control-allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
         'Access-Control-Allow-Headers': 'Content-Type',
         'Access-Control-Allow-Methods': 'GET'
       }),body:myXMLText1
  }
this._http.get(url,HttpOptions).subscribe(data=>{
  var seller_id = "testuser_sellerseller123";
})
  }
}

import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { param } from 'jquery';
import { BehaviorSubject } from 'rxjs';
import { FormArray } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class SellerService {
  public isLoading = new BehaviorSubject(false);

  // this.tracking_id = params['tracking_id'];
  // this.bank_ref_no = params['bank_ref_no'];
  // this.order_status = params['order_status'];
  // this.amount = params['amount'];
  // this.billing_name = params['billing_name'];

  SaveResponseData(responsedetails: any) {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/SavebillingResponse',
      {  tracking_id: responsedetails.tracking_id,
        bank_ref_no: responsedetails.bank_ref_no,
        order_status:responsedetails.order_status,
        amount:responsedetails.amount,
        billing_name:responsedetails.billing_name,
        delivery_address:responsedetails.delivery_address,
        merchant_param2: responsedetails.merchant_param2}, HttpOptions);
  }
  
  changeQuestionnaireForProduct(isRemove: boolean, itemId: any, questionId: any) {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    return this.httpClient.post(environment.BaseUrl + '/api/Questionnaires/changeQuestionnaireForProduct',
      { 'ProductId': itemId, 'QuestionnaireName': questionId, 'IsRemove': isRemove }, HttpOptions);
  }

  AddQuestionForProduct(itemId: any, questionId: any) {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    return this.httpClient.post(environment.BaseUrl + '/api/Questionnaires/AddQuestionForProduct',
      { 'ProductId': itemId, 'QuestionId': questionId }, HttpOptions);
  }
  public headers: Headers;
  constructor(private httpClient: HttpClient, private route: Router) { }
  getSellerList(UserName: any, Enrollment:any) {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/ListOfStudents?UserName=${UserName}&&Enrollment=${Enrollment}`);
  }

  getStudentReportList()
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/ListOfStudentDetails`)
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  getOrderList(UserName: any) {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetOrderListOfTopSix?UserName=${UserName}`);
  }
  eBayCount(UserName: any) {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetEbayOrderListcount?UserName=${UserName}`);
  }
  OrderCountwithResponse(UserName: any) {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetTotalOrdersWithResponse?UserName=${UserName}`);
  }
  OrderCountwithoutResponse(UserName: any) {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetTotalOrdersWithoutResponse?UserName=${UserName}`);
  }
  countofproduct(Productlist: any) {
    return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/CountOfProduct', { 
      'SelletId': window.sessionStorage.getItem('UserName'),
      'userRole': window.localStorage.getItem('userRole')
    });
  }

  getProductList(fiterValue: any) {
    return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ListOfProductsFromDatabase', {
      'categoryName': fiterValue,
      'TagName': '',
      'SelletId': window.sessionStorage.getItem('UserName'),
      'userRole': window.localStorage.getItem('userRole'),
    });
  }
  ListOfQuestionnaires() {
    return this.httpClient.post(environment.BaseUrl + '/api/Questionnaires/ListOfQuestionnaires', {});
  }
  DeleteSeller(formData: any) 
  {
    debugger;
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    return this.httpClient.post(environment.BaseUrl + `api/EbaySeller/DeleteSeller`, {
      id: formData.id,
      UserName: window.sessionStorage.getItem('UserName'),
    }, HttpOptions);
  }

IsAllowStudentData(formData: any)
{ 
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
    })
  }
  return this.httpClient.post(environment.BaseUrl + `api/EbaySeller/IsAllowStudent`, {
    Id:formData.sellerId,
    IsAllow: formData.yesnooption,
    EndDate:formData.enddate
  }, HttpOptions);
}

  UpdateSellers(formData: any)   {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    return this.httpClient.post(environment.BaseUrl + `api/EbaySeller/UpdateStudentFees`, {
      Id:formData.id,
      Enrollmentno: formData.enrollmentno,
      Name:  formData.name,
      Course:  formData.course,
      Department: formData.department,
      Category: formData.category,
      Status_Year: formData.status_Year,
      D_ND:formData.d_ND,
      InsituteFee:formData.insituteFee,
      Accommodation_Chag:formData.accommodation_Chag,
      Electricity_Chag:formData.electricity_Chag,
      MessEsttb_Chag:formData.messEsttb_Chag,
      semesterCharg:formData.semesterCharg,
      MessFee:formData.messFee,
      OtherFee:formData.otherFee,
      TotalFees:formData.totalFees,
      parameter3:window.sessionStorage.getItem('Email'),
      modeOfPayment:formData.modeOfPayment,
      p_type:formData.p_type,
      amountStatus:formData.amountStatus,
      description:formData.description,
      paidAmount:formData.paidAmount,
      transactiontime_date:formData.transactiontime_date,
    }, HttpOptions);
  }

  
  setupemail(formData: any)   {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    return this.httpClient.post(environment.BaseUrl + `api/EbaySeller/SEndMailForIssue`, {
      email: window.sessionStorage.getItem('Email'),
      subject: formData.subject,
      body: formData.body
    }, HttpOptions);
  }

ResponseHANDLER2(response:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ResponseHandlerCCavenue', {response},HttpOptions);}
// 'order_id': request.order_id,
// 'tracking_id': request.tracking_id,
//     'bank_ref_no' : request.bank_ref_no,
//     'order_status' : request.order_status,
//     'failure_message': request.failure_message,
//     'payment_mode' : request.payment_mode,
//     'card_name' : request.card_name,
//     'status_code' : request.status_code,
//    'status_message' : request.status_message,
//    'currency' : request.currency,
//     'amount' : request.amount,
//     'billing_name' : request.billing_name,
//     'billing_address' : request.billing_address,
//     'billing_city' : request.billing_city,
//     'billing_state': request.billing_state,
//     'billing_zip'  : request.billing_zip,
//     'billing_country' : request.billing_country,
//     'billing_tel' : request.billing_tel,
//     'billing_email' : request.billing_email,
//     'delivery_name' : request.delivery_name,
//     'delivery_address' : request.delivery_address,
//     'delivery_city' : request.delivery_city,
//     'delivery_state' : request.delivery_state,
//     'delivery_zip' : request.delivery_zip,
//     'delivery_country' : request.delivery_country,
//     'delivery_tel' : request.delivery_tel,
//     'merchant_param1' : request.merchant_param1,
//     'merchant_param2' : request.merchant_param2,
//    'merchant_param3' : request.merchant_param3,
//     'merchant_param4' : request.merchant_param4,
//     'merchant_param5' : request.merchant_param5,
//     'vault' : request.vault,
//    'offer_type' : request.offer_type,
//     'offer_code' : request.offer_code,
//     'discount_value' : request.discount_value,
//     'mer_amount': request.mer_amount,
//     'eci_value' : request.eci_value,
//     'retry' : request.retry,
//     'response_code' : request.response_code,
//     'billing_notes' : request.billing_notes,
//     'trans_date' : request.trans_date,
//     'bin_country' : request.bin_country,
Checksumresponse(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumResponseHandler?', {
  'merchant_code':'IIT_RK',
  'encdata':'cxzczxc',
  'access_code': request.access_code,
  'amount': request.amount,
  'billing_address': request.billing_address,
  'billing_city':request.billing_city,
  'billing_country':request.billing_country,
  'billing_email': request.billing_email,
  'billing_name': request.billing_name,
  'billing_state':request.billing_state,
  'billing_tel':request.billing_tel,
  'billing_zip': request.billing_zip,
  'cancel_url':request.cancel_url,
  'Enrollmentno':request.Enrollmentno,

},HttpOptions);
}
ChecksumOfOpenFees(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfOtherFEES?', {
  'merchant_code':'IIT_RK',
  'encdata':'cxzczxc',
  'Name': request.Name,
  'Department': request.Department,
  'Course': request.Course,
  'Year':request.Year,
  'Aadhar_No':request.Aadhar_No,
  'DepositeAmountFor': request.DepositeAmountFor,
  'MobileNo': request.MobileNo,
  'Email':request.Email,
  'OtherFeeAmount':request.OtherFeeAmount,
  'Remark': request.Remark,
  'Enrollmentno':request.Enrollmentno,
},HttpOptions);
}

ChecksumOfOtherFEES(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfOtherFEES?', {
  'merchant_code':'IIT_RK',
  'encdata':'cxzczxc',
  'Name': request.Name,
  'Department': request.Department,
  'Course': request.Course,
  'Year':request.Year,
  'Aadhar_No':request.Aadhar_No,
  'DepositeAmountFor': request.DepositeAmountFor,
  'MobileNo': request.MobileNo,
  'Email':request.Email,
  'OtherFeeAmount':request.OtherFeeAmount,
  'Remark': request.Remark,
  'Enrollmentno':request.Enrollmentno,
},HttpOptions);
}


  ResponseHandler(request:any){
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ResponseHandler?', {
     'access_code': request.access_code,
     'TotalFees': request.amount,
     'billing_address': request.billing_address,
     'billing_city':request.billing_city,
     'billing_country':request.billing_country,
'billing_email': request.billing_email,
'billing_name': request.billing_name,
'billing_state':request.billing_state,
'billing_tel':request.billing_tel,
'billing_zip': request.billing_zip,
'cancel_url':request.cancel_url,
'currency': request.currency,
'delivery_address': request.delivery_address,
'delivery_city':request.delivery_city,
'delivery_country':request.delivery_country,
'delivery_name': request.delivery_name,
'delivery_state': request.delivery_state,
'delivery_tel': request.delivery_tel,
'delivery_zip': request.delivery_zip,
'encRequest': request.encRequest,
'language': request.language,
'merchant_id': request.merchant_id,
'order_id': request.order_id,
'redirect_url': request.redirect_url,
'Enrollmentno':request.Enrollmentno,
'userRole':request.userRole
    },HttpOptions);
  }
 
  setupseller(sellerdetails: any) {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/Setupsellertoken?', {
      'UserEmail': window.sessionStorage.getItem('Email'),
      'UserName': window.sessionStorage.getItem('UserName'),
      'SellerEmail': sellerdetails.SellerEmail,
      'Userid': sellerdetails.Userid,
      'Token': sellerdetails.Token,
      'FirstName': sellerdetails.FirstName,
      'Lastname': sellerdetails.Lastname
    }, HttpOptions);
  }

  showQuestions(ProductId: any) {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    let param = new HttpParams();
    // Begin assigning parameters
    param = param.append('ProductId', ProductId);

    return this.httpClient.get(environment.BaseUrl + '/api/Questionnaires/GetQuestionByProductId', {
      params: param
    });
  }

  saveQuestionariesResponce(array: any) {
    return this.httpClient.post(environment.BaseUrl + '/api/Questionnaires/SaveAnswers', array);
  }
  SaveStudentAdmissionDetails(formData: any) {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    //GetSubscriptionMaster() { return this.http.get(this.API_ENDPOINT + '/api/SubscriptionMaster/ListOfSubscriptionsMaster') }
    //BaseUrl
    // return this.httpClient.post(`https://localhost:44349/api/users/Signup`,formData,HttpOptions);
    return this.httpClient.post(environment.BaseUrl + `api/StudentRegistration/SaveAddmistiondetails`, formData, HttpOptions);
  }
  UpdateAcadData(formData: any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.BaseUrl + `api/StudentRegistration/UpdateAddmistiondetails`, formData, HttpOptions);
  }

  // OrderCountwithResponse(UserName: any) {
  //   debugger;
  //   return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetTotalOrdersWithResponse?UserName=${UserName}`);
  // }
  getStudentadmissiolist(UserName: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/ListOfStudent?UserName=${UserName}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  getNewEntrantslist(UserName: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/ListOfNewEnterance?UserName=${UserName}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  getCCBlist(UserName: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/ListOfCCB?UserName=${UserName}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  getFinancelist(UserName: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/ListOfFinance?UserName=${UserName}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  getNonTeachinglist(UserName: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/ListOfNonTeaching?UserName=${UserName}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  ListOfMedicalContribution(UserName: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/ListOfMedicalContribution?UserName=${UserName}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  getLibrarylist(UserName: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/Librarylist?UserName=${UserName}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  GetCATNOs() {
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/GetCATNOs');
  }
  ListExpenditure()
  {
    return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListExpenditure');
  }

  getCatDeatilaAccReGNo(Id: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/getCatDeatilaAccReGNo?Id=${Id}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  getCatDeatilasofExixtingStudent(Id: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/getCatDeatilasofExixtingStudent?Id=${Id}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  
  StudentStatusgetCatDeatilasofExixtingStudent(Id: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/StudentStatusgetCatDeatilasofExixtingStudent?Id=${Id}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  
 
  getExcelCode(Id: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/getExcelCode?Id=${Id}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }

  GetstudentFeesData(Id: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/GetStudentFeesData?Id=${Id}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }

  GetstudentFeesIITRRefrence(Id: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/GetStudentFeesDataIITR?Id=${Id}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  
  
  GetEnrollmentNoDetails(Id: any)
  {
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/getCatDeatilasofExixtingStudent?Id=${Id}`);
  }
  getStudentRegistrationDeatilas(Id: any)
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/getStudentRegistrationDeatilas?Id=${Id}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  // getCatDeatilaAccReGNo(Id:any){
  //   let param = new HttpParams();
    
  //   // Begin assigning parameters
  //   param = param.append('CATId', Id);
    
  //   return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/getCatDeatilaAccReGNo`
  //   ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
  //   'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
  //   }

  showProuctQuestions(ProductId: any) {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    let param = new HttpParams();
    // Begin assigning parameters
    param = param.append('ProductId', ProductId);

    return this.httpClient.get(environment.BaseUrl + '/api/Questionnaires/GetProductQues', {
      params: param
    });
  }
  
  ChecksumOfExistingStudentsPay(request:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfExistingStudentsPay?', {
    'merchant_code':'IIT_RK',
    'encdata':'cxzczxc',
    'Name': request.Name,
    'Enrollmentno': request.Enrollmentno,
    'Category': request.Category,
    'Class':request.Class,
    'Course':request.Course,
  'D_ND': request.D_ND,
  'Department': request.Department,
  'InsituteFee':request.InsituteFee,
  'MessFee':request.MessFee,
  'OtherFee': request.OtherFee,
  'Semester':request.Semester,
  // 'Stu_Type':request.Stu_Type,
  'TotalFees':request.TotalFees,
  'currency':request.currency,
  'Stu_Type': window.localStorage.getItem('userRole'),
  'stMob':request.stMob,
  'stEmail':request.stEmail,
  'StName':request.StName,
  'stMediFee':request.stMediFee,
  'accommodation_Chag':request.accommodation_Chag,
  'electricity_Chag':request.electricity_Chag,
  'messEsttb_Chag':request.messEsttb_Chag,
  'semesterCharg':request.semesterCharg,
  },HttpOptions);
  }

//MBA Fees Collection
  PayMBAFees(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfMBAFEES?', {
  'merchant_code':'IIT_RK',
  'encdata':'cxzczxc',
  'catRegistrationNo': request.catRegistrationNo,
  'applicationSeqNo': request.applicationSeqNo,
  'applicantName': request.applicantName,
  'applicantCategory':request.applicantCategory,
  'course':request.course,
'department': request.department,
'year': request.year,
'instituteFee':request.instituteFee,
'mobileNo':request.mobileNo,
'emailId': request.emailId,
'aadharCardNo':request.aadharCardNo,
'bankAccountNo':request.bankAccountNo,
'bankIFSCCode':request.bankIFSCCode,
},HttpOptions);
}
PayDOSWFees(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfDOSWDonetionFEES?', {
  'merchant_code':'IIT_RK',
  'encdata':'cxzczxc',
  'Name': request.Name,
  'Amount':request.Amount,
  'remarks':request.remarks,
  'Parameter3':request.remarks,
  'EnrollmentNo':request.EnrollmentNo,
  'p_type':'DOSW-Fee'
},HttpOptions);
}


NewEnteranceFeesCollection(request:any)
{
const HttpOptions={
  headers:new HttpHeaders({
    'Content-Type':'application/json'
  })
}
return this.httpClient.post(environment.BaseUrl+'/api/StudentRegistration/ChecksumVLSIMTECH',{
  'merchant_code':'IIT_RK',
  'encdata':'cxzczxc',
  'certificateType':request.certificateType,
  'prefix':request.prefix,
  'applicationid': request.applicationid,
  'fatherName':request.fatherName,
  'mobileno':request.mobileno,
  'name': request.name,
  'aadharno':request.aadharno,
  'category':request.category,
  'programme':request.programme,
  'bankname':request.bankname,
  'bankholdername':request.bankholdername,
  'ifsccode':request.ifsccode,
  'amount':request.amount,
  'class': request.class,
  'department': request.department,
  'year':request.year,
  'fathername': request.fathername,
  'mothername': request.mothername,
  'bhawannumber': request.bhawannumber,
  'roomnumber': request.roomnumber,
  'mobilenumber': request.mobilenumber,
  'gmailid': request.gmailid,
  'coursestartdate':request.coursestartdate,
  'courseenddate': request.courseenddate,
  'iitrid': request.iitrid,
  'paymentreceipt': request.paymentreceipt,
  'degreescancopy': request.degreescancopy,
  'mailid':request.mailid,
  'accountno':request.accountno},
  HttpOptions);}







Non_TeachingFeesViaHDFC(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfNonTeachingHDFC?', {
    'merchant_code':'IIT_RK',
    'encdata':'cxzczxc',
    'AdvtNo':request.AdvtNo,
    'PostNo':request.PostNo,
    'PostName':request.PostName,
    'Username':request.Username,
    'Name':request.Name,
    'E_mailID':request.E_mailID,
    'MobileNo':request.MobileNo,
    'AadharNo':request.AadharNo,
    'TotalFee':request.TotalFee,
    'p_type':'Non-Teaching-Fee',
    'checkSums':'RecruitmentCell2021',},HttpOptions);}
    
PayDOSWFeesViaHDFC(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfDOSWFEESHDFC?', {
    'merchant_code':'IIT_RK',
    'encdata':'cxzczxc',
    'Name': request.Name,
    'Amount':request.Amount,
    'remarks':request.remarks,
    'EnrollmentNo':request.EnrollmentNo,
    'Parameter3':request.Parameter3,
    'purpose':request.purpose,
    'p_type':'DOSW-Fee'
  },HttpOptions);
}


PayCCBFees(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfCCBFEES?', {
  'merchant_code':'IIT_RK',
  'encdata':'cxzczxc',
  'Name': request.Name,
  'Amount':request.Amount,
  'remarks':request.remarks,
  'EnrollmentNo':request.EnrollmentNo,
},HttpOptions);
}

PayCCBFeesViaHDFC(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfCCBFEESHDFC?', {
    'merchant_code':'IIT_RK',
    'encdata':'cxzczxc',
    'Name': request.Name,
    'Amount':request.Amount,
    'remarks':request.remarks,
    'EnrollmentNo':request.EnrollmentNo,
  },HttpOptions);
}

PayLibraryFees(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfLibraryFEES?', {
  'merchant_code':'IIT_RK',
  'encdata':'cxzczxc',
  'Name': request.Name,
  'Amount':request.Amount,
  'remarks':request.remarks,
  'EnrollmentNo':request.EnrollmentNo,
},HttpOptions);
}
PayFinanceFees(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ChecksumOfFinanceFEES?', {
  'merchant_code':'IIT_RK',
  'encdata':'cxzczxc',
  'Name': request.Name,
  'Amount':request.Amount,
  'remarks':request.remarks,
  'EnrollmentNo':request.EnrollmentNo,
},HttpOptions);
}

getDataForStatus(sellerUserId:any){
  let param = new HttpParams();
  // Begin assigning parameters
  param = param.append('sellerUserId', sellerUserId);
  return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetDataFormStatus`
  ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
  }
  getDataofNewEnteranceReport(sellerUserId:any){
    let param = new HttpParams();
    // Begin assigning parameters
    param = param.append('sellerUserId', sellerUserId);
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/PHDNewEnteranceReport`
    ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
    }
  getDataofCourse(sellerUserId:any)
  {
    let param = new HttpParams();
    // Begin assigning parameters
    param = param.append('sellerUserId', sellerUserId);
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetDataOfCourse`
    ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
    }

  
  getStudentFeesAmountStatus(sellerUserId:any){
    let param = new HttpParams();
    // Begin assigning parameters
    param = param.append('sellerUserId', sellerUserId);
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getStudentFeesAmountStatus`
    ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
    }

    getStudentSemester(sellerUserId:any){
      let param = new HttpParams();
      // Begin assigning parameters
      param = param.append('sellerUserId', sellerUserId);
      return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getStudentSemester`
      ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
      }

      getStudentCourse(sellerUserId:any){
        let param = new HttpParams();
        // Begin assigning parameters
        param = param.append('sellerUserId', sellerUserId);
        return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getStudentCourse`
        ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
        }

        GetRemarkList(sellerUserId:any){
          let param = new HttpParams();
          // Begin assigning parameters
          param = param.append('sellerUserId', sellerUserId);
          return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetRemarkList`
          ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
          'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
          }

        getExcelDescription(sellerUserId:any){
          let param = new HttpParams();
         
          // Begin assigning parameters
          param = param.append('sellerUserId', sellerUserId);
          
          return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getExceldescription`
          ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
          'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
          }
          getExcelCodeData(sellerUserId:any){
            let param = new HttpParams();
            
            // Begin assigning parameters
            param = param.append('sellerUserId', sellerUserId);
            
            return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getExcelCodeData`
            ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
            'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
            }
  
  getCCBDataAmountStatus(sellerUserId:any){
    let param = new HttpParams();
   
    // Begin assigning parameters
    param = param.append('sellerUserId', sellerUserId);
    
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getCCBDataAmountStatus`
    ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
    }

    // RefreshCCBDataAmountStatus(sellerUserId:any){
    //   let param = new HttpParams();
    //   debugger;
    //   // Begin assigning parameters
    //   param = param.append('sellerUserId', sellerUserId);

    //   return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshCCBDataAmountStatus`
    //   ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
    //   'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
    //   }


      RefreshCCBDataAmountStatus(ccbId: any)
  {
    
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshCCBDataAmountStatus?ccbId=${ccbId}`);
  }
  RefreshLibraryDataAmountStatus(libraryId: any)
  {
    
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshLibraryDataAmountStatus?libraryId=${libraryId}`);
  }
  RefreshFinanceDataAmountStatus(financeId: any)
  {
   
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshFinanceDataAmountStatus?financeId=${financeId}`);
  }

  RefreshNonTeachingDataAmountStatus(id: any)
  {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshNonTeachingDataAmountStatus?id=${id}`);
  }
  RefreshMedicalDataAmountStatus(id: any)
  {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshMedicalDataAmountStatus?id=${id}`);
  }

  VLSIMTECHrefrecneDataAmountStatus(id: any)
  {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshMtechVLSIDataAmountStatus?id=${id}`);
  }
  searchDetail(DataSearch: string)
  {    
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/SearchMTECHVLSI?DataSearch=${DataSearch}`);
  }
  RefreshStudentDetailsDataAmountStatus(stuId: any)
  {
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/RefreshStudentDetailsDataAmountStatus?stuId=${stuId}`);
  }
  StudentPortalRefresh(stuId: any)
  {
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/StudentPortalRefresh?stuId=${stuId}`);
  }



    getLibraryDataAmountStatus(sellerUserId:any){
      let param = new HttpParams();
      // Begin assigning parameters
      param = param.append('sellerUserId', sellerUserId);
      return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getLibraryDataAmountStatus`
      ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
      'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
      }

      getFinanceDataAmountStatus(sellerUserId:any,Username:any)
      {
        let param = new HttpParams();
        // Begin assigning parameters
        param = param.append('sellerUserId', sellerUserId);
        param=param.append('Username',Username);
        return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getFinanceDataAmountStatus`
        ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
        }


        
      GetDataForPayments(sellerUserId:any)
      {
        let param = new HttpParams();
        // Begin assigning parameters
        param = param.append('sellerUserId', sellerUserId);
        return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetDataForPayments`
        ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
        }


        getMedicalDataAmountStatus(sellerUserId:any,Username:any)
        {
          let param = new HttpParams();
          // Begin assigning parameters
          param = param.append('sellerUserId', sellerUserId);
          param=param.append('Username',Username);
          
          return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getMedicalDataAmountStatus`
          ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
          'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
          }


GetFilterDateData(startDate: string,endDate:string) 
  {
    let  headers= new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
      let param = new HttpParams();
      param = param.append('startDate', startDate);
      param = param.append('endDate', endDate);
      return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetFilterDataOfDate`
      ,{params:param,headers:headers})
    }
    GetFilterDataOfDatePHD(startDate: string,endDate:string) 
  {
    let  headers= new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
      let param = new HttpParams();
      param = param.append('startDate', startDate);
      param = param.append('endDate', endDate);
      return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetFilterDataOfDatePHD`
      ,{params:param,headers:headers})
    }
    GetFilterDateDataStudentFees(startDate: string,endDate:string) 
  {
    let  headers= new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
      let param = new HttpParams();
      param = param.append('startDate', startDate);
      param = param.append('endDate', endDate);
      return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetFilterDataOfDateStudentFees`
      ,{params:param,headers:headers})
    }
  
    ResponseHandlerHDFC(request:any){
      const HttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ResponseHandlerHDFC?', {
      'access_code': request.access_code,
      'instituteFee': request.instituteFee,
      'catRegistrationNo': "",
      'applicationSeqNo': request.applicationSeqNo,
      'applicantName': request.applicantName,
      'applicantCategory':request.applicantCategory,
     'course':request.course,
     'department': request.department,
     'year': "2021",
     'mobileNo':request.mobileNo,
     'emailId': request.emailId,
     'aadharCardNo':request.aadharCardNo,
     'bankAccountNo':request.bankAccountNo,
     'bankIFSCCode':request.bankIFSCCode,
     'noofdepartments':request.noofdepartments
      },HttpOptions);
    }
    HDFCChallanVerifcation(request:any)
    {
      const HttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/HDFCChallanVerification?', {
        'access_code': request.access_code,
        'Name': request.Name,
        'Enrollmentno': request.Enrollmentno,
        'Category': request.Category,
        'Class':request.Class,
        'Course':request.Course,
        'D_ND': request.D_ND,
        'Department': request.Department,
        'InsituteFee':request.InsituteFee,
        'MessFee':request.MessFee,
        'OtherFee': request.OtherFee,
        'Semester':request.Semester,
        'TotalFees':request.TotalFees,
        'currency':request.currency,
        'Stu_Type': window.localStorage.getItem('userRole'),
        'stMob':request.stMob,
        'stEmail':request.stEmail,
        'StName':request.StName,
        'stMediFee':request.stMediFee,
        'Id':request.id,
        'stReg':request.stReg,
        'semesterCharg':request.semesterCharg,
    },HttpOptions);

    }

    MedicalContributionHDFCBank(request:any){
      const HttpOptions = 
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }

      return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/MedicalContributionHDFCBank?', 
      {
       'access_code': request.access_code,
       'Name': request.Name,
       'Category': request.Category,
       'CategoryNo': request.CategoryNo,
       'pan':request.pan,
       'mobileno':request.mobileno,
       'email': request.email,
       'ContributionNameorPurpuse': request.ContributionNameorPurpuse,
       'amount':request.amount,
       'Remarks':request.Remarks,
       'currency': "INR",
       },HttpOptions);
    }

    ResponseHandlerOfHDFCExistingStudents(request:any){
      const HttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ResponseHandlerOfHDFCExistingStudents?', 
      {
       'access_code': request.access_code,
       'Name': request.Name,
       'Enrollmentno': request.Enrollmentno,
       'Category': request.Category,
       'Class':request.Class,
       'Course':request.Course,
       'D_ND': request.D_ND,
       'Department': request.Department,
       'InsituteFee':request.InsituteFee,
       'MessFee':request.MessFee,
       'OtherFee': request.OtherFee,
       'Semester':request.Semester,
       //'Stu_Type':request.Stu_Type,
       'TotalFees':request.TotalFees,
       'currency':request.currency,
       'Stu_Type': window.localStorage.getItem('userRole'),
       'stMob':request.stMob,
       'stEmail':request.stEmail,
       'StName':request.StName,
       'stMediFee':request.stMediFee,
       'accommodation_Chag':request.accommodation_Chag,
       'electricity_Chag':request.electricity_Chag,
       'messEsttb_Chag':request.messEsttb_Chag,
       'semesterCharg':request.semesterCharg,
       'Id':request.id,
      },
      HttpOptions);
    }
    ResponseHandlerOfHDFCForStudents(request:any){
      const HttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/ResponseHandlerOfHDFCforStudents?', {
       'access_code': request.access_code,
       'Name': request.name,
       'Enrollmentno': request.enrollmentno,
       'Category': request.category,
       'Class':request.class,
       'Course':request.course,
       'D_ND': request.d_ND,
       'Department': request.department,
       'InsituteFee':request.insituteFee,
       'MessFee':request.messFee,
       'OtherFee': request.otherFee,
       'Semester':request.semester,
        //'Stu_Type':request.Stu_Type,
       'TotalFees':request.totalFees,
       'currency':request.currency,
       'Stu_Type': window.localStorage.getItem('userRole'),
       'stMob':request.stMob,
       'stEmail':request.stEmail,
       'StName':request.stName,
       'stMediFee':request.stMediFee,
       'Id':request.id
    
      },HttpOptions);
    }
    sendTansactonMail(request:any)
    {
      const HttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/sendTansactonMail?', {
        'amountDiff': request.amountDiff,
        'amountStatus': request.amountStatus,
        'bankRefNo': request.bankRefNo,
        'category': request.category,
        'course':request.course,
        'd_ND':request.d_ND,
        'date':request.date,
        'department':request.department,
        'description':request.description,
        'enrollmentno':request.enrollmentno,
        'iitR_Ref_No':request.iitR_Ref_No,
        'insituteFee':request.insituteFee,
        'messFee':request.messFee,
        'name':request.name,
        'otherFee':request.otherFee,
        'p_type':request.p_type,
        'paidAmount':request.paidAmount,
        'remark':request.remark,
        'semester':request.semester,
        'stEmail':request.stEmail,
        'status_Year':request.status_Year,
        'totalFees':request.totalFees,
        'transactiontime_date':request.transactiontime_date,
       },HttpOptions);
    }

    savestudentdata(request:any)
    {
      const HttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/SavePGStudentRegistrationData?', {
        'Parameter1':request.Parameter1,
        'Parameter2':request.Parameter1,
        'Parameter3':request.Parameter3,
        'Parameter4':request.Parameter4,
        'jeeapplicationno'     :request.jeeapplicationno,
        'fullname'                : request.fullname,
        'dob'             :request.dob,
        'departmentname' : request.departmentname,
        'gender'                : request.gender,
        'programme'             : request.programme,
        'category'               : request.category,
        'year'                : request.year,
        'physicallydisabled'   : request.physicallydisabled,
        'semester'     : request.semester,
        'nationality'                : request.nationality,
        'specialization'             : request.specialization,
        'iitrmailid'     : request.iitrmailid,
        'scheme'                : request.scheme,
        'aadharno'             :request.aadharno,
        'bhawan' : request.bhawan,
        'roomno'                : request.roomno,
        'undertaking2'          : request.undertaking2,
        'permanentaddress'      : request.permanentaddress,
        'correspondenceaddress' : request.correspondenceaddress,
        'pincode'               : request.pincode,
        'pincode1'     : request.pincode1,
        'nameofaccountholder'  : request.nameofaccountholder,
        'bankname'             : request.bankname,
        'bankaccountno'     : request.bankaccountno,
        'bankifsccode'      : request.bankifsccode,
        'fathername'             :request.fathername,
        'fdesignation' : request.fdesignation,
        'fathermobile'         : request.fathermobile,
        'fatheremailid'        : request.fatheremailid,
        'undertaking3'         : request.undertaking3,
        'fatherpermentaddress' : request.fatherpermentaddress,
        'fathercorrespondenceaddress': request.fathercorrespondenceaddress,
        'pin'     : request.pin,
        'pincode2'             : request.pincode2,
        'mothername'           : request.mothername,
        'motherdesignation'    : request.motherdesignation,
        'phoneno'              : request.phoneno,
        'motheremailid'        :request.motheremailid,
        'undertaking4' : request.undertaking4,
        'motherpermentaddress' : request.motherpermentaddress,
        'motherpincode'             : request.motherpincode,
        'cpincode'             : request.cpincode,
        'guardianname'         : request.guardianname,
        'guardiandesignation'  : request.guardiandesignation,
        'guardianmobileno'     : request.guardianmobileno,
        'guardianemailid'      : request.guardianemailid,
        'undertaking5'         : request.undertaking5,
        'guardianpermanentaddress' : request.guardianpermanentaddress,
        'guardiancorrespondenceaddress' : request.guardiancorrespondenceaddress,
        'guardianpincode'   : request.guardianpincode,
        'localguardianname' : request.localguardianname,
        'localguardianemailid'     : request.localguardianemailid,
        'localguardianmobileno'    : request.localguardianmobileno,
        'localguardianprementaddress' : request.localguardianprementaddress,
        'localguardianpincode'       : request.localguardianpincode,
        'MSCCategory'               : request.MSCCategory,
        'MBACategory'                : request.MBACategory,
       // 'mcaimportFileJAM2020'               : request.mcaimportFileJAM2020,
        'mcaimportFileJAM2020':request.mcaimportFileJAM2020,
        'mcaimportFile10thMarksheet'     :request.mcaimportFile10thMarksheet,
        'mcaimportFile12thMarksheet'                : request.mcaimportFile12thMarksheet,
        'mcaimportFileUGmarksheets'             : request.mcaimportFileUGmarksheets,
        'mcaimportFilePGmarksheets'             : request.mcaimportFilePGmarksheets,
        'mcaimportFilequalifyingdegree'               : request.mcaimportFilequalifyingdegree,
        'mcaimportFileCategorycertificate'                : request.mcaimportFileCategorycertificate,
        'mcaimportFileAadhar'               : request.mcaimportFileAadhar,
        'mcaimportFilephysicallyhandicapped'     : request.mcaimportFilephysicallyhandicapped,
        'mcaimportFileIncomeTax'                : request.mcaimportFileIncomeTax,
        'mcaimportFileannexureI'             : request.mcaimportFileannexureI,
        'mcaimportFileannexureII'             : request.mcaimportFileannexureII,
        'mcaimportFileannexureIII' : request.mcaimportFileannexureIII,
        'mcaimportFileIsubmission' : request.mcaimportFileIsubmission,
        'candidatescategorystudents' : request.candidatescategorystudents,
        'regularcandidatesimportFileGATEScore'     : request.regularcandidatesimportFileGATEScore,
        'regularcandidatesimportFile10thMarksheet'                : request.regularcandidatesimportFile10thMarksheet,
        'regularcandidatesimportFile12thMarksheet'             : request.regularcandidatesimportFile12thMarksheet,
        'regularcandidatesimportFileUGmarksheets'             : request.regularcandidatesimportFileUGmarksheets,
        'regularcandidatesimportFilePGmarksheets':  request.regularcandidatesimportFilePGmarksheets,
        'regularcandidatesimportFilequalifyingdegree'             : request.regularcandidatesimportFilequalifyingdegree,
        'regularcandidatesimportFileCategorycertificate'               : request.regularcandidatesimportFileCategorycertificate,
        'regularcandidatesimportFilephysicallyhandicapped'                : request.regularcandidatesimportFilephysicallyhandicapped,
        'regularcandidatesimportFileAadhar'               : request.regularcandidatesimportFileAadhar,
        'annexureIregularcandidates'     : request.annexureIregularcandidates,
        'annexureIIregularcandidates'    : request.annexureIIregularcandidates,
        'annexureIIIregularcandidates'    : request.annexureIIIregularcandidates,
        'regularcandidatesimportFileIsubmission1' : request.regularcandidatesimportFileIsubmission1,
        'iitgraduatesimportFile10thMarksheet'             : request.iitgraduatesimportFile10thMarksheet,
        'iitgraduatesimportFile12thMarksheet'               : request.iitgraduatesimportFile12thMarksheet,
        'iitgraduatesimportFileUGmarksheets'                : request.iitgraduatesimportFileUGmarksheets,
        'iitgraduatesimportFilePGmarksheets'               : request.iitgraduatesimportFilePGmarksheets,
        'iitgraduatesimportFilequalifyingdegree'     : request.iitgraduatesimportFilequalifyingdegree,
        'iitgraduatesimportFileCategorycertificate'                : request.iitgraduatesimportFileCategorycertificate,
        'iitgraduatesimportFilephysicallyhandicapped'             : request.iitgraduatesimportFilephysicallyhandicapped,
        'iitgraduatesannexureI'             : request.iitgraduatesannexureI,
        'iitgraduatesannexureII'             : request.iitgraduatesannexureII,
        'iitgraduatesannexureIII'               : request.iitgraduatesannexureIII,
        'iitgraduatesimportFileIsubmission'                : request.iitgraduatesimportFileIsubmission,
        'sponsoredcandidatesimportFile10thMarksheet'               : request.sponsoredcandidatesimportFile10thMarksheet,
        'sponsoredcandidatesimportFile12thMarksheet'     : request.sponsoredcandidatesimportFile12thMarksheet,
        'sponsoredcandidatesimportFileUGmarksheets'                : request.sponsoredcandidatesimportFileUGmarksheets,
        'sponsoredcandidatesimportFilePGmarksheets'             : request.sponsoredcandidatesimportFilePGmarksheets1,
        'sponsoredcandidatesimportFileCategorycertificate'             : request.sponsoredcandidatesimportFileCategorycertificate,
        'sponsoredcandidatesimportFileAadhar'             : request.sponsoredcandidatesimportFileAadhar,
        'sponsoredcandidatesimportFilephysicallyhandicapped'               : request.sponsoredcandidatesimportFilephysicallyhandicapped,
        'sponsoredcandidatesimportFileExperiencecertificate'                : request.sponsoredcandidatesimportFileExperiencecertificate,
        'sponsoredcandidatesimportFileSponsorshipCertificate'               : request.sponsoredcandidatesimportFileSponsorshipCertificate,
        'sponsoredcandidatesimportFileNoObjection'     : request.sponsoredcandidatesimportFileNoObjection,
        'sponsoredcandidatesimportFilerelieving'                : request.sponsoredcandidatesimportFilerelieving,
        'sponsoredcandidatesannexureI'             : request.sponsoredcandidatesannexureI,
        'sponsoredcandidatesannexureII'             : request.sponsoredcandidatesannexureII,
        'sponsoredcandidatesannexureIII'             : request.sponsoredcandidatesannexureIII,
        'sponsoredcandidatesimportFileIsubmission'               : request.sponsoredcandidatesimportFileIsubmission,
        'foreignnationalsimportFile10thMarksheet' : request.foreignnationalsimportFile10thMarksheet,
        'foreignnationalsimportFile12thMarksheet'               : request.foreignnationalsimportFile12thMarksheet,
        'foreignnationalsimportFileUGmarksheets'     : request.foreignnationalsimportFileUGmarksheets,
        'foreignnationalimportFilePassport'                : request.foreignnationalimportFilePassport,
        'foreignnationalimportFileVisa'             : request.foreignnationalimportFileVisa,
        'foreignnationalimportFileHIV'               : request.foreignnationalimportFileHIV,
        'foreignnationalannexureI'     : request.foreignnationalannexureI,
        'foreignnationalannexureII'                : request.foreignnationalannexureII,
        'foreignnationalannexureIII'             : request.foreignnationalannexureIII,
        'foreignnationalimportFileIsubmission'             : request.foreignnationalimportFileIsubmission,
        'mbaimportFileCATScoreCard'             : request.mbaimportFileCATScoreCard,
        'mbaimportFile10thMarksheet'               : request.mbaimportFile10thMarksheet,
        'mbaimportFile12thMarksheet'                : request.mbaimportFile12thMarksheet,
        'mbaimportFileUGmarksheets'               : request.mbaimportFileUGmarksheets,
        'mbaimportFilePGmarksheets'     : request.mbaimportFilePGmarksheets,
        'mbaimportFilequalifyingdegree'                : request.mbaimportFilequalifyingdegree,
        'mbaimportFileCategorycertificate'             : request.mbaimportFileCategorycertificate,
        'mbaimportFileAadhar'             : request.mbaimportFileAadhar,
        'mbaimportFilephysicallyhandicapped'             : request.mbaimportFilephysicallyhandicapped,
        'mbaimportFileIncomeTax'                : request.mbaimportFileIncomeTax,
        'mbaannexureI'               : request.mbaannexureI,
        'mbaannexureII'     : request.mbaannexureII,
        'mbaannexureIII'                : request.mbaannexureIII,
        'mbaimportFileIsubmission'             : request.mbaimportFileIsubmission,
        'passportsizephoto': request.passportsizephoto,
        'sign'             : request.sign,
        'undertaking1': request.undertaking1,
        'feesamount': request.feesamount,
        'dateofpayment': request.dateofpayment,
        'transactionid': request.transactionid,
        'email': request.email,
    },HttpOptions);
    }
  getStudentRegistrationList()
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/ListOfStudentRegistration');
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  VerifyStatus(formData:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       })   
    }
    return this.httpClient.post(environment.BaseUrl+`api/EbaySeller/VerifyStaus`,{
      'Enrollmentno':formData.Enrollmentno,
      'RefrenceNo':formData.RefrenceNo,
      'amount':formData.amount,
      'p_type':formData.p_type,
    },HttpOptions);
  }
  CheckOTPAPI(formData:any)
  {
    formData.value.otp;
    //return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/ListOfStudents?UserName=${UserName}&&Enrollment=${Enrollment}`);
    return this.httpClient.get(environment.BaseUrl + `api/Users/VerifyOTPEmailForUpload?otp=${formData.value.otp}&&createdBy=${formData.value.createdBy}&&questionId=${formData.value.questionId}`);
  }
SaveExcelDataWithSameExcelCode(formData:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
  }
  return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/SaveExcelDataFrJEEWithSameExcelCode`,
  {
     'QuestionText':formData.Uploadexcelsheet,
     'TagName':formData.name,
     'questionTypeId':formData.questionTypeId,
     'startdate':formData.startdate,
     'enddate':formData.enddate,
     'Descriptions':formData.descriptions,
     'Email':formData.Email,
  }
  ,HttpOptions);
}
SaveExpenditureExcelData(formData:any)
{    const HttpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
    //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
   })   
}
  return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/SaveExcelDataOfExpenditure`,
  {
    'OrderDurationType':formData.filename,
    'reportid':formData.filecode,
    'ReportName':formData.Uploadexcelsheet,
  }
  ,HttpOptions);
}
SaveBankStatements(formData:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
  }
  return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/SaveBankStatementFile`,
  {
    'name':formData.Name,
    'excelname':formData.ExcelName,
    'description':formData.Descriptions,
    'email': formData.Email,
  }
  ,HttpOptions);
}
  SaveExcelData(formData:any)
  {    
    const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
  }
    return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/SaveExcelData`,
    {
      'TagName':formData.fullname,
      'QuestionText':formData.Uploadexcelsheet,
      'Createdby':formData.excelcode,
      'Descriptions':formData.Descriptions,
       'startdate':formData.startdate,
       'enddate':formData.enddate,
       'Email': formData.Email,
       'starttime':formData.starttime ,
       'endtime':formData.endtime,
    }
    ,HttpOptions);
  }
  SaveOfflineExcelData(formData:any)
  {    const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
  }
    return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/SaveOfflineExceldata`,
    {
      'TagName':formData.fullname,
      'QuestionText':formData.Uploadexcelsheet,
      'Createdby':formData.excelcode,
      'Descriptions':formData.Descriptions,
      'QuestionTypeId':formData.excelcode,
      'FileType':formData.filetype,
      'Email': formData.Email,
    }
    ,HttpOptions);
  }
  StudentCompline(formData:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
        //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
    }
    return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/Savestudentcompline`,
    {
      'Enrollmentno':formData.Enrollmentno,
      'Name':formData.Name,
      'IITRMailId':formData.IITRMailId,
      'MobileNo':formData.MobileNo,
      'IssueType':formData.IssueType,
      'comment':formData.comment,
    }
    ,HttpOptions);
  }
  UpdateADCIData(request:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.BaseUrl + '/api/StudentRegistration/UpdateADCIData?', {
      'id':request.id,
     'title': request.title,
     'sponsorname': request.sponsorname,
     'link': request.link,
     'parameter3': request.parameter3,
     'startdate': request.startdate,
     'enddate':request.enddate,
    },HttpOptions);
}

  
  SubmitADCIData(formData:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
        //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
    }
    return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/SaveADCIData`,
    {
       'title' :formData.title,
       'sponsorname' :formData.sponsorName,
       'link':formData.websitelink,
       'startdate' :formData.startdate,
       'enddate' :formData.enddate,
       'departmentname' :formData.departmentname,
       'filedata' :formData.filedata,
       'toppings' :formData.toppings,
       'Parameter3':formData.proposaltheme,
    }
    ,HttpOptions);
  }

  SaveGenerateReceiptdata(formData:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
        //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
    }
    return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/SaveGenerateReceiptdata`,
    {
       'PanNo' :formData.PanNo,
       'BookNo' :formData.BookNo,
       'ReceiptNo':formData.ReceiptNo,
       'Datee' :formData.Datee,
       'ReceivedFrom' :formData.ReceivedFrom,
       'Asumofwords' :formData.Asumofwords,
       'through' :formData.through,
       'No' :formData.No,
       'dated':formData.dated,
       'amount':formData.amount,
    }
    ,HttpOptions);
  }

  Savestartstopformdata(formData:any)
  {    const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
  }
    return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/Savestartstopformdata`,
    {
      'Name':formData.fullname,
      'NormalizedName':formData.Descriptions,
    }
    ,HttpOptions);
  }
  Stopapplication(formData:any)
  {    const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
  }
    return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/Stopapplication`,
    {
      'Name':formData.fullname,
      'NormalizedName':formData.Descriptions,
    }
    ,HttpOptions);
  }
  Getexceldata() 
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/LoadExcelData') 
  }
  GetActiveExcel() 
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/GetActiveExcel') 
  }
  ActivestudentData()
  {
    return this.httpClient.get(environment.BaseUrl + '/api/Users/ActivestudentData') 
  }
 
  GetCoursedata() 
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/GetCourseList') 
  }

  Getsemesterdata() 
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/GetSemesterList') 
  }


  GetRemarkdata() 
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/GetRemarkData') 
  }

  GetDescriptiondata() 
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/GetExcelDescriptionList') 
  }
  
  GetExcelCodedata() 
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/GetExcelCodeDataList') 
  }

  LoadStudentDetails() 
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/LoadStudentDetails') 
  }
  RunExe(data:any,Id:any,createdBy:any) 
  { 
    return this.httpClient.get(environment.BaseUrl +   `/api/StudentRegistration/runexcelexe?data=${data}&&Id=${Id}&&createdBy=${createdBy}`);
  }
  UpdateStatusExcel(questionId:any,questionTypeId:any)
  {
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/UpdateStatusExcel?questionId=${questionId}&&questionTypeId=${questionTypeId}`);
  }
  DeactiveStatus(questionId:any,questionTypeId:any)
  {
    return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/DeactiveStatusExcel?questionId=${questionId}&&questionTypeId=${questionTypeId}`);
  }
  RunExeOffline(data:any,Id:any,questionTypeId:any) 
  { 
    return this.httpClient.get(environment.BaseUrl +   `/api/StudentRegistration/UploadStudentOldDataofFEES?data=${data}&&Id=${Id}&&questionTypeId=${questionTypeId}`);
  }

  Expenditurerunexcelexe(orderDurationId:any,reportid:any,reportName:any)
  {
    return this.httpClient.get(environment.BaseUrl +   `/api/StudentRegistration/Expenditurerunexcelexe?data=${orderDurationId}&&Id=${reportid}&&createdBy=${reportName}`);
  }
  DeleteExcel(questionId: any) {
    return this.httpClient.get(environment.BaseUrl  +`/api/StudentRegistration/DeleteExcel?questionId=${questionId}`);
  }
  DeleteADCIDATA(id: any) {
    return this.httpClient.get(environment.BaseUrl  +`/api/StudentRegistration/DeleteADCIDATA?id=${id}`);
  }
  UpdatesChangesOfSameExcel(data:any,Id:any,questionTypeId:any,descriptions: any) 
  {
    return this.httpClient.get(environment.BaseUrl +   `/api/StudentRegistration/UpdatesChangesOfSameExcel?data=${data}&&Id=${Id}&&questionTypeId=${questionTypeId}&&descriptions=${descriptions}`);
  }

  GetFeesDiffrence(questionTypeId: any) {
    return this.httpClient.get(environment.BaseUrl  +`/api/StudentRegistration/GetDiffrenceofStudentFees?excelcode=${questionTypeId}`);
  }
  GetFinalStatus(questionTypeId: any) {
    return this.httpClient.get(environment.BaseUrl  +`/api/StudentRegistration/FillFinalStatus?excelcode=${questionTypeId}`);
  }
  RefreshFeesStatus(questionTypeId: any) {
    return this.httpClient.get(environment.BaseUrl  +`/api/EbaySeller/HDFCStatusAPIExistingStudents?questionTypeId=${questionTypeId}`);
  }
  RefreshFeesSBI(questionTypeId: any) {
    return this.httpClient.get(environment.BaseUrl  +`/api/EbaySeller/StatusAPIofExistingStudentsSBI?questionTypeId=${questionTypeId}`);
  }
  ListOfStudentIssues(UserName: any)
  {
    return this.httpClient.get(environment.BaseUrl  +`/api/EbaySeller/ListOfStudentIssues?UserName=${UserName}`);
  }
  
  UpdateStudentFees(formData: any)   {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    return this.httpClient.post(environment.BaseUrl + `api/EbaySeller/UpdateStudentFees`, {
      sellerId: formData.sellerId,
      UserEmail: window.sessionStorage.getItem('Email'),
      UserName: window.sessionStorage.getItem('UserName'),
      'Token': formData.token,
      sellerEmail: formData.sellerEmail,
      sellerUserId: formData.sellerUserId
    }, HttpOptions);
  }
UpdateExcelData(formData:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
    })
  }
  return this.httpClient.post(environment.BaseUrl + `api/StudentRegistration/UpdateExcelData`, {
    QuestionId:formData.questionId,
    startdate: formData.startdate,
    enddate: formData.enddate,
    descriptions: formData.descriptions,
    starttime:formData.starttime,
    endtime:formData.endtime,
  }, HttpOptions);
}


  VerificationChallan(request:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/HDFCChallanDoubleVerification?', {
      'BeneficiaryAccountNo': request.BeneficiaryAccountNo,
      'TotalFees': request.totalFees,
  },HttpOptions);
  }
 GetFormdata() {
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/getormdata');
  }
  AddExpenditure(request:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
        //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
    }
      return this.httpClient.post(environment.BaseUrl+`api/EbaySeller/AddExpenditure`,
      {
        'QuestionTypeName':request.ac_head,
        'Receipts':request.Receipts,
        'Expenditure':request.Expenditure,
        'Balance':request.Balance,
      }
      ,HttpOptions);
  }

  getExpenditureExcelList()
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getExpenditureExcelList`)
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }


  AddStudentProfile(formData:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
    })
  }
  return this.httpClient.post(environment.BaseUrl + `api/EbaySeller/AddStudentProfile`, {
    fullname:formData.fullname,
    mobileNo: formData.mobileNo,
    alternateno: formData.alternateno,
    email: formData.email,
    alternateemail:formData.alternateemail,
    address:formData.address,
    accountname:formData.accountname,
    accountno:formData.accountno,
    ifsccode:formData.ifsccode,
    bankname:formData.bankname,
    paymentreceipt:formData.paymentreceipt,
    passportsizephoto:formData.passportsizephoto,
    Enrollment:formData.Enrollment
  }, HttpOptions);
}

RefreshAllStatusAPI()
{ 
  return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshAllStatusAPI`)
  //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
}

RefreshAllMedicalStatusAPI()
{ 
  return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshAllMedicalStatusAPI`)
  //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
}

RefreshAllStatusAPIMTECHVLSI()
{ 
  //return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshAllStatusAPI`)
  return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/RefreshPHDNewEnteranceData`); 
}

ListOfADCIData(UserName: any)
{
  return this.httpClient.get(environment.BaseUrl  +`/api/StudentRegistration/ListOfADCIData?UserName=${UserName}`);
}

StudentBifurcationExcelUpload(formData:any)
  {    const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
  }
    return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/StudentBifurcationExcelUpload`,
    {
      'TagName':formData.fullname,
      'QuestionText':formData.Uploadexcelsheet,
      'Createdby':formData.excelcode,
      'Descriptions':formData.Descriptions,
       'startdate':formData.startdate,
       'enddate':formData.enddate,
       'Email': formData.Email,
       'starttime':formData.starttime ,
       'endtime':formData.endtime,
    }
    ,HttpOptions);
  }

  StudentBifurcationExcelRun(data:any,Id:any,createdBy:any) 
  { 
    return this.httpClient.get(environment.BaseUrl +   `/api/StudentRegistration/StudentBifurcationExcelRun?data=${data}&&Id=${Id}&&createdBy=${createdBy}`);
  }

  UpadteDataWithSameExcelCode(formData:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
  }
  return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/UpdateDataInSameExcel`,
  {
     'QuestionText':formData.Uploadexcelsheet,
     'TagName':formData.name,
     'questionTypeId':formData.questionTypeId,
     'startdate':formData.startdate,
     'enddate':formData.enddate,
     'Descriptions':formData.descriptions,
     'Email':formData.Email,
     'starttime':formData.starttime,
     'endtime':formData.endtime,
  }
  ,HttpOptions);
}

AddEmail(response:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/AddEmail', {
    'EmailId':response.Email,
  },HttpOptions);
}

LoadEmails()
{
  return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/LoadEmails') 
}
LoadPensionerExcelData()
{
  return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/LoadPensionerExcelData') 
}
EmailList()
{
  return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/EmailList') 
}

GetDictionarydataOfStudent() 
{ 
  return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/SortDataofExcelOrderByCourse') 
}

GetDonationReceiptList()
{ 
  return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/GetDonationReceiptList`)
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
}

PayDonationViaHDFC(request:any)
 {
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/PayDonationViaHDFC?', {
    'merchant_code':'IIT_RK',
    'encdata':'cxzczxc',
    'Nameofdonar': request.Nameofdonar,
    'Phone':request.Phone,
    'Email':request.Email,
    'PersonalDetails':request.PersonalDetails,
    'PurposeOfDonation':request.PurposeOfDonation,
    'Amount':request.Amount,
    'Remark':request.Remark,
    'Pan':request.Pan,
  },HttpOptions);
 }

DonationReceipt(order_id: any) 
{
  return this.httpClient.get(environment.BaseUrl  +`/api/EbaySeller/DonationRecept?order_id=${order_id}`);
}

SendMailPensioner(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  var colorsCSV = request['userType'].join(",");
  const PensionerModel =
      {
         'body': request['body'],
         'subject':request['Subject'],
         'userType':colorsCSV
      }
      console.log(PensionerModel);
    return this.httpClient.post(environment.BaseUrl + `api/StudentRegistration/SendEmail`, {
      PensionerModel
  }, HttpOptions);
}

getSCPCStudentList(UserName: any, Enrollment:any) 
{
  return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/ListOfStudents?UserName=${UserName}&&Enrollment=${Enrollment}`);
}

GetDataForSCPC(Id: any)
{ 
  return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetDetailsWellnessofEnrollmentNo?Id=${Id}`);
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
}

UpdateSCSPData(formData:any)
{    
    const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
      //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
     })   
  }
    return this.httpClient.post(environment.BaseUrl+`api/StudentRegistration/UpdateSCSPdata`,
    {
      'Stu_Type':formData.nativeSelectFormControl,
      'Id':formData.id,
      'Enrollmentno':formData.enrollmentno,
      'Category':formData.category,
      'InsituteFee':formData.insituteFee,
      'TotalFees':formData.totalFees,
    }
    ,HttpOptions);
}

GetBankUploadData() 
{ 
  return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/LoadBankStatementData') 
}

RunExeBankStatement(id:any) 
{ 
  return this.httpClient.get(environment.BaseUrl +   `/api/StudentRegistration/UploadMainAccountBalanceSheet?id=${id}`);
}

GetAccountStatementbankdata() 
{ 
  return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/LoadAccountStaementData') 
}

PaySRICPaymentViaHDFC(request:any)
{
  const HttpOptions = 
  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/PaySRICViaHDFC?', {
      'merchant_code':'IIT_RK',
      'encdata':'cxzczxc',
      'AdvanceInvoiceNo': request.AdvanceInvoiceNo,
      'Contracted_principalAmount':request.Contracted_principalAmount,
      'Department':request.Department,
      'EmailId':request.EmailId,
      'GSTAmount':request.GSTAmount,
      'GSTNo':request.GSTNo,
      'GrossAmount':request.GrossAmount,
      'NameOfFundingAgency':request.NameOfFundingAgency,
      'NameofRemitter':request.NameofRemitter,
      'NetAmount':request.NetAmount,
      'PanNo':request.PanNo,
      'PaymentDetails':request.PaymentDetails,
      'PaymentType':request.PaymentType,
      'PrincipalInvestigator':request.PrincipalInvestigator,
      'ProjectName':request.ProjectName,
      'ProjectNo':request.ProjectNo,
      'ProjectPI':request.ProjectPI,
      'Remarks':request.Remarks,
      'TDS_GS':request.TDS_GS,
      'TDS_Income_tax':request.TDS_Income_tax,
  },HttpOptions);
}

SubmitPNBPayRequest(request:any)
{
  const HttpOptions = 
  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/SubmitPNBPayRequest?', {
      'MID':'',
      'merchantRequest':'',
      'EncryptKey':'',
      'OrderId':'',
      'NetAmount':request.NetAmount,
      'CurrencyName':'INR',
      'TransType':'S',
      'Remarks':request.Remarks,
      'AdvanceInvoiceNo': request.AdvanceInvoiceNo,
      'Contracted_principalAmount':request.Contracted_principalAmount,
      'Department':request.Department,
      'EmailId':request.EmailId,
      'GSTAmount':request.GSTAmount,
      'GSTNo':request.GSTNo,
      'GrossAmount':request.GrossAmount,
      'NameOfFundingAgency':request.NameOfFundingAgency,
      'NameofRemitter':request.NameofRemitter,
      'PanNo':request.PanNo,
      'PaymentDetails':request.PaymentDetails,
      'PaymentType':request.PaymentType,
      'PrincipalInvestigator':request.PrincipalInvestigator,
      'ProjectName':request.ProjectName,
      'ProjectNo':request.ProjectNo,
      'ProjectPI':request.ProjectPI,
      'TDS_GS':request.TDS_GS,
      'TDS_Income_tax':request.TDS_Income_tax,
  },HttpOptions);
}


SendMailPensionerIITR(request:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  var colorsCSV = request['userType'].join(",");
  const PensionerModel =
      {
         'body': request['body'],
         'subject':request['Subject'],
         'userType':colorsCSV
      }
      console.log(PensionerModel);
    return this.httpClient.post(environment.BaseUrl + `api/StudentRegistration/SendMailPensionerIITR`, {
      PensionerModel
  }, HttpOptions);
}

UploadExcelPensioner(response:any)
{
  const HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  return this.httpClient.post(environment.BaseUrl + '/api/StudentRegistration/SaveExcelDataPensioner', 
  {
  first_name : response.fullname,
  descriptions : response.Descriptions,
  Uploadexcelsheet : response.Uploadexcelsheet,
  }
  ,HttpOptions);
}

RunExcelDataPensioner(id:any) 
{ 
  return this.httpClient.get(environment.BaseUrl +   `/api/StudentRegistration/UploadPensionerExcelData?id=${id}`);
}

getExcelDescriptionData(sellerUserId:any)
{
  let param = new HttpParams();
  // Begin assigning parameters
  param = param.append('sellerUserId', sellerUserId);
  return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getExcelDescriptionData`
  ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
  }

  GetPHDNewEnteranceCount()
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/PHDNewEnteranceCount');
  }

  
  ResponseHandlerOfHDFCMarriedHostelBill(request:any){
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/HDFCMarriedHostelPayment?',{
     'access_code': request.access_code,
     'Name': request.Name,
     'Enrollment_Employment': request.Enrollment_Employment,
     'currency': request.currency,
     'AddressOfPayer':request.AddressOfPayer,
     'BillRelated':request.BillRelated,
     'billno':request.billno,
     'Date':request.Date,
     'Total':request.Total,
     'Months':request.Months,
     'paymentSlip':request.paymentSlip,
     'amounttobepaid': request.amounttobepaid,
     'HouseRent':request.HouseRent,
     'ElectricityCharges': request.ElectricityCharges,
     'WaterChanges': request.WaterChanges,
     'MeterCharges':request.MeterCharges,
     'FurnitureCharges':request.FurnitureCharges,
     'OtherCharges': request.OtherCharges,
     'Arrear':request.Arrear,
     'hostelname':request.hostelname
      //'Stu_Type':request.Stu_Type,
    },HttpOptions);
  }

  RefreshAPI() 
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/RefreshDOSW_FinanceDataRefreshAPI') 
  }

  GetMarriedHostelBillData()
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/GetMarriedHostel');
    //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
  }
  MarriedHostelStatusAPI()
  { 
    return this.httpClient.get(environment.BaseUrl + '/api/StudentRegistration/MarriedHostelStatusAPI');
  }
  
  getMarriedHostelAmountStatus(sellerUserId:any){
    let param = new HttpParams();
   
    // Begin assigning parameters
    param = param.append('sellerUserId', sellerUserId);
    
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getMarriedHostelAmountStatus`
    ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
    }

    RefreshExcelStatus(questionTypeId: any) {
      return this.httpClient.get(environment.BaseUrl  +`/api/EbaySeller/RefreshExcelStatus?questionTypeId=${questionTypeId}`);
    }

    GetExcelRemark(sellerUserId:any)
    {
      let param = new HttpParams();
      param = param.append('sellerUserId', sellerUserId);
      return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/GetExcelRemark`
      ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json','Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
    }

    AddStudentData(formData: any)   {
      debugger;
      const HttpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
        })
      }
      return this.httpClient.post(environment.BaseUrl + `api/EbaySeller/AddStudentData`, {
        Enrollmentno: formData.enrollmentno,
        Name:  formData.name,
        Course:  formData.course,
        Department: formData.department,
        Category: formData.category,
        Status_Year: formData.status_Year,
        Semester:formData.Semester,
        D_ND:formData.d_ND,
        InsituteFee:formData.insituteFee,
        Accommodation_Chag:formData.accommodation_Chag,
        Electricity_Chag:formData.electricity_Chag,
        MessEsttb_Chag:formData.messEsttb_Chag,
        semesterCharg:formData.semesterCharg,
        MessFee:formData.messFee,
        OtherFee:formData.otherFee,
        TotalFees:formData.totalFees,
        parameter3:window.sessionStorage.getItem('Email'),
        stReg:formData.stReg,
        remark:formData.remark
      }, HttpOptions);
    }
    MarriedHostelReceiptDetails(Id: any)
    { 
      return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/MarriedHostelReceiptDetails?Id=${Id}`);
      //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
    }

    MarriedHostelRefresh(stuId: any)
    {
      return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/MarriedHostelRefresh?stuId=${stuId}`);
    }

    MedicalFundsReceiptDetails(Id: any)
    { 
      return this.httpClient.get(environment.BaseUrl + `/api/StudentRegistration/MedicalFundsReceiptDetails?Id=${Id}`);
      //return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/ListOfStudentDetails?UserName=${UserName}`); 
    }
    
    SaveAuditoriumBooking(formData:any)
    {
      const HttpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
          //  'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
         })   
      }
      return this.httpClient.post(environment.BaseUrl+`api/Questionnaires/AuditoriumBooking`,
      {
         'auditoriumname':formData.auditoriumname,
         'department' : formData.department,
         'Name' : formData.Name,
         'category' : formData.category,
         'IITRMailId':formData.IITRMailId,
         'PhoneNo':formData.PhoneNo,
         'Date' :formData.Date,
         'endtime':formData.endtime,
         'starttime':formData.starttime
      }
      ,HttpOptions);
    }

    GetFilterDataOfDateMedicalContribution(startDate: string,endDate:string) 
    {
      let  headers= new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
        let param = new HttpParams();
        param = param.append('startDate', startDate);
        param = param.append('endDate', endDate);
        return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetFilterDataOfDateMedicalContribution`
        ,{params:param,headers:headers})
      }
      
}

import { Component, OnInit } from '@angular/core';
import * as jquery from 'jquery';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { SellerService } from '../Services/Seller/seller.service';
interface EventTarget { result: any; }
const image = '';
declare var $: any;

@Component({
  selector: 'app-scpc',
  templateUrl: './scpc.component.html',
  styleUrls: ['./scpc.component.scss']
})
export class SCPCComponent implements OnInit {
  SellerDbList: any[] = [];
  TaskDbList: any[] = [];
  IsDelete:boolean=false;
  loading = false;
  submitted = false;
  isLoader = true;
  users: any = {};
  IsEdit = false;
  excelFile= true;
  userType:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  listcount: any;
  AmountStatusList: any;
  exelList:any = [];
  Enrollment: string;
  UserName: string;
  SCPCdata:any;
  IsEditForm: boolean;
  EditSCPCform:FormGroup;
  order_level:any;
  nativeSelectFormControl = new FormControl('valid', [
  Validators.required,
  Validators.pattern('valid'),
  ]);
  feesdata: any;
  ListOfSCPCstudentData: any;
  SCPCcategory: string;
  SCPCinsituteFee: string;
  constructor(private http: HttpClient,private sellerService:SellerService, private toastr: ToastrService,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService) 
  {
    this.EditSCPCform= this.formBuilder.group({
      name:[``, Validators.required],
      enrollmentno:['',Validators.required],
      department:['',Validators.required],
      category:['',Validators.required],
      id:['',Validators.required],
      rebate:['',Validators.required],
      nativeSelectFormControl:[],
      course:[],
      semesterCharg:[],
      messEsttb_Chag:[],
      electricity_Chag:[],
      accommodation_Chag:[],
      otherFee:[],
      messFee:[],
      insituteFee:['',Validators.required],
      totalFees:['',Validators.required],
     });
   }

  ngOnInit() {
    this.Enrollment=localStorage.getItem('Enrollment')
    this.UserName = window.sessionStorage.getItem('UserName');
    this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 20,
    processing: true
    };
  }

  firstDropDownChanged(val: any){
  this.sellerService.GetDataForSCPC(val).subscribe((data: any) => {
  if(data.statusCode==200)
  {
    this.TaskDbList = data.data.data;
    this.toastr.success("Data found successfully")
  }
  else
  {
    this.toastr.error("Data not found")
  }
  },
  (err: HttpErrorResponse) => {
  });
  }
  Edit(formdata:any)
  {
  this.SCPCdata=formdata;
  sessionStorage.setItem('SCPCcategory', this.SCPCdata.category);
  sessionStorage.setItem('SCPCinsituteFee', this.SCPCdata.insituteFee);
  this.ListOfSCPCstudentData=formdata;
  if(this.SCPCdata.semesterCharg==null)
  {
  this.SCPCdata.semesterCharg=0;
  }
  this.IsEditForm=true;
  }

  CloseEdit()
  {this.IsEditForm=false;}

  getDataForSemester(seller: any)
  {
    this.feesdata=this.SCPCdata;
    if(seller != "")
    {
    if(seller=="Full")
    {
      this.SCPCdata=this.feesdata;
      this.SCPCcategory = window.sessionStorage.getItem('SCPCcategory');
      this.SCPCinsituteFee = window.sessionStorage.getItem('SCPCinsituteFee');
      let categorchanges=this.SCPCcategory+"-C1";
      let calcutation=Number(this.SCPCinsituteFee)-100000;
      this.SCPCdata.insituteFee=calcutation;
      this.SCPCdata.category=categorchanges
      this.SCPCdata.totalFees= Number(this.SCPCdata.semesterCharg) + Number(this.SCPCdata.messEsttb_Chag)+ Number(this.SCPCdata.electricity_Chag) + Number(this.SCPCdata.accommodation_Chag)+ Number(this.SCPCdata.otherFee)+Number(this.SCPCdata.messFee)+Number(this.SCPCdata.insituteFee)
    }
    else
    {
      this.SCPCcategory = window.sessionStorage.getItem('SCPCcategory');
      this.SCPCinsituteFee = window.sessionStorage.getItem('SCPCinsituteFee');
      let categorchanges=this.SCPCcategory+"-C2"
      this.SCPCdata.category=categorchanges
      let tutionfee=this.SCPCinsituteFee;
      let calcutation=Number(this.SCPCinsituteFee)-100000;
      let final=Number(calcutation)+Number(33333);
      this.SCPCdata.insituteFee=final;
      this.SCPCdata.totalFees= Number(this.SCPCdata.semesterCharg) + Number(this.SCPCdata.messEsttb_Chag)+ Number(this.SCPCdata.electricity_Chag) + Number(this.SCPCdata.accommodation_Chag)+ Number(this.SCPCdata.otherFee)+Number(this.SCPCdata.messFee)+Number(this.SCPCdata.insituteFee)
    }
  }
    
  }
  Suminsitute(data: any)
  {
   this.SCPCdata.totalFees = Number(data) + Number(this.SCPCdata.accommodation_Chag) + Number(this.SCPCdata.electricity_Chag)+Number(this.SCPCdata.messFee)+Number(this.SCPCdata.messEsttb_Chag)+Number(this.SCPCdata.otherFee); 
  }

  UpdateStudentData()
  {
    this.nativeSelectFormControl.value;
    this.spinner.show();
    const data=
   {
    'id':this.EditSCPCform.controls['id'].value,
    'enrollmentno':this.EditSCPCform.controls['enrollmentno'].value,
    'category':this.EditSCPCform.controls['category'].value,
    'insituteFee':this.EditSCPCform.controls['insituteFee'].value ,
    'totalFees':this.EditSCPCform.controls['totalFees'].value ,
    'nativeSelectFormControl':this.nativeSelectFormControl.value,
   }
   this.sellerService.UpdateSCSPData(data).subscribe(res=>
    {
    console.log(res);
    if(res["statusCode"]==404)
    {
      // this.wrongCurrentPassword=true;
    }
    else
    {
    this.toastr.success(res["message"]);
    }
    this.spinner.hide(); 
    },
    error=>
    {
    console.log(error);
    this.spinner.hide();
    });

  }
}

import { SelectionModel } from '@angular/cdk/collections';
import { Component, TemplateRef, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { SellerService } from '../Services/Seller/seller.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSort } from '@angular/material'
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { from } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

export interface PeriodicElement {
  title: string;
  position: number;
  // weight: number;
  // symbol: string;
  price: any;
  currency: any;
  sellerid:any;
  displayProductId: any;
  // sku:string;
  // alias:string;
  PresetGroup: string;
  // Category:string;
  // warehouselocation:string;
  // questionnaire:any;
}
export class QuestionVM {
  UserEmail: string;
  UserName: string;
  ProductId: string;
  QuetionList: QuetionField[] = null;

}

export class QuetionField {
  QuestionText: string;
  TagName: string;
  QuestionType: string;
  ProductId: string;
}

export class QuestionInputModel {
  QuestionText: string;
  TagName: string;
  QuestionType: string;
}

export interface QuestionInputList {
  questionTypeId: string;
  questionText: number;
  tagName: string;

}

const ProductQuestionList: QuestionInputModel[] = [];
const ProductDbList1: PeriodicElement[] = [];
declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class ProductsComponent implements OnInit {
  inputModel: QuetionField;
  inputData: QuestionVM;
  public invoiceForm: FormGroup;
  IsEdit = false;
  IsNewQuestion = false;
  editMode = false;
  isRemove = false;
  editdataAfter:any;
  isCollapsed = false;
  EnableData=false;
  users: any = {};
  Extra: any[] = [];
  title = 'nocill';
  ProductDbList: any[] = [];
  dataResultList: PeriodicElement[] = [];
  QuestionResultList: QuestionInputModel[] = [];
  IsEXISTQUES = false;
  IsNewQUE = false;
  QuestionResultDB: any[] = [];
  IsQuestion: boolean = false;
  question: any[] = [];
  questiondata: any[] = [];
  ListOfQuestionnaires: any[] = [];
  NewListOfQuestionnaires: any[] = [];
  isLoader = true;
  filterValue: any;
  displayedColumns: string[] = ['select', 'displayProductId','sellerid', 'price', 'title', 'Category'];
  QuestionColumns: string[] = ['questionTypeId', 'questionText', 'tagName'];
  controls: any;
  lenght: any;
  tagName:any[];


  // displayedColumns: string[] = ['select','tag','sku','alias', 'name','Category', 'weight','warehouselocation','questionnaire'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ProductDbList);
  dataSourceSimple = new MatTableDataSource<PeriodicElement>(this.ProductDbList);
  QuestionDataSource = new MatTableDataSource<QuestionInputModel>(this.QuestionResultDB);
  QuestionFormSubmit: boolean = false;
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  closeResult: string;
  showMobile: any;
  questionId: any[] = [];
  selectedvalue: any;
  userRole: string;
  index: any;
  ProductId: any;
  contacts: Array<'itemRows'>;

  //questionaries component//

  IsNewQUESTIONNAIRE = false;
  PriceSerching: any;
  questionTypedeatils: any;
  listcount: any;
  displayProductId: string;
  UserName: any;
  ListOfQuestionnairesDBDB: any;
  selectedQuestionnaires: any;
  IsRemoveQuestionnaires: boolean;
  isRemovelist: any[] = [];
  //-----------------------//
  constructor(private spinner: NgxSpinnerService, private _fb: FormBuilder, private QuestionnaireService: QuestionnaireService, private router: Router, config: NgbModalConfig, private modalService: NgbModal, private dialog: MatDialog, private sellerService: SellerService, private formbuilder: FormBuilder, private toastr: ToastrService) {
    config.backdrop = 'static';
    this.contacts = [];
    config.keyboard = false;
    this.invoiceForm = this.formbuilder.group({
      QuestionText: ['', Validators.required],
      QuestionType: ['',],
      TagName: ['',]
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  open(content) {
    if (this.showMobile) {
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  getDismissReason(reason: any) {
    throw new Error("Method not implemented.");
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }



  ngOnInit() {

    this.index = 0;
    console.log(this.index, 'logindex')
    this.userRole = localStorage.getItem('userRole');
    this.dataSource.paginator = this.paginator;
    this.spinner.show();
    this.QuestionnaireService.GetListOfQuestionnaires(this.UserName).subscribe((data: any) => {
      console.log(data.data, 'que');
      this.ListOfQuestionnaires = data.data.data;
      this.Extra = this.ListOfQuestionnaires;
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
    this.QuestionnaireService.getQuestionType().subscribe((data: any) => {
      this.questionTypedeatils = data.data.data;
      console.log(data.data.data, 'typelist')
    })
    this.invoiceForm = this._fb.group({
      itemRows: this._fb.array([this.initItemRows()], Validators.required)
      // itemRows: this._fb.array([], Validators.required)

    });

    this.sellerService.getProductList(this.filterValue).subscribe((data: any) => {
      console.log(data.data);
      this.ProductDbList = data.data.data;
      this.listcount = data.data.data.length;
      this.spinner.hide();
      this.toastr.info("We have" + " " + this.listcount + " " + "products");
      this.dataResultList = this.ProductDbList;
      this.dataSource.data = this.dataResultList;
      if(this.dataResultList.length>0){
        this.EnableData;
        }
        else{
        this.EnableData=true;
        $('.table-wrap').hide()
        //$('.mat-paginator').hide();
        }
      console.log(this.ProductDbList);
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
    this.dataSource.filterPredicate =
      (data: PeriodicElement, filtersJson: string) => {
        const matchFilter = [];
        const temp = [];
        const filters = filtersJson;
        temp.push(filters);
        temp.forEach(filter => {
          const val = data.displayProductId === null ? '' : data.displayProductId;
          const val3 = data.title === null ? '' : data.title;
          matchFilter.push(
            val3.toLowerCase().includes(filter.toLowerCase()) ||
            val.includes(filter)
          )
        });
        return matchFilter.every(Boolean);
      };
    // get question list
    //get qustion list
  }
  ngAfterViewInit() {
    this.dataSourceSimple.paginator = this.paginator;
    this.dataSourceSimple.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    const filters = filterValue.trim().toLowerCase();
    this.dataSource.filter = filters;
  }

  applyFilterSimple(filterValue: string) {
    const filtersSimple = filterValue.trim().toLowerCase();
    this.dataSourceSimple.filter = filtersSimple;
  }
  //add new row here//
  get formArr() {
    return this.invoiceForm.get('itemRows') as FormArray;
  }
  getItemRows(form) {
    return form.get('itemRows').controls;
  }
  initItemRows() {
    this.IsNewQuestion = true;
    return this._fb.group({
      QuestionText: ['', Validators.maxLength(100)],
      TagName: [''],
      QuestionType: ['']
    });
  }
  addNewRow() {
    this.IsNewQuestion = true;
    this.formArr.push(this.initItemRows());
  }
  deleteRow(index: number) {
    index = 1;
    console.log(index, 'indexvalue')
    if (index == 1) {
      this.formArr.removeAt(index);
    }
    else {
      index = 0;
    }
  }
  //close row//-------

  AddQuestion() {
    let itemRows = this.invoiceForm.get('itemRows') as FormArray;
    console.log(this.invoiceForm);
    this.QuestionFormSubmit = true;
    var x = localStorage.getItem("product id");
    if (itemRows.status == "VALID") {
      this.QuestionnaireService.AddQuestion(this.invoiceForm.value.itemRows).subscribe((result: any) => {
        // console.log(result);
        localStorage.removeItem('product id');
        this.IsEdit = false;
        this.sellerService.getProductList(this.filterValue).subscribe((data: any) => {
          // console.log(data.data);
          this.ProductDbList = data.data.data;
          this.dataResultList = this.ProductDbList;
          this.dataSource.data = this.dataResultList;
          console.log(this.ProductDbList);
        },
          (err: HttpErrorResponse) => {
            this.isLoader = false;
          });
        this.toastr.success(result.message);
      },
        (err: HttpErrorResponse) => {
        });
    }
    else {
      this.toastr.error(" Please fill all input fields");
    }
  }
  ViewProduct(editdata) {
    this.spinner.show();
    this.editdataAfter=editdata;
    this.IsEdit = true;
    this.IsNewQUE = false;
    this.IsEXISTQUES = false;
    this.users = editdata;
    this.ProductId = editdata.displayProductId;
    localStorage.setItem('product id', editdata.displayProductId)
    // console.log('users', this.users);
    this.sellerService.showProuctQuestions(editdata.displayProductId).subscribe((data: any) => {
      // var removeclass=document.getElementById("abc").style += "";
      // removeclass.classList.add("bodyfgfg");
       $('.abc').addClass('addremovecss');
      //$('.abc').style += "overflow:hidden !important";
      // document.getElementById('#abc').style += "padding-top:40px";
      localStorage.setItem('class','1');
      this.lenght = data.data.data.length;
      this.isRemovelist=data.data.data[0];
      console.log( this.isRemovelist,"isRemovelist")
      if (this.lenght > 0) {
        this.editMode = true;
      }
      else {
        this.editMode = false;
      }
      this.QuestionResultDB = data.data.data;
      // this.ListOfQuestionnaires = [];
      var _a = [];
      for (var i = 0; i < this.QuestionResultDB.length; i++) {
        for (var j = 0; j < this.Extra.length; j++) {
          if (this.QuestionResultDB[i].questionText != this.Extra[j].questionText) {
            _a.push(this.Extra[i]);
          }
        }
      }
      var _x = [];
      var l = [];
      $.each(_a, function (e, t) {
        if (t != undefined) {
          for (let i = 0; i < t.outputList.length; i++) {
            if ($.inArray(t.outputList[i].questionId, _x) == -1) {
              if (t.outputList[i].questionId != "") {
                l.push(t.outputList[i].questionId)
                _x.unshift(l);
              }
            }
          }
        }
      });
      _x=_x[0];
      this.ListOfQuestionnaires.forEach(element => {
        const found = this.QuestionResultDB.some((item) => item.questionId === element.questionId);
        // const found = this.QuestionResultDB.indexOf(element) >= 0;
        if (!found) {
          this.NewListOfQuestionnaires.push(element);
        }
      });
      this.QuestionResultList = this.QuestionResultDB;
      this.QuestionDataSource.data = this.QuestionResultList;
      this.IsQuestion = true;
      // console.log(this.QuestionResultDB);
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
  }
  myfunc(data: any) {
    var url = data;
    window.open(url);
  }

  Goback() {
    this.IsEdit = false;
    //document.getElementById("side_body").classList.remove("bodyfgfg");
   //.removeclass('bodyfgfg');
    this.sellerService.getProductList(this.filterValue).subscribe((data: any) => {
      console.log(data.data);
      $('.abc').removeClass('addremovecss');
      this.ProductDbList = data.data.data;
      this.dataResultList = this.ProductDbList;
      this.dataSource.data = this.dataResultList;
      // console.log(this.ProductDbList);
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
    this.formArr.reset();
    var removeclass = document.getElementById("side_body")
    removeclass.classList.remove("bodyfgfg");
   
  }
  changeQuestionnaireForProduct() {
    this.selectedQuestionnaires;
    this.sellerService.changeQuestionnaireForProduct(this.isRemove, this.users.displayProductId, this.selectedQuestionnaires).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this.toastr.success("Questionnaire change successfully")
        this.displayProductId = localStorage.getItem('product id');
        console.log('users', this.users);
        this.sellerService.showQuestions(this.displayProductId).subscribe((data: any) => {
          this.lenght = data.data.data.length;
          this.QuestionResultDB = data.data.data;
          this.isRemovelist=data.data.data[0];
          if(this.isRemovelist==undefined)
          {
            this.isRemovelist=[];
          }
          else
          {
            this.isRemovelist=data.data.data[0];
          }
          console.log(this.isRemovelist,"isRemovelist");
          this.lenght = data.data.data.length;
          if (this.lenght > 0) {
            this.editMode = true;
          }
          else {
            this.editMode = false;
          }
          var _a = [];
          for (var i = 0; i < this.QuestionResultDB.length; i++) {
            for (var j = 0; j < this.Extra.length; j++) {
              if (this.QuestionResultDB[i].questionText != this.Extra[j].questionText) {
                _a.push(this.Extra[i]);
              }
            }
          }
          var _x = [];
          var l = [];
          $.each(_a, function (e, t) {
            if(t !=undefined)
            {
            $.each(t.outputList, function (e, z) {
              if ($.inArray(z.questionId, _x) == -1) {
                if (z.questionId != "") {
                  _x.push(z.questionId)
                  l.push(z);
                }
              }              
            })
          }
          });
          _x=_x[0];
            // if ($.inArray(t.questionId, _x) == -1) {
            //   if (t.questionId != "") {
            //     _x.push(t.outputList.questionId)
            //     l.push(t);
            //   }
            // }
          this.ListOfQuestionnaires.forEach(element => {
            const found = this.QuestionResultDB.some((item) => item.questionId === element.questionId);
            // const found = this.QuestionResultDB.indexOf(element) >= 0;
            if (!found) {
              this.NewListOfQuestionnaires.push(element);
            }
          });
          this.QuestionResultList = this.QuestionResultDB;
          this.QuestionDataSource.data = this.QuestionResultList;
          this.IsQuestion = true;
          console.log(this.QuestionResultDB);
        },
          (err: HttpErrorResponse) => {
            this.isLoader = false;
          });
      }
      else {
        this.toastr.error("Something went wrong, please try again");
      }
    })
  }
  addQuestionForProduct() {
    this.selectedvalue;
    let a = this.questionId.map(x => x).join(",");
    this.sellerService.AddQuestionForProduct(this.users.displayProductId, this.selectedvalue).subscribe((data: any) => {
      if (data.statusCode == 200) {
        this.toastr.success("Questionnaire added successfully")
        this.displayProductId = localStorage.getItem('product id');
        console.log('users', this.users);
        this.sellerService.showQuestions(this.displayProductId).subscribe((data: any) => {
          this.lenght = data.data.data.length;
          this.isRemovelist=data.data.data[0];
          if(this.isRemovelist==undefined)
          {
            this.isRemovelist=[];
          }
          else
          {
            this.isRemovelist=data.data.data[0];
          }
          if (this.lenght > 0) {
            this.editMode = true;
          }
          else {
            this.editMode = false;
          }
          this.QuestionResultDB = data.data.data;
          var _a = [];
          for (var i = 0; i < this.QuestionResultDB.length; i++) {
            for (var j = 0; j < this.Extra.length; j++) {
              if (this.QuestionResultDB[i].questionText != this.Extra[j].questionText) {
                _a.push(this.Extra[i]);
              }
            }
          }
           var _x = [];
           var l = [];
           $.each(_a, function (e, t) {
            if (t != undefined) {
            if ($.inArray(t.questionId, _x) == -1) {
               if (t.questionId != "") {
                _x.push(t.questionId)
                 l.push(t);
               }
             }
            }
         });
        
          // var _x = [];
          // var l = [];
          // $.each(_a, function (e, t) {
          //   $.each(t.outputList, function (e, z) {
          //     if ($.inArray(z.questionId, _x) == -1) {
          //       if (z.questionId != "") {
          //         _x.push(z.questionId)
          //         l.push(z);
          //       }
          //     }              
          //   })
          // });
          // debugger;
          // _x=_x[0];
          this.ListOfQuestionnaires.forEach(element => {
            const found = this.QuestionResultDB.some((item) => item.questionId === element.questionId);
            // const found = this.QuestionResultDB.indexOf(element) >= 0;
            if (!found) {
              this.NewListOfQuestionnaires.push(element);
            }
          });
          this.QuestionResultList = this.QuestionResultDB;
          this.QuestionDataSource.data = this.QuestionResultList;
          this.IsQuestion = true;
          console.log(this.QuestionResultDB);
        },
          (err: HttpErrorResponse) => {
            this.isLoader = false;
          });
        this.IsEXISTQUES = false;
      }
      else {
        this.toastr.error("Something went wrong, please try again");
      }
    })
  }
  Newque() {
    this.IsEXISTQUES = false;
    this.IsNewQUE = true;
    this.isRemove = false;

    this.IsRemoveQuestionnaires = false;
    //   this.QuestionnaireService.GetListOfQuestionnaires(this.UserName).subscribe((data:any) => {
    //     debugger;
    //   this.ListOfQuestionnairesDBDB = data.data.data;
    //   this.listcount= data.data.data.length;
    //   // this.toastr.info("We have"+" "+this.listcount+" "+"questionnaires list");
    //   this.dataResultList = this.ListOfQuestionnairesDBDB;
    //   this.dataSource.data = this.dataResultList;
    //   console.log(this.ListOfQuestionnairesDBDB);
    //   this.spinner.hide();
    // },
    //   (err: HttpErrorResponse) => {
    //       this.isLoader = false;
    //   });
  }
  ExitQue() {
    this.isRemove = false;
    this.IsNewQUE = false;
    this.IsEXISTQUES = true;
    this.IsRemoveQuestionnaires = false;
  }
  RemoveQuestionnaires() {
    this.isRemove = true;
    this.IsEXISTQUES = false;
    this.IsNewQUE = false;
    this.IsRemoveQuestionnaires = true;
  }
}






import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Inject } from '@angular/core';
import { JwtModule, JwtHelperService } from "@auth0/angular-jwt";
import { HttpClientModule } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public headers: Headers;
  constructor(public jwtHelper: JwtHelperService) { }
  public isAuthenticated(): boolean {
    const token1 = window.sessionStorage.getItem("token");
    const token2 = window.localStorage.getItem("token");
    if(token1!=undefined)
      return !this.jwtHelper.isTokenExpired(token1);
    else if(token2!=undefined)
      return !this.jwtHelper.isTokenExpired(token2);
  }
}

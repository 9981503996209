import { Component, OnInit, ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Inject } from '@angular/core';
import { ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material';
import { OrderService } from '../Services/Order/order-service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfStmt } from '@angular/compiler';
import { SellerService } from '../Services/Seller/seller.service';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
  animal: string;
  name: string;
}
import { Subscription, interval } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { debug } from 'util';
//import * as jsPDF from 'jspdf';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var jsPDF: any;

export interface PeriodicElement {
  Enrollmentno:any;
  Name:any;
  AlternateConNO:any;
  AlternateEmail:any;
  Address:any;
  Department:any;
  Category:any;
  Year:any;
  Aadhar_No:any;
  DepositeAmountFor:any;
  MobileNo:any;
  Email:any;
  Remark:any;
  Bank:any;
  Date:any;
  OtherFeeAmount:any;
  AmountStatus:any;
  IITR_Ref_No:any;
  BankRefNo:any;
  Description:any;
  Transactiontime_date:any;
  checkSum:any;
}

export interface QuestionList {
  questionTypeId: string;
  questionText: number;
}
const ProductQuestionList: QuestionList[] = [];

@Component({
  selector: 'app-iitrconsolidated',
  templateUrl: './iitrconsolidated.component.html',
  styleUrls: ['./iitrconsolidated.component.scss']
})
export class IitrconsolidatedComponent implements OnInit {
  QuestionResultList: QuestionList[] = [];
  QuestionResultDB: any[] = [];
  IsQuestion: boolean = false;
  question: any[] = [];
  CusName: any[] = [];
  ProName: any[] = [];
  ListOfQuestionnaires: any[] = [];
  OrdersComponent: any[] = [];
  AnswerDetails: any[] = [];
  AnswerDetails1: any[] = [];
  // QuestionResponseForm:FormGroup;
  form: FormGroup;
  order_level: any;
  name_level: any;
  IsEdit = false;
  OrderDetail: any;
  oderid: any;
  deleteData: any;
  EnableData = false;
  animal: string;
  name: string;
  CustomerName: string;
  isCollapsed = false;
  title = 'nocill';
  ProfileImages:string;
  OrderDbList: any[] = [];
  SellerDBList: any[] = [];
  DurationList: any[] = [];
  dataResultList: PeriodicElement[] = [];
  QuestionDataSource = new MatTableDataSource<QuestionList>(this.QuestionResultDB);
  displayedColumns: string[] = ['select', 'Enrollmentno', 'profilePic', 'Name', 'MobileNo','AlternateConNO','Email','AlternateEmail','Address', 'accountHolName', 'AccountNo', 'IFSC', 'BankName', 'ViewOrder'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.OrderDbList);
  dataSourceSimple = new MatTableDataSource<PeriodicElement>(this.OrderDbList);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  isLoader: boolean;
  order_id:string;
  tracking_id:string;
  bank_ref_no:string;
  order_status:string;
  amount:any;
  billing_name:string;
  Isresponse: boolean;
  users: any;
  email: any;
  userRole: any;
  userName: string;
  showDropDown: boolean;
  fields: any = [];
  getall: any = [];
  val4: any;
  index: any;
  disableButton: boolean;

  // form array
  QuestionResponseForm = new FormGroup({
    answertext: new FormArray([]),
    QuestionType: new FormArray([])
  });
  statusCode: number;
  message: any;
  QuetionList: any[];
  lenght: any;
  questionTypedeatils: any;
  isresponse: any;
  disableSubmit: any;
  buttonsubmit: number;
  listcount: any;
  questionType: string;
  displayOrderId: string;
  IsConfirmation: boolean;
  IsresponseSBI: boolean;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  path: string;
  BankStatement: string;
  //create array
  get answertext(): FormArray { return this.form.get('answertext') as FormArray; }
  get QuestionType(): FormArray { return this.form.get('QuestionType') as FormArray; }
  public groups: any[] = [
    {
      name: "",
      fields: [
        {}
      ]
    }]
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  // ngAfterViewInit(): void {
  //   this.dataSource.sort = this.sort;
  // }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public downloadAsPDF() {
    const doc = new jsPDF();

    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const pdfTable = this.pdfTable.nativeElement;

    doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });

    doc.save('StudentsDeatils.pdf');
  }
  public downloadPDF() {
    const doc = new jsPDF();

    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTable = this.pdfasTable.nativeElement;
    doc.fromHTML(pdfasTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceipt.pdf');
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    // return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  constructor(private QuestionnaireService: QuestionnaireService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private _http: OrderService, public dialog: MatDialog, private router: Router, private _sellerService: SellerService, private toastr: ToastrService,private route: ActivatedRoute) { 
     // this.IsEdit= true;
     this.route.queryParams.subscribe(params => {
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
  });
     this.QuestionResponseForm = this.formBuilder.group({
      answertext: [``,],
      QuestionType: [``,],
      group: this.formBuilder.array([]),
      Questions: this.formBuilder.array([])
    });
    this.group = [];
    // this.form = this.formBuilder.group({
    //   Answertext:new FormControl (),
    //   radioAnswer:new FormControl ()
    // });
  }
  group: {
    id: number;
    answertext: string;
    QuestionType: string;
  }[];
  get demoArray() {
    return this.QuestionResponseForm.get('answertext') as FormArray;
    return this.QuestionResponseForm.get('QuestionType') as FormArray;
  }
  addItem(item) {
    this.group.push(item);
    this.demoArray.push(this.formBuilder.control(false));
  }
  removeItem() {
    this.group.pop();
    this.demoArray.removeAt(this.demoArray.length - 1);
  }
  // getfilter(name) {
  //   debugger;
  //   var email = [];
  //   this.dataSource.data = [];
  //   this.dataSource.data = this.getall;
  //   var myval = $.grep(this.dataSource.data, function (n, i) {
  //     if (name != '' && name != undefined) {
  //       email = n.customer.email;
  //     }
  //     return name == email;
  //   });
  //   if (name != "Select Email") {
  //     this.dataSource.data = myval;
  //     var _p = [];
  //     debugger
  //     _p.unshift("Select Product Title")
  //     $.each(myval, function (e, t) {
  //       if ($.inArray(t.transactionItemTitle, _p) == -1) {
  //         if (t.transactionItemTitle != "") {
  //           if (t.transactionItemTitle != "Invalid Request") {
  //             _p.push(t.transactionItemTitle)
  //           }
  //         }
  //       }
  //     });
  //     this.ProName = _p;
  //   }
  // }
  // getfilterbyPro(name) {
  //   debugger;
  //   var Product = "";
  //   this.dataSource.data = [];
  //   this.dataSource.data = this.getall;
  //   var myval = $.grep(this.dataSource.data, function (n, i) {
  //     if (name != '' && name != undefined) {
  //       Product = n.transactionItemTitle;
  //     }
  //     return name.trim() == Product.trim();
  //   });
  //   if (name != "Select Product Title") {
  //     this.dataSource.data = myval;
  //   }
  // }

  get AnswerText(): any { return this.form.get('AnswerText') }
  get AnswerRadio(): any { return this.form.get('AnswerRadio'); }
  onSubmit() {
    var adata = [];
    var adata1 = [];
    console.log(this.QuestionResponseForm.value);
    // console.log(this.AnswerText);  // 'Nancy'
    adata1.push($(this))
    console.log(this.form.value);   // {name: {first: 'Nancy', last: 'Drew'}, email: ''}
    console.log(this.form.status);
    this.displayOrderId = localStorage.getItem("displayOrderId");
    var _pxObj = [];
    $("._pxInfs").each(function () {
      var _pax = {};
      _pax["ProductId"] = $(this).attr("title");
      $(this).find(":input[title]").each(function () {
        _pax[$(this).attr("title")] = $(this).val();
      });
      _pxObj.push(_pax);
    });
    //var a=(document.getElementById('TypeText')).value;
    var input = document.getElementById("TypeText")
    if (input != null) {
      var a = (<HTMLInputElement>document.getElementById('TypeText')).value;
      var _b = [];
      _b = (a as string).split("_");
      //var radioValue = $("input[name='gender']:checked").val();
      for (var i = 0; i < _pxObj.length; i++) {
        if (_pxObj[i].QuestionId == _b[1]) {
          _pxObj[i].AnswerText = '';
          // _pxObj[i].AnswerText = $("input[name='QuestionType']:checked").val();
          _pxObj[i].AnswerText = _b[0]
        }
        _pxObj[i].ProductId = localStorage.getItem("displayProductId");
        _pxObj[i].displayOrderId = localStorage.getItem("displayOrderId");
        _pxObj[i].CustomerId = localStorage.getItem("customerId");
      }
    }
    this.AnswerDetails1 = _pxObj;
    const varr =
    {
      'AnswerDetails1': this.AnswerDetails1
    }
    this._sellerService.saveQuestionariesResponce(varr).subscribe((data: any) => {
      this.IsEdit = false;
      this.toastr.success(data.message);
      console.log(data);
      this.ngOnInit();
    });
  }


  // Confirmation(data: any): void {
  //   debugger;
  //   this.deleteData = '';
  //   this.deleteData = data;
  //   if (data.orderPaymentStatus == "Complete") {
  //     this.openDialog(this.deleteData);
  //   }
  //   else { this.IsConfirmation = true; }

  // }
 // removerow() { this.openDialog(this.deleteData); }
  // openDialog(data: any): void {
  //   this.IsConfirmation = false;
  //   this.spinner.show();
  //   this._http.UpdateOrders(data.displayOrderId).subscribe((data: any) => {
  //     if (data.statusCode == 200) {
  //       const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
  //         width: '470px',
  //         height: 'auto',
  //         panelClass: 'matcustom-modalbox',
  //         data: {
  //           OrderId: data.displayOrderId,
  //         }
  //       });
  //       dialogRef.afterClosed().subscribe(result => {
  //         console.log('The dialog was closed');
  //         this.animal = result;
  //       });
  //       this.ngOnInit();
  //       console.log('Order Uppdate successfully');
  //       this.router.navigate(["#"]);
  //       this.spinner.hide();
  //     }
  //     else {
  //       data.statusCode = 400;
  //     }
  //   });
  // }

  private updatePage: Subscription;

  ngOnInit() {
    this.IsresponseSBI=false;
    const data =
          {
            'tracking_id' : this.tracking_id,
            'bank_ref_no' : this.bank_ref_no,
            'order_status' : this.order_status,
            'amount' : this.amount,
            'billing_name' :this.billing_name,
            'merchant_param2':this.merchant_param2,
            'delivery_address':this.delivery_address,
            'sbi_ref_no':this.sbi_ref_no,
            'iitr_ref_no':this.iitr_ref_no,
            'status':this.status,
            'desc':this.desc,
            'Transactiontime_date': this.Transactiontime_date,
          }
          // this.iitr_ref_no = window.sessionStorage.setItem('iitr_ref_no',this.iitr_ref_no);
          // this.amount = window.sessionStorage.setItem('amount',this.amount);
          // this.iitr_ref_no = window.sessionStorage.getItem('iitr_ref_no');
          // this.amount=window.sessionStorage.getItem('amount');
    
          if(this.status=="Success")
          {
            this.IsresponseSBI = true;
          }
           else if(this.status=="Failure") 
          {
            this.IsresponseSBI = true;
          }
          else if(this.status=="Aborted")
        {
          this.IsresponseSBI = true;
        }






    this.disableSubmit = 0;
    this.index = 0;
    this.spinner.show();
    this.form = new FormGroup({});
    //this.getFormInstance();
    this.userRole = localStorage.getItem('userRole');
    this.showDropDown = this.userRole == "Seller" || this.userRole == "Customer" ? false : true;
    this.userName = window.sessionStorage.getItem('UserName');
    this.email = window.sessionStorage.getItem('Email');
    // getStudentProfileList
    // this._http.getOtherFeesList().subscribe((data: any) => {
    this._http.getStudentProfileList().subscribe((data: any) => {
      if(data.data.data==null)
      {
        this.listcount=0
      }
      else
      {
        this.listcount = data.data.data.length;
      }
      this.toastr.info("We have" + " " + this.listcount + " " + "records");
      this.OrderDbList = data.data.data == null ? null : data.data.data;
      console.log(this.OrderDbList, 'getOrders');
      var arr1 = [];
      // arr1.unshift("Select Seller");
      // $.each(this.OrderDbList, function (e, t) {
      //   if ($.inArray(t.sellerId, arr1) == -1) {
      //     if (t.sellerId != "") {
      //       arr1.push(t.sellerId)
      //     }
      //   }
      // });
      this.SellerDBList = arr1;
      this.dataResultList = this.OrderDbList;
      var _a = [];
      var _p = [];
      // _a.unshift("Select Email")
      // $.each(this.dataResultList, function (e, t) {
      //   if ($.inArray(t.customer.email, _a) == -1) {
      //     if (t.customer.email != "" && t.customer.email.trim() != "Email address not found") {
      //       if (t.customer.email.trim() != "Invalid Request") {
      //         _a.push(t.customer.email)
      //       }
      //     }
      //   }
      // });
      this.CusName = _a;
      var _p = [];
      // _p.unshift("Select Product Title")
      // $.each(this.dataResultList, function (e, t) {
      //   if ($.inArray(t.transactionItemTitle, _p) == -1) {
      //     if (t.transactionItemTitle != "") {
      //       if (t.transactionItemTitle != "Invalid Request") {
      //         _p.push(t.transactionItemTitle)
      //       }
      //     }
      //   }
      // });
      this.ProName = _p;
      if(this.dataResultList!=null)
      {
        this.dataSource.data = this.dataResultList;
        if (this.dataResultList.length > 0) 
      {
        this.EnableData;
      }
      else {
        this.EnableData = true;
        $('.ord-mbl-rsp').hide()
        //$('.mat-paginator').hide();
      }
      }
      this.getall = this.dataSource.data;
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.isLoader = false;
      this.spinner.hide();
    })

    //serch---------------//
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate =
      (data: PeriodicElement, filtersJson: string) => {
        const matchFilter = [];
        const temp = [];
        const filters = filtersJson;
        temp.push(filters);
        temp.forEach(filter => {
          const val = data.AlternateConNO === null ? '' : data.AlternateConNO;
          const val2 = data.Enrollmentno === null ? '' : data.Enrollmentno;
          const val3 = data.Name === null ? '' : data.Name;
          if (data.Email != null) {
            this.val4 = data.Email === null ? '' : data.Email;
          }
          else 
          {
            this.val4 = 'No record Found';
          }
          this.CustomerName = data.Date + ' ' + data.Date;
          const val5 = data.Department === null ? '' : data.Department;
          const val6 = data.MobileNo === null ? '' : data.MobileNo;
          const val7 = data.BankRefNo === null ? '' : data.BankRefNo;
          const val8 = data.Year === null ? '' : data.Year;
          const val9 = data.Aadhar_No === null ? '' : data.Aadhar_No;
          matchFilter.push(val.includes(filter) ||
          val2.includes(filter) ||
          val3.toLowerCase().includes(filter.toLowerCase()) ||
          this.val4.toLowerCase().includes(filter.toLowerCase()) ||
          val5.toLowerCase().includes(filter.toLowerCase()) ||
          val6.toLowerCase().includes(filter.toLowerCase()) ||
          val8.toLowerCase().includes(filter.toLowerCase()) ||
          val9.toLowerCase().includes(filter.toLowerCase()) ||
          val7.includes(filter)
          );
        });
        return matchFilter.every(Boolean);
      };
    //search--------------//
    //   this.dataSource.filterPredicate = 
    // (data: PeriodicElement, filtersJson: string) => 
    // {
    //     const matchFilter = [];
    //     const filters = JSON.parse(filtersJson);
    //     filters.forEach(filter => {
    //       const val = data[filter.id] === null ? '' : data[filter.id];
    //       matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
    //     });
    //       return matchFilter.every(Boolean);
    //   };

    this._http.getOrderDurationList().subscribe((data: any) => {
      this.DurationList.unshift({
        'isActive': false,
        'orderDurationId': 0,
        'orderDurationType': 'Select All'
      })
      this.DurationList = data.data;
    })
    this.QuestionnaireService.getQuestionType().subscribe((data: any) => {
      this.questionTypedeatils = data.data.data;
    })
    this.ProfileImages="https://www.w3schools.com/howto/img_avatar.png";
  }

  ngAfterViewInit() {
    this.dataSourceSimple.paginator = this.paginator;
    this.dataSourceSimple.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    const filters = filterValue.trim().toLowerCase();
    this.dataSource.filter = filters;
  }

  applyFilterSimple(filterValue: string) {
    const filtersSimple = filterValue.trim().toLowerCase();
    this.dataSourceSimple.filter = filtersSimple;
  }


  updateStats() {
    console.log('I am doing something every 10 second');
  }
  downloadFile(fileName) {    
    //var url = environment.BaseUrl + `api/StudentRegistration/uploaddocument`;
    let url = environment.BaseUrl + `api/StudentRegistration/DownloadFileBankStatement?fileName=` + fileName;
    window.open(url);
  }

  ViewOrder(editdata) {
    this.path = environment.BaseUrl + "AppData/AccountStatements/";
    this.isresponse = editdata.isResponse;
    this.form.reset();
    this.IsEdit = true;
    // this.users = editdata;
    this.OrderDetail = editdata;
    console.log('users', this.users);
    localStorage.setItem("displayOrderId", editdata.displayOrderId)
    localStorage.setItem("customerId", editdata.customerId)
    localStorage.setItem("displayProductId", editdata.transactionItemId);
    this.BankStatement = this.path + this.OrderDetail.sellerUserId;
    // this._sellerService.showQuestions(editdata.displayOrderId).subscribe((data: any) => {
    //   debugger;
    //   console.log('questions', data.data);
    //   $('.abc').addClass('addremovecss');
    //   this.users = data.data.data.item2;
    //   debugger;
    //   this.disableSubmit= editdata.isResponse;
    //   for (let i = 0; i < data.data.data.length; i++) {
    //     if (data.data.data[i].answerText === null) {
    //       debugger;
    //       this.disableSubmit = 1;
    //       console.log(this.disableSubmit, 'disableSubmit')
    //     }
    //   }
    //   this.QuestionResultDB = data.data.data.item1;
    //   this.lenght = this.QuestionResultDB.length;
    //   this.QuestionResultList = this.QuestionResultDB;
    //   this.QuestionDataSource.data = this.QuestionResultList;
    //   this.fields = [];
    // },
    //   (err: HttpErrorResponse) => {

    //   });
  }



  getFields() {
    return this.fields;
  }

  Goback() {
    $('.abc').removeClass('addremovecss');
    this.IsEdit = false;
    this.IsConfirmation = false;
    this.QuestionResponseForm.reset();

  }
  // applyFilter(filterValue: string) {

  //   const tableFilters = [];
  //   tableFilters.push({
  //     id: 'transactionItemTitle',
  //     value: filterValue
  //   });
  //   this.dataSource.filter = JSON.stringify(tableFilters);
  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }
  getSelectedData(filterDate: any) {
    this.spinner.show();
    this.email = window.sessionStorage.getItem('Email');
    this._http.getOrders(filterDate, this.email, this.userName).subscribe((data: any) => {
      this._http.getOrderDurationList().subscribe((data: any) => {
        this.DurationList = data.data;
        this.DurationList.unshift({
          'isActive': 'false',
          'orderDurationId': 0,
          'orderDurationType': 'Select All'
        })
        this.spinner.hide();
      })
      var cpyProName = this.ProName;
      this.ProName = [];
      this.ProName = cpyProName;
      this.DurationList = data.data;
      this.dataResultList = this.DurationList;
      this.dataSource.data = this.dataResultList;
    });
  }

  getDataForSeller(seller: any) {
    if (seller == "Select Seller") {
      this.ngOnInit();
    }
    this._http.getDataForSeller(seller).subscribe((data: any) => {
      this._http.getOrderDurationList().subscribe((data: any) => {
        this.DurationList = data.data;
      })
      this.DurationList = data.data;
      var _a = [];
      this.CusName = [];
      this.ProName = [];
      var _p = [];
      _a.unshift("Select Email")
      $.each(this.DurationList, function (e, t) {
        if ($.inArray(t.customer.email, _a) == -1) {
          if (t.customer.email != "" && t.customer.email.trim() != "Email address not found") {
            if (t.customer.email.trim() != "Invalid Request") {
              _a.push(t.customer.email)
            }
          }
        }
      });
      this.CusName = _a;
      _p.unshift("Select Product Title")
      $.each(this.DurationList, function (e, t) {
        if ($.inArray(t.transactionItemTitle, _p) == -1) {
          if (t.transactionItemTitle != "" && t.transactionItemTitle.trim() != "Product title not found") {
            if (t.transactionItemTitle.trim() != "Invalid Request") {
              _p.push(t.transactionItemTitle)
            }
          }
        }
      });
      this.ProName = _p;
      this.dataResultList = this.DurationList;
      this.dataSource.data = this.dataResultList;
    });
  }
  SaveResponse($event) {
    const group = {
      id: 1,
      questionText: [``,]
    };
    this.addItem(group);
  }


  saveform() {
    this.form.value
  }
  getFormInstance() {
    this.form = new FormGroup({});
    this.groups.forEach(g => {
      this.form.addControl(g.name, new FormGroup({}))
      const group = this.form.get(g.name) as FormGroup;
      g.fields.forEach(x => {
        if (x.type == 'checkbox') {
          group.addControl(x.name, new FormGroup({}))
          x.options.forEach(o => {
            (group.get(x.name) as FormGroup).addControl(o.key, new FormControl(false))
          })
        }
        else {
          group.addControl(x.name,
            new FormControl('', x.required ? Validators.required : null))
        }
      })
    })
  }
}
// @Component({
//   selector: 'dialog-overview-example-dialog',
//   templateUrl: 'dialog-overview-example-dialog.html',
// })
// export class DialogOverviewExampleDialog {
//   constructor(
//     public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
//     @Inject(MAT_DIALOG_DATA) public data: DialogData) {
//     console.log(data)
//   }
// }
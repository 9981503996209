import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthguardloginService } from './Services/AuthGuardLogin/authguardlogin.service';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { AuthGuardService } from './Services/AuthGuard/auth-guard.service';
import { FullWidthLayoutComponent } from './full-width-layout/full-width-layout.component';
import { ProductsComponent } from './products/products.component';
import { CustomersComponent } from './customers/customers.component';
import { OrdersComponent } from './orders/orders.component';
import { SellersComponent } from './sellers/sellers.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OrderdetailsComponent } from './CustomerSection/orderdetails/orderdetails.component';
import { CustomerquestionnaireComponent } from './CustomerSection/customerquestionnaire/customerquestionnaire.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { EbayOrderListComponent } from './ebay-order-list/ebay-order-list.component';
import { SetupSellerComponent } from './SellerSection/setup-seller/setup-seller.component';
import { AddquestionnairesComponent } from './addquestionnaires/addquestionnaires.component';
import { AddmultiplequestionnairesComponent } from './addmultiplequestionnaires/addmultiplequestionnaires.component';
import { AddquestionsComponent } from './addquestions/addquestions.component';
import { CcavenuresponseComponent } from './ccavenuresponse/ccavenuresponse.component';
import { ResponsedataComponent } from './responsedata/responsedata.component';
import {StudentreportsComponent} from './studentreports/studentreports.component'
import { TablewithfilterComponent } from './tablewithfilter/tablewithfilter.component';
import { IitrconsolidatedComponent } from './iitrconsolidated/iitrconsolidated.component';
import { IITROORKEEConsolidatedComponent } from './iitroorkee-consolidated/iitroorkee-consolidated.component';
import { StudentAdmissionComponent } from './student-admission/student-admission.component';
import { NonTeachingpostComponent } from './non-teachingpost/non-teachingpost.component';
import { StudentsdetailsComponent } from './studentsdetails/studentsdetails.component';
import { CcbFeeComponent } from './ccb-fee/ccb-fee.component';
import { MBAFeesComponent } from './mba-fees/mba-fees.component';
import { NewEntrantsListComponent } from './new-entrants-list/new-entrants-list.component';
import { LibraryFeeComponent } from './library-fee/library-fee.component';
import { FinanceFeeComponent } from './finance-fee/finance-fee.component';
import { CCBListComponent } from './ccb-list/ccb-list.component';
import { FinanceListComponent } from './finance-list/finance-list.component';
import { LibraryListComponent } from './library-list/library-list.component';
import { StudentRegistrationComponent } from './student-registration/student-registration.component';
import { ExexistingstudentpaymentComponent } from './exexistingstudentpayment/exexistingstudentpayment.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { UploadExcelComponent } from './upload-excel/upload-excel.component';
import { ExcelListComponent } from './excel-list/excel-list.component';
import { HDFCChallanComponent } from './hdfc-challan/hdfc-challan.component';
import { FessDeatilsComponent } from './fess-deatils/fess-deatils.component';
import { StudentpaymentdetailsComponent } from './studentpaymentdetails/studentpaymentdetails.component';
import { EDoswComponent } from './e-dosw/e-dosw.component';
import { AccountDashbordComponent } from './account-dashbord/account-dashbord.component';
import { UploadExpenditurereportComponent } from './upload-expenditurereport/upload-expenditurereport.component';
import { OfflineStudentsComponent } from './offline-students/offline-students.component';
import { NewenteranceFeesComponent } from './newenterance-fees/newenterance-fees.component';
import { ProfileComponent } from './profile/profile.component';
import { NonteachinglistComponent } from './nonteachinglist/nonteachinglist.component';
import { StudentcomplinelistComponent } from './studentcomplinelist/studentcomplinelist.component';
import { ADCIComponent } from './adci/adci.component';
import { AdciDashboardComponent } from './adci-dashboard/adci-dashboard.component';
import { UploadStudentexceldataComponent } from './upload-studentexceldata/upload-studentexceldata.component';
import { StudentLoginComponent } from './student-login/student-login.component';
import { StudentHomeComponent } from './student-home/student-home.component';
import { MediContributionComponent } from './medi-contribution/medi-contribution.component';
import { MediFundListComponent } from './medi-fund-list/medi-fund-list.component';
import { BankstatementsComponent } from './bankstatements/bankstatements.component';
import { UploadBankstatementsComponent } from './upload-bankstatements/upload-bankstatements.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { EmailMasterComponent } from './email-master/email-master.component';
import { StudentMasterComponent } from './student-master/student-master.component';
import { GenratereceiptComponent } from './genratereceipt/genratereceipt.component';
import { ListOfReceiptsComponent } from './list-of-receipts/list-of-receipts.component';
import { IitrDonationComponent } from './iitr-donation/iitr-donation.component';
import { SCPCComponent } from './scpc/scpc.component';
import { SricComponent } from './sric/sric.component';
import { NewApplicantListComponent } from './new-applicant-list/new-applicant-list.component';
import { MarriedHostelBillComponent } from './married-hostel-bill/married-hostel-bill.component';
import { MarrirdHostelDashComponent } from './marrird-hostel-dash/marrird-hostel-dash.component';
import { MedicalReceiptComponent } from './medical-receipt/medical-receipt.component';
import { AuditoriumBookingComponent } from './auditorium-booking/auditorium-booking.component';



const routes: Routes = [
  // {
  //   path: 'home',
  //   component: HomeComponent,
  //   canActivate:[AuthGuardService]
  // },
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  // {path:'FullWidthLayout',component: FullWidthLayoutComponent},
  // {path:'Product',component: ProductsComponent},
  // {
  //   path:'login',
  //   component:LoginComponent,
  //   canActivate:[AuthguardloginService]
  // },
  // {
  //   path:'signup',
  //   component:SignupComponent,
  //   canActivate:[AuthguardloginService]
  // },
  // {
  //   path:'ForgotPassword',
  //   component:ForgotPasswordComponent
  // },
  // {
  //   path:'ResetPassword',
  //   component:ResetPasswordComponent
  // },
  // {
  //   path:'Questionnaire',
  //   component:QuestionnaireComponent,
  //  // canActivate:[AuthGuardService]
  // },
 
  
       {path: 'Seller',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],  
       children: [
       {path:'Questionnaire',component:QuestionnaireComponent,canActivate: [AuthGuardService]},
       {path:'Product',component: ProductsComponent,canActivate:[AuthGuardService]},
       {path: 'home',component: HomeComponent,canActivate:[AuthGuardService]},
       {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},
       {path:'customer',component:CustomersComponent,canActivate:[AuthGuardService]},
       {path:'orders',component:OrdersComponent,canActivate:[AuthGuardService]},
       {path:'customerquestionnaire',component:CustomerquestionnaireComponent,canActivate:[AuthGuardService]},
       {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
       {path:'setupseller',canActivate:[AuthGuardService],component:SetupSellerComponent},
       {path:'add-questionnaires',canActivate:[AuthGuardService],component:AddquestionnairesComponent},
       {path:'add-multi-questionnaires',canActivate:[AuthGuardService],component:AddmultiplequestionnairesComponent},
       {path:'add-group-questionnaires',canActivate:[AuthGuardService],component:AddquestionsComponent},
       {path:'CCavenuResponse',canActivate:[AuthGuardService],component:CcavenuresponseComponent},
       {path:'tablewithfilter',canActivate:[AuthGuardService],component:TablewithfilterComponent},
       {path:'onsolidated',canActivate:[AuthGuardService],component:IitrconsolidatedComponent},
       {path:'studentsdetails',canActivate:[AuthGuardService],component:StudentsdetailsComponent}]},

 
      {path: 'Admin',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
      children: [
      {path:'Questionnaire',component:QuestionnaireComponent,canActivate: [AuthGuardService]},
      {path:'Product',component: ProductsComponent,canActivate:[AuthGuardService]},
      {path: 'home',component: HomeComponent,canActivate:[AuthGuardService]},
      // {path:'customer',component:CustomersComponent,canActivate:[AuthGuardService]},
      {path:'orders',component:OrdersComponent,canActivate:[AuthGuardService]},
      {path:'Studentdetails',component:SellersComponent},
      {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
      {path:'setupseller',canActivate:[AuthGuardService],component:SetupSellerComponent},
      {path:'add-questionnaires',canActivate:[AuthGuardService],component:AddquestionnairesComponent},
      {path:'add-multi-questionnaires',canActivate:[AuthGuardService],component:AddmultiplequestionnairesComponent},
      {path:'StudentReport',canActivate:[AuthGuardService],component:StudentreportsComponent},
      {path:'tablewithfilter',canActivate:[AuthGuardService],component:TablewithfilterComponent},
      {path:'onsolidated',canActivate:[AuthGuardService],component:IitrconsolidatedComponent},
      {path:'studentsdetails',canActivate:[AuthGuardService],component:StudentsdetailsComponent},
      {path:'NewEntrantsList',canActivate:[AuthGuardService],component:NewEntrantsListComponent},
      {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},
      {path:'CCBList',canActivate:[AuthGuardService],component:CCBListComponent},
      {path:'FinanceList',canActivate:[AuthGuardService],component:FinanceListComponent},
      {path:'LibraryList',canActivate:[AuthGuardService],component:LibraryListComponent},
      {path:'UploadExcel',canActivate:[AuthGuardService],component:UploadExcelComponent},
      {path:'Excel_Data',canActivate:[AuthGuardService],component:ExcelListComponent},
      {path:'Feesdetails',canActivate:[AuthGuardService],component:FessDeatilsComponent},
      {path:'StartStop',canActivate:[AuthGuardService],component:ResponsedataComponent},
      {path:'offline-students',canActivate:[AuthGuardService],component:OfflineStudentsComponent},
      {path:'Profile',component:ProfileComponent,canActivate:[AuthGuardService]},
      {path:'StudentComplineList',component:StudentcomplinelistComponent,canActivate:[AuthGuardService]},
      {path:'UploadStudentExcelData',component:UploadStudentexceldataComponent,canActivate:[AuthGuardService]},
      {path:'NonTeachingList',canActivate:[AuthGuardService],component:NonteachinglistComponent},
      {path:'MedicalFundList',canActivate:[AuthGuardService],component:MediFundListComponent},
      {path:'BankStatements',canActivate:[AuthGuardService],component:BankstatementsComponent},
      {path:'UploadBankExcel', canActivate:[AuthGuardService],component:UploadBankstatementsComponent},
      {path:'StudentMaster',canActivate:[AuthGuardService],component:StudentMasterComponent},
      {path:'StudentFeesDetails',component:StudentpaymentdetailsComponent,canActivate:[AuthGuardService]},
      {path:'SCPC',component:SCPCComponent,canActivate:[AuthGuardService]},
      {path:'AccountsDashboard',component:AccountDashbordComponent,canActivate:[AuthGuardService]},
      {path:'NewApplicant',component:NewApplicantListComponent,canActivate:[AuthGuardService]},
      {path:'SendMail',component:CustomersComponent,canActivate:[AuthGuardService]},
      {path:'SendMailIITR',component:CcavenuresponseComponent,canActivate:[AuthGuardService]},
      {path:'EmailMaster',component:EmailMasterComponent,canActivate:[AuthGuardService]},
      {path:'MarriedHostel', component:MarrirdHostelDashComponent,canActivate:[AuthGuardService]},
    ]},


      {path:'ADCI',component:FullWidthLayoutComponent,canActivate:[AuthGuardService],
       children:[
      {path: 'home',component: HomeComponent,canActivate:[AuthGuardService]},
      {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
      {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},
      {path:'ADCI_Dashboard',component:AdciDashboardComponent,canActivate:[AuthGuardService]}]},

      {path:'SubAdmin',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
       children:[
      {path: 'home',component: HomeComponent,canActivate:[AuthGuardService]},
      {path:'Studentdetails',component:SellersComponent},
      {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
      {path:'CCBList',canActivate:[AuthGuardService],component:CCBListComponent},
      {path:'FinanceList',canActivate:[AuthGuardService],component:FinanceListComponent},
      {path:'LibraryList',canActivate:[AuthGuardService],component:LibraryListComponent},
      {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},
      {path:'NewEntrantsList',canActivate:[AuthGuardService],component:NewEntrantsListComponent},
      {path:'NonTeachingList',canActivate:[AuthGuardService],component:NonteachinglistComponent},
      {path:'MedicalFundList',canActivate:[AuthGuardService],component:MediFundListComponent},
      {path:'SCPC',component:SCPCComponent,canActivate:[AuthGuardService]},
      {path:'NewApplicant',component:NewApplicantListComponent,canActivate:[AuthGuardService]},
      {path:'MarriedHostel', component:MarrirdHostelDashComponent,canActivate:[AuthGuardService]},
    ]},

      {path:'DOSW',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
      children:[
      {path: 'home',component: HomeComponent,canActivate:[AuthGuardService]},
      {path:'Studentdetails',canActivate:[AuthGuardService],component:SellersComponent},
      {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
      // {path:'CCBList',canActivate:[AuthGuardService],component:CCBListComponent},
      {path:'FinanceList',canActivate:[AuthGuardService],component:FinanceListComponent},
      {path:'MarriedHostel', component:MarrirdHostelDashComponent,canActivate:[AuthGuardService]},
      // {path:'LibraryList',canActivate:[AuthGuardService],component:LibraryListComponent},
      {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},]},

      {path:'NonTeaching',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
      children:[
      {path: 'home',component: HomeComponent,canActivate:[AuthGuardService]},
      // { path:'Studentdetails',component:SellersComponent},
      {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
      // {path:'CCBList',canActivate:[AuthGuardService],component:CCBListComponent},
      {path:'NonTeachingList',canActivate:[AuthGuardService],component:NonteachinglistComponent},
      // {path:'LibraryList',canActivate:[AuthGuardService],component:LibraryListComponent},
      {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},]},
  
   
       {path: 'Customer',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
       children: [
       {path:'Questionnaire',component:QuestionnaireComponent,canActivate: [AuthGuardService]},
       {path:'Product',component: ProductsComponent,canActivate: [AuthGuardService]},
       {path:'home',component: HomeComponent},
       {path:'orders',component:OrdersComponent,canActivate:[AuthGuardService]},
       {path:'orderdetails', component:OrderdetailsComponent},
       {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
       {path:'CCavenuResponse',canActivate:[AuthGuardService],component:CcavenuresponseComponent},
       {path:'Studentdetails',component:SellersComponent},
       {path:'onsolidated',canActivate:[AuthGuardService],component:IitrconsolidatedComponent},
       {path:'studentsdetails',canActivate:[AuthGuardService],component:StudentsdetailsComponent},
       {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},
       {path:'StudentFeesDetails',component:StudentpaymentdetailsComponent,canActivate:[AuthGuardService]},
       {path:'Profile',component:ProfileComponent,canActivate:[AuthGuardService]},]},

      {path: 'Student',component: FullWidthLayoutComponent,
      // component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
      children: [
      {path:'Questionnaire',component:QuestionnaireComponent,canActivate: [AuthGuardService]},
      {path:'Product',component: ProductsComponent,canActivate: [AuthGuardService]},
      {path: 'home',component: HomeComponent},
      {path:'StudentHome',component:StudentHomeComponent},
      {path:'orders',component:OrdersComponent,canActivate:[AuthGuardService]},
      {path:'orderdetails', component:OrderdetailsComponent},
      {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
      {path:'CCavenuResponse',canActivate:[AuthGuardService],component:CcavenuresponseComponent},
      {path:'Studentdetails',component:SellersComponent},
      {path:'onsolidated',canActivate:[AuthGuardService],component:IitrconsolidatedComponent},
      {path:'studentsdetails',canActivate:[AuthGuardService],component:StudentsdetailsComponent},
      {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},
      {path:'StudentFeesDetails',component:StudentpaymentdetailsComponent,canActivate:[AuthGuardService]},
      {path:'Profile',component:ProfileComponent,canActivate:[AuthGuardService]},]},

      
       {path: 'Librarian',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
       children: [
       {path:'Questionnaire',component:QuestionnaireComponent,canActivate: [AuthGuardService]},
       {path:'Product',component: ProductsComponent,canActivate: [AuthGuardService]},
       {path: 'home',component: HomeComponent},
       {path:'orders',component:OrdersComponent,canActivate:[AuthGuardService]},
       {path:'orderdetails', component:OrderdetailsComponent},
       {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
       {path:'CCavenuResponse',canActivate:[AuthGuardService],component:CcavenuresponseComponent},
       {path:'Studentdetails',component:SellersComponent},
       {path:'onsolidated',canActivate:[AuthGuardService],component:IitrconsolidatedComponent},
       {path:'studentsdetails',canActivate:[AuthGuardService],component:StudentsdetailsComponent},
       {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},]},
  
       {path:'Warden',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
       children:[
       {path: 'home',component: HomeComponent,canActivate:[AuthGuardService]},
       {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
       {path:'MarriedHostel', component:MarrirdHostelDashComponent,canActivate:[AuthGuardService]},
       {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},]},


       {path: 'bio',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
       children: [
       {path:'Questionnaire',component:QuestionnaireComponent,canActivate: [AuthGuardService]},
       {path:'Product',component: ProductsComponent,canActivate: [AuthGuardService]},
       {path: 'home',component: HomeComponent},
       {path:'orders',component:OrdersComponent,canActivate:[AuthGuardService]},
       {path:'orderdetails', component:OrderdetailsComponent},
       {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
       {path:'CCavenuResponse',canActivate:[AuthGuardService],component:CcavenuresponseComponent},
       {path:'Studentdetails',component:SellersComponent},
       {path:'onsolidated',canActivate:[AuthGuardService],component:IitrconsolidatedComponent},
       {path:'studentsdetails',canActivate:[AuthGuardService],component:StudentsdetailsComponent},
       {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},]},

       {path: 'CCB',component: FullWidthLayoutComponent, canActivate: [AuthGuardService],
       children: [
       {path:'home',component: HomeComponent},
       {path:'CCBList',canActivate:[AuthGuardService],component:CCBListComponent},
       {path:'FinanceList',canActivate:[AuthGuardService],component:FinanceListComponent},
       {path:'LibraryList',canActivate:[AuthGuardService],component:LibraryListComponent},
       {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
       {path:'ChangePassword',component:ChangePasswordComponent,canActivate:[AuthGuardService]},]},


       {path: '', redirectTo: 'login', pathMatch: 'full' },
       {path:'login', component:LoginComponent},
       {path:'home', component:HomeComponent},
       {path:'signup',component:SignupComponent},
       {path:'forgot-password', component: ForgotPasswordComponent },
       {path:'ResetPassword',component:ResetPasswordComponent,},
       {path:'my-profile',canActivate:[AuthGuardService],component:MyProfileComponent},
       {path:'consolidated',component:IITROORKEEConsolidatedComponent},
       //  {path:'StudentAdmission',component:StudentAdmissionComponent},
      {path:'NonTeachingpost',component:NonTeachingpostComponent},
       //  {path:'studentsdetails',canActivate:[AuthGuardService],component:StudentsdetailsComponent},
       {path:'CCB_Fee',component:CcbFeeComponent},
       {path:'DOSW_Fee', component:EDoswComponent},
       {path:'newEntrants_Fee',component:MBAFeesComponent},
       {path:'Library_Fee',component:LibraryFeeComponent},
       {path:'Finance_Fee',component:FinanceFeeComponent},
       //  {path:'StudentRegistration',component:StudentRegistrationComponent},
       {path:'ExistingStudents',component:ExexistingstudentpaymentComponent},
       {path:'PaymentStatus',component:CustomerquestionnaireComponent},
       
       {path:'HDFC-Challan',component:HDFCChallanComponent},
       {path:'UploadExpenditurereport',component:UploadExpenditurereportComponent},
       // {path:'New_Entrants_Fees',component:NewenteranceFeesComponent},
       {path:'ADCI_Form',component:ADCIComponent},
       {path:'ADCI_Dashboard',component:AdciDashboardComponent},
       {path:'StudentLogin',component:StudentLoginComponent},
       {path:'StudentHome',component:StudentHomeComponent},
       {path:'Medical_Contribution',component:MediContributionComponent},
       {path:'PageNotFound',component:PagenotfoundComponent},
       {path:'Product',component: ProductsComponent},
       {path:'Genrate_Receipt',component:GenratereceiptComponent},
       {path:'List-of-Receipt',component:ListOfReceiptsComponent},
       {path:'IITR_Donation',component:IitrDonationComponent},
       {path:'SRIC', component:SricComponent},
       {path:'MarriedHostelBill',component:MarriedHostelBillComponent},
      //  For Married hostel bill receipt
       {path:'Receipts',component:PaymentStatusComponent},
       {path:'medical_receipt',component:MedicalReceiptComponent},
       {path:'AuditoriumBooking',component:AuditoriumBookingComponent}
      ];
       
       

       @NgModule({
       imports: [RouterModule.forRoot(routes)],
       exports: [RouterModule]})
       export class AppRoutingModule {}

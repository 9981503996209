import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private httpClient:HttpClient) { }
  PostChangePasswordForm(formData:any){
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
    }
    console.log(window.sessionStorage.getItem("token"));
    return this.httpClient.post(environment.BaseUrl+`api/users/ChangePassword`,{
      'Email':window.sessionStorage.getItem('Email'),
      'Password':formData.Password,
      'NewPassword':formData.NewPassword
    },HttpOptions);
  }
}

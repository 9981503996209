import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SellerService } from '../Services/Seller/seller.service';

@Component({
  selector: 'app-upload-studentexceldata',
  templateUrl: './upload-studentexceldata.component.html',
  styleUrls: ['./upload-studentexceldata.component.scss']
})
export class UploadStudentexceldataComponent implements OnInit {
  stringDateModel: string = new Date().toString();
  time = {hour: 13, minute: 30};
  meridian = true;
  firstNme: string;
  lastname: string;
  name: string;
  UploadStudentExcelForm:FormGroup;
  uploadexcelFormSubmit:boolean;
  Uploadexcelsheet: string;
  fileNameList: any = [];
  private httpClient: HttpClient;
  uploadexceldata: any;
  response1: any = {};
  user: { imgPath: ""; };
  startdate1: string;
  enddate1: string;
  enddate:any;
  startdate:any;

  constructor(public datepipe: DatePipe,private router:Router,private sellerService: SellerService,private formBuilder:FormBuilder,private spinner: NgxSpinnerService,private toastr: ToastrService,private httpBackend: HttpBackend) 
  {
    this.UploadStudentExcelForm = this.formBuilder.group(
      { 
        fullname:[``,Validators.required],
        Descriptions:[``,Validators.required],
        Uploadexcelsheet: [''],
        excelcode:[``],
        startdate:[''],
        enddate:[''],
        starttime:[''],
        endtime:['']
      }
    );
   }

  ngOnInit() {
    this.firstNme=localStorage.getItem('firstName');
    this.lastname=localStorage.getItem('lastname');
    this.name=this.firstNme +" " + this.lastname
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
}
  downloadFile()
  {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = '/assets/StudentFeesbifurcation_Sample.xlsx';
    //link.download = path;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  uploadFileBrowse(fileInput, name) 
  {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowserExcelsheet(<File>fileInput.target.files, name);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowserExcelsheet(fileData, DocType) 
  {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName=this.UploadStudentExcelForm.controls.Uploadexcelsheet.value +"-"+DocType;
      formData.append(fileName,"");
      this.fileNameList.push(fileData[i].DocType);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    this.uploadexceldata=this.UploadStudentExcelForm.value.Uploadexcelsheet
    var url = environment.BaseUrl + `api/EbaySeller/UploadExcel`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.Uploadexcelsheet = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  UploadExcelSubmit()
  {
    this.uploadexcelFormSubmit=true;
      if(this.Uploadexcelsheet==""||this.Uploadexcelsheet==undefined)
      {
        this.toastr.error("Upload and check mandatory fields");
      }
      else{
        this.spinner.show();
       const data=
       {
        'fullname':this.UploadStudentExcelForm.controls['fullname'].value,
        'Uploadexcelsheet':this.Uploadexcelsheet,
        'excelcode':this.UploadStudentExcelForm.controls['excelcode'].value ,
        'Descriptions':this.UploadStudentExcelForm.controls['Descriptions'].value ,
        'startdate':this.startdate1,
        'enddate':this.enddate1,
        'starttime':this.UploadStudentExcelForm.controls['starttime'].value ,
        'endtime':this.UploadStudentExcelForm.controls['endtime'].value ,
        'Email':sessionStorage.getItem('Email'),
       }
        this.sellerService.StudentBifurcationExcelUpload(data).subscribe(res=>{
          console.log(res);
          if(res["statusCode"]==404){
            // this.wrongCurrentPassword=true;
          }
          else{
        //  this.wrongCurrentPassword=false;
         alert(res["message"]);
          this.UploadStudentExcelForm.reset();
          this.router.navigate(["Admin/Excel_Data"]);
          
          this.uploadexcelFormSubmit=false;
        }
        this.spinner.hide(); 
      },
      error=>{
        console.log(error);
        this.spinner.hide();
      });
      }

  }

  onDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.startdate1=finalDate;
   }
   onDateSelectend(event)
   {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.enddate1=finalDate;
   }
}

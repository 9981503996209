import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {MatFormFieldModule, MatError} from '@angular/material/form-field';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select'
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import {MatButtonModule, MatCheckboxModule } from '@angular/material';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';
import { LoginComponent } from './login/login.component';
import { BsDropdownModule, setTheme } from 'ngx-bootstrap';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import{QuestionnairessComponent} from './CustomerSection/orderdetails/questionnaire.component'

import {MatTooltipModule} from '@angular/material/tooltip';
import { FullWidthLayoutComponent } from './full-width-layout/full-width-layout.component';
import { ProductsComponent } from './products/products.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator'
import {MatSidenavModule} from '@angular/material/sidenav';
import { LoginService } from './Services/Login/login.service';
import { AuthService } from './Services/Auth/auth.service';
import { ProductComponent } from './product/product.component';
import { CommonserviceService } from './Services/CommonService/commonservice.service';
import { ForgotPasswordService } from './Services/ForgotPassword/forgot-password.service';
import { ResetPasswordService } from './Services/ResetPassword/reset-password.service';
import { SignupService } from './Services/SignUp/signup.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CustomersComponent } from './customers/customers.component';
import { OrdersComponent,DialogOverviewExampleDialog } from './orders/orders.component';
import { SellersComponent } from './sellers/sellers.component';
import { ModalService } from './Services/modal.service';
import {MatDialogModule} from '@angular/material/dialog';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DataTablesModule } from 'angular-datatables';
import {MatTabsModule} from '@angular/material/tabs';
import { OrderdetailsComponent } from './CustomerSection/orderdetails/orderdetails.component';
import { CustomerquestionnaireComponent } from './CustomerSection/customerquestionnaire/customerquestionnaire.component';
import { from } from 'rxjs';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MatSortModule } from '@angular/material';
import { EbayOrderListComponent } from './ebay-order-list/ebay-order-list.component';
import { SetupSellerComponent } from './SellerSection/setup-seller/setup-seller.component';
import { AddquestionnairesComponent } from './addquestionnaires/addquestionnaires.component';
import { ToastrModule } from 'ngx-toastr';
import '../polyfills';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AddmultiplequestionnairesComponent } from './addmultiplequestionnaires/addmultiplequestionnaires.component';
import { AddquestionsComponent } from './addquestions/addquestions.component';
import { CcavenuresponseComponent } from './ccavenuresponse/ccavenuresponse.component';
import { ResponsedataComponent } from './responsedata/responsedata.component';
import { StudentreportsComponent } from './studentreports/studentreports.component';
import { TablewithfilterComponent } from './tablewithfilter/tablewithfilter.component';
import { IitrconsolidatedComponent } from './iitrconsolidated/iitrconsolidated.component';
import { IITROORKEEConsolidatedComponent } from './iitroorkee-consolidated/iitroorkee-consolidated.component';
import { StudentAdmissionComponent } from './student-admission/student-admission.component';
import { NonTeachingpostComponent } from './non-teachingpost/non-teachingpost.component';
import { MatRadioModule } from '@angular/material/radio';
import {MatDatepickerModule,MatNativeDateModule} from '@angular/material';
import { StudentsdetailsComponent } from './studentsdetails/studentsdetails.component';
import { CcbFeeComponent } from './ccb-fee/ccb-fee.component';
import { MBAFeesComponent } from './mba-fees/mba-fees.component';
import { NewEntrantsListComponent } from './new-entrants-list/new-entrants-list.component';
import { LibraryFeeComponent } from './library-fee/library-fee.component';
import { FinanceFeeComponent } from './finance-fee/finance-fee.component';
import { CCBListComponent } from './ccb-list/ccb-list.component';
import { FinanceListComponent } from './finance-list/finance-list.component';
import { LibraryListComponent } from './library-list/library-list.component';
import { StudentRegistrationComponent } from './student-registration/student-registration.component';
import { ExcelService } from 'src/app/Services/excel.service';
import {DatePipe} from '@angular/common';
import { ExexistingstudentpaymentComponent } from './exexistingstudentpayment/exexistingstudentpayment.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { UploadExcelComponent } from './upload-excel/upload-excel.component';
import { ExcelListComponent } from './excel-list/excel-list.component';  
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as $ from "jquery";
import { HDFCChallanComponent } from './hdfc-challan/hdfc-challan.component';
import { FessDeatilsComponent } from './fess-deatils/fess-deatils.component';
import { MasterExcelComponent } from './master-excel/master-excel.component';
import { StudentpaymentdetailsComponent } from './studentpaymentdetails/studentpaymentdetails.component';
import { EDoswComponent } from './e-dosw/e-dosw.component';
import { AccountDashbordComponent } from './account-dashbord/account-dashbord.component';
import { UploadExpenditurereportComponent } from './upload-expenditurereport/upload-expenditurereport.component';
import { OfflineStudentsComponent } from './offline-students/offline-students.component';
import { NewenteranceFeesComponent } from './newenterance-fees/newenterance-fees.component';
import { ProfileComponent } from './profile/profile.component';
import { NonteachinglistComponent } from './nonteachinglist/nonteachinglist.component';
import { StudentcomplinelistComponent } from './studentcomplinelist/studentcomplinelist.component';
import { ADCIComponent } from './adci/adci.component';
import { AdciDashboardComponent } from './adci-dashboard/adci-dashboard.component';
import { UploadStudentexceldataComponent } from './upload-studentexceldata/upload-studentexceldata.component';
import { StudentLoginComponent } from './student-login/student-login.component';
import { StudentHomeComponent } from './student-home/student-home.component';
import { MediContributionComponent } from './medi-contribution/medi-contribution.component';
import { MediFundListComponent } from './medi-fund-list/medi-fund-list.component';
import { BankstatementsComponent } from './bankstatements/bankstatements.component';
import { UploadBankstatementsComponent } from './upload-bankstatements/upload-bankstatements.component';
import {MatProgressBarModule } from '@angular/material/progress-bar';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { EmailMasterComponent } from './email-master/email-master.component';
import { StudentMasterComponent } from './student-master/student-master.component';
import { GenratereceiptComponent } from './genratereceipt/genratereceipt.component';
import { ListOfReceiptsComponent } from './list-of-receipts/list-of-receipts.component';
import { IitrDonationComponent } from './iitr-donation/iitr-donation.component';
import { SCPCComponent } from './scpc/scpc.component';
import { SricComponent } from './sric/sric.component';
import { NewApplicantListComponent } from './new-applicant-list/new-applicant-list.component';
import { MarriedHostelBillComponent } from './married-hostel-bill/married-hostel-bill.component';
import { MarrirdHostelDashComponent } from './marrird-hostel-dash/marrird-hostel-dash.component';
import { MedicalReceiptComponent } from './medical-receipt/medical-receipt.component';
import { AuditoriumBookingComponent } from './auditorium-booking/auditorium-booking.component';


@NgModule({
  declarations: 
  [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    QuestionnaireComponent,
    FullWidthLayoutComponent,
    ProductsComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ProductComponent,
    FooterComponent,
    ChangePasswordComponent,
    CustomersComponent,
    OrdersComponent,
    SellersComponent,
    OrderdetailsComponent,
    CustomerquestionnaireComponent,
    QuestionnairessComponent,
    MyProfileComponent,
    EbayOrderListComponent,
    DialogOverviewExampleDialog,
    SetupSellerComponent,
    AddquestionnairesComponent,
    AddmultiplequestionnairesComponent,
    AddquestionsComponent,
    CcavenuresponseComponent,
    ResponsedataComponent,
    StudentreportsComponent,
    TablewithfilterComponent,
    IitrconsolidatedComponent,
    IITROORKEEConsolidatedComponent,
    StudentAdmissionComponent,
    NonTeachingpostComponent,
    StudentsdetailsComponent,
    CcbFeeComponent,
    MBAFeesComponent,
    NewEntrantsListComponent,
    LibraryFeeComponent,
    FinanceFeeComponent,
    CCBListComponent,
    FinanceListComponent,
    LibraryListComponent,
    StudentRegistrationComponent,
    ExexistingstudentpaymentComponent,
    PaymentStatusComponent,
    UploadExcelComponent,
    ExcelListComponent,
    HDFCChallanComponent,
    FessDeatilsComponent,
    MasterExcelComponent,
    StudentpaymentdetailsComponent,
    EDoswComponent,
    AccountDashbordComponent,
    UploadExpenditurereportComponent,
    OfflineStudentsComponent,
    NewenteranceFeesComponent,
    ProfileComponent,
    NonteachinglistComponent,
    StudentcomplinelistComponent,
    ADCIComponent,
    AdciDashboardComponent,
    UploadStudentexceldataComponent,
    StudentLoginComponent,
    StudentHomeComponent,
    MediContributionComponent,
    MediFundListComponent,
    BankstatementsComponent,
    UploadBankstatementsComponent,
    PagenotfoundComponent,
    EmailMasterComponent,
    StudentMasterComponent,
    GenratereceiptComponent,
    ListOfReceiptsComponent,
    IitrDonationComponent,
    SCPCComponent,
    SricComponent,
    NewApplicantListComponent,
    MarriedHostelBillComponent,
    MarrirdHostelDashComponent,
    MedicalReceiptComponent,
    AuditoriumBookingComponent,
    
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    HttpClientModule,
    NgxSpinnerModule,
    BsDropdownModule.forRoot(),
    MatButtonModule,
    MatCheckboxModule,
    AngularMultiSelectModule,
    MatTooltipModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatDialogModule,
    MatTabsModule,
    DataTablesModule,
    CollapseModule.forRoot(),
    MatSortModule,
    ToastrModule.forRoot(),
    MatProgressSpinnerModule,
    MatRadioModule,
    MatDatepickerModule,MatNativeDateModule,NgbModule,
    MatDatepickerModule,
    MatInputModule,
    MatProgressBarModule,
  ],
  providers: [{ provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService,LoginService,AuthService,CommonserviceService,ForgotPasswordService,ResetPasswordService,SignupService, ModalService,ExcelService,DatePipe],
  bootstrap: [AppComponent],
  entryComponents:[OrdersComponent,DialogOverviewExampleDialog]
})

export class AppModule {
  constructor() {
    setTheme('bs3'); // or 'bs4'
  }
 } 

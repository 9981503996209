import {SelectionModel} from '@angular/cdk/collections';
import {Component, TemplateRef, OnInit} from '@angular/core';
import {ViewChild } from '@angular/core'
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material';
import { QuestionnaireService } from '../../Services/Questionnaire/questionnaire.service';
import { HttpErrorResponse } from '@angular/common/http';


export interface PeriodicElement 
{
  
  position: number;
 
  // symbol: string;
  tag: string;
 
 
 
  
  
 
 }
 const ListOfQuestionnairesDB1: PeriodicElement[] = [];
declare var $: any;

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnairessComponent implements OnInit {
  isCollapsed = false;
  users: any = {};
  title = 'nocill';
  ListOfQuestionnairesDB: any[] = [];
  dataResultList: PeriodicElement[] = [];
  isLoader = true;
  displayedColumns: string[] = ['select', 'position', 'tag'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ListOfQuestionnairesDB);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  closeResult: string;
  showMobile: any;
  UserName: any;
  constructor(config: NgbModalConfig,private QuestionnaireService:QuestionnaireService,private modalService: NgbModal) 
    {
      config.backdrop = 'static';
      config.keyboard = false;
    }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  open(content) {
    if(this.showMobile) {
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  getDismissReason(reason: any) {
    throw new Error("Method not implemented.");
  }


   /** The label for the checkbox on the passed row */
   checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }



  ngOnInit(){
    this.dataSource.paginator = this.paginator;
    // lod list of seller
      this.QuestionnaireService.GetListOfQuestionnaires(this.UserName).subscribe((data:any) => {
      this.ListOfQuestionnairesDB = data.data.data;
      this.dataResultList = this.ListOfQuestionnairesDB;
      this.dataSource.data = this.dataResultList;
      console.log(this.ListOfQuestionnairesDB);
    },
      (err: HttpErrorResponse) => {
          this.isLoader = false;
      });            
  }
  
}
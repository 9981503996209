import { SelectionModel } from '@angular/cdk/collections';
// import { Component, Inject } from '@angular/core';
// import { ViewChild } from '@angular/core'
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material';
import { environment } from 'src/environments/environment';
import { OrderService } from '../Services/Order/order-service';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfStmt } from '@angular/compiler';
import { SellerService } from '../Services/Seller/seller.service';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { ToastrService } from 'ngx-toastr';

import { Subscription, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { debug } from 'util';
//import * as jsPDF from 'jspdf';
declare var jsPDF: any;



export interface PeriodicElement {
  jeeapplicationno :any;
  fullname:any;
  dob     :any;
  departmentname:any;
  gender        :any;
  programme:any;
  category             :any;
 year:any;
  physicallydisabled:any;
  semester          :any;
  nationality                   :any;
  specialization                :any;
  iitrmailid                    :any;
  scheme                        :any;
  aadharno         :any;
  bhawan                        :any;
  roomno                        :any;
  undertaking2                  :any;
  permanentaddress              :any;
  correspondenceaddress         :any;
  pincode                       :any;
  pincode1                      :any;
  nameofaccountholder           :any;
  bankname                      :any;
  bankaccountno                 :any;
  bankifsccode                  :any;
  fathername                    :any;
  fdesignation                  :any;
  fathermobile                  :any;
  fatheremailid                 :any;
  undertaking3                  :any;
  fatherpermentaddress          :any;
  fathercorrespondenceaddress   :any;
  pin                           :any;
  pincode2                      :any;
  mothername                    :any;
  motherdesignation             :any;
  phoneno                       :any;
  motheremailid                 :any;
  undertaking4                  :any;
  motherpermentaddress          :any;
  motherpincode                 :any;
  cpincode                      :any;
  guardianname                  :any;
  guardiandesignation           :any;
  guardianmobileno              :any;
  guardianemailid               :any;
  undertaking5                  :any;
  guardianpermanentaddress      :any;
  guardiancorrespondenceaddress :any;
  guardianpincode               :any;
  localguardianname             :any;
  localguardianemailid          :any;
  localguardianmobileno         :any;
  localguardianprementaddress   :any;
  localguardianpincode          :any;
  MSCCategory                   :any;
  MBACategory                   :any;
  
  mcaimportFileJAM2020                              :any;
  mcaimportFile10thMarksheet                        :any;
  mcaimportFile12thMarksheet                        :any;
  mcaimportFileUGmarksheets                         :any;
  mcaimportFilePGmarksheets                         :any;
  mcaimportFilequalifyingdegree                     :any;
  mcaimportFileCategorycertificate                  :any;
  mcaimportFileAadhar                               :any;
  mcaimportFilephysicallyhandicapped                :any;
  mcaimportFileIncomeTax                            :any;
  mcaimportFileannexureI                            :any;
  mcaimportFileannexureII                           :any;
  mcaimportFileannexureIII                          :any;
  mcaimportFileIsubmission                          :any;
  CandidatesCategory                                :any;
  regularcandidatesimportFileGATEScore              :any;
  regularcandidatesimportFile10thMarksheet          :any;
  regularcandidatesimportFile12thMarksheet          :any;
  regularcandidatesimportFileUGmarksheets           :any;
  regularcandidatesimportFilequalifyingdegree       :any;
  regularcandidatesimportFileCategorycertificate    :any;
  regularcandidatesimportFilephysicallyhandicapped  :any;
  regularcandidatesimportFileAadhar                 :any;
  regularcandidatesimportFilePGmarksheets           :any;
  annexureIregularcandidates                        :any;
  annexureIIregularcandidates                       :any;
  annexureIIIregularcandidates                      :any;
  regularcandidatesimportFileIsubmission1           :any;
  iitgraduatesimportFile10thMarksheet               :any;
  iitgraduatesimportFile12thMarksheet               :any;
  iitgraduatesimportFileUGmarksheets                :any;
  iitgraduatesimportFilePGmarksheets                :any;
  iitgraduatesimportFilequalifyingdegree            :any;
  iitgraduatesimportFileCategorycertificate         :any;
  iitgraduatesimportFilephysicallyhandicapped       :any;
  iitgraduatesannexureI                             :any;
  iitgraduatesannexureII                            :any;
  iitgraduatesannexureIII                           :any;
  iitgraduatesimportFileIsubmission                 :any;
  sponsoredcandidatesimportFile10thMarksheet        :any;
  sponsoredcandidatesimportFile12thMarksheet        :any;
  sponsoredcandidatesimportFileUGmarksheets         :any;
  sponsoredcandidatesimportFilePGmarksheets         :any;
  sponsoredcandidatesimportFileCategorycertificate  :any;
  sponsoredcandidatesimportFileAadhar               :any;
  sponsoredcandidatesimportFilephysicallyhandicapped  :any;
  sponsoredcandidatesimportFileExperiencecertificate  :any;
  sponsoredcandidatesimportFileSponsorshipCertificate :any;
  sponsoredcandidatesimportFileNoObjection            :any;
  sponsoredcandidatesimportFilerelieving              :any;
  sponsoredcandidatesannexureI                        :any;
  sponsoredcandidatesannexureII                       :any;
  sponsoredcandidatesannexureIII                      :any;
  sponsoredcandidatesimportFileIsubmission            :any;
  foreignnationalsimportFile10thMarksheet             :any;
  foreignnationalsimportFile12thMarksheet             :any;
  foreignnationalsimportFileUGmarksheets              :any;
  foreignnationalimportFilePassport                   :any;
  foreignnationalimportFileVisa                       :any;
  foreignnationalimportFileHIV                        :any;
  foreignnationalannexureI                            :any;
  foreignnationalannexureII                           :any;
  foreignnationalannexureIII                          :any;
  foreignnationalimportFileIsubmission                :any;
  mbaimportFileCATScoreCard                           :any;
  mbaimportFile10thMarksheet                          :any;
  mbaimportFile12thMarksheet                          :any;
  mbaimportFileUGmarksheets                           :any;
  mbaimportFilePGmarksheets                           :any;
  mbaimportFilequalifyingdegree                       :any;
  mbaimportFileCategorycertificate                    :any;
  mbaimportFileAadhar                                 :any;
  mbaimportFilephysicallyhandicapped                  :any;
  mbaimportFileIncomeTax                              :any;
  mbaannexureI                                        :any;
  mbaannexureII                                       :any;
  mbaannexureIII                                      :any;
  mbaimportFileIsubmission :any;
  sign    :any;                                           
  passportsizephoto :any;
  undertaking1:any;
  feesamount:any;
  dateofpayment :any;
  transactionid :any;
  parameter1:any;
  parameter2:any;
  parameter3:any;
  parameter4:any;
}


@Component({
  selector: 'app-studentsdetails',
  templateUrl: './studentsdetails.component.html',
  styleUrls: ['./studentsdetails.component.scss']
})
export class StudentsdetailsComponent implements OnInit {
  
  private httpClient: HttpClient;
public createImgPath = (serverPath: string) => {
  return `https://localhost:5001/${serverPath}`;
}
  IsEdit = false;
  dataResultList: PeriodicElement[] = [];

  StudentReportDB: any[] = [];
  EnableData=false;
  displayedColumns: string[] = ['select','passportsizephoto','sign','jeeapplicationno','fullname','dob','departmentname','programme','gender','year','category','physicallydisabled','semester','nationality',
  'specialization','iitrmailid','scheme','aadharno','bhawan','roomno',
  'permanentaddress','pincode','parameter1','parameter3','correspondenceaddress','pincode1','parameter2','parameter4','nameofaccountholder','bankaccountno','bankname',
  'bankifsccode','fathername','fdesignation','fathermobile','fatheremailid','fatherpermentaddress','fathercorrespondenceaddress','pin','mothername','motherdesignation','phoneno','motheremailid',
  'pincode2','motherpermentaddress','motherpincode','cpincode','guardianname',
   'guardiandesignation','guardianmobileno','guardianemailid','guardianpermanentaddress','guardiancorrespondenceaddress','guardianpincode','localguardianname','localguardianemailid','localguardianmobileno','localguardianprementaddress',
    'localguardianpincode','MBACategory',
    'mbaimportFileCATScoreCard','mbaimportFile10thMarksheet','mbaimportFile12thMarksheet','mbaimportFileUGmarksheets','mbaimportFilePGmarksheets',
   'mbaimportFilequalifyingdegree','mbaimportFileCategorycertificate','mbaimportFileAadhar','mbaimportFilephysicallyhandicapped','mbaimportFileIncomeTax',
    'mbaannexureI','mbaannexureII','mbaannexureIII','mbaimportFileIsubmission',
      'undertaking1','feesamount','dateofpayment','transactionid','Action'];

      // 'pincode','MSCCategory','mcaimportFileJAM2020','mcaimportFile10thMarksheet','mcaimportFile12thMarksheet','mcaimportFileUGmarksheets','mcaimportFilePGmarksheets','mcaimportFilequalifyingdegree','mcaimportFileCategorycertificate','mcaimportFileAadhar',
  //  'mcaimportFilephysicallyhandicapped','mcaimportFileIncomeTax','mcaimportFileannexureI','mcaimportFileannexureII','mcaimportFileannexureIII','mcaimportFileIsubmission','CandidatesCategory','regularcandidatesimportFileGATEScore','regularcandidatesimportFile10thMarksheet','regularcandidatesimportFile12thMarksheet','regularcandidatesimportFileUGmarksheets',
  //  'regularcandidatesimportFilequalifyingdegree','regularcandidatesimportFileCategorycertificate','regularcandidatesimportFilephysicallyhandicapped','regularcandidatesimportFileAadhar','regularcandidatesimportFilePGmarksheets',
  //  'annexureIregularcandidates','annexureIIregularcandidates','annexureIIIregularcandidates','regularcandidatesimportFileIsubmission1','iitgraduatesimportFile10thMarksheet',
  //  'iitgraduatesimportFile12thMarksheet','iitgraduatesimportFileUGmarksheets','iitgraduatesimportFilePGmarksheets','iitgraduatesimportFilequalifyingdegree','iitgraduatesimportFileCategorycertificate',
  //  'iitgraduatesimportFilephysicallyhandicapped','iitgraduatesannexureI','iitgraduatesannexureII','iitgraduatesannexureIII','iitgraduatesimportFileIsubmission',
  //  'sponsoredcandidatesimportFile10thMarksheet','sponsoredcandidatesimportFile12thMarksheet','sponsoredcandidatesimportFileUGmarksheets','sponsoredcandidatesimportFilePGmarksheets','sponsoredcandidatesimportFileCategorycertificate',
  //  'sponsoredcandidatesimportFileAadhar','sponsoredcandidatesimportFilephysicallyhandicapped','sponsoredcandidatesimportFileExperiencecertificate','sponsoredcandidatesimportFileSponsorshipCertificate','sponsoredcandidatesimportFileNoObjection',	
  //  'sponsoredcandidatesimportFilerelieving','sponsoredcandidatesannexureI','sponsoredcandidatesannexureII','sponsoredcandidatesannexureIII','sponsoredcandidatesimportFileIsubmission',	
  // 'foreignnationalsimportFile10thMarksheet','foreignnationalsimportFile12thMarksheet','foreignnationalsimportFileUGmarksheets','foreignnationalimportFilePassport','foreignnationalimportFileVisa',	
  //  'foreignnationalimportFileHIV','foreignnationalannexureI','foreignnationalannexureII','foreignnationalannexureIII','foreignnationalimportFileIsubmission',,'acad','dosw''undertaking2',
 

   dataSource = new MatTableDataSource<PeriodicElement>(this.StudentReportDB);

  dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.StudentReportDB);


  selection = new SelectionModel<PeriodicElement>(true, []);
  StudentReportsDbList: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  users: any;
  updateofficalform: FormGroup;
  dataExist: boolean;
  profileedit: boolean;
  UserName: any;
  userRole: any;
  response1: any={};
  user: { imgPath: any; };


  constructor(private toastr: ToastrService, private sellerService:SellerService,private spinner: NgxSpinnerService,private formbuilder:FormBuilder,private router : Router,private route: ActivatedRoute)
   {
    const dataResultList: PeriodicElement[] = [];
    for (let i = 1; i <= 15; i++) {
      //this.SellerDbList.push(this.createNewUser(i));
    }
    // Assign the data to the data source for the table to render
    this.dataSourceSimple = new MatTableDataSource(dataResultList);
    this.dataSource = new MatTableDataSource(dataResultList);


    this.updateofficalform = this.formbuilder.group(
      { 
        studId:[''],
        jeeapplicationno:[''],
        departmentname:[''],
        sclass:[''],
       
        specialization:[''],
        branch:[''],
        roomno:[''],
        category:[''],
        dob:[''],
        physicallydisabled:[''],
        bankname:[''],
        bankaccountno:[''],
        gender:[''],
        maritalstatus:[''],
        studentmobileno:[''],
        studentmailidiitr:[''],
        studentmailid:[''],
        nationality:[''],
        bloodgroup:[''],
        fathername:[''],
       // fothermobile:[''],
        mothername:[''],
        mothermobile:[''],
        guardianname:[''],
        guardianmobileno:[''],
        pin:[''],
        undertaking:[''],
        acad:[''],
        dosw:[''],
        fathermobile:['']
      })
    }

  ngOnInit() {
    this.userRole=window.localStorage.getItem('userRole');
    this.UserName = window.sessionStorage.getItem('UserName');
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;




// ********************************//
    //this.spinner.show();
    this.sellerService.getStudentRegistrationList().subscribe((data:any) => {
      this.spinner.hide();
      this.StudentReportsDbList = data.data.data;
      
      this.dataResultList = this.StudentReportsDbList;
      this.dataSource.data = this.dataResultList;
  },
  (err: HttpErrorResponse) => {
  });

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadFile(fileName)
  {
    let url = "https://localhost:44349/api/StudentRegistration/DownloadFile?fileName=" + fileName;
    window.open(url);
  }

  public downloadAsPDF() {
    const doc = new jsPDF();

    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const pdfTable = this.pdfTable.nativeElement;

    doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });

    doc.save('StudentRecords.pdf');
  }
  editofficaldata(userProfile) 
  {
    this.IsEdit=false;
    this.profileedit= true;
    this.users = userProfile;
    console.log('users', this.users);
  //   this._sellerService.showQuestions(editdata.items.displayProductId).subscribe((data:any) => {
  //     console.log('questions',data.data);
  // },
  //   (err: HttpErrorResponse) => {
       
  //   });
  }
  ViewOrder(editdata) 
  {
    $('.abc').addClass('addremovecss');
    this.IsEdit = true;
    this.users = editdata;
  }
  Goback() {
    $('.abc').removeClass('addremovecss');
    this.IsEdit = false;
    this.profileedit=false;
  }
  UpdateAcadDate()
  {
    this.sellerService.UpdateAcadData(this.updateofficalform.value).subscribe((data:any)=>{
      this.dataExist = false;
      if(data.statusCode==200)
      {
        this.profileedit=false;
        this.toastr.success("success")
        this.router.navigate(['/Admin/studentsdetails']);
      }
else
{
  this.toastr.error("something went wrong, please try again");
}
      // this.toastr.success(data.data.message);
      });
  }
}



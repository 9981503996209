import { Component, OnInit } from '@angular/core';
import * as jquery from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { SellerService } from '../Services/Seller/seller.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
interface EventTarget { result: any; }
const image = '';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-studentcomplinelist',
  templateUrl: './studentcomplinelist.component.html',
  styleUrls: ['./studentcomplinelist.component.scss']
})
export class StudentcomplinelistComponent implements OnInit {
  private httpClient: HttpClient;
  fileNameList: any = [];
  TaskDbList: any[] = [];
  IsDelete:boolean=false;
  IsEditForm:boolean=false;
  
  IsWithSameExcel:boolean=false;
  IsOTPVerify:boolean=false;
  loading = false;
  submitted = false;
  isLoader = true;
  users: any = {};
  IsEdit = false;
  excelFile= true;
  userType:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  // excelwithsamecode: any;
  firstNme: string;
  lastname: string;
  name: string;
  ExcelWithSameCode:FormGroup;
  VerifyOTPsubmit:FormGroup;
  EditExcelform:FormGroup;
  excelwithsamecodedata: any;
  uploadexceldata: any; 
  user: { imgPath: ""; };
  response1: any = {};
  Uploadexcelsheet:string;
  startdate1: string;
  enddate1: string;
  ipAddress: any;
  excelcode: any;
  Userrole: string;
  constructor(public datepipe: DatePipe,private http: HttpClient,private sellerService:SellerService, private toastr: ToastrService,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService,private httpBackend: HttpBackend) { }

  ngOnInit() {
    this.firstNme=localStorage.getItem('firstName');
    this.lastname=localStorage.getItem('lastname');
    this.name=this.firstNme +" " + this.lastname; 
    this.Userrole=sessionStorage.getItem('UserName')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.Loadstudentcomplinelist();
  }

  Loadstudentcomplinelist() {
    this.spinner.show();
    this.sellerService.ListOfStudentIssues(this.Userrole).subscribe((data: any) => {
     
      console.log('new karan', data);
      this.TaskDbList = data.data.data;
      this.spinner.hide();
      this.dtTrigger.next();
    },
      (err: HttpErrorResponse) => {
      });
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SellerService } from '../Services/Seller/seller.service';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-master',
  templateUrl: './email-master.component.html',
  styleUrls: ['./email-master.component.scss']
})
export class EmailMasterComponent implements OnInit {
  EmailForm:FormGroup;
  EmailValidationForm:FormGroup;
  IsSendMailForm: FormGroup;
  uploadexcelForm: FormGroup;
  uploadexcelFormSubmit:boolean=false;
  RemarkDbList: any[] = [];
  EmailFormSubmit:boolean=false;
  IsSendMail:boolean=false;
  TaskDbList: any[] = [];
  emailDbList: any[];
  dtTrigger: Subject<any> = new Subject();
  name = 'Angular 5';
  dropdownList = [];
  selectedItems: Array<any>;
  dropdownSettings = {};
  toppingList: any[];
  AmountStatusList: any;
  Isexcel:boolean=false;
  fileNameList: any = [];
  private httpClient: HttpClient;
  uploadexceldata: any;
  user: { imgPath: ""; };
  response1: any = {};
  Uploadexcelsheet: string;
  IsExcelList:boolean=false;
  LoadExcelListDB:any[]=[];
  dtOptions: DataTables.Settings = {};
  IsresponseHDFC: boolean;
  password: string;
  IsEmailValid: boolean;

  constructor(private httpBackend: HttpBackend,private formBuilder:FormBuilder, private router:Router,  private spinner: NgxSpinnerService,private sellerService:SellerService,private toastr: ToastrService) 
  { 
    this.EmailForm = this.formBuilder.group({ 
      Email:[``,[Validators.required, Validators.email]],});

    this.IsSendMailForm=this.formBuilder.group({
      Subject:[''],
      body:[''],
      userType:['']});

      this.uploadexcelForm = this.formBuilder.group(
        { 
          fullname:[``,Validators.required],
          Descriptions:[``,Validators.required],
          Uploadexcelsheet: [``,Validators.required],
        }
      );

      this.EmailValidationForm=this.formBuilder.group(
        {
          Password:[``, Validators.required],
        })
}

  ngOnInit() 
  {
  
    this.GetEmailList();
    this.IsSendMailForm = this.formBuilder.group({
      userType: new FormControl(''),Subject:[''],body:[''],});
    this.LoadEmails();
    //this.dropdownList = [{"id":1,"itemName":"India"},{"id":2,"itemName":"Singapore"},{"id":3,"itemName":"Australia"},{"id":4,"itemName":"Canada"}];
    //this.dropdownSettings = { singleSelection: false, text:"Select Countries",selectAllText:'Select All',unSelectAllText:'UnSelect All',enableSearchFilter: true,classes:"myclass custom-class" };
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
  }
  GetEmailList()
  {
    this.spinner.show();
    this.sellerService.EmailList().subscribe((data: any) => {
      this.RemarkDbList = data.data.data;
      console.log(this.RemarkDbList);
      this.spinner.hide();
      },(err: HttpErrorResponse) => {});
  }


  downloadFile()
  {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = '/assets/PensionersExcelData.xlsx';
    //link.download = path;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  LoadEmails()
  {
      this.spinner.show();
      this.sellerService.LoadEmails().subscribe((data: any) => {
      this.TaskDbList = data.data.data;
      this.spinner.hide();
      this.dtTrigger.next();
      },(err: HttpErrorResponse) => {});
  }
  
  
AddEmail()
{
  this.EmailFormSubmit=true;
  if(!this.EmailForm.valid){
    return;
  }
  else
  {
    this.spinner.show();
    this.sellerService.AddEmail(this.EmailForm.value).subscribe((data:any)=>{
      if(data.statusCode==200)
      {
        this.toastr.success("Email added Successfully");
       // this.LoadEmails();
      }
      else if(data.statusCode==302)
      {
        this.toastr.error("Email already added")
      }
    this.spinner.hide(); 
  });
 }
}
UploadExcelSubmit()
{
  this.uploadexcelFormSubmit=true;
    this.spinner.show();
    const data=
   {
  'fullname':this.uploadexcelForm.controls['fullname'].value,
  'Uploadexcelsheet':this.Uploadexcelsheet,
  'Descriptions':this.uploadexcelForm.controls['Descriptions'].value ,
   }

    this.sellerService.UploadExcelPensioner(data).subscribe((data:any)=>{
      if(data.statusCode==200)
      {
        this.toastr.success("Data added Successfully");
      }
      else if(data.statusCode==302)
      {
        this.toastr.error("Data already added")
      }
    this.spinner.hide(); 
  });

}
openSendMail()
{
  this.IsSendMail=true;
  this.toppingList=this.emailDbList;
  this.dropdownList =this.TaskDbList;
}
openExcelList()
{
  this.IsExcelList=true;
  this.sellerService.LoadPensionerExcelData().subscribe((data: any) => {
    this.LoadExcelListDB = data.data.data;
    this.spinner.hide();
    this.dtTrigger.next();

    },(err: HttpErrorResponse) => {});
}
CloseExcelList()
{
  this.IsExcelList=false;
}

GoBack()
{
  this.IsSendMail=false;
}

onItemSelect(item:any){
  console.log(item["email"],'new karan',);
  console.log(this.selectedItems);
}
OnItemDeSelect(item:any){
   console.log(item,"OnItemDeSelect");
   console.log(this.selectedItems);
}
onSelectAll(items: any){
   console.log(items,"onSelectAll");
}
onDeSelectAll(items: any){
   console.log(items,"onDeSelectAll");
}
OpenExcelForm()
{
this.Isexcel=true;

}
CloseExcel()
{
  this.Isexcel=false;
}

uploadFileBrowse(fileInput, name) 
{
  //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
  if (fileInput != null) {
    // if (fileInput.target.value != "" && fileInput.target.value != null) {
    this.uploadFileBrowserExcelsheet(<File>fileInput.target.files, name);
    // }
    fileInput.target.value = "";
  }
  else {
    this.toastr.error("You could not upload document", "")
  }
  //}
}
uploadFileBrowserExcelsheet(fileData, DocType) 
{
  const formData = new FormData();
  this.fileNameList = [];
  for (let i = 0; i < fileData.length; i++) {
    formData.append('file[]', fileData[i]);
    var fileName=this.uploadexcelForm.controls.Uploadexcelsheet.value +"-"+DocType;
    formData.append(fileName,"");
    this.fileNameList.push(fileData[i].DocType);
  }
  this.httpClient = new HttpClient(this.httpBackend);
  this.uploadexceldata=this.uploadexcelForm.value.Uploadexcelsheet
  var url = environment.BaseUrl + `api/EbaySeller/UploadExcel`;
  this.httpClient.post(url, formData)
    .subscribe(res => {
      this.response1 = res;
      this.user = {
        imgPath: this.response1.renameFile,
      }
      this.Uploadexcelsheet = this.user.imgPath;
      this.toastr.success("data upload successfully.");
    })
}

Runexc(con)
{
  this.spinner.show();
  this.sellerService.RunExcelDataPensioner(con.id).subscribe((data: any) => {
    this.spinner.hide();
    if(data.statusCode==200)
    {
      this.toastr.info("Excel Uploaed Successfully" )
      this.ngOnInit();
    }
    else if(data.statusCode==400)
    {
      this.toastr.warning(data.excelExits)
    }
    else
    {
      this.toastr.error("Please try again" )
    }
    console.log('new karan', data);
    var dd = data.data;
  },
    (err: HttpErrorResponse) => {
    });
}




}

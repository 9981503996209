import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {
    constructor(private httpClient:HttpClient) { }
    getCustomers(UserName:any){
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
    }
    return this.httpClient.get(environment.BaseUrl+`api/EbayCustomer/Customers?UserName=${UserName}`);
}

GetOrderDetailsByCustomerId(CustomerId: any) 
{
  return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetOrderByCustomer?CustomerId=${CustomerId}`)
}
}
import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SellerService } from '../Services/Seller/seller.service';
import html2canvas from 'html2canvas';  
declare var jsPDF: any;

@Component({
  selector: 'app-list-of-receipts',
  templateUrl: './list-of-receipts.component.html',
  styleUrls: ['./list-of-receipts.component.scss']
})
export class ListOfReceiptsComponent implements OnInit {
  TaskDbList: any[] = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  DonationData: any;
  e_receiptOpen: boolean;
  constructor(private datePipe: DatePipe,private http: HttpClient,private sellerService:SellerService, private toastr: ToastrService,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.LoadDonationData();
  }
  LoadDonationData() {
    this.spinner.show();
    this.sellerService.GetDonationReceiptList().subscribe((data: any) => {
      console.log('new karan', data);
      this.TaskDbList = data.data.data;
      this.spinner.hide();
      this.dtTrigger.next();
    },
      (err: HttpErrorResponse) => {
      });
  }

  OpenDonationReceipt(con)
  {
    this.DonationData=con;
    this.e_receiptOpen=true;
  }
  Gobackereceipt()
  {
    this.e_receiptOpen=false;
  }

  public downloadBeena()
  {
    var data = document.getElementById('pdfTable');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295; 
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('DonationReceipt.pdf'); // Generated PDF  
    });  

  }
}

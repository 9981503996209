import { SelectionModel } from '@angular/cdk/collections';
// import { Component, Inject } from '@angular/core';
// import { ViewChild } from '@angular/core'
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material';

import { OrderService } from '../Services/Order/order-service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfStmt } from '@angular/compiler';
import { SellerService } from '../Services/Seller/seller.service';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { ToastrService } from 'ngx-toastr';

import { Subscription, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { debug } from 'util';
//import * as jsPDF from 'jspdf';
declare var jsPDF: any;

export interface PeriodicElement {
  Enrollmentno: any;
  Name: any;
  Class: string;
  Department: string;
  Category: string;
  D_ND: number;
  TYPE: any;
  FeeTuition: any;
  OthChargesMBA: string;
  MiscFee: string;
  Exam: string;
  Reg: any;
  MediFee: string;
  Internet: any;
  Gymkhana: any;
  OneTimePayment: any;
  Hostel: any
  Elect: any;
  MedIns: any;
  IndTour: any;
  Bhawan: any;
  Sec: any;
  Lib: any;
  FeeOthers: any;
  TotalInsFee: any;
  OneTimeMessAdmFee: any;
  MessSecurity: any;
  HoelMessSubs: any;
  FeeMess: any;
  TotalCCB: any;
  FeeTotal: any;
  FeePaid: any;
  Diff: any;
  DueDate: any;
  Status: any;
  Remarks: any;
  Email: any;
  Mob: any;
  Report2019: any;
  Semester: any;
}

@Component({
  selector: 'app-studentreports',
  templateUrl: './studentreports.component.html',
  styleUrls: ['./studentreports.component.scss']
})
export class StudentreportsComponent{
  private sum=0;
  private value; 
  IsEdit = false;
  dataResultList: PeriodicElement[] = [];
  StudentReportDB: any[] = [];

  EnableData=false;
  displayedColumns: string[] = ['select','Enrollmentno','Name','Class','Department','Category','D_ND','TYPE','FeeTuition','OthChargesMBA','MiscFee','Exam','Reg','MediFee','Internet','Gymkhana','OneTimePayment','Hostel','Elect','MedIns','IndTour','Bhawan','Sec','Lib','FeeOthers','TotalInsFee','OneTimeMessAdmFee','MessSecurity','HoelMessSubs','FeeMess','TotalCCB','FeeTotal','FeePaid','Diff','DueDate','Status','Remarks','Email','Mob','Report2019','Semester','Action'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.StudentReportDB);

  dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.StudentReportDB);


  selection = new SelectionModel<PeriodicElement>(true, []);
  StudentReportsDbList: any;
  listcount: any;
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  users: any;
  StuDBList: any[];
  TotalFees: any;
  TotalFeesIns: number;

  constructor(private toastr: ToastrService,private sellerService:SellerService,private spinner: NgxSpinnerService,private formbuilder:FormBuilder,private router : Router,private route: ActivatedRoute) { 
    const dataResultList: PeriodicElement[] = [];
    for (let i = 1; i <= 15; i++) {
      //this.SellerDbList.push(this.createNewUser(i));
    }
    // Assign the data to the data source for the table to render
    this.dataSourceSimple = new MatTableDataSource(dataResultList);
    this.dataSource = new MatTableDataSource(dataResultList);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;




// ********************************//
    this.sellerService.getStudentReportList().subscribe((data:any) => {
      this.spinner.show();
  this.StudentReportsDbList = data.data.data;
  this.listcount= data.data.data.length;
 // this.toastr.info("We have"+" "+this.listcount+" "+"seller list");
  this.dataResultList = this.StudentReportsDbList;
  this.dataSource.data = this.dataResultList;
  this.spinner.hide();
  var arr = [];
      arr = data.data.data
      arr.unshift({ semester: "", stuId: "Select Semester" });
     // this.StuDBList = arr;
       for (let i = 0; i < data.data.data.length; i++) {
         if (data.data.data[i].semester != null) {
           this.StuDBList = arr[i].semester;
         console.log(this.StuDBList, 'studentsemesterlist')
         }
       }
       let total = 0;
       for (var i = 0; i < this.StudentReportsDbList.length; i++) {
        if (this.StudentReportsDbList[i].feePaid) {
          var numberValue = Number(this.StudentReportsDbList[i].feePaid);
        total += numberValue;
            this.TotalFees = total;
        }
    }
    console.log(this.TotalFees, 'totalfees')
    let totalFeesIns2 = 0;
    for (var i = 0; i < this.StudentReportsDbList.length; i++) {
     if (this.StudentReportsDbList[i].feeTotal) {
       var numberValue = Number(this.StudentReportsDbList[i].feeTotal);
       totalFeesIns2 += numberValue;
         this.TotalFeesIns = totalFeesIns2;
     }
 }
 console.log(this.TotalFeesIns, 'totalfeesIns')
  if(this.dataResultList.length>0){
    this.EnableData;
    }
    else{
    this.EnableData=true;
    $('.table-wrap').hide()
    //$('.mat-paginator').hide();
    }
  console.log(this.StudentReportsDbList);
  this.spinner.hide();
},
  (err: HttpErrorResponse) => {
  });
  //************************** */
  //this.dataResultList[1].semester
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public downloadAsPDF() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfTable = this.pdfTable.nativeElement;
    doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('StudentRecords.pdf');
  }
  ViewOrder(editdata) 
  {
    $('.abc').addClass('addremovecss');
    this.IsEdit = true;
    this.users = editdata;
    // this.sellerService.GetOrderDetailsByCustomerId(this.CustomerId).subscribe((data:any)=>{
    // debugger;
    // this.OrderDbListOfCustomer=data.data.data;
    // var phoneno= this.OrderDbListOfCustomer[0].phone;
    // this.customerphoneno=this.OrderDbListOfCustomer[0].phone;
    // console.log("customeroder",this.OrderDbListOfCustomer)
    // })
    // console.log('users', this.users);
  }
  Goback() {
    $('.abc').removeClass('addremovecss');
    this.IsEdit = false;
  }

}

import { Component, OnInit } from '@angular/core';
import { CommonserviceService } from '../Services/CommonService/commonservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResetPasswordService } from '../Services/ResetPassword/reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm:FormGroup;
  resetFormSubmit:true;
  wrongOtp:boolean
  confirmPasswordError:boolean;
  otp:string="";
  invalidOtp:boolean;
  hide:boolean=true;
  constructor(private commonService:CommonserviceService, private formBuilder:FormBuilder,
    private router:Router,  private spinner: NgxSpinnerService,
    private resetPwSrv:ResetPasswordService) {
      this.resetForm = this.formBuilder.group(
        { 
          Password:[``,[Validators.required]],
          ConfirmPassword:[``,[Validators.required]],
          OTP:[``,[Validators.required]]
        }
      );
     }

  ngOnInit() {
    this.commonService.emailForgotFormObsrv.subscribe(res=>{
      if(res==undefined)
        this.router.navigate(['/login']);
    })
  }
  get InvalidOtp(){
    console.log(this.otp);
    return this.invalidOtp;
  }
  CheckOTPValidity(otp:string){
    for(var text of otp){
      if(text.charCodeAt(0) >=48 && text.charCodeAt(0) <= 57){
        this.otp = otp;
        this.invalidOtp=false;
      }
      else
        {
          (<HTMLInputElement>document.getElementById("otp")).value=null;
          this.invalidOtp=true;
          this.otp=null;
        }
    }

   }
  ResetFormSubmit (){
    this.resetFormSubmit=true;
    this.confirmPasswordError=false;
   
    if(this.resetForm.value.Password!=this.resetForm.value.ConfirmPassword)
    {
      this.confirmPasswordError = true;
      return;
    }
    if(!this.resetForm.valid){
      return;
    }
      this.confirmPasswordError = false;
      this.spinner.show();
      this.resetPwSrv.PostResetPasswordForm(this.resetForm.value).subscribe(res=>{
        console.log(res);
        if(res["statusCode"]==404){
          this.wrongOtp=true;
        }
        else if(res["statusCode"]==304){
          alert(res["message"]);
        }
        else{
       this.wrongOtp=false;
       alert(res["message"]);
       this.router.navigate(["/login"]);
      }
      this.spinner.hide(); 
    },
    error=>{
      this.spinner.hide();
    });
   }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SellerService } from 'src/app/Services/Seller/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import * as jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import html2canvas from 'html2canvas';  
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
declare var jsPDF: any;

@Component({
  selector: 'app-customerquestionnaire',
  templateUrl: './customerquestionnaire.component.html',
  styleUrls: ['./customerquestionnaire.component.scss']
})
export class CustomerquestionnaireComponent implements OnInit {
  StatusOfFeesForm:FormGroup;
  CATDetailList: any[] = [];
  AmountStatus: any;
  EnrollmentNo: any;
  StudentName: any;
  StudentCategory: any;
  bankRefNo: any;
  TotalFees: any;
  RefrenceNo: any;
  Description: any;
  TaskDbList: any[] = [];
  @ViewChild('pdfasTableHDFC', {static: false}) pdfasTableHDFC: ElementRef;
  TransactionDate: any;
  Dispalydata: any;
  users: any;
  AmountStatusList: any;
  Studentpaydata: any;
  date: string;
  currentDate = new Date();
  Diffrence: any;
  e_receiptOpen: boolean;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(private datePipe: DatePipe,private SpinnerService: NgxSpinnerService,private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) { 
    this.StatusOfFeesForm=this.formBuilder.group({
      Enrollmentno:[``, Validators.required],
     });
  }

  ngOnInit() {
    this.Dispalydata= this.CATDetailList.length;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
  }
  public downloadPDFHDFC() {
    var margin = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    };
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTableHDFC = this.pdfasTableHDFC.nativeElement;
    doc.fromHTML(pdfasTableHDFC.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    },
	function(bla){doc.save('PaymentReceipt.pdf');},
margin);
    
  }
  public downloadPDFHDFC1() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTableHDFC = this.pdfasTableHDFC.nativeElement;
    doc.fromHTML(pdfasTableHDFC.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceipt.pdf');
  }
  Reset()
  {
    this.StatusOfFeesForm.reset();
    this.router.navigate(["/PaymentStatus"]);

  }
  firstDropDownChanged(val: any) {
    console.log(val);
    this.SpinnerService.show();
      this.sellerService.StudentStatusgetCatDeatilasofExixtingStudent(val).subscribe((data: any) => {
        if(data.statusCode==200)
        {
          this.SpinnerService.hide();
          this.CATDetailList = data.data.data;
          this.TaskDbList== data.data;
          this.Dispalydata= this.CATDetailList.length;
          this.toastr.show("Done")
        }
        else{
          this.toastr.show("No Data Found");
        }
      })
  }

  Refresh(editdata) 
  {
   // this.IsEdit = true;
    this.users = editdata;
    this.SpinnerService.show();
    this.sellerService.StudentPortalRefresh(this.users.id).subscribe((data: any) => {
      if(data.data != null)
{
      this.AmountStatusList = data.data.data.data;
      this.CATDetailList=data.data.data;
      if(this.AmountStatusList=='Already Success'){
        this.toastr.show(this.AmountStatusList)
      }
      else
      {
      this.toastr.show(this.AmountStatusList)
      this.SpinnerService.hide();
      }
     this.firstDropDownChanged(this.users.enrollmentno)
    }
    else
    {
      this.toastr.show('No Status Updated.')
      this.SpinnerService.hide();
    }
      //this.spinner.hide();
    })
  }

  OpenPaymentReceipt(con)
  {
    this.Studentpaydata=con;
    this.date = this.datePipe.transform(this.currentDate, 'dd-MM-yyyy');
    if(this.Studentpaydata.amountStatus!="Success")
    {
      this.Studentpaydata.messPaidFee = 0;
      this.Studentpaydata.otherPaidFee = 0;
      this.Studentpaydata.insitutePaidFee = 0;
      this.Studentpaydata.paidAmount=0;
     // this.Studentpaydata.messEsttb_Chag = 0;
      this.Studentpaydata.accommodation_Chag = 0;
      this.Studentpaydata.electricity_Chag = 0;
    }
    else
    {
      this.Studentpaydata.messFee = Number(con.messFee)+ Number(con.messEsttb_Chag);
      this.Studentpaydata.insituteFee = Number(con.insituteFee)+ Number(con.semesterCharg)+ Number(con.accommodation_Chag)+ Number(con.electricity_Chag);
      this.Studentpaydata.messPaidFee = Number(con.messFee);
      this.Studentpaydata.otherPaidFee = con.otherFee;
      this.Studentpaydata.insitutePaidFee = con.insituteFee;
      this.Studentpaydata.messEsttb_Chag = con.messEsttb_Chag;
      this.Studentpaydata.accommodation_Chag = con.accommodation_Chag;
      this.Studentpaydata.electricity_Chag = con.electricity_Chag;
   if(this.Studentpaydata.amountStatus=="Success")
   {
   this.Studentpaydata.paidAmount=con.paidAmount;
   }
    else{
      this.Studentpaydata.paidAmount=Number(con.messFee) + Number(con.otherFee) +Number(con.insituteFee) + Number(con.accommodation_Chag) + Number(con.electricity_Chag);
    }
    }
    //By Beena
    if(this.Studentpaydata.amountStatus!="Success")
    {
    this.Studentpaydata.messFee = (Number(con.messFee)+ Number(con.messEsttb_Chag));
    this.Studentpaydata.insituteFee = Number(con.insituteFee)+ Number(con.semesterCharg)+ Number(con.accommodation_Chag)+ Number(con.electricity_Chag);
    }
    this.Studentpaydata.messFeeDiff = (Number(this.Studentpaydata.messFee)) - this.Studentpaydata.messPaidFee;
    this.Studentpaydata.otherFeeDiff = this.Studentpaydata.otherFee - this.Studentpaydata.otherPaidFee;
    this.Studentpaydata.insituteFeeDiff = this.Studentpaydata.insituteFee - this.Studentpaydata.insitutePaidFee;
    this.Diffrence = Number(this.Studentpaydata.totalFees) - Number(this.Studentpaydata.paidAmount);
    //this.Diffrence = Number(this.Studentpaydata.totalFees);
    this.e_receiptOpen=true;
  }
  Gobackereceipt()
  {
    this.e_receiptOpen=false;
  }
  public downloadBeena()
  {
    var data = document.getElementById('pdfTable');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('PaymentReceiptofIITR_Fee.pdf'); // Generated PDF  
    });  

  }
}

import { Component, OnInit } from '@angular/core';
import { SellerService } from '../Services/Seller/seller.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-responsedata',
  templateUrl: './responsedata.component.html',
  styleUrls: ['./responsedata.component.scss']
})
export class ResponsedataComponent implements OnInit {
  firstNme: string;
  lastname: string;
  name: string;
  forminfo:any;
  startstopForm:FormGroup;
  uploadexcelForm:FormGroup;
  uploadexcelFormSubmit:boolean;
  TaskDbList: any[] = [];
  Name: any[];
  Date: any[];
  description: any[];
  option: any[];

  constructor(private sellerService: SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route:ActivatedRoute,private spinner: NgxSpinnerService) { 
    this.route.params.subscribe(params => {
      // this.param = params['yourParam'];
      // this.initialiseState(); // reset and set based on new parameter this time
      console.log("ccavenue response")
  });

  this.startstopForm = this.formBuilder.group(
    { 
      fullname:[``,Validators.required],
      Descriptions:[``,Validators.required],
    }
  );
  }

  ngOnInit() {
    this.firstNme=localStorage.getItem('firstName');
    this.lastname=localStorage.getItem('lastname');
    this.name=this.firstNme +" " + this.lastname;

    this.sellerService.GetFormdata().subscribe((data: any) => {
      this.TaskDbList = data.data.data;
      this.Name= this.TaskDbList[0].name;
      this.Date= this.TaskDbList[0].concurrencyStamp;
      this.description= this.TaskDbList[0].normalizedName;
      this.option= this.TaskDbList[0].options;
    })

  }


  Start()
  {
  this.spinner.show();
  const data=
   {
  'fullname':this.startstopForm.controls['fullname'].value,
  'Descriptions':this.startstopForm.controls['Descriptions'].value ,
   }
    this.sellerService.Savestartstopformdata(data).subscribe(res=>{
      console.log(res);
     alert(res["message"]);
     this.toastr.show(res["message"]);
     this.forminfo=res["message"];
    this.spinner.hide(); 
    this.ngOnInit();
  },
  error=>{
    console.log(error);
    this.spinner.hide();
  });
  }
Stop()
{
    this.spinner.show();
   const data=
   {
  'fullname':this.startstopForm.controls['fullname'].value,
  'Descriptions':this.startstopForm.controls['Descriptions'].value ,
   }
    this.sellerService.Stopapplication(data).subscribe(res=>{
      console.log(res);
      alert(res["message"]);
      this.toastr.show();
      this.forminfo=res["message"];
     this.spinner.hide(); 
     this.ngOnInit(); 
  },
  error=>{
    console.log(error);
    this.spinner.hide();
  });
  
}

}

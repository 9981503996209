import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SellerService } from '../Services/Seller/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import * as jsPDF from 'jspdf';
declare var jsPDF: any;
export interface PeriodicElement {
  EnrollmentNo:any;
  Name:any;
  Amount:any;
  remarks:any;
}
@Component({
  selector: 'app-library-fee',
  templateUrl: './library-fee.component.html',
  styleUrls: ['./library-fee.component.scss']
})
export class LibraryFeeComponent implements OnInit {
  LibraryFeesForm:FormGroup;
  LibraryFeesFormSubmit:boolean=false;
  encdata:any;
  merchant_code:any
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;
  accessCode: any;
  merchant_id:any;
  encRequest : any;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  IsresponseSBI: boolean;
  ApplicationSeq_No:any;
  CATRegistrationNos:any;
  applicationSeqNo:any = {};
  AmountStatus:any;
  ApplicantName: any;
  questionTypedeatils: any;
  currency: string;
  CATDetailList: any;
  submitted: boolean;
  RegistrationNo: any;
  constructor(private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  { 
    this.route.queryParams.subscribe(params => {
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
      this.ApplicationSeq_No=params['ApplicationSeq_No'];
      this.ApplicantName=params['ApplicantName'];
      this.RegistrationNo=params['RegistrationNo']
  });
    this.LibraryFeesForm = this.formBuilder.group({
      EnrollmentNo:[``, Validators.required],
      Name:[``,Validators.required],
      Amount:[``,Validators.required],
      remarks:[``,Validators.required],
      encdata:[''],
      merchant_code:[''],
          })
  }

  ngOnInit() {
    this.accessCode = 'AVKR88GJ13AH16RKHA';
    this.merchant_id='234485';
    this.currency='INR';
    this.merchant_code='IIT_RK';
    this.IsresponseSBI=false;

const data =
      {
        'tracking_id' : this.tracking_id,
        'bank_ref_no' : this.bank_ref_no,
        'order_status' : this.order_status,
        'amount' : this.amount,
        'billing_name' :this.billing_name,
        'merchant_param2':this.merchant_param2,
        'delivery_address':this.delivery_address,
        'sbi_ref_no':this.sbi_ref_no,
        'iitr_ref_no':this.iitr_ref_no,
        'status':this.status,
        'desc':this.desc,
        'Transactiontime_date': this.Transactiontime_date,
        'RegistrationNo':this.RegistrationNo,
      }
      if(this.status=="Success")
      {
        this.IsresponseSBI = true;
       // this.downloadPDF();
      }
       else if(this.status=="Failure") 
      {
        this.IsresponseSBI = true;
       // this.downloadPDF();
      }
      else if(this.status=="Aborted")
    {
      this.IsresponseSBI = true;
     // this.downloadPDF();
    }
    this.LibraryFeesForm.reset();
  }
  public downloadPDF() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTable = this.pdfasTable.nativeElement;
    doc.fromHTML(pdfasTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceiptofNewEntrants_Fee.pdf');
  }
  PayLibrary()
  {
    this.LibraryFeesFormSubmit=true;
    if(!this.LibraryFeesForm.valid){
      return;
    }

    this.submitted = true;
      let parameter = "iitr_ref_no=34344333|amount=1";
      this.sellerService.PayLibraryFees(this.LibraryFeesForm.value).subscribe((data:any)=>{
     console.log('sbiencdata',data.data.data)
     this.encdata=data.data.data;
     console.log(this.merchant_code,this.encdata);
     if(data.data.data.amountStatus!="Success")
     {
     setTimeout(() => {
      this.formsbi.nativeElement.submit()
    }, 1000);
    }
    else
    {
      this.toastr.info(data.data.message)
    }
    },
    error => {
      console.log(error)
      }
    );
  }

  Reset()
  {
    this.LibraryFeesForm.reset();
  }
  Goback() {
    $('.abc').removeClass('addremovecss');
     window.location.href='https://iitrpaydemo.azurewebsites.net/Library_Fee';
    this.IsresponseSBI=false;
  }
}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SellerService } from '../Services/Seller/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import * as jsPDF from 'jspdf';
declare var jsPDF: any;
interface Food {
  value: string;
  viewValue: string;
}
export interface PeriodicElement {
  applicationSeqNo:any;
      fullname:any;
      sclass:any;
      departmentname:any;
      specialization:any;
      branch:any;
      bhawan:any;
      roomno:any;
      category:any;
      dob:any;
}
@Component({
  selector: 'app-mba-fees',
  templateUrl: './mba-fees.component.html',
  styleUrls: ['./mba-fees.component.scss']
})
export class MBAFeesComponent implements OnInit {
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;
  @ViewChild('pdfasTableHDFC', {static: false}) pdfasTableHDFC: ElementRef;
  @ViewChild('form', {static: false}) form: ElementRef;
  accessCode: any;
  merchant_id:any;
  encRequest : any;
  encdata:any;
  merchant_code:any;
  currency: string;
  CATDetailList: any;
  submitted: boolean;
  Noofdept:any;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  IsresponseSBI: boolean;
  ApplicationSeq_No:any;
  CATRegistrationNos:any;
  applicationSeqNo:any = {};
  MBAFeesForm:FormGroup;
  questionTypedeatils: any;
  AppliedFees:any=0;
  order_level:any;
  AmountStatus:any;
  foods: Food[] = [
    {value: 'a', viewValue: '(a)'},
    {value: 'b', viewValue: '(b)'},
    {value: 'c', viewValue: '(c)'},
    {value: 'd', viewValue: '(d)'},
    {value: 'e', viewValue: '(e)'},
  ];
  CATRegistrationNo: any;
  ApplicantName: any;
  IsresponseHDFC: boolean=false;

  constructor(private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) { 

    this.route.queryParams.subscribe(params => {
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
      this.ApplicationSeq_No=params['ApplicationSeq_No'];
      this.ApplicantName=params['ApplicantName'];
      this.CATRegistrationNo=params['RegistrationNo'];
  });




    this.MBAFeesForm = this.formBuilder.group({
      catRegistrationNo:[``, Validators.required],
       applicationSeqNo:[``, Validators.required],
       applicantName:[``,Validators.required],
       applicantCategory:[``,Validators.required],
       course:[``,Validators.required],
       department:[``,Validators.required],
       year:[``,Validators.required],
       instituteFee:[``,Validators.required],
       mobileNo:[``,Validators.required],
       emailId:[``,Validators.required],
       aadharCardNo:[``,Validators.required],
       bankAccountNo:[``,Validators.required],
       bankIFSCCode:[``,Validators.required],
       amountStatus:[``],
       currency:[''],
       encdata:[''],
       merchant_code:[''],
       noofdepartments:[``,Validators.required],
   });
  }

  ngOnInit() 
  {
    this.accessCode = 'AVKR88GJ13AH16RKHA';
    this.merchant_id='234485';
    this.currency='INR';
    this.merchant_code='IIT_RK';
    this.Noofdept=0;
    this.IsresponseSBI=false;
    const data =
          {
            'tracking_id' : this.tracking_id,
            'bank_ref_no' : this.bank_ref_no,
            'order_status' : this.order_status,
            'amount' : this.amount,
            'billing_name' :this.billing_name,
            'merchant_param2':this.merchant_param2,
            'delivery_address':this.delivery_address,
            'sbi_ref_no':this.sbi_ref_no,
            'iitr_ref_no':this.iitr_ref_no,
            'status':this.status,
            'desc':this.desc,
            'Transactiontime_date': this.Transactiontime_date,
            'RegistrationNo':this.CATRegistrationNo
          }
          // this.iitr_ref_no = window.sessionStorage.setItem('iitr_ref_no',this.iitr_ref_no);
          // this.amount = window.sessionStorage.setItem('amount',this.amount);
          // this.iitr_ref_no = window.sessionStorage.getItem('iitr_ref_no');
          // this.amount=window.sessionStorage.getItem('amount');
    
          if(this.status=="Success")
          {
            this.IsresponseSBI = true;
           // this.downloadPDF();
          }
           else if(this.status=="Failure") 
          {
            this.IsresponseSBI = true;
           // this.downloadPDF();
          }
          else if(this.status=="Aborted")
        {
          this.IsresponseSBI = true;
         // this.downloadPDF();
        }

        if(this.order_status=="Success")
        {
          this.IsresponseHDFC = true;
        }
         else if(this.order_status=="Failure") 
        {
          this.IsresponseHDFC = true;
        }
        else if(this.order_status=="Aborted")
        {
        this.IsresponseHDFC = true;
        }
        this.MBAFeesForm.reset();
        this.MBAFeesForm = this.formBuilder.group({
        catRegistrationNo:[``, Validators.required],
        applicationSeqNo:[``, Validators.required],
        applicantName:[``,Validators.required],
        applicantCategory:[``,Validators.required],
        course:[``,Validators.required],
        department:[``,Validators.required],
        year:[``,Validators.required],
        instituteFee:[``,Validators.required],
        mobileNo:[``,Validators.required],
        emailId:[``,Validators.required],
        aadharCardNo:[``],
        bankAccountNo:[``,Validators.required],
        bankIFSCCode:[``,Validators.required],
        amountStatus:[``],
        currency:[''],
        encdata:[''],
        merchant_code:[''],
        noofdepartments:[``,Validators.required]});
        this.sellerService.GetCATNOs().subscribe((data: any) => {
        this.questionTypedeatils = data.data.data;
    })
  }
  
    firstDropDownChanged(val: any) {
    console.log(val);
    if (val == "Choose Your CAT Registration No.") {
      this.ngOnInit();
    }
      this.sellerService.getCatDeatilaAccReGNo(val).subscribe((data: any) => {
        this.CATDetailList = data.data.data;
        this.AmountStatus=this.CATDetailList.amountStatus;
        console.log("AmountList",this.AmountStatus);
        this.MBAFeesForm.controls["applicationSeqNo"].patchValue(this.CATDetailList.applicationSeqNo);
        this.MBAFeesForm.controls["applicantName"].patchValue(this.CATDetailList.applicantName);
        this.MBAFeesForm.controls["applicantCategory"].patchValue(this.CATDetailList.applicantCategory);
        this.MBAFeesForm.controls["course"].patchValue(this.CATDetailList.course);
        this.MBAFeesForm.controls["department"].patchValue(this.CATDetailList.department);
        this.MBAFeesForm.controls["year"].patchValue(this.CATDetailList.year);
        this.MBAFeesForm.controls["instituteFee"].patchValue(this.CATDetailList.instituteFee);
        this.MBAFeesForm.controls["mobileNo"].patchValue(this.CATDetailList.mobileNo);
        this.MBAFeesForm.controls["emailId"].patchValue(this.CATDetailList.emailId);
        this.MBAFeesForm.controls["aadharCardNo"].patchValue(this.CATDetailList.aadharCardNo);
        this.MBAFeesForm.controls["bankAccountNo"].patchValue(this.CATDetailList.bankAccountNo);
        this.MBAFeesForm.controls["bankIFSCCode"].patchValue(this.CATDetailList.bankIFSCCode);
        console.log("CATDetailList",this.CATDetailList);
         this.MBAFeesForm.controls["catRegistrationNo"].patchValue(this.CATDetailList.catRegistrationNo);
         this.MBAFeesForm.controls["amountStatus"].patchValue(this.CATDetailList.amountStatus);                                                                  
      })
  }

  getDataForMBA(seller: any)
  {
  if (seller == "Choose Your CAT Registration No.") {
    this.ngOnInit();
  }
    this.sellerService.getCatDeatilaAccReGNo(seller).subscribe((data: any) => {
      this.CATDetailList = data.data;
    })
  }
  public downloadPDF() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTable = this.pdfasTable.nativeElement;
    doc.fromHTML(pdfasTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceiptofNewEntrants_Fee.pdf');
  }
  public downloadPDFHDFC() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;}};
    const pdfasTableHDFC = this.pdfasTableHDFC.nativeElement;
    doc.fromHTML(pdfasTableHDFC.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceiptofNewEntrants_Fee.pdf');
  }

  PayFees()
  {
    this.submitted = true;
      let parameter = "iitr_ref_no=34344333|amount=1";
      this.sellerService.PayMBAFees(this.MBAFeesForm.value).subscribe((data:any)=>{
     console.log('sbiencdata',data.data.data)
     this.encdata=data.data.data;
     console.log(this.merchant_code,this.encdata);
     if(data.data.data.amountStatus!="Success")
     {
     setTimeout(() => {
      this.formsbi.nativeElement.submit()
    }, 1000);}
    else
    {
      this.toastr.info(data.data.message)
    }},
    error => 
    {
      console.log(error)
    }
    );
  }
  PayFeesHDFC()
  {
    if(this.MBAFeesForm.value['instituteFee']!="0" || this.MBAFeesForm.value['applicantName']!=""|| this.MBAFeesForm.value['department']!="")
    {
      const data=
      {
     'applicationSeqNo':this.MBAFeesForm.controls['applicationSeqNo'].value,
     'catRegistrationNo':this.MBAFeesForm.controls['catRegistrationNo'].value,
     'applicantName':this.MBAFeesForm.controls['applicantName'].value,
     'applicantCategory':this.MBAFeesForm.controls['applicantCategory'].value ,
     'department':this.MBAFeesForm.controls['department'].value ,
     'noofdepartments':this.MBAFeesForm.controls['noofdepartments'].value,
     'year':this.MBAFeesForm.controls['year'].value,
     'course':this.MBAFeesForm.controls['course'].value,
     'instituteFee':this.AppliedFees,
     'mobileNo':this.MBAFeesForm.controls['mobileNo'].value,
     'emailId':this.MBAFeesForm.controls['emailId'].value,
     'aadharCardNo':this.MBAFeesForm.controls['aadharCardNo'].value,
     'bankAccountNo':this.MBAFeesForm.controls['bankAccountNo'].value,
     'bankIFSCCode':this.MBAFeesForm.controls['bankIFSCCode'].value,
      }
    this.sellerService.ResponseHandlerHDFC(data).subscribe((data:any)=>{
    console.log('---------------------', data.data.data)
    this.encRequest = data.data.data; 
    console.log(this.accessCode,this.encRequest);
     if(data.data.data.amountStatus!="Success")
     {
    setTimeout(() => {
      this.form.nativeElement.submit()
    }, 1000);
  }
  else
  {
    this.toastr.info(data.data.message)
  }
    }, error => {
    console.log(error)
    }
    );
  }
  else
  {
    this.toastr.error("Please check all Parameters.")
  }
  }

  Summess(data)
  {
    if(this.MBAFeesForm.value['applicantCategory']=="GEN" || this.MBAFeesForm.value['applicantCategory']=="OBC" || this.MBAFeesForm.value['applicantCategory']=="GEN-EWS")
    {
      this.AppliedFees=data * 200;
    }
    if(this.MBAFeesForm.value['applicantCategory']=="GEN-PD" || this.MBAFeesForm.value['applicantCategory']=="OBC-PD"|| this.MBAFeesForm.value['applicantCategory']=="SC"|| this.MBAFeesForm.value['applicantCategory']=="ST")
    {
      this.AppliedFees=data * 100;
    }
  }

  CategoryChanges(cat)
  {
    if(cat=="GEN" || cat=="OBC" || cat=="GEN-EWS")
    {
      this.AppliedFees=this.MBAFeesForm.value['noofdepartments'] * 200;
    }
    if(cat=="GEN-PD" || cat=="OBC-PD"|| cat=="SC"|| cat=="ST")
    {
      this.AppliedFees=this.MBAFeesForm.value['noofdepartments'] * 100;
    }
  }

  Reset()
  {
    this.MBAFeesForm.reset();
  }
  Goback() 
  {
    $('.abc').removeClass('addremovecss');
     window.location.href='https://payment.iitr.ac.in/newEntrants_Fee';
    this.IsresponseSBI=false;
    this.IsresponseHDFC=false;
  }
}

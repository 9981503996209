import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SellerService } from '../Services/Seller/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import * as jsPDF from 'jspdf';
declare var jsPDF: any;

@Component({
  selector: 'app-iitroorkee-consolidated',
  templateUrl: './iitroorkee-consolidated.component.html',
  styleUrls: ['./iitroorkee-consolidated.component.scss']
})
export class IITROORKEEConsolidatedComponent implements OnInit {
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;
  accessCode: any;
  merchant_id:any;
  encRequest : any;
  encdata:any;
  merchant_code:any;
  currency: string;

  addquestionnairesform:FormGroup;
  submitted: boolean;
  isLoader: boolean;
  questionTypedeatils: any;
  radioSelected: any;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  IsresponseSBI: boolean;

  constructor(private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute)
  { 
    this.route.queryParams.subscribe(params => {
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
  });
    


    
    this.addquestionnairesform = this.formBuilder.group({
    Name:[``, Validators.required],
    Department:[``,Validators.required],
    Course:[``,Validators.required],
    Year:[``,Validators.required],
    Aadhar_No:[``],
    DepositeAmountFor:[``,Validators.required],
    MobileNo:[``,Validators.required],
    Email:[``,Validators.required],
    OtherFeeAmount:[``,Validators.required],
    Remark:[``,Validators.required],
    Enrollmentno:[``,Validators.required],
    encdata:[''],
    merchant_code:[''],
        })
      }
  ngOnInit() {
    this.accessCode = 'AVKR88GJ13AH16RKHA';
    this.merchant_id='234485';
    this.currency='INR';
    this.merchant_code='IIT_RK';

    this.IsresponseSBI=false;
    const data =
          {
            'tracking_id' : this.tracking_id,
            'bank_ref_no' : this.bank_ref_no,
            'order_status' : this.order_status,
            'amount' : this.amount,
            'billing_name' :this.billing_name,
            'merchant_param2':this.merchant_param2,
            'delivery_address':this.delivery_address,
            'sbi_ref_no':this.sbi_ref_no,
            'iitr_ref_no':this.iitr_ref_no,
            'status':this.status,
            'desc':this.desc,
            'Transactiontime_date': this.Transactiontime_date,
          }
          // this.iitr_ref_no = window.sessionStorage.setItem('iitr_ref_no',this.iitr_ref_no);
          // this.amount = window.sessionStorage.setItem('amount',this.amount);
          // this.iitr_ref_no = window.sessionStorage.getItem('iitr_ref_no');
          // this.amount=window.sessionStorage.getItem('amount');
    
          if(this.status=="Success")
          {
            this.IsresponseSBI = true;
          }
           else if(this.status=="Failure") 
          {
            this.IsresponseSBI = true;
          }
          else if(this.status=="Aborted")
        {
          this.IsresponseSBI = true;
        }

this.addquestionnairesform.reset();


  }
  PayFees()
  {
    this.submitted = true;
      let parameter = "iitr_ref_no=34344333|amount=1";
      this.sellerService.ChecksumOfOpenFees(this.addquestionnairesform.value).subscribe((data:any)=>{
     console.log('sbiencdata',data.data.data)
     this.encdata=data.data.data;
     console.log(this.merchant_code,this.encdata);
     setTimeout(() => {
      this.formsbi.nativeElement.submit()
    }, 1000);
    },
    error => {
      console.log(error)
      }
    );
  }

  public downloadPDF() {
    const doc = new jsPDF();

    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTable = this.pdfasTable.nativeElement;
    doc.fromHTML(pdfasTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceipt.pdf');
  }
  Goback() {
    $('.abc').removeClass('addremovecss');
    // local
   // window.location.href='http://localhost:4200/Admin/Studentdetails';
    // sever
  // window.location.href='https://iitrwebapppayment.azurewebsites.net/Admin/Studentdetails';
     window.location.href='https://iitrpaydemo.azurewebsites.net/consolidated';
    //http://localhost:4200
    //window.location.href='https://iitrwebapppayment.azurewebsites.net/Admin/Studentdetails';
    
    this.IsresponseSBI=false;
  }
}

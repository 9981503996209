import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  login = new BehaviorSubject<boolean>(false);
  loginObsrv = this.login.asObservable();
  username = new BehaviorSubject<string>(window.sessionStorage.getItem("UserName")!=undefined?window.sessionStorage.getItem("UserName"):window.localStorage.getItem("UserName"));
  usernameObsrv = this.username.asObservable();
  emailForgotForm = new BehaviorSubject<string>(undefined);
  emailForgotFormObsrv = this.emailForgotForm.asObservable();
  constructor() {
    if(window.sessionStorage.getItem("token")!=undefined || window.localStorage.getItem("token")!=undefined)
      {
        this.login.next(true);
      }
   }

}

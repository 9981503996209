import { Component, OnInit } from '@angular/core';
import {ProfileService} from '../Services/profile/profile.service'
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { SellerService } from '../Services/Seller/seller.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  dataExist:boolean=false;
  email: string;
  userProfile:any['firstName']=[];
  UserName: string;
  users: any;
  profileedit: boolean= false;
  Profileform: FormGroup;
  fileNameList: any = [];
  private httpClient: HttpClient;
  response1: any = {};
  user: { imgPath: ""; };
  paymentSlip: string;
  paymentSlipFlag: boolean = true;
  dataimageup: any;
  url = '';
  Enrollment: string;

  constructor(private sellerService: SellerService,private SpinnerService: NgxSpinnerService,private httpBackend: HttpBackend,private toastr: ToastrService,private router: Router,private profileService:ProfileService,private formbuilder:FormBuilder,private modalService: NgbModal) {
    this.Profileform = this.formbuilder.group(
      { 
        fullname:[``, Validators.required],
        mobileNo:[``, Validators.required],
        alternateno:[``, Validators.required],
        email:[``, Validators.required],
        alternateemail:[``, Validators.required],
        address:['', Validators.required],
        accountname:['', Validators.required],
        accountno:['', Validators.required],
        ifsccode:['', Validators.required],
        bankname:['', Validators.required],
        paymentreceipt: ['', Validators.required],
        fileInput:['', Validators.required],
        Enrollment:['', Validators.required],
        filePayment:['',Validators.required],
        passportsizephoto: new FormControl('', Validators.required),
      })
   }

  ngOnInit() {
    this.Enrollment= window.localStorage.getItem('Enrollment');
  }

  paymentReceiptDetail(fileInput) {
    if (fileInput != null) {
      this.uploadPaymentReceipt(<File>fileInput.target.files);
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadPaymentReceipt(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.Profileform.controls.paymentreceipt.value;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    this.SpinnerService.show();
    var url = environment.BaseUrl + `api/EbaySeller/uploadbankaccountstatements`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.SpinnerService.hide();
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.Profileform.controls.paymentreceipt.patchValue(this.user.imgPath);
        this.paymentSlip = this.user.imgPath;
        this.paymentSlipFlag = true;
      })
  }
  onSelectFilePassport(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.dataimageup = <FileReader>event.target;
        this.url = this.dataimageup.result;
        // this.uploadFile_onSelectFileSign(event);
      }
    }
  }

  addProfile()
  {
    const data=
    {
   'fullname':this.Profileform.controls['fullname'].value,
   'mobileNo':this.Profileform.controls['mobileNo'].value ,
   'alternateno':this.Profileform.controls['alternateno'].value,
   'email':this.Profileform.controls['email'].value,
   'alternateemail':this.Profileform.controls['alternateemail'].value,
   'address':this.Profileform.controls['address'].value,
   'accountname':this.Profileform.controls['accountname'].value,
   'accountno':this.Profileform.controls['accountno'].value,
   'ifsccode':this.Profileform.controls['ifsccode'].value,
   'bankname':this.Profileform.controls['bankname'].value,
   'paymentreceipt':this.Profileform.controls['paymentreceipt'].value,
   'Enrollment':this.Enrollment,
   'filePayment':this.Profileform.controls['filePayment'].value,
   'passportsizephoto':this.url,
    }
    this.sellerService.AddStudentProfile(data).subscribe((result: any) => {
      if (result.statusCode == 200) {
        this.Profileform.reset();
        this.url="";
        this.paymentSlip="";
        this.toastr.success("Data Added Successfully.");
       // this.router.navigate(["/Admin/home"]);
      }
      else {
        this.toastr.error("something went wrong please try again");
      }
      this.SpinnerService.hide();
    })
  }
  Goback()
  {
    this.Profileform.reset();
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-addquestions',
  templateUrl: './addquestions.component.html',
  styleUrls: ['./addquestions.component.scss']
})
export class AddquestionsComponent implements OnInit {
  public invoiceForm: FormGroup;
  submitted: boolean;
  isLoader: boolean;
  questionTypedeatils: any;
  radioSelected: any;
  contacts: Array<'itemRows'>;

  constructor(private QuestionnaireService: QuestionnaireService,private _fb: FormBuilder,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,config: NgbModalConfig) {
    config.backdrop = 'static';
    this.contacts = [];
    config.keyboard = false;
    this.invoiceForm = this.formBuilder.group({
      QuestionText: ['', Validators.required],
      QuestionType: ['',],
      TagName: ['',]
    });
   }

  ngOnInit() 
  {
    this.QuestionnaireService.getQuestionType().subscribe((data: any) => {
    this.questionTypedeatils = data.data.data;
    console.log(data.data.data,'typelist')
    })
    this.invoiceForm = this._fb.group({
      itemRows: this._fb.array([this.initItemRows()], Validators.required)
      // itemRows: this._fb.array([], Validators.required)

    });
  }
//add new row here//
get formArr() {
  return this.invoiceForm.get('itemRows') as FormArray;
}
getItemRows(form) {
  return form.get('itemRows').controls;
}
initItemRows() {
  return this._fb.group({
    QuestionText: ['', Validators.maxLength(100)],
    TagName: [''],
    QuestionType: ['']
  });
}
addNewRow() {
  this.formArr.push(this.initItemRows());
}
deleteRow(index: number) {
  index = 1;
  console.log(index, 'indexvalue')
  if (index == 1) {
    this.formArr.removeAt(index);
  }
  else {
    index = 0;
  }
}

AddQuestion() {
  let itemRows = this.invoiceForm.get('itemRows') as FormArray;
  console.log(this.invoiceForm);
  if(itemRows.status=="VALID")
  {
  this.QuestionnaireService.AddMultiQuestion(this.invoiceForm.value.itemRows).subscribe((result :any) => {
    console.log(result);
    localStorage.removeItem('product id');
    if(result.statusCode==200)
    {
    this.router.navigate(["/Seller/Questionnaire"]);
    this.toastr.success("Questionnaires added successfully");
    }
    else
    {
      this.toastr.error("Something went wrong, please try again"); 
    }
  },
    (err: HttpErrorResponse) => {
    });
  }
  else
  {
    this.toastr.error(" Please fill all input fields");
  }
}

Goback() 
{
  this.router.navigate(["/Seller/Questionnaire"]);
}
}

import { Component, OnInit } from '@angular/core';
import {ProfileService} from '../Services/profile/profile.service'
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  dataExist:boolean=false;
  email: string;
  userProfile:any['firstName']=[];
  UserName: string;
  users: any;
  profileedit: boolean= false;
  updateProfileform: FormGroup;

  constructor(private toastr: ToastrService,private router: Router,private profileService:ProfileService,private formbuilder:FormBuilder,private modalService: NgbModal) 
  { 
    this.updateProfileform = this.formbuilder.group(
      { 
        userId:[''],
        firstName:[``],
        lasttName:[``],
        mobileNo:[``],
        registrationAddress:[''],
        city:[''],
        state:[''],
        country:[''],
        postalCode:['']
      })
  }

  ngOnInit() {
    //this.UserName= window.localStorage.getItem('UserName');
    this.UserName= window.sessionStorage.getItem('UserName');
    this.profileService.GetUserProfile(this.UserName).subscribe((data:any)=>{
      this.userProfile=data.data;
      console.log(this.userProfile);
      this.dataExist=true;
    })
  }
  ViewProfile(userProfile) 
  {
    this.dataExist=false;
    this.profileedit= true;
    this.users = userProfile;
    console.log('users', this.users);
  //   this._sellerService.showQuestions(editdata.items.displayProductId).subscribe((data:any) => {
  //     console.log('questions',data.data);
  // },
  //   (err: HttpErrorResponse) => {
       
  //   });
  }
  Goback() {
    this.profileedit=false;
    this.dataExist=true;
  }
  UpdateProfile()
  {
    this.profileService.UpdateProfile(this.updateProfileform.value).subscribe((data:any)=>{
      this.dataExist = false;
      if(data.statusCode==200)
      {
        this.toastr.success("Profile updated successfull")
        this.router.navigate(['/Admin/home']);
      }
else
{
  this.toastr.error("something went wrong, please try again");
}
      // this.toastr.success(data.data.message);
      });
   //   window.location.reload();
  }
}

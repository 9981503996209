import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-adci',
  templateUrl: './adci.component.html',
  styleUrls: ['./adci.component.scss']
})
export class ADCIComponent implements OnInit {
  ShowFilter = false;
  ADCIForm:FormGroup;
  // toppings = new FormControl();
  toppingList: string[] = ['All Departments','Architecture and Planning', 'Applied Science and Engineering', 'Biotechnology', 'Chemical Engineering', 'Chemistry', 'Civil Engineering','Computer Science and Engineering','Earthquake Engineering','Earth Sciences','Electrical Engineering','Electronics and Communication Engineering','Humanities and Social Sciences','Hydrology','Hydro and Renewable Energy','Management Studies','Mathematics','Mechanical and Industrial Engineering','Metallurgical and Materials Engineering','Paper Technology','Polymer and Process Engineering','Physics','Water Resources Development and Management','Centre of Nanotechnology','Centre of Excellence in Disaster Mitigation & Management','Centre for Transportation Systems','Continuing Education Centre','Design Innovation Center','Institute Instrumentation Centre'];
  userProfile:any['firstName']=[];
  UserName: string;
  users: any;
  profileedit: boolean= false;
  Profileform: FormGroup;
  fileNameList: any = [];
  private httpClient: HttpClient;
  response1: any = {};
  user: { imgPath: ""; };
  paymentSlip: string;
  paymentSlipFlag: boolean = true;

  constructor(private spinner: NgxSpinnerService,private httpBackend: HttpBackend,private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  { 
    this.ADCIForm = this.formBuilder.group({
      title:[``, Validators.required],
      sponsorName:[``,Validators.required],
      websitelink:[``,Validators.required],
      startdate:[``,Validators.required],
      enddate:[``,Validators.required],
      departmentname:[''],
      filedata:['',Validators.required],
      toppings:[''],
      proposaltheme:[''],
      paymentreceipt: ['', Validators.required],})
  }

  ngOnInit() 
  {
  }
  UploadFile(fileInput) 
  {
    if (fileInput != null) 
    {
      this.uploadPaymentReceipt(<File>fileInput.target.files);
      fileInput.target.value = "";
    }
    else 
    {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadPaymentReceipt(fileData) 
  {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) 
    {
      formData.append('file[]', fileData[i]);
      var fileName = this.ADCIForm.controls.paymentreceipt.value;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    this.spinner.show();
    var url = environment.BaseUrl + `api/EbaySeller/uploadADCIDocuments`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.spinner.hide();
        this.response1 = res;
        this.user = 
        {
          imgPath: this.response1.fileName,
        }
        this.ADCIForm.controls.paymentreceipt.patchValue(this.user.imgPath);
        this.paymentSlip = this.user.imgPath;
        this.paymentSlipFlag = true;
      })
  }
  addsponserdata()
  {
    this.spinner.show();
    const data =
    {
      'filedata' : this.paymentSlip,
      'paymentreceipt':this.ADCIForm.controls['paymentreceipt'].value,
      'toppings' : this.ADCIForm.controls['toppings'].value,
      'enddate' : this.ADCIForm.controls['enddate'].value,
      'startdate' : this.ADCIForm.controls['startdate'].value,
      'websitelink':this.ADCIForm.controls['websitelink'].value,
      'sponsorName':this.ADCIForm.controls['sponsorName'].value,
      'title' :this.ADCIForm.controls['title'].value,
      'proposaltheme':this.ADCIForm.controls['proposaltheme'].value,
    }
    this.sellerService.SubmitADCIData(data).subscribe((data:any)=>{
      this.spinner.hide();
      //this.IsStudentCompline=false;
      this.toastr.success(data.message);
    }, error => {
      console.log(error)
      });
    this.ADCIForm.reset();
    this.paymentSlip=null;
    // this.toastr.success("Data submit sccessfully ");
  }
}

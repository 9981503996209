import { SelectionModel } from '@angular/cdk/collections';
// import { Component, Inject } from '@angular/core';
// import { ViewChild } from '@angular/core'
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material';

import { OrderService } from '../Services/Order/order-service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfStmt } from '@angular/compiler';
import { SellerService } from '../Services/Seller/seller.service';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { ToastrService } from 'ngx-toastr';

import { Subscription, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { debug } from 'util';
//import * as jsPDF from 'jspdf';
import { ExcelService } from '../Services/excel.service';
import * as XLSX from 'xlsx'; 
import {DatePipe} from '@angular/common';
declare var jsPDF: any;

export interface PeriodicElement 
{
      catRegistrationNo:any;
      applicationSeqNo:any;
      applicantName:any;
      applicantCategory:any;
      course:any;
      department:any;
      year:any;
      instituteFee:any;
      mobileNo:any;
      emailId:any;
      aadharCardNo:any;
      bankAccountNo:any;
      bankIFSCCode:any;
      amountStatus:any;
      // CreatedBy:any;
}
@Component({
  selector: 'app-new-applicant-list',
  templateUrl: './new-applicant-list.component.html',
  styleUrls: ['./new-applicant-list.component.scss']
})
export class NewApplicantListComponent implements OnInit {
  IsEdit = false;
  dataResultList: PeriodicElement[] = [];
  order_level: any;
  startDate:any;
  endDate:any;
  StudentReportDB: any[] = [];
  EnableData=false;
  displayedColumns: string[] = ['select','applicationSeqNo','applicantName','applicantCategory','course','noofdepartments','mobileNo','emailId','aadharCardNo','bankAccountNo',
'bankIFSCCode','instituteFee','amountStatus','bankRefNo','bank','iitR_Ref_No','transactiontime_date','description','catRegistrationNo','Action'];
 

   dataSource = new MatTableDataSource<PeriodicElement>(this.StudentReportDB);
   

  dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.StudentReportDB);


  selection = new SelectionModel<PeriodicElement>(true, []);
  StudentReportsDbList: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;  
  users: any;
  updateofficalform: FormGroup;
  SortDateForm:FormGroup;
  dataExist: boolean;
  profileedit: boolean;
  UserName: any;
  userRole: any;
  AmountStatusList: any;
  exelList:any = [];
  someDateVar: string;
  someDateVar2: string;
  PHDNew:any;

  constructor(public datepipe: DatePipe,private excelService:ExcelService,private toastr: ToastrService,private sellerService:SellerService,private spinner: NgxSpinnerService,private formbuilder:FormBuilder,private router : Router,private route: ActivatedRoute) 
  { 
    const dataResultList: PeriodicElement[] = [];
    for (let i = 1; i <= 15; i++) {
      //this.SellerDbList.push(this.createNewUser(i));
    }
    // Assign the data to the data source for the table to render
    this.dataSourceSimple = new MatTableDataSource(dataResultList);
    this.dataSource = new MatTableDataSource(dataResultList);

    this.updateofficalform = this.formbuilder.group(
      { 
        catRegistrationNo:[''],
        applicationSeqNo:[''],
        applicantName:[''],
        applicantCategory:[''],
        course:[''],
        department:[''],
        year:[''],
        instituteFee:[''],
        mobileNo:[''],
        aadharCardNo:[''],
        emailId:[''],
        bankaccountno:[''],
        bankAccountNo:[''],
        bankIFSCCode:[''],
        amountStatus:[''],
       
      })
      this.SortDateForm= this.formbuilder.group(
        { 
          startDate:[''],
          endDate:[''],
        })
  }

  ngOnInit() 
  {
    this.userRole=window.localStorage.getItem('userRole');
    this.UserName = 'Ph.D.';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // ********************************//
   this.getLoadData();
  this.PHDNewEnteranceCount();
  }
  getLoadData()
  {
      this.sellerService.getNewEntrantslist(this.UserName).subscribe((data:any) => {
      this.spinner.show();
      this.StudentReportsDbList = data.data.data;
  // this.listcount= data.data.data.length;
 // this.toastr.info("We have"+" "+this.listcount+" "+"seller list");
  this.dataResultList = this.StudentReportsDbList;
  this.dataSource.data = this.dataResultList;
  this.spinner.hide();
  },
  (err: HttpErrorResponse) => {
  });
  }
  getDataForSeller(seller: any)
  {
    this.sellerService.getDataofNewEnteranceReport(seller).subscribe((data: any) => {
        this.AmountStatusList = data.data;
        this.dataResultList = this.AmountStatusList;
        this.dataSource.data = this.dataResultList;
      })
  }

  PHDNewEnteranceCount()
  {
    this.sellerService.GetPHDNewEnteranceCount().subscribe((data: any) => {
    this.PHDNew = data.data;
      })
  }

  getDataofCourse(seller: any)
  {
    this.sellerService.getDataofCourse(seller).subscribe((data: any) => 
    {
        this.AmountStatusList = data.data;
        this.dataResultList = this.AmountStatusList;
        this.dataSource.data = this.dataResultList;
    })
  }

  getDate(data:any)
  {
     this.startDate;
     this.endDate;
    // let myDate = new Date(); 
     this.someDateVar = this.datepipe.transform(this.startDate, "yyyy/dd/MM");
     this.someDateVar2 = this.datepipe.transform(this.endDate, "yyyy/dd/MM");
     this.sellerService.GetFilterDataOfDatePHD(this.someDateVar,this.someDateVar2).subscribe((data: any) => {
     this.AmountStatusList = data.data.data;
     this.dataResultList = this.AmountStatusList;
     this.dataSource.data = this.dataResultList;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) 
    {
      this.dataSource.paginator.firstPage();
    }
  }
  public downloadAsPDF() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfTable = this.pdfTable.nativeElement;
    doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });

    doc.save('StudentRecords.pdf');
  }
  editofficaldata(userProfile) 
  {
    this.IsEdit=false;
    this.profileedit= true;
    this.users = userProfile;
    console.log('users', this.users);
  //   this._sellerService.showQuestions(editdata.items.displayProductId).subscribe((data:any) => {
  //     console.log('questions',data.data);
  // },
  //   (err: HttpErrorResponse) => {
       
  //   });
  }
  ViewOrder(editdata) 
  {
    $('.abc').addClass('addremovecss');
    this.IsEdit = true;
    this.users = editdata;
  }
  Goback() {
    $('.abc').removeClass('addremovecss');
    this.IsEdit = false;
    this.profileedit=false;
  }
  UpdateAcadDate()
  {
    this.sellerService.UpdateAcadData(this.updateofficalform.value).subscribe((data:any)=>{
      this.dataExist = false;
      if(data.statusCode==200)
      {
        this.profileedit=false;
        this.toastr.success("success")
        this.router.navigate(['/Admin/studentsdetails']);
      }
else
{
  this.toastr.error("something went wrong, please try again");
}
      // this.toastr.success(data.data.message);
      });
  }
  currentDate = new Date();

  ExportTOExcel() 
  {  
   // document.getElementById("TypeText")
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();  
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');  
    XLSX.writeFile(wb, 'ScoreSheet.xlsx');  
  }  
  // exportAsXLSX():void {
  //   this.excelService.exportAsExcelFile(this.data, 'sample');
  // }
  exportAsXLSX():void {
      this.dataSource.data = this.dataResultList;
      this.exelList=this.dataSource.data;
    //this.exelList.push(this.dataSource.data[0]);
    this.excelService.exportAsExcelFile(this.exelList,'StudentFeesReport');
  }

  Refresh(editdata) 
  {
   // this.IsEdit = true;
    this.users = editdata;
    this.spinner.show();
    this.sellerService.VLSIMTECHrefrecneDataAmountStatus(this.users.feesId).subscribe((data: any) => {
      this.AmountStatusList = data.data;
      if(data.data.data=='Already Success'){
        this.toastr.show(this.AmountStatusList);
        this.spinner.hide();
        this.getLoadData();
      }
      else if(data.data.data=='1|No Record Found|')
      {
        this.spinner.hide();
        this.toastr.show(this.AmountStatusList.data)
      }
      else{
        this.spinner.hide();
      this.toastr.show(this.AmountStatusList.data)
    }
  //     this.dataResultList = this.AmountStatusList;
  //     this.dataSource.data = this.dataResultList;
  //     this.dataSource.paginator = this.paginator;
  // this.dataSource.sort = this.sort;
      
    })
  }

  RefershAllData()
  {
    this.spinner.show();
    this.sellerService.RefreshAllStatusAPIMTECHVLSI().subscribe((data: any) => {
      this.AmountStatusList = data.data;
      if(data.data.data=='Already Success'){
        this.toastr.show(this.AmountStatusList)
      }
      else{
      this.toastr.show(this.AmountStatusList.data)
    }
  //     this.dataResultList = this.AmountStatusList;
  //     this.dataSource.data = this.dataResultList;
  //     this.dataSource.paginator = this.paginator;
  // this.dataSource.sort = this.sort;
      this.spinner.hide();
      this.ngOnInit();
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonserviceService } from '../Services/CommonService/commonservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ForgotPasswordService } from '../Services/ForgotPassword/forgot-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm:FormGroup;
  forgotPasswordFormSubmit:true;
  userDoesNotExist:boolean
  constructor(private commonService:CommonserviceService, private formBuilder:FormBuilder,
     private router:Router,  private spinner: NgxSpinnerService,
     private forgotPasswordService:ForgotPasswordService) {
    this.forgotPasswordForm = this.formBuilder.group(
      { 
        Email:[``,[Validators.required,Validators.minLength(3), Validators.maxLength(50)]],
      }
    );
   }

  ngOnInit() {
  }
  ForgotFormSubmit(){
    this.forgotPasswordFormSubmit=true;
    if(!this.forgotPasswordForm.valid){
      return;
    }
    else{
      this.spinner.show();
      this.forgotPasswordService.PostForgotPasswordForm(this.forgotPasswordForm.value).subscribe(res=>{
        console.log(res);
        if(res["statusCode"]==404){
          this.userDoesNotExist=true;
        }
        else{
       this.commonService.emailForgotForm.next(this.forgotPasswordForm.value.Email);
       this.userDoesNotExist=false;
       alert(res["message"]);
       this.router.navigate(["/ResetPassword"]);
      }
      this.spinner.hide(); 
    },
    error=>{
      this.spinner.hide();
    });
   }
}
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { data } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonserviceService } from '../Services/CommonService/commonservice.service';
import { LoginService } from '../Services/Login/login.service';

@Component({
  selector: 'app-student-login',
  templateUrl: './student-login.component.html',
  styleUrls: ['./student-login.component.scss']
})
export class StudentLoginComponent implements OnInit {
  FormStudentLogin: FormGroup;
  IsOTP: any=false;
  userRole: string;
  statuscode: string;
  constructor(private formBuilder:FormBuilder, private serviceLogin:LoginService, private spinner: NgxSpinnerService,private router:Router, private commonService:CommonserviceService, private toastr: ToastrService) 
  { 
    this.FormStudentLogin = this.formBuilder.group({
      email:[``,[Validators.required,Validators.minLength(3), Validators.maxLength(50)]],
      otp:[``,[Validators.required, Validators.minLength(1),Validators.maxLength(50)]],
      // Enrollment:[``],
    });
  }

  ngOnInit() {
    this.IsOTP=false;
  }
  OTPVerify()
  {
    
    this.spinner.show();
    this.serviceLogin.VerifyOTP(this.FormStudentLogin.value,"").subscribe(res=>{
     if(res["data"]["statusCode"]==200 )
     {
      this.toastr.success((res["data"]["message"]));
      window.localStorage.setItem("Email",`${res["data"]["data"]["studentEmail"]}`);
      localStorage.setItem('Enrollment',res["data"]["data"]["enrollmentNo"]);
      localStorage.setItem('UserName',res["data"]["data"]["name"]);
      localStorage.setItem('PageId',res["data"]["data"]["pageId"])
      this.userRole="Student-N-01";
      localStorage.setItem('userRole', this.userRole)
      this.router.navigate(["Student/StudentHome"]);
      this.spinner.hide();
    }
    else
    {
      this.spinner.hide();
      this.toastr.error((res["data"]["message"]));
    }
    })
  }
  OTPRequest()
  {
    this.spinner.show();
    this.serviceLogin.SendOTP(this.FormStudentLogin.value).subscribe(res=>{
      console.log(res);
      // res["data"].isActive
      if(res["statusCode"]==400)
      {
        this.toastr.error(res["message"]);
      }
      else if(res["statusCode"]==304)
      {
        alert(res["message"]);
      }
      else
      {
        this.toastr.success((res["message"]));
        this.IsOTP=res["data"].isActive;    
    }
    this.spinner.hide(); 
  },
  error=>{
    this.spinner.hide();
  });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private httpClient:HttpClient) { }
  PostLoginForm(formData:any){
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
       })   
    }
    return this.httpClient.post(environment.BaseUrl+`api/users/Login`,formData,HttpOptions);
  }
  SendOTP1(formData:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
       })   
    }
    return this.httpClient.post(environment.BaseUrl+`api/users/SendOTPStudentLogin`,formData,HttpOptions);
  }

  SendOTP(email:any) 
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/users/SendOTPStudentLogin?email=${email.email}`);
  }

  VerifyOTP(otp:any,email:any) 
  { 
    return this.httpClient.get(environment.BaseUrl + `/api/users/StudentVerifyOTP?otp=${otp.otp}&&email=${otp.email}`);
  }
  
}


import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { OrderService } from '../Services/Order/order-service';
import { ProfileService } from '../Services/profile/profile.service';
import { SellerService } from '../Services/Seller/seller.service';

@Component({
  selector: 'app-student-master',
  templateUrl: './student-master.component.html',
  styleUrls: ['./student-master.component.scss']
})
export class StudentMasterComponent implements OnInit {
  Enrollment:string="";
  UserName:any;
  userProfile: any;
  enrollmentno: string;
  name:any;
  emailId: any;
  mobileNo: any;
  category: any;
  course: any;
  department: any;
  d_DN: any;
  status_year: any;
  ProfileImage: any;
  LastLogindate: any;
  logintime: any;
  space: string;
  IsFeeSection: boolean=false;
  TaskDbList: any[] = [];
  LogDbList: any[] = [];
  TaskDbListstudent: any[] = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(private _http: OrderService, private spinner: NgxSpinnerService,private formbuilder:FormBuilder, private sellerService: SellerService, private toastr: ToastrService, private profileService: ProfileService) { }

  ngOnInit() {
    if(this.ProfileImage==null)
    {
this.ProfileImage="assets/img/avatar2.png";
    }
  }
  clickmobiledata(val: any) 
  {
   val= this.Enrollment
 this.FindMasterData(val);
  }
  FindMasterData(val: any)
  {
  this.spinner.show();
    this.profileService.GetStudentProfileDataFromEnrollment(val).subscribe((data:any)=>{
     this.spinner.hide();
     if(data.data== null)
     {
       this.toastr.show("Data Not Found")
      this.userProfile="";
      this.name="";
      this.enrollmentno="";
      this.emailId="";
      this.mobileNo="";
      this.category="";
      this.course="";
      this.department="";
      this.d_DN="";
      this.status_year="";
      this.ProfileImage="assets/img/avatar2.png";
      this.LastLogindate="";
      this.logintime="";
     }
     else
     {
      this.toastr.success("Data Found Successfully")
      this.userProfile=data.data;
      this.name=this.userProfile.name;
      this.enrollmentno=this.userProfile.enrollmentno;
      this.emailId=this.userProfile.emailId;
      this.mobileNo=this.userProfile.mobileNo;
      this.category=this.userProfile.category;
      this.course=this.userProfile.course;
      this.department=this.userProfile.department;
      this.d_DN=this.userProfile.d_DN;
      this.status_year=this.userProfile.status_year;
      this.ProfileImage=this.userProfile.firstName;
      this.LastLogindate=this.userProfile.lastLogindate;
      this.logintime=this.userProfile.logintime;
      this.space=" ";
     }
    })
  }
  Reset()
  {
    this.Enrollment="";
  }
  IsFeeSectionOpen()
  {
    this.IsFeeSection=true;
    this. loadExcel() ;
  }
  GoBackStud()
  {
    this.IsFeeSection=false;
  }

  loadExcel() 
  {
    this.UserName=this.emailId;
    this.Enrollment=this.enrollmentno;
   if(this.UserName != undefined && this.Enrollment != undefined && this.UserName != "" && this.Enrollment != "")
   {
    this.spinner.show();
    this.sellerService.getSellerList(this.UserName,this.Enrollment).subscribe((data: any) => {
      if(data.data.statusCode== 404)
      {
        this.toastr.error("No Data Found.");
        this.spinner.hide();
        this.dtTrigger.next();
      }
      else
      {
      this.TaskDbList = data.data.data;
      this.LogDbList=data.data.datamodel;
      this.TaskDbListstudent=data.data.data;
      this.spinner.hide();
      this.dtTrigger.next();
      }
    },
      (err: HttpErrorResponse) => {
      });
  }
  else
  {
this.toastr.error("Please enter enrollment no.")
  }
}


}

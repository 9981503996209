import { Component, OnInit, ViewChild } from '@angular/core';
import { SellerService } from '../Services/Seller/seller.service';
import { from } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatOption } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerService } from '../Services/customer/customer.service';

@Component({
  selector: 'app-ccavenuresponse',
  templateUrl: './ccavenuresponse.component.html',
  styleUrls: ['./ccavenuresponse.component.scss']
})
export class CcavenuresponseComponent implements OnInit {
  IsSendMailForm: FormGroup;
  RemarkDbList: any[] = [];
  // myselectedFoods: any[] = [];
  txt: string;
  userTypeFilters = [
    {key: 1, value: 'Value 1',},
    {key: 2, value: 'Value 2',},
    {key: 3, value: 'Value 3',},
    {key: 4, value: 'Value 4',}];

  @ViewChild('allSelected',{static: false}) private allSelected: MatOption;
  constructor(private router:Router,private formBuilder:FormBuilder,private sellerService:SellerService,private spinner: NgxSpinnerService,private customerService:CustomerService,private toastr: ToastrService)
   { 
    this.IsSendMailForm=this.formBuilder.group(
    {
     Subject:[''],
     body:[''],
     toppings:[],
     order_level:[],
     EmailList:[],
     });
   }

  ngOnInit() 
  {this.GetEmailList();
   this.IsSendMailForm = this.formBuilder.group({
     userType: new FormControl(''),
     Subject:[''],
     body:[''],
   });
  }

  toggleAllSelection() 
  {
    if (this.allSelected.selected) {
      this.IsSendMailForm.controls.userType.patchValue([...this.RemarkDbList.map(item => item), 0]);
        // .patchValue([...this.userTypeFilters.map(item => item.key), 0]);
    } else {this.IsSendMailForm.controls.userType.patchValue([]);}
  }
  GetEmailList()
  {
    this.spinner.show();
    this.sellerService.EmailList().subscribe((data: any) => {
      this.RemarkDbList = data.data.data;
      this.spinner.hide();
      },(err: HttpErrorResponse) => {});
  }
  Goback() 
  {
    $('.abc').removeClass('addremovecss');
    this.router.navigate(["Admin/EmailMaster"]);
  }

  SendMail()
  {
    let emaillist=null;
    const data =
    {
      'userType':this.IsSendMailForm.controls['userType'].value,
      'EmailList' : emaillist,
      'Subject':this.IsSendMailForm.controls['Subject'].value,
      'body' : this.IsSendMailForm.controls['body'].value,
    }
    this.sellerService.SendMailPensionerIITR(data).subscribe((data: any) => {
      this.toastr.success(data.message);
      this.IsSendMailForm.reset();
    }, error => 
    {
      console.log(error)
      });
  }
}

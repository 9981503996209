import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SellerService } from 'src/app/Services/Seller/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import * as jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import html2canvas from 'html2canvas';  
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
declare var jsPDF: any;

@Component({
  selector: 'app-medical-receipt',
  templateUrl: './medical-receipt.component.html',
  styleUrls: ['./medical-receipt.component.scss']
})
export class MedicalReceiptComponent implements OnInit {
  StatusOfFeesForm:FormGroup;
  CATDetailList: any[] = [];
  AmountStatus:any;
  EnrollmentNo:any;
  StudentName: any;
  StudentCategory:any;
  bankRefNo:any;
  TotalFees:any;
  RefrenceNo:any;
  Description:any;
  @ViewChild('pdfasTableHDFC', {static: false}) pdfasTableHDFC: ElementRef;
  Tracking_id:any;
  dtOptions: DataTables.Settings = {};
  Dispalydata: any=0;
  emp_data: any;
  AmountStatusList: any;
  MarriedData: any;
  date: string;
  currentDate = new Date();
  e_receiptOpen: boolean;
  constructor(private datePipe: DatePipe,private SpinnerService: NgxSpinnerService,private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  { 
    this.StatusOfFeesForm=this.formBuilder.group({
      Enrollmentno:[``, Validators.required],
      RefrenceNo:  [``, Validators.required],
      amount:      [``, Validators.required],
      p_type:      [``, Validators.required],
     });
  }

  ngOnInit() {
    this.Dispalydata= this.CATDetailList.length;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
  }
  public downloadPDFHDFC() {
    const doc = new jsPDF();
    const specialElementHandlers = 
    {
      '#editor': function (element, renderer) 
      {
        return true;
      }
    };
    const pdfasTableHDFC = this.pdfasTableHDFC.nativeElement;
    doc.fromHTML(pdfasTableHDFC.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceipt.pdf');
  }
  Reset()
  {
    this.StatusOfFeesForm.reset();
    this.router.navigate(["/PaymentStatus"]);
  }
  
  firstDropDownChanged(val: any) 
  {
    
    console.log(val);
    this.SpinnerService.show();
      this.sellerService.MedicalFundsReceiptDetails(val).subscribe((data: any) => {
        if(data.statusCode==200)
        {
          this.SpinnerService.hide();
          this.CATDetailList = data.data.data;
          this.CATDetailList== data.data;
          this.Dispalydata= this.CATDetailList.length;
          this.toastr.show("Done")
        }
        else{
          this.toastr.show("No Data Found");
        }
      })
  }
  clickmobiledata(val:any)
  {
   debugger;
   var data= this.StatusOfFeesForm.controls.Enrollmentno.value;
   this.firstDropDownChanged(data);
  }

  Refresh(editdata) 
  {
   // this.IsEdit = true;
    this.emp_data = editdata;
    this.SpinnerService.show();
    this.sellerService.MarriedHostelRefresh(this.emp_data.id).subscribe((data: any) => {
      if(data.data != null)
{
      this.AmountStatusList = data.data.data.data;
      this.CATDetailList=data.data.data;
      if(this.AmountStatusList=='Already Success'){
        this.toastr.show(this.AmountStatusList)
      }
      else
      {
      this.toastr.show(this.AmountStatusList)
      this.SpinnerService.hide();
      }
     this.firstDropDownChanged(this.emp_data.enrollment_Employment)
    }
    else
    {
      this.toastr.show('No Status Updated.')
      this.SpinnerService.hide();
    }
      //this.spinner.hide();
    })
  }
  OpenPaymentReceipt(con)
  {
debugger;
    this.MarriedData=con;
    this.date = this.datePipe.transform(this.currentDate, 'dd-MM-yyyy');
    this.e_receiptOpen=true;
  }
  Goback()
  {
    this.e_receiptOpen=false;
  }
  public downloadBeena()
  {
    var data = document.getElementById('pdfTable');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('IITR_Bill_Receipt.pdf'); // Generated PDF  
    });  

  }
}

import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SellerService } from '../Services/Seller/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { ModalService } from '../Services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-student-registration',
  templateUrl: './student-registration.component.html',
  styleUrls: ['./student-registration.component.scss']
})
export class StudentRegistrationComponent implements OnInit {
  @ViewChild('labelImport', { static: false }) labelImport: ElementRef;
  formImport: FormGroup;
  fileToUpload: File = null;
  nomCourant = 'Select Course';
  nomCourant1 = 'Select Category';
  nomCourant2 = 'Select';
  nomCourant3 = 'Select';
  IsLavel2: boolean;
  IsLavel1: boolean;
  IsLavel3: boolean;
  IsLavel4: boolean;
  IsLavel5: boolean = false;
  IsLavel6: boolean = false;
  MBA: boolean;
  MSC: boolean;
  PHD: boolean;
  MTECH: boolean;
  CandidatesCategory: boolean;
  RegularCandidates: boolean;
  IITGraduates: boolean;
  Sponsoredcandidates: boolean;
  StudentREgistrationform: FormGroup;
  StudentFormSubmit: boolean = false;
  status: any;
  humanizeBytes: Function;
  dragOver: boolean;
  ForeignNationals: boolean;
  EnraollmentDetailList: any;
  AmountStatus: any;
  dataimageup: any;
  url = '';
  url1 = '';
  checked: any;
  checked1: any;
  checked2: any = false;
  checked3: any;
  checked4: any;
  checked5: any;
  BankIFSC: any;
  MSCCategory: boolean = false;
  MBACategory: boolean = false;
  Email: string;
  private bodyText: string;
  submitted: boolean;
  fileNameList: any = [];
  files: any = [];
  target: any = {};
  @Input() Documents: any;
  public response: { dbPath: '' };
  response1: any = {};
  mcaimportFile10thMarksheet10: any;


  //




  private httpClient: HttpClient;
  user: { imgPath: ""; };
  mcaimportFileJAM2020MCA1: any;
  mcaimportFile10thMarksheet1: string;
  mcaimportFile12thMarksheet1: string;
  mcaimportFileUGmarksheets1: string;
  mcaimportFilePGmarksheets1: string;
  mcaimportFilequalifyingdegree1: string;
  mcaimportFileCategorycertificate1: string;
  mcaimportFileAadhar: string;
  mcaimportFilephysicallyhandicapped1: string;
  mcaimportFileIncomeTax1: string;
  mcaimportFileannexureI1: string;
  mcaimportFileannexureII1: string;
  mcaimportFileannexureIII1: string;
  mcaimportFileIsubmission1: string;
  regularcandidatesimportFileGATEScore1: string;
  regularcandidatesimportFile10thMarksheet1: string;
  regularcandidatesimportFile12thMarksheet1: string;
  regularcandidatesimportFileUGmarksheets1: string;
  regularcandidatesimportFilePGmarksheets1: string;
  regularcandidatesimportFilequalifyingdegree1: string;
  regularcandidatesimportFileCategorycertificate1: string;
  regularcandidatesimportFilephysicallyhandicapped1: string;
  annexureIregularcandidates1: string;
  annexureIIregularcandidates1: string;
  annexureIIIregularcandidates1: string;
  regularcandidatesimportFileIsubmission11: string;
  iitgraduatesimportFile10thMarksheet1: string;
  iitgraduatesimportFile12thMarksheet1: string;
  iitgraduatesimportFileUGmarksheets1: string;
  iitgraduatesimportFilePGmarksheets1: string;
  iitgraduatesimportFilequalifyingdegree1: string;
  iitgraduatesimportFileCategorycertificate1: string;
  iitgraduatesimportFilephysicallyhandicapped1: string;
  iitgraduatesannexureI1: string;
  iitgraduatesannexureII1: string;
  iitgraduatesannexureIII1: string;
  iitgraduatesimportFileIsubmission1: string;
  sponsoredcandidatesimportFile10thMarksheet1: string;
  sponsoredcandidatesimportFile12thMarksheet1: string;
  sponsoredcandidatesimportFileUGmarksheets1: string;
  sponsoredcandidatesimportFilePGmarksheets1: string;
  sponsoredcandidatesimportFileCategorycertificate1: string;
  sponsoredcandidatesimportFileAadhar1: string;
  sponsoredcandidatesimportFilephysicallyhandicapped1: string;
  sponsoredcandidatesimportFileExperiencecertificate1: string;
  sponsoredcandidatesimportFileSponsorshipCertificate1: string;
  sponsoredcandidatesimportFileNoObjection1: string;
  sponsoredcandidatesimportFilerelieving1: string;
  sponsoredcandidatesannexureI1: string;
  sponsoredcandidatesannexureII1: string;
  sponsoredcandidatesannexureIII1: string;
  sponsoredcandidatesimportFileIsubmission1: string;
  foreignnationalsimportFile10thMarksheet1: string;
  foreignnationalsimportFile12thMarksheet1: string;
  foreignnationalsimportFileUGmarksheets1: string;
  foreignnationalimportFilePassport1: string;
  foreignnationalimportFileVisa1: string;
  foreignnationalimportFileHIV1: string;
  foreignnationalannexureI1: string;
  foreignnationalannexureII1: string;
  foreignnationalimportFileIsubmission1: string;
  mbaimportFileCATScoreCard1: string;
  mbaimportFile10thMarksheet1: string;
  mbaimportFile12thMarksheet1: string;
  mbaimportFileUGmarksheets1: string;
  mbaimportFilePGmarksheets1: string;
  mbaimportFilequalifyingdegree1: string;
  mbaimportFileCategorycertificate1: string;
  mbaimportFilephysicallyhandicapped1: string;
  mbaimportFileIncomeTax1: string;
  mbaannexureI1: string;
  mbaannexureII1: string;
  mbaannexureIII1: string;
  mbaimportFileIsubmission1: string;
  onSelectFileSign1: string;
  onSelectFile1: string;
  regularcandidatesimportFileAadhar1: string;
  foreignnationalannexureIII1: string;
  mbaimportFileAadhar1: string;
  PermentAddress: any;
  CorrespondensAddress: any;
  FatherPermentAddress: any;
  FatherCorrespondensAddress: any;
  FatherPinAddress: any;
  MotherPinAddress: any;
  MotherPermentAddress: any;
  GuardianonPinAddress: any;
  GuardianonPermentAddress: any;
  passportsizephoto: any;
  SSign: any;
  CorrespondensCity: any;
  CorrespondensState: any;
  studentjeeno: any;

  // *ngIf="userRole =='CCB'"
  constructor(private SpinnerService: NgxSpinnerService, private httpBackend: HttpBackend, private modalService: ModalService, private sellerService: SellerService, private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService, private route: ActivatedRoute) {
    this.IsLavel1 = true;
    this.StudentREgistrationform = this.formBuilder.group({
      Parameter1: [``, Validators.required],
      Parameter2: [``, Validators.required],
      Parameter3: [``, Validators.required],
      Parameter4: [``, Validators.required],
      jeeapplicationno: [``, Validators.required],
      StudId: [``, Validators.required],
      Enrollmentno: [``, Validators.required],
      ApplicationNO: ['', Validators.required],
      fullname: ['', Validators.required],
      programme: ['', Validators.required],
      fathername: ['', Validators.required],
      gender: ['', Validators.required],
      fathermobile: ['', Validators.required],
      aadharno: ['', Validators.required],
      semester: ['', Validators.required],
      year: ['', Validators.required],
      fdesignation: ['', Validators.required],
      category: ['', Validators.required],
      physicallydisabled: ['', Validators.required],
      nationality: ['', Validators.required],
      departmentname: ['', Validators.required],
      specialization: ['', Validators.required],
      iitrmailid: ['', Validators.required],
      scheme: ['', Validators.required],
      permanentaddress: ['', Validators.required],
      candidatescategorystudents: ['', Validators.required],
      MSCCategory: ['', Validators.required],
      MBACategory: ['', Validators.required],
      transactionid: ['', Validators.required],
      dob: ['', Validators.required],
      nameofaccountholder: ['', Validators.required],
      pin: ['', Validators.required],
      feesamount: ['', Validators.required],
      phoneno: ['', Validators.required],
      parameter2: ['', Validators.required],
      dateofpayment: ['', Validators.required],
      mcaimportFileJAM2020: new FormControl('', Validators.required),
      mcaimportFile10thMarksheet: new FormControl('', Validators.required),
      mcaimportFile12thMarksheet: new FormControl('', Validators.required),
      mcaimportFileUGmarksheets: new FormControl('', Validators.required),
      mcaimportFilePGmarksheets: new FormControl('', Validators.required),
      mcaimportFileCategorycertificate: new FormControl('', Validators.required),
      mcaimportFileAadhar: new FormControl('', Validators.required),
      mcaimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      mcaimportFilequalifyingdegree: new FormControl('', Validators.required),
      regularcandidatesimportFileGATEScore: new FormControl('', Validators.required),
      importFileExperiencecertificate: new FormControl('', Validators.required),
      importFileSponsorshipCertificate: new FormControl('', Validators.required),
      mcaimportFileIsubmission: new FormControl('', Validators.required),
      regularcandidatesimportFileIsubmission1: new FormControl('', Validators.required),
      passportsizephoto: new FormControl('', Validators.required),
      importFilerelieving: new FormControl('', Validators.required),
      importFilePassport: new FormControl('', Validators.required),


      importFileCATScoreCard: new FormControl('', Validators.required),

      importFileIsubmission3: new FormControl('', Validators.required),

      mcaimportFileIncomeTax: new FormControl('', Validators.required),
      mcaimportFileannexureI: new FormControl('', Validators.required),
      mcaimportFileannexureII: new FormControl('', Validators.required),
      mcaimportFileannexureIII: new FormControl('', Validators.required),
      regularcandidatesimportFile10thMarksheet: new FormControl('', Validators.required),
      regularcandidatesimportFile12thMarksheet: new FormControl('', Validators.required),
      regularcandidatesimportFileUGmarksheets: new FormControl('', Validators.required),
      regularcandidatesimportFilequalifyingdegree: new FormControl('', Validators.required),
      regularcandidatesimportFileCategorycertificate: new FormControl('', Validators.required),
      regularcandidatesimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      regularcandidatesimportFileAadhar: new FormControl('', Validators.required),
      regularcandidatesimportFilePGmarksheets: new FormControl('', Validators.required),
      annexureIregularcandidates: new FormControl('', Validators.required),
      annexureIIregularcandidates: new FormControl('', Validators.required),
      annexureIIIregularcandidates: new FormControl('', Validators.required),
      iitgraduatesimportFile10thMarksheet: new FormControl('', Validators.required),
      iitgraduatesimportFile12thMarksheet: new FormControl('', Validators.required),
      iitgraduatesimportFileUGmarksheets: new FormControl('', Validators.required),
      iitgraduatesimportFilePGmarksheets: new FormControl('', Validators.required),
      iitgraduatesimportFilequalifyingdegree: new FormControl('', Validators.required),
      iitgraduatesimportFileCategorycertificate: new FormControl('', Validators.required),
      iitgraduatesimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      iitgraduatesannexureI: new FormControl('', Validators.required),
      iitgraduatesannexureII: new FormControl('', Validators.required),
      iitgraduatesannexureIII: new FormControl('', Validators.required),
      iitgraduatesimportFileIsubmission: new FormControl('', Validators.required),
      sponsoredcandidatesimportFile10thMarksheet: new FormControl('', Validators.required),
      sponsoredcandidatesimportFile12thMarksheet: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileUGmarksheets: new FormControl('', Validators.required),
      sponsoredcandidatesimportFilePGmarksheets: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileCategorycertificate: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileAadhar: new FormControl('', Validators.required),
      sponsoredcandidatesimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileExperiencecertificate: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileSponsorshipCertificate: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileNoObjection: new FormControl('', Validators.required),
      sponsoredcandidatesimportFilerelieving: new FormControl('', Validators.required),
      sponsoredcandidatesannexureI: new FormControl('', Validators.required),
      sponsoredcandidatesannexureII: new FormControl('', Validators.required),
      sponsoredcandidatesannexureIII: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileIsubmission: new FormControl('', Validators.required),
      foreignnationalsimportFile10thMarksheet: new FormControl('', Validators.required),
      foreignnationalsimportFile12thMarksheet: new FormControl('', Validators.required),
      foreignnationalsimportFileUGmarksheets: new FormControl('', Validators.required),
      foreignnationalimportFilePassport: new FormControl('', Validators.required),
      foreignnationalimportFileVisa: new FormControl('', Validators.required),
      foreignnationalimportFileHIV: new FormControl('', Validators.required),
      foreignnationalannexureI: new FormControl('', Validators.required),
      foreignnationalannexureII: new FormControl('', Validators.required),
      foreignnationalannexureIII: new FormControl('', Validators.required),
      foreignnationalimportFileIsubmission: new FormControl('', Validators.required),
      mbaimportFileCATScoreCard: new FormControl('', Validators.required),
      mbaimportFile10thMarksheet: new FormControl('', Validators.required),
      mbaimportFile12thMarksheet: new FormControl('', Validators.required),
      mbaimportFileUGmarksheets: new FormControl('', Validators.required),
      mbaimportFilePGmarksheets: new FormControl('', Validators.required),
      mbaimportFilequalifyingdegree: new FormControl('', Validators.required),
      mbaimportFileCategorycertificate: new FormControl('', Validators.required),
      mbaimportFileAadhar: new FormControl('', Validators.required),
      mbaimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      mbaimportFileIncomeTax: new FormControl('', Validators.required),
      mbaannexureI: new FormControl('', Validators.required),
      mbaannexureII: new FormControl('', Validators.required),
      mbaannexureIII: new FormControl('', Validators.required),
      mbaimportFileIsubmission: new FormControl('', Validators.required),
      sign: new FormControl('', Validators.required),
      course: ['', Validators.required],
      bhawan: ['', Validators.required],
      roomno: ['', Validators.required],
      bankname: ['', Validators.required],
      bankaccountno: ['', Validators.required],
      bankifsccode: ['', Validators.required],
      studentmobileno: ['', Validators.required],
      undertaking: ['', Validators.required],
      undertaking1: ['', Validators.required],
      undertaking2: ['', Validators.required],
      undertaking3: ['', Validators.required],
      undertaking4: ['', Validators.required],
      undertaking5: ['', Validators.required],
      correspondenceaddress: ['', Validators.required],
      pincode: ['', Validators.required],
      pincode1: ['', Validators.required],
      fatheremailid: ['', Validators.required],
      fatherpermentaddress: ['', Validators.required],
      fathercorrespondenceaddress: ['', Validators.required],
      pincode2: ['', Validators.required],
      mothername: ['', Validators.required],
      motherdesignation: ['', Validators.required],
      motheremailid: ['', Validators.required],
      motherpermentaddress: ['', Validators.required],
      mothercorrespondenceaddress: ['', Validators.required],
      motherpincode: ['', Validators.required],
      cpincode: ['', Validators.required],
      guardianname: ['', Validators.required],
      guardiandesignation: ['', Validators.required],
      guardianmobileno: ['', Validators.required],
      guardianemailid: ['', Validators.required],
      guardianpermanentaddress: ['', Validators.required],
      guardiancorrespondenceaddress: ['', Validators.required],
      guardianpincode: ['', Validators.required],
      correspondencepincode: ['', Validators.required],
      localguardianname: ['', Validators.required],
      localguardianemailid: ['', Validators.required],
      localguardianmobileno: ['', Validators.required],
      localguardianprementaddress: ['', Validators.required],
      localguardianpincode: ['', Validators.required],
      email: ['', Validators.required],
    })
  }



  ngOnInit() {
    this.FatherPinAddress = "";
    this.FatherCorrespondensAddress = "";
    this.Email = sessionStorage.getItem('Email');
    this.GetDetailsfromEnrollmentNo(this.Email);
    this.status = "Success";
    if (this.status == "Success") {
      this.IsLavel1 = true;
    }
    this.MBA = false;
    this.MSC = false;
    this.CandidatesCategory = false;
    this.RegularCandidates = false;
    this.IITGraduates = false;
    this.Sponsoredcandidates = false;
    this.ForeignNationals = false;
    this.checked2 = false;
    this.StudentREgistrationform = this.formBuilder.group({
      Parameter1: [``, Validators.required],
      Parameter2: [``, Validators.required],
      Parameter3: [``, Validators.required],
      Parameter4: [``, Validators.required],
      jeeapplicationno: [``, Validators.required],
      StudId: [``, Validators.required],
      fullname: ['', Validators.required],
      programme: ['', Validators.required],
      gender: ['', Validators.required],
      category: ['', Validators.required],
      physicallydisabled: ['', Validators.required],
      nationality: ['', Validators.required],
      nameofaccountholder: ['', Validators.required],
      departmentname: ['', Validators.required],
      specialization: ['', Validators.required],
      fathername: ['', Validators.required],
      fathermobile: ['', Validators.required],
      aadharno: ['', Validators.required],
      fdesignation: ['', Validators.required],
      feesamount: ['', Validators.required],
      dateofpayment: ['', Validators.required],
      semester: ['', Validators.required],
      parameter2: ['', Validators.required],
      year: ['', Validators.required],
      pin: ['', Validators.required],
      iitrmailid: ['', Validators.required],
      scheme: ['', Validators.required],
      dob: ['', Validators.required],
      phoneno: ['', Validators.required],
      bhawan: ['', Validators.required],
      roomno: ['', Validators.required],
      bankname: ['', Validators.required],
      bankaccountno: ['', Validators.required],
      bankifsccode: ['', Validators.required],
      studentmobileno: ['', Validators.required],
      undertaking: ['', Validators.required],
      undertaking1: ['', Validators.required],
      undertaking2: ['', Validators.required],
      undertaking3: ['', Validators.required],
      undertaking4: ['', Validators.required],
      undertaking5: ['', Validators.required],
      course: ['', Validators.required],
      correspondenceaddress: ['', Validators.required],
      permanentaddress: ['', Validators.required],
      mcaimportFileJAM2020: ['', Validators.required],
      mcaimportFile10thMarksheet: ['', Validators.required],
      mcaimportFile12thMarksheet: ['', Validators.required],
      mcaimportFileIsubmission: new FormControl('', Validators.required),
      regularcandidatesimportFileIsubmission1: new FormControl('', Validators.required),
      passportsizephoto: new FormControl('', Validators.required),
      sign: new FormControl('', Validators.required),
      importFileIsubmission3: new FormControl('', Validators.required),
      mcaimportFileannexureIII: new FormControl('', Validators.required),
      mcaimportFilePGmarksheets: new FormControl('', Validators.required),
      mcaimportFileannexureI: new FormControl('', Validators.required),
      mcaimportFileannexureII: new FormControl('', Validators.required),
      mcaimportFilequalifyingdegree: new FormControl('', Validators.required),
      mcaimportFileAadhar: new FormControl('', Validators.required),
      mcaimportFileCategorycertificate: new FormControl('', Validators.required),
      mcaimportFileUGmarksheets: new FormControl('', Validators.required),
      mcaimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      mcaimportFileIncomeTax: new FormControl('', Validators.required),
      regularcandidatesimportFileGATEScore: new FormControl('', Validators.required),
      regularcandidatesimportFile10thMarksheet: new FormControl('', Validators.required),
      regularcandidatesimportFile12thMarksheet: new FormControl('', Validators.required),
      regularcandidatesimportFileUGmarksheets: new FormControl('', Validators.required),
      regularcandidatesimportFilequalifyingdegree: new FormControl('', Validators.required),
      regularcandidatesimportFileCategorycertificate: new FormControl('', Validators.required),
      regularcandidatesimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      regularcandidatesimportFileAadhar: new FormControl('', Validators.required),
      regularcandidatesimportFilePGmarksheets: new FormControl('', Validators.required),
      annexureIregularcandidates: new FormControl('', Validators.required),
      annexureIIregularcandidates: new FormControl('', Validators.required),
      annexureIIIregularcandidates: new FormControl('', Validators.required),
      iitgraduatesimportFile10thMarksheet: new FormControl('', Validators.required),
      iitgraduatesimportFile12thMarksheet: new FormControl('', Validators.required),
      iitgraduatesimportFileUGmarksheets: new FormControl('', Validators.required),
      iitgraduatesimportFilePGmarksheets: new FormControl('', Validators.required),
      iitgraduatesimportFilequalifyingdegree: new FormControl('', Validators.required),
      iitgraduatesimportFileCategorycertificate: new FormControl('', Validators.required),
      iitgraduatesimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      iitgraduatesannexureI: new FormControl('', Validators.required),
      iitgraduatesannexureII: new FormControl('', Validators.required),
      iitgraduatesannexureIII: new FormControl('', Validators.required),
      iitgraduatesimportFileIsubmission: new FormControl('', Validators.required),
      sponsoredcandidatesimportFile10thMarksheet: new FormControl('', Validators.required),
      sponsoredcandidatesimportFile12thMarksheet: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileUGmarksheets: new FormControl('', Validators.required),
      sponsoredcandidatesimportFilePGmarksheets: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileCategorycertificate: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileAadhar: new FormControl('', Validators.required),
      sponsoredcandidatesimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileExperiencecertificate: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileSponsorshipCertificate: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileNoObjection: new FormControl('', Validators.required),
      sponsoredcandidatesimportFilerelieving: new FormControl('', Validators.required),
      sponsoredcandidatesannexureI: new FormControl('', Validators.required),
      sponsoredcandidatesannexureII: new FormControl('', Validators.required),
      sponsoredcandidatesannexureIII: new FormControl('', Validators.required),
      sponsoredcandidatesimportFileIsubmission: new FormControl('', Validators.required),
      foreignnationalsimportFile10thMarksheet: new FormControl('', Validators.required),
      foreignnationalsimportFile12thMarksheet: new FormControl('', Validators.required),
      foreignnationalsimportFileUGmarksheets: new FormControl('', Validators.required),
      foreignnationalimportFilePassport: new FormControl('', Validators.required),
      foreignnationalimportFileVisa: new FormControl('', Validators.required),
      foreignnationalimportFileHIV: new FormControl('', Validators.required),
      foreignnationalannexureI: new FormControl('', Validators.required),
      foreignnationalannexureII: new FormControl('', Validators.required),
      foreignnationalannexureIII: new FormControl('', Validators.required),
      foreignnationalimportFileIsubmission: new FormControl('', Validators.required),
      mbaimportFileCATScoreCard: new FormControl('', Validators.required),
      mbaimportFile10thMarksheet: new FormControl('', Validators.required),
      mbaimportFile12thMarksheet: new FormControl('', Validators.required),
      mbaimportFileUGmarksheets: new FormControl('', Validators.required),
      mbaimportFilePGmarksheets: new FormControl('', Validators.required),
      mbaimportFilequalifyingdegree: new FormControl('', Validators.required),
      mbaimportFileCategorycertificate: new FormControl('', Validators.required),
      mbaimportFileAadhar: new FormControl('', Validators.required),
      mbaimportFilephysicallyhandicapped: new FormControl('', Validators.required),
      mbaimportFileIncomeTax: new FormControl('', Validators.required),
      mbaannexureI: new FormControl('', Validators.required),
      mbaannexureII: new FormControl('', Validators.required),
      mbaannexureIII: new FormControl('', Validators.required),
      mbaimportFileIsubmission: new FormControl('', Validators.required),
      candidatescategorystudents: new FormControl('', Validators.required),
      MSCCategory: ['', Validators.required],
      MBACategory: ['', Validators.required],
      transactionid: ['', Validators.required],
      pincode: ['', Validators.required],
      pincode1: ['', Validators.required],
      fatheremailid: ['', Validators.required],
      fatherpermentaddress: ['', Validators.required],
      fathercorrespondenceaddress: ['', Validators.required],
      pincode2: ['', Validators.required],
      mothername: ['', Validators.required],
      motherdesignation: ['', Validators.required],
      motheremailid: ['', Validators.required],
      motherpermentaddress: ['', Validators.required],
      mothercorrespondenceaddress: ['', Validators.required],
      motherpincode: ['', Validators.required],
      cpincode: ['', Validators.required],
      guardianname: ['', Validators.required],
      guardiandesignation: ['', Validators.required],
      guardianmobileno: ['', Validators.required],
      guardianemailid: ['', Validators.required],
      guardianpermanentaddress: ['', Validators.required],
      guardiancorrespondenceaddress: ['', Validators.required],
      guardianpincode: ['', Validators.required],
      correspondencepincode: ['', Validators.required],
      localguardianname: ['', Validators.required],
      localguardianemailid: ['', Validators.required],
      localguardianmobileno: ['', Validators.required],
      localguardianprementaddress: ['', Validators.required],
      localguardiancorrespondenceaddress: ['', Validators.required],
      localguardianpincode: ['', Validators.required],
      email: ['', Validators.required],
    });
    this.bodyText = 'This text can be updated in modal 1';
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
      this.toastr.error("Please enter only number in account number");
    }
    return true;

  }

  lavel1() {
    if (this.StudentREgistrationform.value.nameofaccountholder == "" || this.StudentREgistrationform.value.bankname == "" || this.StudentREgistrationform.value.bankaccountno == "" || this.StudentREgistrationform.value.bankifsccode == "" || this.StudentREgistrationform.value.Parameter1 == "" || this.StudentREgistrationform.value.Parameter3 == "" || this.StudentREgistrationform.value.permanentaddress == "" || this.StudentREgistrationform.value.pincode == "") {
      this.StudentFormSubmit = true;
      this.toastr.error("Kindly Fill Mandatory details");
      this.IsLavel1 = true;
      this.IsLavel3 = false;
      this.IsLavel2 = false;
    }
    else {
      this.IsLavel1 = false;
      this.IsLavel3 = false;
      this.IsLavel2 = true;
    }

  }
  lavel2() {
    if (this.StudentREgistrationform.value.fathername == "" || this.StudentREgistrationform.value.mothername == "" || this.StudentREgistrationform.value.localguardianname == "" || this.StudentREgistrationform.value.localguardianmobileno == "" || this.StudentREgistrationform.value.localguardianmobileno == "") {
      this.toastr.error("Kindly Fill Mandatory details");
      this.IsLavel1 = false;
      this.IsLavel3 = false;
      this.IsLavel2 = true;
    }
    else {
      this.IsLavel1 = false;
      this.IsLavel3 = true;
      this.IsLavel2 = false;
    }
  }
  lavel3() {
    // this.toastr.error("Alert: Kindly upload  Mandatory Documents");
    this.IsLavel1 = false;
    this.IsLavel3 = true;
    this.IsLavel2 = false;
    this.IsLavel4 = true;
  }
  cancel() {
    this.IsLavel1 = true;
    this.IsLavel3 = false;
    this.IsLavel2 = false;
  }
  IsMSCSubmit() {
    this.IsLavel4 = true;
    this.IsLavel3 = false;
    this.IsLavel6 = false;
    // this.IsLavel1=false;
    // this.IsLavel2=false;
  }
  IsFees() {
    if (this.StudentREgistrationform.value.mbaimportFileCATScoreCard == "" || this.StudentREgistrationform.value.mbaimportFile10thMarksheet == "" || this.StudentREgistrationform.value.mbaimportFile12thMarksheet == "" || this.StudentREgistrationform.value.mbaimportFileUGmarksheets == "" || this.StudentREgistrationform.value.mbaannexureI == "" || this.StudentREgistrationform.value.mbaannexureII == "" || this.StudentREgistrationform.value.mbaannexureIII == "") {
      this.toastr.error("Kindly Fill Mandatory details");
      this.IsLavel6 = false;
      this.IsLavel4 = false;
      this.IsLavel3 = true;
    }
    else {
      this.IsLavel6 = true;
      this.IsLavel4 = false;
      this.IsLavel3 = false;
    }

  }
  Goback() {
    this.IsLavel2 = true;
    this.IsLavel1 = false;
    this.IsLavel3 = false;
  }
  BackToUploadData() {
    this.IsLavel3 = true;
    this.IsLavel2 = false;
    this.IsLavel1 = false;
    this.IsLavel6 = false;

  }
  firstDropDownChanged(val: any) {
    console.log(val);
    if (val == "MSC") {
      this.MSCCategory = true;
      this.MSC = false;
      this.RegularCandidates = false;
      this.IITGraduates = false;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = false;
      this.CandidatesCategory = false;
      this.MBA = false;
      this.MBACategory = false;
    }
    else if (val == "MTech/March/MURP") {
      this.MSC = false;
      this.RegularCandidates = false;
      this.IITGraduates = false;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = false;
      this.CandidatesCategory = true;
      this.MBA = false;
      this.MSCCategory = false;
      this.MBACategory = false;

    }
    else if (val == "MBA") {
      this.MSC = false
      this.MBA = false;
      this.MBACategory = true;
      this.RegularCandidates = false;
      this.IITGraduates = false;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = false;
      this.CandidatesCategory = false;
      this.MSCCategory = false;
    }
  }
  firstDropDownChanged1(val: any) {
    console.log(val);
    if (val == "RegularCandidates") {
      this.RegularCandidates = true;
      this.MBA = false;
      this.MSC = false;
      this.CandidatesCategory = true;
      this.IITGraduates = false;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = false;
    }
    else if (val == "IITGraduates") {
      this.MSC = false;
      this.RegularCandidates = false;
      this.IITGraduates = true;
      this.MBA = false;
      this.MSC = false;
      this.CandidatesCategory = true;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = false;
    }
    else if (val == "Sponsoredcandidates") {
      this.MSC = false;
      this.MBA = false;
      this.RegularCandidates = false;
      this.IITGraduates = false;
      this.Sponsoredcandidates = true;
      this.ForeignNationals = false;
      this.CandidatesCategory = true;
    }
    else if (val == "ForeignNationals") {
      this.MBA = false;
      this.MSC = false;
      this.RegularCandidates = false;
      this.IITGraduates = false;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = true;
      this.CandidatesCategory = true;
    }

  }
  firstDropDownChanged2(val: any) {
    console.log(val);
    if (val == "IndianNationals") {
      this.MSCCategory = true;
      this.MSC = true;
      this.RegularCandidates = false;
      this.IITGraduates = false;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = false;
      this.CandidatesCategory = false;
      this.MBA = false;
    }
    else if (val == "ForeignNationals") {
      this.MSCCategory = true;
      this.MBA = false;
      this.MSC = false;
      this.RegularCandidates = false;
      this.IITGraduates = false;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = true;
      this.CandidatesCategory = false;

    }
  }
  firstDropDownChanged3(val: any) {
    console.log(val);
    if (val == "IndianNationals1") {
      this.MBACategory = true;
      this.MSC = false;
      this.RegularCandidates = false;
      this.IITGraduates = false;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = false;
      this.CandidatesCategory = false;
      this.MBA = true;
    }
    else if (val == "ForeignNationals1") {
      this.MSCCategory = false;
      this.MBACategory = true
      this.MBA = false;
      this.MSC = false;
      this.RegularCandidates = false;
      this.IITGraduates = false;
      this.Sponsoredcandidates = false;
      this.ForeignNationals = true;
      this.CandidatesCategory = false;

    }
  }

  GetDetailsfromEnrollmentNo(val: any) {
    console.log(val);
    if (val == "Choose Your CAT Registration No.") {
      this.ngOnInit();
    }
    this.SpinnerService.show();
    this.sellerService.GetEnrollmentNoDetails(val).subscribe((data: any) => {
      this.EnraollmentDetailList = data.data.data;
      this.AmountStatus = this.EnraollmentDetailList.amountStatus;
      console.log("AmountList", this.AmountStatus);
      this.StudentREgistrationform.controls["jeeapplicationno"].patchValue(this.EnraollmentDetailList.jeeapplicationno);
      // this.StudentREgistrationform.controls["PGStudId"].patchValue(this.EnraollmentDetailList.PGStudId);
      this.StudentREgistrationform.controls["fullname"].patchValue(this.EnraollmentDetailList.fullname);
      this.StudentREgistrationform.controls["dob"].patchValue(this.EnraollmentDetailList.dob);
      this.StudentREgistrationform.controls["gender"].patchValue(this.EnraollmentDetailList.gender);
      this.StudentREgistrationform.controls["category"].patchValue(this.EnraollmentDetailList.category);
      this.StudentREgistrationform.controls["physicallydisabled"].patchValue(this.EnraollmentDetailList.physicallydisabled);
      this.StudentREgistrationform.controls["nationality"].patchValue(this.EnraollmentDetailList.nationality);
      this.StudentREgistrationform.controls["iitrmailid"].patchValue(this.EnraollmentDetailList.iitrmailid);
      this.StudentREgistrationform.controls["aadharno"].patchValue(this.EnraollmentDetailList.aadharno);
      this.StudentREgistrationform.controls["bhawan"].patchValue(this.EnraollmentDetailList.bhawan);
      this.StudentREgistrationform.controls["roomno"].patchValue(this.EnraollmentDetailList.roomno);
      this.StudentREgistrationform.controls["departmentname"].patchValue(this.EnraollmentDetailList.departmentname);
      this.StudentREgistrationform.controls["programme"].patchValue(this.EnraollmentDetailList.programme);
      this.StudentREgistrationform.controls["year"].patchValue(this.EnraollmentDetailList.year);
      this.StudentREgistrationform.controls["specialization"].patchValue(this.EnraollmentDetailList.specialization);
      this.StudentREgistrationform.controls["scheme"].patchValue(this.EnraollmentDetailList.scheme);
      this.StudentREgistrationform.controls["semester"].patchValue(this.EnraollmentDetailList.semester);
      this.StudentREgistrationform.controls["permanentaddress"].patchValue(this.EnraollmentDetailList.permanentaddress);
      this.StudentREgistrationform.controls["pincode"].patchValue(this.EnraollmentDetailList.pincode);
      this.StudentREgistrationform.controls["Parameter1"].patchValue(this.EnraollmentDetailList.parameter1);
      this.StudentREgistrationform.controls["Parameter3"].patchValue(this.EnraollmentDetailList.parameter3);
      this.StudentREgistrationform.controls["correspondenceaddress"].patchValue(this.EnraollmentDetailList.correspondenceaddress);
      this.StudentREgistrationform.controls["pincode1"].patchValue(this.EnraollmentDetailList.pincode1);
      this.StudentREgistrationform.controls["Parameter2"].patchValue(this.EnraollmentDetailList.parameter2);
      this.StudentREgistrationform.controls["Parameter4"].patchValue(this.EnraollmentDetailList.parameter4);
      this.StudentREgistrationform.controls["nameofaccountholder"].patchValue(this.EnraollmentDetailList.nameofaccountholder);
      this.StudentREgistrationform.controls["bankname"].patchValue(this.EnraollmentDetailList.bankname);
      this.StudentREgistrationform.controls["bankaccountno"].patchValue(this.EnraollmentDetailList.bankaccountno);
      this.StudentREgistrationform.controls["bankifsccode"].patchValue(this.EnraollmentDetailList.bankifsccode);
      this.StudentREgistrationform.controls["fathername"].patchValue(this.EnraollmentDetailList.fathername);
      this.StudentREgistrationform.controls["fdesignation"].patchValue(this.EnraollmentDetailList.fdesignation);
      this.StudentREgistrationform.controls["fathermobile"].patchValue(this.EnraollmentDetailList.fathermobile);
      this.StudentREgistrationform.controls["fatheremailid"].patchValue(this.EnraollmentDetailList.fatheremailid);
      this.StudentREgistrationform.controls["fatherpermentaddress"].patchValue(this.EnraollmentDetailList.fatherpermentaddress);
      this.StudentREgistrationform.controls["pin"].patchValue(this.EnraollmentDetailList.pin);
      this.StudentREgistrationform.controls["fathercorrespondenceaddress"].patchValue(this.EnraollmentDetailList.fathercorrespondenceaddress);
      this.StudentREgistrationform.controls["pincode2"].patchValue(this.EnraollmentDetailList.pincode2);
      this.StudentREgistrationform.controls["mothername"].patchValue(this.EnraollmentDetailList.mothername);
      this.StudentREgistrationform.controls["phoneno"].patchValue(this.EnraollmentDetailList.phoneno);
      this.StudentREgistrationform.controls["motherdesignation"].patchValue(this.EnraollmentDetailList.motherdesignation);
      this.StudentREgistrationform.controls["motheremailid"].patchValue(this.EnraollmentDetailList.motheremailid);
      this.StudentREgistrationform.controls["guardianname"].patchValue(this.EnraollmentDetailList.guardianname);
      this.StudentREgistrationform.controls["guardianmobileno"].patchValue(this.EnraollmentDetailList.guardianmobileno);
      this.StudentREgistrationform.controls["guardiandesignation"].patchValue(this.EnraollmentDetailList.guardiandesignation)
      this.StudentREgistrationform.controls["guardianemailid"].patchValue(this.EnraollmentDetailList.guardianemailid);
      this.StudentREgistrationform.controls["guardianpermanentaddress"].patchValue(this.EnraollmentDetailList.guardianpermanentaddress);
      this.StudentREgistrationform.controls["guardianpincode"].patchValue(this.EnraollmentDetailList.guardianpincode);
      this.StudentREgistrationform.controls["guardiancorrespondenceaddress"].patchValue(this.EnraollmentDetailList.guardiancorrespondenceaddress);
      this.StudentREgistrationform.controls["correspondencepincode"].patchValue(this.EnraollmentDetailList.correspondencepincode);
      this.StudentREgistrationform.controls["localguardianname"].patchValue(this.EnraollmentDetailList.localguardianname);
      this.StudentREgistrationform.controls["localguardianemailid"].patchValue(this.EnraollmentDetailList.localguardianemailid);
      this.StudentREgistrationform.controls["localguardianmobileno"].patchValue(this.EnraollmentDetailList.localguardianmobileno);
      this.StudentREgistrationform.controls["localguardianprementaddress"].patchValue(this.EnraollmentDetailList.localguardianprementaddress);
      this.StudentREgistrationform.controls["localguardiancorrespondenceaddress"].patchValue(this.EnraollmentDetailList.localguardiancorrespondenceaddress);
      this.StudentREgistrationform.controls["localguardianpincode"].patchValue(this.EnraollmentDetailList.localguardianpincode);
      this.StudentREgistrationform.controls["mbaimportFileCATScoreCard"].patchValue(this.EnraollmentDetailList.mbaimportFileCATScoreCard);
      this.StudentREgistrationform.controls["mbaimportFile10thMarksheet"].patchValue(this.EnraollmentDetailList.mbaimportFile10thMarksheet);
      this.StudentREgistrationform.controls["mbaimportFile12thMarksheet"].patchValue(this.EnraollmentDetailList.mbaimportFile12thMarksheet);
      this.StudentREgistrationform.controls["mbaimportFileUGmarksheets"].patchValue(this.EnraollmentDetailList.mbaimportFileUGmarksheets);
      this.StudentREgistrationform.controls["mbaimportFilePGmarksheets"].patchValue(this.EnraollmentDetailList.mbaimportFilePGmarksheets);
      this.StudentREgistrationform.controls["mbaimportFilequalifyingdegree"].patchValue(this.EnraollmentDetailList.mbaimportFilequalifyingdegree);
      this.StudentREgistrationform.controls["mbaimportFileCategorycertificate"].patchValue(this.EnraollmentDetailList.mbaimportFileCategorycertificate);
      this.StudentREgistrationform.controls["mbaimportFileAadhar"].patchValue(this.EnraollmentDetailList.mbaimportFileAadhar);
      this.StudentREgistrationform.controls["mbaimportFilephysicallyhandicapped"].patchValue(this.EnraollmentDetailList.mbaimportFilephysicallyhandicapped);
      this.StudentREgistrationform.controls["mbaimportFileIncomeTax"].patchValue(this.EnraollmentDetailList.mbaimportFileIncomeTax);
      this.StudentREgistrationform.controls["mbaannexureI"].patchValue(this.EnraollmentDetailList.mbaannexureI);
      this.StudentREgistrationform.controls["mbaannexureII"].patchValue(this.EnraollmentDetailList.mbaannexureII);
      this.StudentREgistrationform.controls["mbaannexureIII"].patchValue(this.EnraollmentDetailList.mbaannexureIII);
      this.StudentREgistrationform.controls["mbaimportFileIsubmission"].patchValue(this.EnraollmentDetailList.mbaimportFileIsubmission);
      this.StudentREgistrationform.controls["feesamount"].patchValue(this.EnraollmentDetailList.feesamount);
      this.StudentREgistrationform.controls["dateofpayment"].patchValue(this.EnraollmentDetailList.dateofpayment);
      this.StudentREgistrationform.controls["transactionid"].patchValue(this.EnraollmentDetailList.transactionid);
      this.StudentREgistrationform.controls["bankifsccode"].patchValue(this.EnraollmentDetailList.bankifsccode);
      this.StudentREgistrationform.controls["nameofaccountholder"].patchValue(this.EnraollmentDetailList.nameofaccountholder);
      this.StudentREgistrationform.controls["bankaccountno"].patchValue(this.EnraollmentDetailList.bankaccountno);
      this.StudentREgistrationform.controls["bankifsccode"].patchValue(this.EnraollmentDetailList.bankifsccode);
      this.StudentREgistrationform.controls["studentmobileno"].patchValue(this.EnraollmentDetailList.studentmobileno);
      //fatherhomemobileno
      // 
      // this.MBAFeesForm.controls["bankifsccode"].patchValue(this.CATDetailList.applicantName);
      this.SpinnerService.hide();
      console.log("EnraollmentDetailList", this.EnraollmentDetailList);
    })

  }



  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.dataimageup = <FileReader>event.target;
        this.url = this.dataimageup.result;
        this.url = reader.result.toString()[1];
        // this.uploadFile_onSelectFile(event);
      }
    }
  }
  uploadFile_onSelectFile(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_onSelectFile(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_onSelectFile(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.onSelectFile1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  onSelectFilePassport(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.dataimageup = <FileReader>event.target;
        this.url = this.dataimageup.result;
        // this.uploadFile_onSelectFileSign(event);
      }
    }
  }

  onSelectFileSign(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.dataimageup = <FileReader>event.target;
        this.url1 = this.dataimageup.result;
        // this.uploadFile_onSelectFileSign(event);
      }
    }
  }
  uploadFile_onSelectFileSign(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_onSelectFileSign(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_onSelectFileSign(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.onSelectFileSign1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }



  Preview() {
    this.IsLavel5 = true;
    this.passportsizephoto = this.StudentREgistrationform.value.sign;
    this.SSign = this.StudentREgistrationform.value.passportsizephoto;
    this.BankIFSC = this.StudentREgistrationform.value.bankifsccode;
  }

  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
  }
  import(): void {
    console.log('import ' + this.fileToUpload.name);
  }

  uploadFileBrowse(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFile(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }

  uploadFile(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFileJAM2020MCA1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFilemcaimportFile10thMarksheet(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowsermcaimportFile10thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowsermcaimportFile10thMarksheet(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFile10thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFilemcaimportFile12thMarksheet(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowsermcaimportFile12thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowsermcaimportFile12thMarksheet(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFile12thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFilemcaimportFileUGmarksheets(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowsermcaimportFile12thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowsermcaimportFileUGmarksheets(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFileUGmarksheets1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFilemcaimportFilePGmarksheets(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowsermcaimportFilePGmarksheets(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowsermcaimportFilePGmarksheets(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFilePGmarksheets1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFilemcaimportFilequalifyingdegree(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowsermcaimportFilequalifyingdegree(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowsermcaimportFilequalifyingdegree(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFilequalifyingdegree1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mcaimportFileCategorycertificate(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mcaimportFileCategorycertificate(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_mcaimportFileCategorycertificate(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFileCategorycertificate1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mcaimportFileAadhar(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mcaimportFileAadhar(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_mcaimportFileAadhar(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFileAadhar = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mcaimportFilephysicallyhandicapped(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mcaimportFilephysicallyhandicapped(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_mcaimportFilephysicallyhandicapped(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFilephysicallyhandicapped1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mcaimportFileIncomeTax(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mcaimportFileIncomeTax(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_mcaimportFileIncomeTax(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFileIncomeTax1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mcaimportFileannexureI(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mcaimportFileannexureI(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_mcaimportFileannexureI(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFileannexureI1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mcaimportFileannexureII(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mcaimportFileannexureII(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_mcaimportFileannexureII(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) 
    {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => 
        {
        this.response1 = res;
        this.user = 
        {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFileannexureII1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mcaimportFileannexureIII(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mcaimportFileannexureIII(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_mcaimportFileannexureIII(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFileannexureIII1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_mcaimportFileIsubmission(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mcaimportFileIsubmission(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_mcaimportFileIsubmission(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.mcaimportFileIsubmission1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_regularcandidatesimportFileGATEScore(fileInput) {
   
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFileGATEScore(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
     
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_regularcandidatesimportFileGATEScore(fileData) {
    
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFileGATEScore1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_regularcandidatesimportFile10thMarksheet(fileInput) {
   
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFile10thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_regularcandidatesimportFile10thMarksheet(fileData) {
    
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
       
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFile10thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_regularcandidatesimportFile12thMarksheet(fileInput) {
   
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFile12thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
     
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_regularcandidatesimportFile12thMarksheet(fileData) {
    
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
       
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFile12thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_regularcandidatesimportFileUGmarksheets(fileInput) {
    
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFileUGmarksheets(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_regularcandidatesimportFileUGmarksheets(fileData) {
    
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
       
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFileUGmarksheets1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_regularcandidatesimportFilePGmarksheets(fileInput) {
    
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFilePGmarksheets(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_regularcandidatesimportFilePGmarksheets(fileData) {
   
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFilePGmarksheets1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_regularcandidatesimportFilequalifyingdegree(fileInput) {
    
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFilequalifyingdegree(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
     
      this.toastr.error("You could not upload document", "")
    }
    //}
  }

  uploadFileBrowser_regularcandidatesimportFilequalifyingdegree(fileData) {
   
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
       
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFilequalifyingdegree1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_regularcandidatesimportFileCategorycertificate(fileInput) {
    
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFileCategorycertificate(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFile_regularcandidatesimportFileAadhar
    (fileInput) {
  
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFileAadhar(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
     
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_regularcandidatesimportFileAadhar(fileData) {
    
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFileAadhar1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFileBrowser_regularcandidatesimportFileCategorycertificate(fileData) {
    
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFileCategorycertificate1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_regularcandidatesimportFilephysicallyhandicapped(fileInput) {
    
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFilephysicallyhandicapped(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_regularcandidatesimportFilephysicallyhandicapped(fileData) {
    
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
       
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFilephysicallyhandicapped1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_annexureIregularcandidates(fileInput) {
   
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_annexureIregularcandidates(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
     
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_annexureIregularcandidates(fileData) {
    
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
       
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.annexureIregularcandidates1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_annexureIIregularcandidates(fileInput) {
    
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_annexureIIregularcandidates(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
     
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_annexureIIregularcandidates(fileData) {
   
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
       
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.annexureIIregularcandidates1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_annexureIIIregularcandidates(fileInput) {
   
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_annexureIIIregularcandidates(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
    
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_annexureIIIregularcandidates(fileData) {
   
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.annexureIIIregularcandidates1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_regularcandidatesimportFileIsubmission1(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_regularcandidatesimportFileIsubmission1(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_regularcandidatesimportFileIsubmission1(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.regularcandidatesimportFileIsubmission11 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_iitgraduatesimportFile10thMarksheet(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesimportFile10thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesimportFile10thMarksheet(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesimportFile10thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_iitgraduatesimportFile12thMarksheet(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesimportFile12thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesimportFile12thMarksheet(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesimportFile12thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_iitgraduatesimportFileUGmarksheets(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesimportFileUGmarksheets(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesimportFileUGmarksheets(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesimportFileUGmarksheets1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_iitgraduatesimportFilePGmarksheets(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesimportFilePGmarksheets(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesimportFilePGmarksheets(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesimportFilePGmarksheets1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_iitgraduatesimportFilequalifyingdegree(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesimportFilequalifyingdegree(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesimportFilequalifyingdegree(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesimportFilequalifyingdegree1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_iitgraduatesimportFileCategorycertificate(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesimportFileCategorycertificate(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesimportFileCategorycertificate(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesimportFileCategorycertificate1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_iitgraduatesimportFilephysicallyhandicapped(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesimportFilephysicallyhandicapped(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesimportFilephysicallyhandicapped(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesimportFilephysicallyhandicapped1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_iitgraduatesannexureI(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesannexureI(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesannexureI(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesannexureI1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_iitgraduatesannexureII(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesannexureII(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesannexureII(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesannexureII1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_iitgraduatesannexureIII(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesannexureIII(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesannexureIII(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesannexureIII1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_iitgraduatesimportFileIsubmission(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_iitgraduatesimportFileIsubmission(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowser_iitgraduatesimportFileIsubmission(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.iitgraduatesimportFileIsubmission1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_sponsoredcandidatesimportFile10thMarksheet(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFile10thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFile10thMarksheet(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFile10thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_sponsoredcandidatesimportFile12thMarksheet(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFile12thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFile12thMarksheet(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFile12thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_sponsoredcandidatesimportFileUGmarksheets(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFileUGmarksheets(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFileUGmarksheets(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFileUGmarksheets1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_sponsoredcandidatesimportFilePGmarksheets(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFilePGmarksheets(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFilePGmarksheets(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFilePGmarksheets1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_sponsoredcandidatesimportFileCategorycertificate(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFileCategorycertificate(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFileCategorycertificate(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFileCategorycertificate1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }


  uploadFile_sponsoredcandidatesimportFileAadhar(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFileAadhar(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFileAadhar(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFileAadhar1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_sponsoredcandidatesimportFilephysicallyhandicapped(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFilephysicallyhandicapped(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFilephysicallyhandicapped(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFilephysicallyhandicapped1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_sponsoredcandidatesimportFileExperiencecertificate(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFileExperiencecertificate(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFileExperiencecertificate(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFileExperiencecertificate1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_sponsoredcandidatesimportFileSponsorshipCertificate(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFileSponsorshipCertificate(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFileSponsorshipCertificate(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFileSponsorshipCertificate1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_sponsoredcandidatesimportFileNoObjection(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFileNoObjection(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }

  uploadFileBrowser_sponsoredcandidatesimportFileNoObjection(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFileNoObjection1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_sponsoredcandidatesimportFilerelieving(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFilerelieving(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFilerelieving(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFilerelieving1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_sponsoredcandidatesannexureI(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesannexureI(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesannexureI(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesannexureI1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_sponsoredcandidatesannexureII(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesannexureII(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesannexureII(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesannexureII1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_sponsoredcandidatesannexureIII(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesannexureIII(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesannexureIII(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesannexureIII1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_sponsoredcandidatesimportFileIsubmission(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_sponsoredcandidatesimportFileIsubmission(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_sponsoredcandidatesimportFileIsubmission(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.sponsoredcandidatesimportFileIsubmission1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_foreignnationalsimportFile10thMarksheet(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalsimportFile10thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalsimportFile10thMarksheet(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalsimportFile10thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_foreignnationalsimportFile12thMarksheet(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalsimportFile12thMarksheet(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalsimportFile12thMarksheet(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalsimportFile12thMarksheet1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_foreignnationalsimportFileUGmarksheets(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalsimportFileUGmarksheets(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalsimportFileUGmarksheets(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalsimportFileUGmarksheets1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_foreignnationalimportFilePassport(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalimportFilePassport(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalimportFilePassport(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalimportFilePassport1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_foreignnationalimportFileVisa(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalimportFileVisa(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalimportFileVisa(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalimportFileVisa1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_foreignnationalimportFileHIV(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalimportFileHIV(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalimportFileHIV(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalimportFileHIV1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }


  uploadFile_foreignnationalannexureI(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalannexureI(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalannexureI(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalannexureI1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_foreignnationalannexureII(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalannexureII(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalannexureII(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalannexureII1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_foreignnationalannexureIII(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalannexureIII(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalannexureIII(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalannexureIII1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_foreignnationalimportFileIsubmission(fileInput) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_foreignnationalimportFileIsubmission(<File>fileInput.target.files);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_foreignnationalimportFileIsubmission(fileData) {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.dbPath,
        }
        this.foreignnationalimportFileIsubmission1 = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_importFileCATScoreCard(fileInput, name) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_importFileCATScoreCard(<File>fileInput.target.files, name);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_importFileCATScoreCard(fileData, DocType) {
    //this.mbaimportFileCATScoreCard1 = fileData[0].name;
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + DocType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].DocType);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    this.studentjeeno = this.StudentREgistrationform.value.jeeapplicationno;
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData).subscribe(res => {
      this.response1 = res;
      this.user = {
        imgPath: this.response1.renameFile,
      }
      this.mbaimportFileCATScoreCard1 = this.user.imgPath;
      // this.toastr.success("data upload successfully.");
    })
  }

  uploadFile_mbaimportFile10thMarksheet(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {

    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFile10thMarksheet(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFile10thMarksheet(fileData, docType) {
    //this.mbaimportFile10thMarksheet1=fileData[0].name;
    const formData = new FormData();
    this.SpinnerService.show();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaimportFile10thMarksheet1 = this.user.imgPath;
        this.SpinnerService.hide();
        // this.toastr.success("data upload successfully.");
      })
  }

  uploadFile_mbaimportFile12thMarksheet(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFile12thMarksheet(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFile12thMarksheet(fileData, docType) {
    //this.mbaimportFile12thMarksheet1=fileData[0].name;;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaimportFile12thMarksheet1 = this.user.imgPath;
        this.SpinnerService.hide();
        // this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaimportFileUGmarksheets(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFileUGmarksheets(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFileUGmarksheets(fileData, docType) {
    //this.mbaimportFileUGmarksheets1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaimportFileUGmarksheets1 = this.user.imgPath;
        this.SpinnerService.hide();
        //this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaimportFilePGmarksheets(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFilePGmarksheets(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFilePGmarksheets(fileData, docType) {
    //this.mbaimportFilePGmarksheets1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaimportFilePGmarksheets1 = this.user.imgPath;
        this.SpinnerService.hide();
        //this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaimportFilequalifyingdegree(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFilequalifyingdegree(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFilequalifyingdegree(fileData, docType) {
    //this.mbaimportFilequalifyingdegree1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaimportFilequalifyingdegree1 = this.user.imgPath;
        this.SpinnerService.hide();
        //this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaimportFileCategorycertificate(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFileCategorycertificate(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFileCategorycertificate(fileData, docType) {
    //this.mbaimportFileCategorycertificate1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaimportFileCategorycertificate1 = this.user.imgPath;
        this.SpinnerService.hide();
        // this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaimportFileAadhar
    (fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFileAadhar(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFileAadhar(fileData, docType) {
    //this.mbaimportFileAadhar1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaimportFileAadhar1 = this.user.imgPath;
        this.SpinnerService.hide();
        //this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaimportFilephysicallyhandicapped(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFilephysicallyhandicapped(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFilephysicallyhandicapped(fileData, docType) {
    //this.mbaimportFilephysicallyhandicapped1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaimportFilephysicallyhandicapped1 = this.user.imgPath
        this.SpinnerService.hide();
        //this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaimportFileIncomeTax(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFileIncomeTax(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFileIncomeTax(fileData, docType) {
    //this.mbaimportFileIncomeTax1= fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaimportFileIncomeTax1 = this.user.imgPath;
        this.SpinnerService.hide();
        //this.toastr.success("data upload successfully.");
      })
  }


  uploadFile_mbaannexureI(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaannexureI(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaannexureI(fileData, docType) {
    //this.mbaannexureI1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaannexureI1 = this.user.imgPath;
        this.SpinnerService.hide();
        //this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaannexureII(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaannexureII(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaannexureII(fileData, docType) {
    //this.mbaannexureII1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.mbaannexureII1 = this.user.imgPath;
        this.SpinnerService.hide();
        //this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaannexureIII(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaannexureIII(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaannexureIII(fileData, docType) {
    //this.mbaannexureIII1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.SpinnerService.hide();
        this.mbaannexureIII1 = this.user.imgPath;
        // this.toastr.success("data upload successfully.");
      })
  }
  uploadFile_mbaimportFileIsubmission(fileInput, docType) {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowser_mbaimportFileIsubmission(<File>fileInput.target.files, docType);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
  }
  uploadFileBrowser_mbaimportFileIsubmission(fileData, docType) {
    //this.mbaimportFileIsubmission1=fileData[0].name;
    this.SpinnerService.show();
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName = this.StudentREgistrationform.controls.jeeapplicationno.value + "-" + docType;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    var url = environment.BaseUrl + `api/EbaySeller/uploaddocument`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.SpinnerService.hide();
        this.mbaimportFileIsubmission1 = this.user.imgPath;
        //  this.mbaimportFileIsubmission1=this.user.imgPath;
        //this.toastr.success("data upload successfully.");
      })
  }
  onFilterChange(eve: any) {
    this.checked2 = !this.checked2;
    if (this.checked2 == true) {
      this.PermentAddress = this.StudentREgistrationform.value.permanentaddress;
      this.CorrespondensAddress = this.StudentREgistrationform.value.pincode;
      this.CorrespondensCity = this.StudentREgistrationform.value.Parameter1;
      this.CorrespondensState = this.StudentREgistrationform.value.Parameter3;
      //this.StudentREgistrationform.controls["correspondenceaddress"]=this.StudentREgistrationform.value.permanentaddress;
    }

  }
  onFilterChangeFather(eve: any) {
    this.checked3 = !this.checked3;
    if (this.checked3 == true) {
      this.FatherPermentAddress = this.StudentREgistrationform.value.fatherpermentaddress;
      this.FatherPinAddress = this.StudentREgistrationform.value.pin;
      //this.StudentREgistrationform.controls["correspondenceaddress"]=this.StudentREgistrationform.value.permanentaddress;
    }

  }
  onFilterChangeMother(eve: any) {
    this.checked4 = !this.checked4;
    if (this.checked4 == true) {
      this.MotherPermentAddress = this.StudentREgistrationform.value.motherpermentaddress;
      this.MotherPinAddress = this.StudentREgistrationform.value.motherpincode;
      //this.StudentREgistrationform.controls["correspondenceaddress"]=this.StudentREgistrationform.value.permanentaddress;
    }

  }
  GuardianonFilterChange(eve: any) {
    this.checked5 = !this.checked5;
    if (this.checked5 == true) {
      this.GuardianonPermentAddress = this.StudentREgistrationform.value.guardianpermanentaddress;
      this.GuardianonPinAddress = this.StudentREgistrationform.value.guardianpincode;
      //this.StudentREgistrationform.controls["correspondenceaddress"]=this.StudentREgistrationform.value.permanentaddress;
    }

  }
  SubmitData() {
    if (this.StudentREgistrationform.value.passportsizephoto == "" || this.StudentREgistrationform.value.sign == "" || this.StudentREgistrationform.value.undertaking1 == false || this.StudentREgistrationform.value.undertaking1 == undefined) {
      this.IsLavel4 == true;
      this.toastr.error("Upload and Check Mandatory fields");
    }
    else {
      this.StudentREgistrationform.value;
      const data =
      {
        'Parameter1': this.StudentREgistrationform.controls['Parameter1'].value,
        'Parameter2': this.StudentREgistrationform.controls['Parameter2'].value,
        'Parameter3': this.StudentREgistrationform.controls['Parameter3'].value,
        'Parameter4': this.StudentREgistrationform.controls['Parameter4'].value,
        'jeeapplicationno': this.StudentREgistrationform.controls['jeeapplicationno'].value,
        'fullname': this.StudentREgistrationform.controls['fullname'].value,
        'dob': this.StudentREgistrationform.controls['dob'].value,
        'departmentname': this.StudentREgistrationform.controls['departmentname'].value,
        'gender': this.StudentREgistrationform.controls['gender'].value,
        'programme': this.StudentREgistrationform.controls['programme'].value,
        'category': this.StudentREgistrationform.controls['category'].value,
        'year': this.StudentREgistrationform.controls['year'].value,
        'physicallydisabled': this.StudentREgistrationform.controls['physicallydisabled'].value,
        'semester': this.StudentREgistrationform.controls['semester'].value,
        'nationality': this.StudentREgistrationform.controls['nationality'].value,
        'specialization': this.StudentREgistrationform.controls['specialization'].value,
        'iitrmailid': this.StudentREgistrationform.controls['iitrmailid'].value,
        'scheme': this.StudentREgistrationform.controls['scheme'].value,
        'aadharno': this.StudentREgistrationform.controls['aadharno'].value,
        'bhawan': this.StudentREgistrationform.controls['bhawan'].value,
        'roomno': this.StudentREgistrationform.controls['roomno'].value,
        'undertaking2': this.StudentREgistrationform.controls['undertaking2'].value,
        'permanentaddress': this.StudentREgistrationform.controls['permanentaddress'].value,
        'correspondenceaddress': this.StudentREgistrationform.controls['correspondenceaddress'].value,
        'pincode': this.StudentREgistrationform.controls['pincode'].value,
        'pincode1': this.StudentREgistrationform.controls['pincode1'].value,
        'nameofaccountholder': this.StudentREgistrationform.controls['nameofaccountholder'].value,
        'bankname': this.StudentREgistrationform.controls['bankname'].value,
        'bankaccountno': this.StudentREgistrationform.controls['bankaccountno'].value,
        'bankifsccode': this.StudentREgistrationform.controls['bankifsccode'].value,
        'fathername': this.StudentREgistrationform.controls['fathername'].value,
        'fdesignation': this.StudentREgistrationform.controls['fdesignation'].value,
        'fathermobile': this.StudentREgistrationform.controls['fathermobile'].value,
        'fatheremailid': this.StudentREgistrationform.controls['fatheremailid'].value,
        'undertaking3': this.StudentREgistrationform.controls['undertaking3'].value,
        'fatherpermentaddress': this.StudentREgistrationform.controls['fatherpermentaddress'].value,
        'fathercorrespondenceaddress': this.StudentREgistrationform.controls['fathercorrespondenceaddress'].value,
        'pin': this.StudentREgistrationform.controls['pin'].value,
        'pincode2': this.StudentREgistrationform.controls['pincode2'].value,
        'mothername': this.StudentREgistrationform.controls['mothername'].value,
        'motherdesignation': this.StudentREgistrationform.controls['motherdesignation'].value,
        'phoneno': this.StudentREgistrationform.controls['phoneno'].value,
        'motheremailid': this.StudentREgistrationform.controls['motheremailid'].value,
        'undertaking4': this.StudentREgistrationform.controls['undertaking4'].value,
        'motherpermentaddress': this.StudentREgistrationform.controls['motherpermentaddress'].value,
        'motherpincode': this.StudentREgistrationform.controls['motherpincode'].value,
        'cpincode': this.StudentREgistrationform.controls['cpincode'].value,
        'guardianname': this.StudentREgistrationform.controls['guardianname'].value,
        'guardiandesignation': this.StudentREgistrationform.controls['guardiandesignation'].value,
        'guardianmobileno': this.StudentREgistrationform.controls['guardianmobileno'].value,
        'guardianemailid': this.StudentREgistrationform.controls['guardianemailid'].value,
        'undertaking5': this.StudentREgistrationform.controls['undertaking5'].value,
        'guardianpermanentaddress': this.StudentREgistrationform.controls['guardianpermanentaddress'].value,
        'guardiancorrespondenceaddress': this.StudentREgistrationform.controls['guardiancorrespondenceaddress'].value,
        'guardianpincode': this.StudentREgistrationform.controls['guardianpincode'].value,
        'localguardianname': this.StudentREgistrationform.controls['localguardianname'].value,
        'localguardianemailid': this.StudentREgistrationform.controls['localguardianemailid'].value,
        'localguardianmobileno': this.StudentREgistrationform.controls['localguardianmobileno'].value,
        'localguardianprementaddress': this.StudentREgistrationform.controls['localguardianprementaddress'].value,
        'localguardianpincode': this.StudentREgistrationform.controls['localguardianpincode'].value,
        'MSCCategory': this.StudentREgistrationform.controls['MSCCategory'].value,
        'MBACategory': this.StudentREgistrationform.controls['MBACategory'].value,
        'mcaimportFileJAM2020': this.mcaimportFileJAM2020MCA1,
        'mcaimportFile10thMarksheet': this.mcaimportFile10thMarksheet1,
        'mcaimportFile12thMarksheet': this.mcaimportFile12thMarksheet1,
        'mcaimportFileUGmarksheets': this.mcaimportFileUGmarksheets1,
        'mcaimportFilePGmarksheets': this.mcaimportFilePGmarksheets1,
        'mcaimportFilequalifyingdegree': this.mcaimportFilequalifyingdegree1,
        'mcaimportFileCategorycertificate': this.mcaimportFileCategorycertificate1,
        'mcaimportFileAadhar': this.mcaimportFileAadhar,
        'mcaimportFilephysicallyhandicapped': this.mcaimportFilephysicallyhandicapped1,
        'mcaimportFileIncomeTax': this.mcaimportFileIncomeTax1,
        'mcaimportFileannexureI': this.mcaimportFileannexureI1,
        'mcaimportFileannexureII': this.mcaimportFileannexureII1,
        'mcaimportFileannexureIII': this.mcaimportFileannexureIII1,
        'mcaimportFileIsubmission': this.mcaimportFileIsubmission1,
        'candidatescategorystudents': this.StudentREgistrationform.controls['candidatescategorystudents'].value,
        'regularcandidatesimportFileGATEScore': this.regularcandidatesimportFileGATEScore1,
        'regularcandidatesimportFile10thMarksheet': this.regularcandidatesimportFile10thMarksheet1,
        'regularcandidatesimportFile12thMarksheet': this.regularcandidatesimportFile12thMarksheet1,
        'regularcandidatesimportFileUGmarksheets': this.regularcandidatesimportFileUGmarksheets1,
        'regularcandidatesimportFilePGmarksheets': this.regularcandidatesimportFilePGmarksheets1,
        'regularcandidatesimportFilequalifyingdegree': this.regularcandidatesimportFilequalifyingdegree1,
        'regularcandidatesimportFileCategorycertificate': this.regularcandidatesimportFileCategorycertificate1,
        'regularcandidatesimportFilephysicallyhandicapped': this.regularcandidatesimportFilephysicallyhandicapped1,
        'regularcandidatesimportFileAadhar': this.regularcandidatesimportFileAadhar1,
        'annexureIregularcandidates': this.annexureIregularcandidates1,
        'annexureIIregularcandidates': this.annexureIIregularcandidates1,
        'annexureIIIregularcandidates': this.annexureIIIregularcandidates1,
        'regularcandidatesimportFileIsubmission1': this.regularcandidatesimportFileIsubmission11,
        'iitgraduatesimportFile10thMarksheet': this.iitgraduatesimportFile10thMarksheet1,
        'iitgraduatesimportFile12thMarksheet': this.iitgraduatesimportFile12thMarksheet1,
        'iitgraduatesimportFileUGmarksheets': this.iitgraduatesimportFileUGmarksheets1,
        'iitgraduatesimportFilePGmarksheets': this.iitgraduatesimportFilePGmarksheets1,
        'iitgraduatesimportFilequalifyingdegree': this.iitgraduatesimportFilequalifyingdegree1,
        'iitgraduatesimportFileCategorycertificate': this.iitgraduatesimportFileCategorycertificate1,
        'iitgraduatesimportFilephysicallyhandicapped': this.iitgraduatesimportFilephysicallyhandicapped1,
        'iitgraduatesannexureI': this.iitgraduatesannexureI1,
        'iitgraduatesannexureII': this.iitgraduatesannexureII1,
        'iitgraduatesannexureIII': this.iitgraduatesannexureIII1,
        'iitgraduatesimportFileIsubmission': this.iitgraduatesimportFileIsubmission1,
        'sponsoredcandidatesimportFile10thMarksheet': this.sponsoredcandidatesimportFile10thMarksheet1,
        'sponsoredcandidatesimportFile12thMarksheet': this.sponsoredcandidatesimportFile12thMarksheet1,
        'sponsoredcandidatesimportFileUGmarksheets': this.sponsoredcandidatesimportFileUGmarksheets1,
        'sponsoredcandidatesimportFilePGmarksheets': this.sponsoredcandidatesimportFilePGmarksheets1,
        'sponsoredcandidatesimportFileCategorycertificate': this.sponsoredcandidatesimportFileCategorycertificate1,
        'sponsoredcandidatesimportFileAadhar': this.sponsoredcandidatesimportFileAadhar1,
        'sponsoredcandidatesimportFilephysicallyhandicapped': this.sponsoredcandidatesimportFilephysicallyhandicapped1,
        'sponsoredcandidatesimportFileExperiencecertificate': this.sponsoredcandidatesimportFileExperiencecertificate1,
        'sponsoredcandidatesimportFileSponsorshipCertificate': this.sponsoredcandidatesimportFileSponsorshipCertificate1,
        'sponsoredcandidatesimportFileNoObjection': this.sponsoredcandidatesimportFileNoObjection1,
        'sponsoredcandidatesimportFilerelieving': this.sponsoredcandidatesimportFilerelieving1,
        'sponsoredcandidatesannexureI': this.sponsoredcandidatesannexureI1,
        'sponsoredcandidatesannexureII': this.sponsoredcandidatesannexureII1,
        'sponsoredcandidatesannexureIII': this.sponsoredcandidatesannexureIII1,
        'sponsoredcandidatesimportFileIsubmission': this.sponsoredcandidatesimportFileIsubmission1,
        'foreignnationalsimportFile10thMarksheet': this.foreignnationalsimportFile10thMarksheet1,
        'foreignnationalsimportFile12thMarksheet': this.foreignnationalsimportFile12thMarksheet1,
        'foreignnationalsimportFileUGmarksheets': this.foreignnationalsimportFileUGmarksheets1,
        'foreignnationalimportFilePassport': this.foreignnationalimportFilePassport1,
        'foreignnationalimportFileVisa': this.foreignnationalimportFileVisa1,
        'foreignnationalimportFileHIV': this.foreignnationalimportFileHIV1,
        'foreignnationalannexureI': this.foreignnationalannexureI1,
        'foreignnationalannexureII': this.foreignnationalannexureII1,
        'foreignnationalannexureIII': this.foreignnationalannexureIII1,
        'foreignnationalimportFileIsubmission': this.foreignnationalimportFileIsubmission1,
        'mbaimportFileCATScoreCard': this.mbaimportFileCATScoreCard1,
        'mbaimportFile10thMarksheet': this.mbaimportFile10thMarksheet1,
        'mbaimportFile12thMarksheet': this.mbaimportFile12thMarksheet1,
        'mbaimportFileUGmarksheets': this.mbaimportFileUGmarksheets1,
        'mbaimportFilePGmarksheets': this.mbaimportFilePGmarksheets1,
        'mbaimportFilequalifyingdegree': this.mbaimportFilequalifyingdegree1,
        'mbaimportFileCategorycertificate': this.mbaimportFileCategorycertificate1,
        'mbaimportFileAadhar': this.mbaimportFileAadhar1,
        'mbaimportFilephysicallyhandicapped': this.mbaimportFilephysicallyhandicapped1,
        'mbaimportFileIncomeTax': this.mbaimportFileIncomeTax1,
        'mbaannexureI': this.mbaannexureI1,
        'mbaannexureII': this.mbaannexureII1,
        'mbaannexureIII': this.mbaannexureIII1,
        'mbaimportFileIsubmission': this.mbaimportFileIsubmission1,
        'passportsizephoto': this.url,
        'sign': this.url1,
        'undertaking1': this.StudentREgistrationform.controls['undertaking1'].value,
        'feesamount': this.StudentREgistrationform.controls['feesamount'].value,
        'dateofpayment': this.StudentREgistrationform.controls['dateofpayment'].value,
        'transactionid': this.StudentREgistrationform.controls['transactionid'].value,
        'email': this.Email,
      }
      this.SpinnerService.show();
      this.sellerService.savestudentdata(data).subscribe((result: any) => {
        console.log(data, 'dataofpgstudent')
        if (result.statusCode == 200) {
          this.StudentREgistrationform.reset();
          this.toastr.success("Your request has been submitted successfully. Confirmation of registration will be sent in due course of time.");
          this.router.navigate(["/Admin/home"]);
        }
        else {
          this.toastr.error("something went wrong please try again");
        }
        this.SpinnerService.hide();
      })
    }

  }

}

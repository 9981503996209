import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SellerService } from '../Services/Seller/seller.service';

@Component({
  selector: 'app-bankstatements',
  templateUrl: './bankstatements.component.html',
  styleUrls: ['./bankstatements.component.scss']
})
export class BankstatementsComponent implements OnInit {
  ExcelDbList: any[] = [];
  TaskDbList:any[]=[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  constructor(public datepipe: DatePipe,private http: HttpClient,private sellerService:SellerService, private toastr: ToastrService,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService,private httpBackend: HttpBackend)
   { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.loadExcel();
  }

  loadExcel() {
    this.spinner.show();
    this.sellerService.GetBankUploadData().subscribe((data: any) => {
      console.log('new karan', data);
      this.TaskDbList = data.data.data;
      this.spinner.hide();
      this.dtTrigger.next();
    },
      (err: HttpErrorResponse) => {

      });
  }
  Runexc(con)
  {
    let data=con;
    this.spinner.show();
    this.sellerService.RunExeBankStatement(con.id).subscribe((data: any) => {
      this.spinner.hide();
      if(data.statusCode==200)
      {
this.toastr.info("Excel Uploaed Successfully" )
this.ngOnInit() ;
      }
      else if(data.statusCode==400)
      {
        this.toastr.warning(data.excelExits)
      }
      else{
        this.toastr.error("Please try again" )
      }
      console.log('new karan', data);
      var dd = data.data;
    },
      (err: HttpErrorResponse) => {
      });
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SellerService } from '../Services/Seller/seller.service';
//import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';  
import { DatePipe } from '@angular/common';
declare var jsPDF: any;

@Component({
  selector: 'app-sric',
  templateUrl: './sric.component.html',
  styleUrls: ['./sric.component.scss']
})
export class SricComponent implements OnInit {
  submitted = false;
  @ViewChild('pdfasTableHDFC', {static: false}) pdfasTableHDFC: ElementRef;
  @ViewChild('form', {static: false}) form: ElementRef;
  @ViewChild('pnbform', {static: false}) pnbform: ElementRef;
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  accessCode: any;
  merchant_id:any;
  encRequest : any;
  encdata:any;
  merchant_code:any;
  currency: string;
  SellerDbList: any[] = [];
  TaskDbList: any[] = [];
  IITRSRICForm:FormGroup;
  // IITRDonationFormSubmit:boolean=false;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  IsresponseSBI: boolean;
  IsresponseHDFC:boolean;
  IsresponsePNB:boolean;
  ApplicationSeq_No:any;
  CATRegistrationNos:any;
  applicationSeqNo:any = {};
  AmountStatus:any;
  ApplicantName: any;
  questionTypedeatils: any;
  RegistrationNo: any;
  e_receiptOpen:boolean=false;
  DonationData:any;
  amountinword: any;
  // pnb data
  merchantRequest : any;
  MID:any;
  StatusMessage: any;
  trans_date: any;
  constructor(private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute)
   {
    this.route.queryParams.subscribe(params => {
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
      this.ApplicationSeq_No=params['ApplicationSeq_No'];
      this.ApplicantName=params['ApplicantName'];
      this.RegistrationNo=params['RegistrationNo'];
      this.StatusMessage=params['StatusMessage'];
      this.trans_date=params['trans_date'];
  });

  this.IITRSRICForm = this.formBuilder.group({
    PaymentType:[``, Validators.required],
    ProjectNo:[``,Validators.required],
    ProjectName:[``,Validators.required],
    ProjectPI:[``,Validators.required],
    NameOfFundingAgency:[``,Validators.required],
    NameofRemitter:[``,Validators.required],
    EmailId:[``,Validators.required],
    PanNo:[``,Validators.required],
    GSTNo:[``,Validators.required],
    PrincipalInvestigator:[``,Validators.required],
    Department:[``,Validators.required],
    AdvanceInvoiceNo:[``,Validators.required],
    Contracted_principalAmount:[``,Validators.required],
    GSTAmount:[``,Validators.required],
    GrossAmount:[''],
    TDS_GS:[``,Validators.required],
    TDS_Income_tax:[``,Validators.required],
    NetAmount:[``,Validators.required],
    PaymentDetails:[``,Validators.required],
    Remarks:[''],
    encdata:[''],
    merchant_code:[''],
        })
    }

  ngOnInit() 
  {
    this.currency='INR';
    this.accessCode = 'AVWV12IG50BT21VWTB';
    this.MID='WL0000000065439';

    this.IITRSRICForm = this.formBuilder.group({
      PaymentType:[``, Validators.required],
      ProjectNo:[``,Validators.required],
      ProjectName:[``,Validators.required],
      ProjectPI:[``,Validators.required],
      NameOfFundingAgency:[``,Validators.required],
      NameofRemitter:[``,Validators.required],
      EmailId:[``,Validators.required],
      PanNo:[``,Validators.required],
      GSTNo:[``,Validators.required],
      PrincipalInvestigator:[``,Validators.required],
      Department:[``,Validators.required],
      AdvanceInvoiceNo:[``,Validators.required],
      Contracted_principalAmount:[``,Validators.required],
      GSTAmount:[``,Validators.required],
      GrossAmount:[''],
      TDS_GS:[``,Validators.required],
      TDS_Income_tax:[``,Validators.required],
      NetAmount:[``,Validators.required],
      PaymentDetails:[``,Validators.required],
      Remarks:[''],
      encdata:[''],
      merchant_code:[''],
          })

    if(this.order_status=="Success")
    {
      this.IsresponseHDFC = true;
    }
     else if(this.order_status=="Failure") 
    {
      this.IsresponseHDFC = true;
    }
    else if(this.order_status=="Aborted")
    {
    this.IsresponseHDFC = true;
    }
    else if(this.order_status=="Invalid")
    {
    this.IsresponseHDFC = true;
    }
    if(this.order_status=="F" || this.order_status=="S")
    {
    this.IsresponsePNB=true;
    }
  }
  get f() { return this.IITRSRICForm.controls; }

  SubmitDataHDFC()
  {
    this.submitted = true;
    if(!this.IITRSRICForm.valid){
      return;
    }
      this.sellerService.PaySRICPaymentViaHDFC(this.IITRSRICForm.value).subscribe((data:any)=>{
     console.log('SRICHdfcencdata',data.data.data)
     this.encRequest = data.data.data; 
     console.log(this.accessCode,this.encRequest);
     if(data.data.data.amountStatus!="Success")
     {
     setTimeout(() => {
      this.form.nativeElement.submit()
    }, 1000);
    }
    else
    {
      this.toastr.info(data.data.message)
    }
    },
    error => {
      console.log(error)
      }
    );
  }

  SubmitPNBPayRequest()
  {
    this.submitted = true;
    if(!this.IITRSRICForm.valid){
      return;
    }
      this.sellerService.SubmitPNBPayRequest(this.IITRSRICForm.value).subscribe((data:any)=>{
     this.merchantRequest = data.data.data; 

     console.log(this.MID,this.merchantRequest);
    //  if(data.data.data.amountStatus!="Success")
    //  {
     setTimeout(() => 
     {
      this.pnbform.nativeElement.submit()
     }, 1000);
    //  }
    // else
    // {
    //   this.toastr.info(data.data.message)
    // }
    },
    error => {
      console.log(error)
      }
    );
  }

  Goback() {
    $('.abc').removeClass('addremovecss');
     window.location.href='https://payment.iitr.ac.in/SRIC';
     //window.location.href='http://localhost:4200/SRIC';
    this.IsresponseSBI=false;
  }
  public downloadPDFHDFC() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTableHDFC = this.pdfasTableHDFC.nativeElement;
    doc.fromHTML(pdfasTableHDFC.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceiptofNewEntrants_Fee.pdf');
  }
}

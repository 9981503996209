import { Component, OnInit } from '@angular/core';
import { CommonserviceService } from '../Services/CommonService/commonservice.service';
import { Router } from '@angular/router';
import { SignupService } from '../Services/SignUp/signup.service'
import { environment } from 'src/environments/environment';
import { from } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isCollapsed = true;
  isHomeActive=true;
  searchtgl: boolean = false;
  title = 'ebayaftercare-app';
  loggedIn:boolean;
  UserName:string;
  userRole:any;
  menuItemCustomer:any;
  menuItemOrder: any;
  menuItemStudent:any;
  menuItemSeller: any;
  menuItemProduct: any;
  menuItemQuestion: any;
  myProfile: any;
  menuItemDash: any;
  menuItemHome:any;
  email:any;
  menuItemResponse: string;
  firstNme: string;
  lastname: string;
  menutablewithfilter: string;
  menuItemIITRFEES: any;
  IsReset:any;
  studentadmission: string;
  New_entrants: string;
  CCBList: string;
  FinanceList: string;
  LibraryList: string;
  changepassword: string;
  UploadExcel: string;
  FessDeatils: string;
  StartStop: any;
  ExcelHome: string;
  offlineexcel: string;
  NonTeachingList: string;
  Studentcomplinelist: string;
  ADCIData: string;
  UploadStudentExcelData: string;
  PageId: string;
  MedicalFundList: any;
  BankStaements: string;
  UplaodBankStatements: string;
  menuItemSCPC: string;
  AccountsDashboard: string;
  PHD_Newentrants: string;
  EmailMasterr: string;
  SendMailMaster: string;
  MarriedHostel: string;
  constructor(private SignupService:SignupService ,private commonService:CommonserviceService,private router : Router){
  };
    LogOut()
    {
     this.email = window.sessionStorage.getItem("Email");
     this.SignupService.LogoutUser(this.email).subscribe((data: any) => {
       console.log("logout successfully!!!");
     });
      window.sessionStorage.removeItem("token");
      window.localStorage.removeItem("token");
      localStorage.removeItem('token');
      localStorage.removeItem('IsReset');
      window.sessionStorage.removeItem("UserName");
      window.sessionStorage.removeItem("Email");
      window.localStorage.removeItem("product id");
      window.localStorage.removeItem("customerId");
      window.localStorage.removeItem("displayOrderId");
      window.localStorage.removeItem("displayProductId");
      window.localStorage.removeItem("Enrollment");
      window.localStorage.removeItem("userRole");
      this.commonService.login.next(false);
      window.location.href="/login";
    }
      ngOnInit() {
        this.userRole= localStorage.getItem('userRole');
        this.firstNme=localStorage.getItem('firstName');
        this.lastname=localStorage.getItem('lastname');
        this.PageId=localStorage.getItem('PageId');
        this.UserName=sessionStorage.getItem('UserName');
        this.commonService.loginObsrv.subscribe(data=>this.loggedIn=data)
        this.commonService.usernameObsrv.subscribe(data=>this.UserName=data);
        if(this.userRole == 'SubAdmin')
        {
          this.NonTeachingList="/SubAdmin/NonTeachingList";
          this.MedicalFundList="/SubAdmin/MedicalFundList";
          this.menuItemSeller='/SubAdmin/Studentdetails';
          this.New_entrants='/SubAdmin/NewEntrantsList';
          this.PHD_Newentrants='/SubAdmin/NewApplicant';
          this.studentadmission='/SubAdmin/studentsdetails';
          this.FessDeatils='/SubAdmin/Feesdetails';
          this.CCBList="/SubAdmin/CCBList";
          this.FinanceList="/SubAdmin/FinanceList";
          this.LibraryList="/SubAdmin/LibraryList";
          this.myProfile='/SubAdmin/my-profile';
          this.changepassword='/SubAdmin/ChangePassword';
          this.menuItemHome='/SubAdmin/home';
          this.menuItemSCPC='/SubAdmin/SCPC';
          this.MarriedHostel='/SubAdmin/MarriedHostel';
        }
        if(this.router.url=="/SubAdmin/MarriedHostel"||this.router.url=="/SubAdmin/ChangePassword"||this.router.url=="/SubAdmin/my-profile"||this.router.url=="/SubAdmin/LibraryList"||this.router.url=="/SubAdmin/FinanceList"||this.router.url=="/SubAdmin/CCBList"||this.router.url=="/SubAdmin/Studentdetails"||this.router.url=="/SubAdmin/NewEntrantsList"||this.router.url=="/SubAdmin/studentsdetails"||this.router.url=="/SubAdmin/Feesdetails"){
          this.isHomeActive = false;
        }
        if(this.userRole == 'DOSW')
        {
          this.menuItemSeller='/DOSW/Studentdetails';
          this.FinanceList="/DOSW/FinanceList";
          this.myProfile='/DOSW/my-profile';
          this.changepassword='/DOSW/ChangePassword';
          this.menuItemHome='/DOSW/home';
          this.MarriedHostel='/DOSW/MarriedHostel';
        }
        if(this.router.url=="/DOSW/MarriedHostel"||this.router.url=="/DOSW/home"||this.router.url=="/DOSW/Studentdetails"||this.router.url=="/DOSW/FinanceList"||this.router.url=="/DOSW/my-profile"||this.router.url=="/DOSW/ChangePassword"){
          this.isHomeActive = false;
        }
        if(this.userRole == 'NonTeaching')
        {
          this.NonTeachingList="/NonTeaching/NonTeachingList";
          this.myProfile='/NonTeaching/my-profile';
          this.changepassword='/NonTeaching/ChangePassword';
        }
        if(this.router.url=="/NonTeaching/ChangePassword"||this.router.url=="/NonTeaching/my-profile"||this.router.url=="/NonTeaching/FinanceList"){
          this.isHomeActive = false;
        }
        if(this.userRole == 'Warden')
        {
          this.MarriedHostel='/Warden/MarriedHostel';
          this.myProfile='/Warden/my-profile';
          this.changepassword='/Warden/ChangePassword';
        }
        if(this.router.url=="/Warden/ChangePassword"||this.router.url=="/Warden/my-profile"||this.router.url=="/Warden/MarriedHostel"){
          this.isHomeActive = false;
        }


        if(this.userRole == 'Admin_ADCI')
        {
          this.ADCIData='/ADCI/ADCI_Dashboard'
          this.myProfile='/ADCI/my-profile';
          this.changepassword='/ADCI/ChangePassword';
        }
        if(this.router.url=="/ADCI/ChangePassword"||this.router.url=="/ADCI/my-profile"||this.router.url=="/ADCI/ADCI_Dashboard")
        {
          this.isHomeActive = false;
        }
        if(this.userRole=='Admin'){
          //this.menuItemOrder='/Admin/StudentReport';
          this.MedicalFundList="/Admin/MedicalFundList";
          this.NonTeachingList="/Admin/NonTeachingList";
          this.menuItemIITRFEES='/Admin/onsolidated';
          this.menuItemStudent='/Admin/StudentReport';
          this.menuItemOrder='/Admin/orders';
          this.menuItemProduct='/Admin/Product';
          this.menuItemSeller='/Admin/Studentdetails';
          this.menuItemQuestion='/Admin/Questionnaire';
          this.myProfile='/Admin/my-profile';
          this.menuItemCustomer='/Admin/customer';
          this.menuItemHome='/Admin/home';
          this.menuItemResponse='/Admin/CCavenuResponse';
          this.menutablewithfilter='/Admin/tablewithfilter';
          this.studentadmission='/Admin/studentsdetails';
          this.New_entrants='/Admin/NewEntrantsList';
          this.changepassword='/Admin/ChangePassword';
          this.CCBList="/CCB/CCBList";
          this.FinanceList="/CCB/FinanceList";
          this.LibraryList="/CCB/LibraryList";
          this.UploadExcel='/Admin/UploadExcel';
          this.FessDeatils='/Admin/Feesdetails';
          this.StartStop='/Admin/StartStop';
          this.ExcelHome='/Admin/Excel_Data';
          this.offlineexcel='/Admin/offline-students';
          this.Studentcomplinelist='/Admin/StudentComplineList';
          this.UploadStudentExcelData='/Admin/UploadStudentExcelData';
          this.BankStaements='/Admin/BankStatements';
          this.UplaodBankStatements='/Admin/UploadBankExcel';
          this.menuItemSCPC='/Admin/SCPC';
          this.AccountsDashboard='/Admin/AccountsDashboard';
          this.PHD_Newentrants='/Admin/NewApplicant';
          this.EmailMasterr='/Admin/EmailMaster';
          this.MarriedHostel='/Admin/MarriedHostel';
          // this.SendMailMaster='/Admin/SendMailIITR';
          // this.menuItemHome='/Admin/setupseller';
        }
        if(this.router.url=="/Admin/MarriedHostel"||this.router.url=="/Admin/EmailMaster"||this.router.url=="/Admin/UploadBankExcel"||this.router.url=="/Admin/BankStatements"||this.router.url=="/Admin/MedicalFundList"||this.router.url=="/Admin/UploadStudentExcelData"||this.router.url=="/Admin/StudentComplineList"||this.router.url=="/Admin/offline-students"||this.router.url=="/Admin/Excel_Data"||this.router.url=="/Admin/StartStop"||this.router.url=="/Admin/Feesdetails"||this.router.url=="/Admin/UploadExcel"||this.router.url=="/Admin/ChangePassword"||this.router.url=="/Admin/LibraryList"||this.router.url=="/Admin/FinanceList"||this.router.url=="/Admin/CCBList"||this.router.url=="/Admin/NewEntrantsList"||this.router.url=='/Admin/studentsdetails'||this.router.url=='/Admin/home'|| this.router.url=='/Admin/tablewithfilter'|| this.router.url=='/Admin/Seller'|| this.router.url=='/Admin/StudentReport'||this.router.url=='/Admin/Product'||this.router.url=='/Admin/customer'||this.router.url=='/Admin/home'|| this.router.url=='/Admin/Questionnaire' || this.router.url=='/Admin/myProfile' || this.router.url=='Admin/CCavenuResponse'){
          this.isHomeActive = false;
        }
        if(this.userRole=='Seller'){
          this.menuItemOrder='/Seller/orders';
          this.menuItemIITRFEES='/Seller/onsolidated';
          this.menuItemProduct='/Seller/Product';
          this.menuItemQuestion='/Seller/Questionnaire';
          this.myProfile='/Seller/my-profile';
          this.menuItemCustomer='/Seller/customer';
          this.menuItemHome='/Seller/home';
          this.menuItemResponse='/Seller/CCavenuResponse';
          this.menutablewithfilter='/Admin/tablewithfilter';
          this.studentadmission='/Seller/studentsdetails';
          this.changepassword='/Seller/ChangePassword';
        }
        if(this.router.url=='/Seller/ChangePassword'||this.router.url=='/Seller/studentsdetails'||this.router.url=='/Seller/Product'|| this.router.url=='/Admin/tablewithfilter'||this.router.url=='/Seller/customer'||this.router.url=='/Seller/home'|| this.router.url=='/Seller/Questionnaire' || this.router.url=='/Seller/myProfile' || this.router.url=='Seller/CCavenuResponse')
        {
          this.isHomeActive = false;
        }
        if(this.userRole=='Student')
        {
          this.menuItemIITRFEES='/Student/onsolidated';
          this.menuItemSeller='/Student/Studentdetails';
          this.menuItemOrder='/Customer/orders';
          this.menuItemProduct='/Customer/Product';
          this.myProfile='/Student/my-profile';
          this.menuItemHome='/Student/home';
          this.menuItemResponse='/Student/CCavenuResponse';
          this.studentadmission='/Student/studentsdetails';
          this.changepassword='/Student/ChangePassword';
        }
         if(this.router.url=='/Student/studentsdetails'||this.router.url=='/Student/Product'||this.router.url=='/Student/home'||this.router.url=='/Student/orders' || this.router.url=='/Student/Studentdetails'){
           this.isHomeActive = false;
         }
         if(this.userRole=='Librarian'){
           this.menuItemIITRFEES='/Librarian/onsolidated';
           this.menuItemSeller='/Librarian/Studentdetails';
           this.menuItemOrder='/Librarian/orders';
           this.menuItemProduct='/Librarian/Product';
           this.myProfile='/Librarian/my-profile';
           this.menuItemHome='/Librarian/home';
           this.menuItemResponse='/Librarian/CCavenuResponse';
           this.studentadmission='/Librarian/studentsdetails';
           this.changepassword='/Librarian/ChangePassword';
         }
         // if(this.router.url=='/Customer/Product'||this.router.url=='/Customer/orders'){
         //   this.isHomeActive = false;
         // }
          if(this.router.url=='/Librarian/ChangePassword'||this.router.url=='/Librarian/studentsdetails'||this.router.url=='/Librarian/Product'||this.router.url=='/Librarian/home'||this.router.url=='/Librarian/orders' || this.router.url=='/Librarian/Studentdetails'){
            this.isHomeActive = false;
          }
          if(this.userRole=='bio'){
            this.menuItemIITRFEES='/bio/onsolidated';
            this.menuItemSeller='/bio/Studentdetails';
            this.menuItemOrder='/bio/orders';
            this.menuItemProduct='/bio/Product';
            this.myProfile='/bio/my-profile';
            this.menuItemHome='/bio/home';
            this.menuItemResponse='/bio/CCavenuResponse';
            this.studentadmission='/bio/studentsdetails';
            this.changepassword='/bio/ChangePassword';
          }
          // if(this.router.url=='/Customer/Product'||this.router.url=='/Customer/orders'){
          //   this.isHomeActive = false;
          // }
           if(this.router.url=='/bio/ChangePassword'||this.router.url=='/bio/studentsdetails'||this.router.url=='/bio/Product'||this.router.url=='/bio/home'||this.router.url=='/bio/orders' || this.router.url=='/bio/Studentdetails')
           {
             this.isHomeActive = false;
           }
          if(this.userRole=='CCB')
          {
            this.CCBList="/CCB/CCBList";
            this.FinanceList="/CCB/FinanceList";
            this.LibraryList="/CCB/LibraryList"
            this.menuItemHome='/CCB/home';
            this.myProfile='/CCB/my-profile';
            this.changepassword='/CCB/ChangePassword';
          }
           if(this.router.url=='/CCB/ChangePassword'||this.router.url=='/CCB/my-profile'||this.router.url=='/CCB/home'||this.router.url=='/CCB/CCBList'||this.router.url=='/CCB/FinanceList'||this.router.url=='/CCB/LibraryList')
           {
             this.isHomeActive = false;
           }
    }
    headersearchtgl()
    {
        this.searchtgl = !this.searchtgl;       
    }

  }
  



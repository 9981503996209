import { HttpBackend } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../Services/modal.service';
import { SellerService } from '../Services/Seller/seller.service';
//import * as jsPDF from 'jspdf';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
declare var jsPDF: any;
export interface PeriodicElement 
{
      catRegistrationNo:any;
      applicationSeqNo:any;
      applicantName:any;
      applicantCategory:any;
      course:any;
      department:any;
      year:any;
      instituteFee:any;
      mobileNo:any;
      emailId:any;
      aadharCardNo:any;
      bankAccountNo:any;
      bankIFSCCode:any;
      amountStatus:any;
      // CreatedBy:any;
}
@Component({
  selector: 'app-newenterance-fees',
  templateUrl: './newenterance-fees.component.html',
  styleUrls: ['./newenterance-fees.component.scss']
})
export class NewenteranceFeesComponent implements OnInit 
{
  

 NewDetailForm: FormGroup;
 @ViewChild('formHDFC', {static: false}) formHDFC: ElementRef;
 @ViewChild('pdfasTableHDFC', {static: false}) pdfasTableHDFC: ElementRef;
  accessCode: string;
  merchant_id: string;
  currency: string;
  merchant_code: string;
  IsresponseSBI: boolean;
  encRequest: any;
  IsresponseHDFC: boolean;
  searchEnable: boolean = false;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  ApplicationSeq_No:any;
  ApplicantName: any;
  RegistrationNo: any;
  bankname: any;
  email: any;
  username: any;
  ApplicationNo: any;
  searchKey: any;
  resultData: any;
  MTECHVLSI: boolean;
  dataResultList: PeriodicElement[] = [];
  StudentReportDB: any[] = [];
  programme:any
  displayedColumns: string[] = ['select','applicationSeqNo','applicantName','applicantCategory','course','mobileNo','emailId','aadharCardNo','bankAccountNo',
'bankIFSCCode','instituteFee','amountStatus','bankRefNo','bank','iitR_Ref_No','parameter3','description','catRegistrationNo','Action'];
dataSource = new MatTableDataSource<PeriodicElement>(this.StudentReportDB);

dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.StudentReportDB);
   selection = new SelectionModel<PeriodicElement>(true, []);
   StudentReportsDbList: any;
  IsEdit: boolean;
  users: any;
  Amount: number;

  constructor(private sellerService:SellerService,private SpinnerService: NgxSpinnerService, private httpBackend: HttpBackend, private modalService: ModalService, private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService, private route: ActivatedRoute,private formbuilder:FormBuilder)
   { 
    const dataResultList: PeriodicElement[] = [];
    for (let i = 1; i <= 15; i++) {
      //this.SellerDbList.push(this.createNewUser(i));
    }
    // Assign the data to the data source for the table to render
    // this.dataSourceSimple = new MatTableDataSource(dataResultList);
   this. dataSource = new MatTableDataSource(dataResultList);
    
    this.dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.StudentReportDB);



    this.route.queryParams.subscribe(params => {
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
      this.ApplicationSeq_No=params['ApplicationSeq_No'];
      this.ApplicantName=params['ApplicantName'];
      this.RegistrationNo=params['RegistrationNo'];
      this.bankname=params['BankName'];
      this.email=params['EmailId'];
      this.username=params['UserName'];
      this.ApplicationNo=params['ApplicationNo'];
  });





    this.NewDetailForm = this.formBuilder.group({
      fatherName:[``, Validators.required],
      mobileno:[``, Validators.required],
      name: [``, Validators.required],
      aadharno:[``, Validators.required],
      category:[``, Validators.required],
      programme:[``, Validators.required],
      bankname:[``, Validators.required],
      bankholdername:[``, Validators.required],
      ifsccode:[``, Validators.required],
      amount:[``, Validators.required],
      // class: [``, Validators.required],
      // department: [``, Validators.required],
      // year:['',Validators.required],
      // fathername: [``, Validators.required],
      // mothername: ['', Validators.required],
      // bhawannumber: ['', Validators.required],
      // roomnumber: ['', Validators.required],
      // mobilenumber: ['', Validators.required],
      // gmailid: ['', Validators.required],
      // coursestartdate: ['', Validators.required],
      // courseenddate: ['', Validators.required],
      // iitrid: ['', Validators.required],
      // paymentreceipt: ['', Validators.required],
      // degreescancopy: ['', Validators.required],
      mailid:['',Validators.required],
      accountno:['',Validators.required],
    })
   }

  ngOnInit() 
  {
    this.Amount=1000;
    this.programme=' M.Tech VLSI for Industry professionals';
    this.accessCode = 'AVKR88GJ13AH16RKHA';
    this.merchant_id='234485';
    this.currency='INR';
    this.merchant_code='IIT_RK';
    this.IsresponseSBI=false;
    this.MTECHVLSI=true;
    const data =
      {
        'tracking_id' : this.tracking_id,
        'bank_ref_no' : this.bank_ref_no,
        'order_status' : this.order_status,
        'amount' : this.amount,
        'billing_name' :this.billing_name,
        'merchant_param2':this.merchant_param2,
        'delivery_address':this.delivery_address,
        'sbi_ref_no':this.sbi_ref_no,
        'iitr_ref_no':this.iitr_ref_no,
        'status':this.status,
        'desc':this.desc,
        'Transactiontime_date': this.Transactiontime_date,
        'ApplicantName':this.ApplicantName,
        'RegistrationNo':this.RegistrationNo,
        
      }
      if(this.status=="Success")
      {
        this.IsresponseSBI = true;
       // this.downloadPDF();
      }
       else if(this.status=="Failure") 
      {
        this.IsresponseSBI = true;
       // this.downloadPDF();
      }
      else if(this.status=="Aborted")
    {
      this.IsresponseSBI = true;
     // this.downloadPDF();
    }

    if(this.order_status=="Success")
    {
      this.IsresponseHDFC = true;
    }
     else if(this.order_status=="Failure") 
    {
      this.IsresponseHDFC = true;
    }
    else if(this.order_status=="Aborted")
  {
    this.IsresponseHDFC = true;
  }
    this.NewDetailForm.reset();
  }
  public hasError = (controlName: string, errorName: string) => 
  {
    return this.NewDetailForm.controls[controlName].hasError(errorName);
  }
  SaveDataNewEnterance()
  {
    if (this.NewDetailForm.valid) 
    {
    this.sellerService.NewEnteranceFeesCollection(this.NewDetailForm.value).subscribe((data:any)=>{
      console.log('Hdfcencdata',data.data.data)
      this.encRequest = data.data.data; 
      console.log(this.accessCode,this.encRequest);
      if(data.data.data.amountStatus!="Success")
      {
      setTimeout(() => {
       this.formHDFC.nativeElement.submit()
     }, 
     1000);
     }
     else
     {
       this.toastr.info(data.data.message)
     }
     })
    }
    else{
      this.toastr.error("Check all the parameters");
    }
    }
Reset()
{
  this.NewDetailForm.reset();
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

keyPress(event: any) {
  const pattern = /[0-9\+\-\ ]/;

  let inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode != 8 && !pattern.test(inputChar)) {
    event.preventDefault();
  }
}

Goback() {
  $('.abc').removeClass('addremovecss');
   window.location.href='https://iitrpaydemo.azurewebsites.net/New_Entrants_Fees';
  this.IsresponseHDFC=false;
}

public downloadPDFHDFC() {
  const doc = new jsPDF();
  const specialElementHandlers = {
    '#editor': function (element, renderer) {
      return true;
    }
  };
  const pdfasTableHDFC = this.pdfasTableHDFC.nativeElement;
  doc.fromHTML(pdfasTableHDFC.innerHTML, 15, 15, {
    width: 190,
    'elementHandlers': specialElementHandlers
  });
  doc.save('PaymentReceiptM-TechVLSI.pdf');
}

searchData(event) {
  this.SpinnerService.show();
  var data= this.searchKey;
  this.sellerService.searchDetail(this.searchKey).subscribe((result: any) => {
    if (result.statusCode == 200) {
    
      this.searchEnable = true;
      this.MTECHVLSI=false;
      if(result.data.data.length !=0)
      {
      this.resultData = result.data.data;
      this.StudentReportsDbList = result.data.data;
      this.dataResultList = this.StudentReportsDbList;
      this.dataSource.data = this.dataResultList;
      }
      else
      {
        this.resultData = null;
      }
      // this.certificateDetailForm.reset();
    }
    else {
      this.toastr.error("something went wrong please try again");
    }
    this.SpinnerService.hide();
  })

}
formRedirect()
{
  this.MTECHVLSI=true;
  this.searchEnable=false;
}

ViewOrder(editdata) 
{
  $('.abc').addClass('addremovecss');
  this.IsEdit = true;
  this.users = editdata;
}
GobackView()
{
  this.IsEdit = false; 
}
}

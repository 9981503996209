import { Component, OnInit} from '@angular/core';
import { CommonserviceService } from './Services/CommonService/commonservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ebayaftercare-app';
  loggedIn:boolean;
  UserName:string;
  constructor(private commonService:CommonserviceService){
  };
  LogOut(){
    window.sessionStorage.removeItem("token");
    window.localStorage.removeItem("token");
    this.commonService.login.next(false);
  }
ngOnInit(){
this.commonService.loginObsrv.subscribe(data=>this.loggedIn=data)
this.commonService.usernameObsrv.subscribe(data=>this.UserName=data);
}

  }
  


import { SelectionModel } from '@angular/cdk/collections';
// import { Component, Inject } from '@angular/core';
// import { ViewChild } from '@angular/core'
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material';
import { OrderService } from '../Services/Order/order-service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfStmt } from '@angular/compiler';
import { SellerService } from '../Services/Seller/seller.service';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { debug } from 'util';
 //import * as jsPDF from 'jspdf';
 import { ExcelService } from '../Services/excel.service';
 declare var jsPDF: any;
 import * as XLSX from 'xlsx'; 
import { environment } from 'src/environments/environment';

export interface PeriodicElement 
{
  enrollment_Employment:any;
  name:any;
  amount:any;
  remarks:any;
  amountStatus:any;
  transactiontime_date:any;
  iitR_Ref_No:any;
  bankRefNo:any;
  descriptions:any;
  tracking_id:any;
  addressOfPayer:any;
  BillNo:any;
  total:any;
  paidAmount:any;
  months:any;
  parameter5:any;
  checkSums:any;
  parameter2:any;
  hostelname:any;
}

@Component({
  selector: 'app-marrird-hostel-dash',
  templateUrl: './marrird-hostel-dash.component.html',
  styleUrls: ['./marrird-hostel-dash.component.scss']
})
export class MarrirdHostelDashComponent implements OnInit {
  IsEdit = false;
  dataResultList: PeriodicElement[] = [];
  StudentReportDB: any[] = [];
  EnableData=false;
  displayedColumns: string[] = ['select','enrollmentNo','name','hostelname','addressOfPayer','BillNo','total','paidAmount','months','parameter5','amountStatus','transactiontime_date','iITR_Ref_No','BankRefNo','description','checkSums','parameter4','Action'];
  //dataSource = new MatTableDataSource<PeriodicElement>(this.StudentReportDB);
  dataSource: MatTableDataSource<PeriodicElement> = new MatTableDataSource();
  dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.StudentReportDB);
  selection = new SelectionModel<PeriodicElement>(true, []);
  StudentReportsDbList: any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  users: any;
  updateofficalform: FormGroup;
  dataExist: boolean;
  profileedit: boolean;
  UserName: any;
  userRole: any;
  order_level:any;
  AmountStatusList: any;
  exelList:any = [];
  emp_data: any;
  isLoading = false;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 50000, 100000];

  constructor(private excelService:ExcelService,private toastr: ToastrService,private sellerService:SellerService,private spinner: NgxSpinnerService,private formbuilder:FormBuilder,private router : Router,private route: ActivatedRoute) 
  {   const dataResultList: PeriodicElement[] = [];
    for (let i = 1; i <= 15; i++) {
      //this.SellerDbList.push(this.createNewUser(i));
    }
    // Assign the data to the data source for the table to render
    this.dataSourceSimple = new MatTableDataSource(dataResultList);
    this.dataSource = new MatTableDataSource(dataResultList);}
    
    ngAfterViewInit() 
    {
      this.dataSource.paginator = this.paginator;
    }
  ngOnInit() {
    this.userRole=window.localStorage.getItem('userRole');
    this.UserName = window.sessionStorage.getItem('UserName');
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // ********************************//
  this.loadData();
  }
  loadData()
  {
    this.isLoading = true;
    
    this.sellerService.GetMarriedHostelBillData().subscribe((data:any) => {
      this.spinner.show();
      this.StudentReportsDbList = data.data.data;
      console.log(this.StudentReportsDbList);
    // this.listcount= data.data.data.length;
   // this.toastr.info("We have"+" "+this.listcount+" "+"seller list");
    this.dataResultList = this.StudentReportsDbList;
    this.dataSource.data = this.dataResultList;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.spinner.hide();
    },
    (err: HttpErrorResponse) => {
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getDataForHostel(seller: any)
  {
    this.sellerService.getMarriedHostelAmountStatus(seller).subscribe((data: any) => {
        this.AmountStatusList = data.data;
        this.dataResultList = this.AmountStatusList;
        this.dataSource.data = this.dataResultList;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
    
  }

  ExportTOExcel() 
  {
    this.dataSource.data = this.dataResultList;
    this.exelList=this.dataSource.data;
    let dataToExport = this.dataSource.filteredData.map(x => ({
   EnrollmentNo_EmploymentID: x.enrollment_Employment,
    Name:x.name,
    HostelName:x.hostelname,
    Address: x.addressOfPayer,
    BillNo:x.parameter2,
    Amount:x.total,
    PaidAmount:x.paidAmount,
    Month:x.months,
    Date:x.parameter5,
    AmountStatus:x.amountStatus,
    TransactionDate:x.transactiontime_date,
    IITR_Ref_No:x.iitR_Ref_No,
    BankRefNo:x.bankRefNo,
    Descriptions:x.descriptions,
    TrackingId:x.checkSums,
      }));
    let workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, <XLSX.Table2SheetOpts>{ sheet: 'Sheet 1' });
    let workBook: XLSX.WorkBook = XLSX.utils.book_new();
    // // Adjust column width
    // var wscols = [
    //   { wch: 50 },
    //   { wch: 50 },
    //   { wch: 30 }
    // ];
    // workSheet["!cols"] = wscols;
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
    XLSX.writeFile(workBook, `MarriedHostelData.xlsx`);
  }

  downloadFile(fileName) {    
    //var url = environment.BaseUrl + `api/StudentRegistration/uploaddocument`;
    let url = environment.BaseUrl + `api/EbaySeller/DownloadFileMarriedHostelBill?fileName=` + fileName;
    window.open(url);
  }

  Refresh(editdata) 
  {
   // this.IsEdit = true;
    this.emp_data = editdata;
    this.spinner.show();
    this.sellerService.MarriedHostelRefresh(this.emp_data.id).subscribe((data: any) => {
    if(data.data != null)
    {
      this.loadData();
      this.AmountStatusList = data.data.data.data;
      this.StudentReportsDbList=data.data.data;
      if(this.AmountStatusList=='Already Success')
      {
        this.toastr.show(this.AmountStatusList)
      }
      else
      {
      this.toastr.show(this.AmountStatusList)
      this.spinner.hide();
      }
      this.firstDropDownChanged(this.emp_data.enrollment_Employment)
    }
    else
    {
      this.toastr.show('No Status Updated.')
      this.spinner.hide();
    }
      //this.spinner.hide();
    })
  }

  firstDropDownChanged(val: any) 
  {
    console.log(val);
    this.spinner.show();
      this.sellerService.MarriedHostelReceiptDetails(val).subscribe((data: any) => {
        if(data.statusCode==200)
        {
          this.spinner.hide();
          this.StudentReportsDbList = data.data.data;
          this.StudentReportsDbList== data.data;
          this.toastr.show("Done")
        }
        else
        {
          this.toastr.show("No Data Found");
        }
      })
  }


  StatusRefresh()
  {
    this.isLoading = true;
    this.spinner.show();
    this.sellerService.MarriedHostelStatusAPI().subscribe((data:any) => {
    this.StudentReportsDbList = data.data.data;
    console.log(this.StudentReportsDbList);
    this.dataResultList = this.StudentReportsDbList;
    this.dataSource.data = this.dataResultList;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.spinner.hide();
    },
    (err: HttpErrorResponse) => {
    });
  }
}

import {SelectionModel} from '@angular/cdk/collections';
import {Component, TemplateRef, OnInit} from '@angular/core';
import {ViewChild } from '@angular/core'
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { HttpErrorResponse } from '@angular/common/http';
import {  FormBuilder, FormGroup } from '@angular/forms';
import {MatSort} from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';


export interface PeriodicElement 
{
  position: number;
  questionnaire: string;
  createdby: any;
  questionAsked:any;
  Type:any;
  questionText:string
  tagName:string
 }
 const ListOfQuestionnairesDB1: PeriodicElement[] = [];
declare var $: any;

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  IsEdit = false;
  IsDelete=false;
  IsConfirmation =false;
  isCollapsed = false;
  users: any = {};
  title = 'nocill';
  ListOfQuestionnairesDB: any[] = [];
  ListOfQuestionnaires:any[]=[];
  dataResultList: PeriodicElement[] = [];
  viewtaglistdata:any[]=[];
  viewtaglistdataafter:any[]=[];
  EnableData=false;
  isLoader = true;
  // displayedColumns: string[] = ['select', 'createdby','questionText', 'Type','questionnaire'];
  displayedColumns: string[] = ['select', 'questionText','questionnaire'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ListOfQuestionnairesDB);
  dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.ListOfQuestionnairesDB);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort,{ static:true}) sort: MatSort;
  closeResult: string;
  showMobile: any;
  QuestionForm:FormGroup;
  QuestionFormSubmit:boolean=false;
  questionTypeValue:any;
  questionTypeId:any;
  updatequestionnairesform: FormGroup;
  DeleteQuestionForm:FormGroup;
  questionTypedeatils: any;
  userRole: string;
  listcount: any;
  Isview: boolean=false;
  tagname: any;
  UserName: any;
  constructor(private spinner: NgxSpinnerService,config: NgbModalConfig,private QuestionnaireService:QuestionnaireService,private modalService: NgbModal, 
    private formbuilder:FormBuilder,private toastr: ToastrService) 
    {
      config.backdrop = 'static';
      config.keyboard = false;
      this.QuestionForm = this.formbuilder.group(
        { 
          QuestionText:[``],
          QuestionType:[``],
          TagName:[``]
        })
        this.updatequestionnairesform = this.formbuilder.group(
          { 
            questionId:[''],
            QuestionText:[``],
            QuestionType:[``],
            TagName:[``]
          })
         this.DeleteQuestionForm = this.formbuilder.group(
          { 
            questionId:[''],
          })
    }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() 
  {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() 
  {
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  open(content) 
  {
    if(this.showMobile) {
      this.modalService.open(content).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  getDismissReason(reason: any) 
  {
    throw new Error("Method not implemented.");
  }
   /** The label for the checkbox on the passed row */
   checkboxLabel(row?: PeriodicElement): string 
   {
    if (!row) 
    {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  ngOnInit()
  {
    this.spinner.show();
    this.userRole= localStorage.getItem('userRole');
    this.QuestionnaireService.getQuestionType().subscribe((data:any)=>{
    this.questionTypedeatils= data.data.data;
    });
    this.dataSource.paginator = this.paginator;
    // lod list of seller
      this.QuestionnaireService.GetListOfQuestionnaires(this.UserName).subscribe((data:any) => {
      this.ListOfQuestionnairesDB = data.data.data;
      this.listcount= data.data.data.length;
      this.toastr.info("We have"+" "+this.listcount+" "+"questionnaires");
      this.dataResultList = this.ListOfQuestionnairesDB;
      this.dataSource.data = this.dataResultList;
      if(this.dataResultList.length>0){
        this.EnableData;
        }
        else{
        this.EnableData=true;
        $('.table-wrap').hide()
        //$('.mat-paginator').hide();
        }
      console.log(this.ListOfQuestionnairesDB);
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
          this.isLoader = false;
      });    
      this.dataSource.filterPredicate = 
      (data: PeriodicElement, filtersJson: string) => 
      {
          const matchFilter = [];
          const temp=[];
          const filters = filtersJson;
          temp.push(filters);  
          temp.forEach(filter => {
            const val = data.tagName === null ? '' : data.tagName;
            const val2 = data.createdby === null ? '' : data.createdby;
            matchFilter.push(val.toLowerCase().includes(filter.toLowerCase()) 
            );
          });
            return matchFilter.every(Boolean);
        };
  }
 
  ngAfterViewInit()
   {
    this.dataSourceSimple.paginator = this.paginator;
    this.dataSourceSimple.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    const filters = filterValue.trim().toLowerCase();
    this.dataSource.filter = filters;
  }
  
  applyFilterSimple(filterValue: string) {
    const filtersSimple = filterValue.trim().toLowerCase();
    this.dataSourceSimple.filter = filtersSimple;
  }
  ViewData(editdata)
  {
    this.Isview = true;
    $('.abc').addClass('addremovecss');
    this.users = editdata;
    this.tagname=editdata.tagName;
    this.viewtaglistdata=editdata.outputList;
  }
  UpdateQue(editdata)
  {
    this.IsEdit = true;
    this.users = editdata;
    this.viewtaglistdataafter=[];
    this.viewtaglistdataafter=this.viewtaglistdata;
    this.viewtaglistdata=editdata.outputList;
  }

DeleteQue(element){
  this.IsDelete=true
  this.users=element;
  this.Isview=false;
}

DeleteQuetion()
{
  this.QuestionnaireService.DeleteQuestionaries(this.DeleteQuestionForm.value).subscribe((data:any)=>{
    this.IsDelete = false;
    this.toastr.success(data.data.message);
    this.ngOnInit();
 //   window.location.reload();
  }, (err: HttpErrorResponse) => {
    this.isLoader = false;
}); 
}
  Goback() {
    $('.abc').removeClass('addremovecss');
    this.Isview=false;
    this.IsEdit = false;
    this.IsDelete=false;
  }
  Gobackedit()
  {
    this.IsEdit=false;
    this.viewtaglistdata=this.viewtaglistdataafter;
  }
  Gobackupdate()
  {
    this.Isview=false;
  }
  AddQuestion()
  {
    this.QuestionFormSubmit=true;
    this.QuestionnaireService.AddQuestionIndividual(this.QuestionForm.value).subscribe((data:any)=>{
    }, (err: HttpErrorResponse) => {
      this.isLoader = false;
  }); 
  }
  UpdateQuestion()
  {
    this.QuestionFormSubmit=true;
    this.QuestionnaireService.UpdateQuestion(this.updatequestionnairesform.value).subscribe((data:any)=>{
      this.IsEdit = false;
      this.toastr.success(data.data.message);
      this.QuestionnaireService.GetListOfQuestionnaires(this.UserName).subscribe((data:any) => {
        this.ListOfQuestionnairesDB = data.data.data;
        this.dataResultList = this.ListOfQuestionnairesDB;
        this.dataSource.data = this.dataResultList;
      });
      this.Isview=false;
   //   window.location.reload();
    }, (err: HttpErrorResponse) => {
      this.isLoader = false;
  }); 
  }

// UpdateQuestion()
// {
//   debugger;
//   this.QuestionFormSubmit=true;
  
//   const data =
//   {
//     questionId: this.updatequestionnairesform.value.questionId,
//     UserEmail: window.sessionStorage.getItem('Email'),
//     UserName: window.sessionStorage.getItem('UserName'),
//     QuestionType: this.updatequestionnairesform.value.QuestionType,
//     TagName: this.updatequestionnairesform.value.TagName,
//     QuestionText:this.updatequestionnairesform.value.QuestionText
//   }
//   this.QuestionnaireService.UpdateQuestion(data).subscribe((result:any)=>{
//     debugger;
//   }, (err: HttpErrorResponse) => {
//     this.isLoader = false;
//   });
// }
}
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as jquery from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { SellerService } from '../Services/Seller/seller.service';
import { ExcelService } from '../Services/excel.service';
interface EventTarget { result: any; }
const image = '';
declare var $: any;
declare var jsPDF: any;


@Component({
  selector: 'app-fess-deatils',
  templateUrl: './fess-deatils.component.html',
  styleUrls: ['./fess-deatils.component.scss']
})
export class FessDeatilsComponent implements OnInit {
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;
  SellerDbList: any[] = [];
  TaskDbList: any[] = [];
  IsDelete:boolean=false;
  loading = false;
  submitted = false;
  isLoader = true;
  users: any = {};
  IsEdit = false;
  excelFile= true;
  userType:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  listcount: any;
  AmountStatusList: any;
  exelList:any = [];
  FeesForm:FormGroup;
  IsView:boolean=false;

  constructor(private excelService:ExcelService,private formbuilder:FormBuilder,private http: HttpClient,private sellerService:SellerService, private toastr: ToastrService,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService) 
  {
    this.FeesForm=this.formbuilder.group(
      {
        Enrollmentno:[''],
        iitR_Ref_No:['']
      })
   }

  ngOnInit() {
    this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 20,
    processing: true
    };
    this.loadExcel();
  }
  loadExcel() 
  {
    this.spinner.show();
    this.sellerService.LoadStudentDetails().subscribe((data: any) => {
    console.log('new karan', data);
    this.TaskDbList = data.data.data;
    this.spinner.hide();
    this.dtTrigger.next();
    },
    (err: HttpErrorResponse) => {
    });
  }

  ngOnDestroy(): void 
  {
    this.dtTrigger.unsubscribe();
  }

  OpenDeleteModal(deletedata) 
  {
    $('.abc').addClass('addremovecss');
    this.IsDelete=true;
    this.users = deletedata;
  }
  clickmobiledata(val:any)
  {
   debugger;
   var data= this.FeesForm.controls.Enrollmentno.value;
   this.firstDropDownChanged(data);
  }

  firstDropDownChanged(val: any)
  {
    this.sellerService.GetstudentFeesData(val).subscribe((data: any) => {
    this.SellerDbList = data.data.data;
    this.listcount= data.data.data.length;
    this.TaskDbList= this.SellerDbList;
    },
    (err: HttpErrorResponse) => {
    });
  }
  firstDropDownChangedIITR(val: any)
  {
    this.sellerService.GetstudentFeesIITRRefrence(val).subscribe((data: any) => {
    this.SellerDbList = data.data.data;
    this.listcount= data.data.data.length;
    this.TaskDbList= this.SellerDbList;
    },
    (err: HttpErrorResponse) => {
    });
  }
  clickmobiledataIITR(val:any)
  {
   debugger;
   var data= this.FeesForm.controls.iitR_Ref_No.value;
   this.firstDropDownChangedIITR(data);
  }

  Refresh(editdata) 
  {
   // this.IsEdit = true;
    this.users = editdata;
    this.spinner.show();
    this.sellerService.RefreshStudentDetailsDataAmountStatus(this.users.stuId).subscribe((data: any) => {
    this.AmountStatusList = data.data;
    if(data.data=='Already Success'){
    this.toastr.show(this.AmountStatusList)
    }
    else{
    this.toastr.show(this.AmountStatusList.data)
    }
    this.firstDropDownChanged(this.users.enrollmentno);
    this.spinner.hide();
    })
  }
  ViewOrder(element)
  {
    debugger;
    this.users = element;
    this.IsView=true;
  }

  public downloadPDF() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTable = this.pdfasTable.nativeElement;
    doc.fromHTML(pdfasTable.innerHTML, 30, 30, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('IITRReceipt.pdf');
  }
  Goback()
  {
    this.IsView=false;
  }
  
}

import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-bankstatements',
  templateUrl: './upload-bankstatements.component.html',
  styleUrls: ['./upload-bankstatements.component.scss']
})
export class UploadBankstatementsComponent implements OnInit 
{

  BankStaementsUploadForm:FormGroup;
  BankStaementsUploadFormSubmit:boolean;
  fileNameList: any = [];
  private httpClient: HttpClient;
  uploadexceldata: any;
  Uploadexcelsheet: string;
  response1: any = {};
  user: { imgPath: ""; };
  name:string;
  firstNme: string;
  lastname: string;

  constructor(public datepipe: DatePipe,private router:Router,private sellerService: SellerService,private formBuilder:FormBuilder,private spinner: NgxSpinnerService,private toastr: ToastrService,private httpBackend: HttpBackend) 
  {
    this.BankStaementsUploadForm = this.formBuilder.group
    ({ 
        fullname:[``,Validators.required],
        Descriptions:[``,Validators.required],
        Uploadexcelsheet: [''],
        excelcode:[''],
    });
  }

  ngOnInit() 
  {
    this.firstNme=localStorage.getItem('firstName');
    this.lastname=localStorage.getItem('lastname');
    this.name=this.firstNme+ " " + this.lastname;
  }

  Reset()
  {
    this.BankStaementsUploadForm.reset();
  }

  downloadFile()
  {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = '/assets/UploadBankBalanceReportFormat.xlsx';
    //link.download = path;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }


  uploadFileBrowse(fileInput, name) 
  {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowserExcelsheet(<File>fileInput.target.files, name);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }

  uploadFileBrowserExcelsheet(fileData, DocType) 
  {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName=this.BankStaementsUploadForm.controls.Uploadexcelsheet.value +"-"+DocType;
      formData.append(fileName,"");
      this.fileNameList.push(fileData[i].DocType);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    this.uploadexceldata=this.BankStaementsUploadForm.value.Uploadexcelsheet
    var url = environment.BaseUrl + `api/EbaySeller/UploadExcelExpenditure`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.Uploadexcelsheet = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }


  SubmitData()
  {
    this.BankStaementsUploadFormSubmit=true;
      if(this.Uploadexcelsheet==""||this.Uploadexcelsheet==undefined)
      {
        this.toastr.error("Upload and check mandatory fields");
      }
      else
      {
    this.spinner.show();
   const data=
   {
  'Name':this.BankStaementsUploadForm.controls['fullname'].value,
  'ExcelName':this.Uploadexcelsheet,
  'Descriptions':this.BankStaementsUploadForm.controls['Descriptions'].value ,
  'Email':sessionStorage.getItem('Email'),
   }
    this.sellerService.SaveBankStatements(data).subscribe(res=>{
      console.log(res);
      if(res["statusCode"]==404)
      {
        // this.wrongCurrentPassword=true;
      }
      else
      {
       //  this.wrongCurrentPassword=false;
      alert(res["message"]);
      this.BankStaementsUploadForm.reset();
      this.router.navigate(["Admin/BankStatements"]);
      this.BankStaementsUploadFormSubmit=false;
    }
    this.spinner.hide(); 
  },
  error=>{
    console.log(error);
    this.spinner.hide();
  });
  }
  }

}

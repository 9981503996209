import { Injectable } from '@angular/core';
import { CommonserviceService } from '../CommonService/commonservice.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  Email:string;
  constructor(private commonService:CommonserviceService, private httpClient:HttpClient) { 
    this.commonService.emailForgotFormObsrv.subscribe(res=>this.Email=res);
  }
  
  PostResetPasswordForm(formData:any){
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
       })   
    }
    return this.httpClient.post(environment.BaseUrl+`api/users/ResetPassword`,{
      'Email':this.Email,
      'OTP':formData.OTP,
      'NewPassword':formData.Password
    },HttpOptions);
  }
}

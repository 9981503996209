import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SellerService } from '../Services/Seller/seller.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-adci-dashboard',
  templateUrl: './adci-dashboard.component.html',
  styleUrls: ['./adci-dashboard.component.scss']
})
export class AdciDashboardComponent implements OnInit {
  
  private httpClient: HttpClient;
  fileNameList: any = [];
  TaskDbList: any[] = [];
  ListDepartment:{name:string;}[];
  IsDelete:boolean=false;
  IsEditForm:boolean=false;
  IsAuthorise:boolean=false;
  IsWithSameExcel:boolean=false;
  IsOTPVerify:boolean=false;
  loading = false;
  submitted = false;
  isLoader = true;
  users: any = {};
  IsEdit = false;
  IsUpdate=false;
  excelFile= true;
  userType:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  // excelwithsamecode: any;
  firstNme: string;
  lastname: string;
  name: string;
  ExcelWithSameCode:FormGroup;
  VerifyOTPsubmit:FormGroup;
  EditExcelform:FormGroup;
  updateADCIform:FormGroup;
  excelwithsamecodedata: any;
  uploadexceldata: any; 
  user: { imgPath: ""; };
  response1: any = {};
  Uploadexcelsheet:string;
  startdate1: string;
  enddate1: string;
  ipAddress: any;
  excelcode: any;
  Userrole: string;
  path: string;
  isresponse: any;
  OrderDetail: any;
  BankStatement: string;
  ADCIData:any;
  paymentSlip: string;
  paymentSlipFlag: boolean;
  HDFCForm:FormGroup;
  password: string;
  IsDeleted: any;
  DeleteData: any;
  constructor(private formbuilder:FormBuilder,public datepipe: DatePipe,private http: HttpClient,private sellerService:SellerService, private toastr: ToastrService,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService,private httpBackend: HttpBackend) 
  { 
    this.updateADCIform= this.formBuilder.group({
      title:[``, Validators.required],
      sponsorname:['',Validators.required],
      link:['',Validators.required],
      enddate:['',Validators.required],
      startdate:['',Validators.required],
      departmentname:[``, Validators.required],
      parameter3:[``, Validators.required],
      id:[``, Validators.required],
      toppings:[``, Validators.required],
     });

     this.HDFCForm=this.formbuilder.group(
      {
        Password:[``, Validators.required],
      })
    
  }

  ngOnInit() 
  {
    this.firstNme=localStorage.getItem('firstName');
    this.lastname=localStorage.getItem('lastname');
    this.name=this.firstNme +" " + this.lastname;
    this.password=null; 
    this.Userrole=sessionStorage.getItem('UserName')
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.LoadADCIDatalist();
  }

  UploadFile(fileInput) 
  {
    if (fileInput != null) 
    {
      this.uploadPaymentReceipt(<File>fileInput.target.files);
      fileInput.target.value = "";
    }
    else 
    {
      this.toastr.error("You could not upload document", "")
    }
  } 
  uploadPaymentReceipt(fileData) 
  {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) 
    {
      formData.append('file[]', fileData[i]);
      var fileName = this.updateADCIform.controls.paymentreceipt.value;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    this.spinner.show();
    var url = environment.BaseUrl + `api/EbaySeller/uploadbankaccountstatements`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.spinner.hide();
        this.response1 = res;
        this.user = 
        {
          imgPath: this.response1.renameFile,
        }
        this.updateADCIform.controls.paymentreceipt.patchValue(this.user.imgPath);
        this.paymentSlip = this.user.imgPath;
        this.paymentSlipFlag = true;
      })
  } 
  LoadADCIDatalist() {
    this.spinner.show();
    this.sellerService.ListOfADCIData(this.Userrole).subscribe((data: any) => {
      console.log('new karan', data);
      this.TaskDbList = data.data.data;
      this.spinner.hide();
      this.dtTrigger.next();
    },
      (err: HttpErrorResponse) => {
      });
  }
  downloadFile(fileName) {    
    //var url = environment.BaseUrl + `api/StudentRegistration/uploaddocument`;
    let url = environment.BaseUrl + `api/StudentRegistration/DownloadFileBankStatement?fileName=` + fileName;
    window.open(url);
  }
  Edit(users)
  {
   const toppingList= [
     {name:'All Departments'}
     ,{name:'Architecture and Planning'}
     ,{name:'Applied Science and Engineering'}
     ,{name:'Biotechnology'}
     ,{name:'Chemical Engineering'}
     ,{name:'Chemistry'}
     ,{name:'Civil Engineering'}
     ,{name:'Computer Science and Engineering'}
     ,{name:'Earthquake Engineering'}
     ,{name:'Earth Sciences'}
     ,{name:'Electrical Engineering'}
     ,{name:'Electronics and Communication Engineering'}
     ,{name:'Humanities and Social Sciences'}
     ,{name:'Hydrology'}
     ,{name:'Hydro and Renewable Energy'}
     ,{name:'Management Studies'}
     ,{name:'Mathematics'}
     ,{name:'Mechanical and Industrial Engineering'}
     ,{name:'Metallurgical and Materials Engineering'}
     ,{name:'Paper Technology'}
     ,{name:'Polymer and Process Engineering'}
     ,{name:'Physics'}
     ,{name:'Water Resources Development and Management'}
     ,{name:'Centre of Nanotechnology'}
     ,{name:'Centre of Excellence in Disaster Mitigation & Management'}
     ,{name:'Centre for Transportation Systems'}
     ,{name:'Continuing Education Centre'}
     ,{name:'Design Innovation Center'}
     ,{name:'Institute Instrumentation Centre'}];
    $('.abc').addClass('addremovecss');
    this.ListDepartment=toppingList;
    this.IsAuthorise=true;
    //this.IsUpdate=true;
    this.users=users;
    let datapay= this.paymentSlip;

  }
  UpdateADCIDataForm(users)
  {
    // const data =
    // {
    //   'filedata' : this.paymentSlip,
    //   'paymentreceipt':this.updateADCIform.controls['paymentreceipt'].value,
    //   'toppings' : this.updateADCIform.controls['toppings'].value,
    //   'enddate' : this.updateADCIform.controls['enddate'].value,
    //   'startdate' : this.updateADCIform.controls['startdate'].value,
    //   'websitelink':this.updateADCIform.controls['websitelink'].value,
    //   'sponsorName':this.updateADCIform.controls['sponsorName'].value,
    //   'title' :this.updateADCIform.controls['title'].value,
    //   'proposaltheme':this.updateADCIform.controls['proposaltheme'].value,
    //   'id':this.updateADCIform.controls['id'].value,
    // }

    this.sellerService.UpdateADCIData(users).subscribe((data:any)=>{
      this.spinner.hide();
      //this.IsStudentCompline=false;
      this.toastr.success(data.message);
      this.IsUpdate=false;
      this.isLoader = false;
      this.ngOnInit();
    }, 
    error => {
      this.isLoader = false;
      console.log(error)
      });
  }
  isDelete(adcidatadelete)
  {
this.IsDeleted=true;
this.DeleteData=adcidatadelete;
  }
  DeleteADCI(adcidatadelete)
  {
    alert("are you sure want to delete?")
    this.sellerService.DeleteADCIDATA(adcidatadelete.id).subscribe((data: any) => {
      this.isLoader = false;
      this.ngOnInit();
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
  }
  ViewOrder(editdata)
  {
    this.path = environment.BaseUrl + "AppData/AccountStatements/";
    //this.isresponse = editdata.isResponse;
    this.IsEdit = true;
    // this.users = editdata;
    this.OrderDetail = editdata;
    this.BankStatement = this.path + this.OrderDetail.filedata;
    console.log('users', this.users);
  }
  Goback() {
    $('.abc').removeClass('addremovecss');
    this.IsEdit = false;
  }
  Gobackupdate()
  {
    $('.abc').removeClass('addremovecss');
    this.IsUpdate=false;
    this.password=null;
  }
  CloseUpdateForm()
  {
    $('.abc').removeClass('addremovecss');
    this.IsAuthorise=false;
  }
  ClosedeleteForm()
  {
    $('.abc').removeClass('addremovecss');
    this.IsDeleted=false;
  }
  DeleteAuthentication()
  {
    this.password="Admin@123";
    if(this.HDFCForm.value.Password==this.password)
    {
      this.IsAuthorise=false;
      this.password=null;
      this.toastr.show("Login Successfully");
      this.IsDeleted=false;
      this.DeleteADCI(this.DeleteData);
    }
    else
    {
      this.toastr.error("Login failed");
    }
  }

  HDFCAuthentication()
  {
    this.password="Admin@123";
    if(this.HDFCForm.value.Password==this.password)
    {
      this.IsAuthorise=false;
      this.password=null;
      this.toastr.show("Login Successfully");
      this.IsUpdate=true;
    }
    else
    {
      this.toastr.error("Login failed");
    }
  }
}

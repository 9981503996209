import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-excel',
  templateUrl: './master-excel.component.html',
  styleUrls: ['./master-excel.component.scss']
})
export class MasterExcelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

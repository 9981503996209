import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
    email: string;
  constructor(private httpClient:HttpClient) { }
  
  GetStudentProfileDataFromEnrollment(formData:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
    }
    let param = new HttpParams();
    // Begin assigning parameters
    param = param.append('UserName', formData);
    return this.httpClient.get(environment.BaseUrl+`api/Users/GetStudentProfileDataFromEnrollment`,{params:param, headers:HttpOptions.headers});
  }

  GetStudentProfileDataFromEnrollmentStudentLoginPage(formData:any)
  {
    let param = new HttpParams();
    // Begin assigning parameters
    param = param.append('UserName', formData);
    return this.httpClient.get(environment.BaseUrl+`api/Users/ProfileDetailsStudentLogin`,{params:param});
  }


  GetUserProfile(formData:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
    }
    let param = new HttpParams();
    // Begin assigning parameters
    param = param.append('UserName', formData);
    return this.httpClient.get(environment.BaseUrl+`api/Users/Profile`,{params:param, headers:HttpOptions.headers});
  }
  GetUserList(formData:any){
    return this.httpClient.get(environment.BaseUrl+`api/Users/UserList`);
  }
  UpdateProfile(formData: any)   {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    return this.httpClient.post(environment.BaseUrl + `api/Users/UpdateProfile`, {
        userId: formData.userId,
        FirstName: formData.firstName,
        LasttName: formData.lasttName,
        MobileNo: formData.mobileNo,
        RegistrationAddress: formData.registrationAddress,
        City: formData.city,
        State:formData.state,
        Country:formData.country,
        PostalCode:formData.postalCode
    }, HttpOptions);
  }
GetCustomerCount(UserName:any)
{
  return this.httpClient.get(environment.BaseUrl + `/api/Users/GetCustomerCount?UserName=${UserName}`);
}
GetStudentActive()
{
  return this.httpClient.get(environment.BaseUrl + '/api/Users/GetStudentActive',);
}

GetOtherFeesStudentCount(UserName:any)
{
  return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetOtherFeesStudentCount?UserName=${UserName}`);
}
GetSellerCount()
{
  return this.httpClient.get(environment.BaseUrl + '/api/Users/GetSellerCount',);
}
GetTotalofExcel1187()
{
  return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/GetTotalofExcel1187',);
}

GetTotalofExcel2288()
{
  return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/GetTotalofExcel2288',);
  
}
GetDataFrom566()
{
  return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/GetDataFrom566',);
  
}
GetTotalofHDFCExcel2288()
{
  return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/GetTotalofHDFCExcel2288',);
}
GetTotalofExcel1187HDFC()
{
  return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/GetTotalofExcel1187HDFC',);
}
GetTotalOf606()
{
  return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/GetTotalOf606',);
}
GetDataFrom2724()
{
  return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/GetDataFrom2724',);
}
GetSumCountMedicalFund()
{
  return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/GetSumOfMedicalFunds',);
}
GetSumOfMarriedHostelFunds()
{
  return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/GetSumOfMarriedHostelFunds',);
}
}
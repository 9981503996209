import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';

@Component({
  selector: 'app-hdfc-challan',
  templateUrl: './hdfc-challan.component.html',
  styleUrls: ['./hdfc-challan.component.scss']
})
export class HDFCChallanComponent implements OnInit {
  HDFCChallanform:FormGroup;
  password: string;
  IsresponseHDFC: boolean;
  HDFCForm:FormGroup;
  HDCFMsg: any;

  constructor(private spinner: NgxSpinnerService,private formbuilder:FormBuilder,private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  {
    this.HDFCChallanform=this.formbuilder.group(
      {
        BeneficiaryAccountNo:[``, Validators.required],
        totalFees:[``, Validators.required],
      })
      this.HDFCForm=this.formbuilder.group(
        {
          Password:[``, Validators.required],
        })
   }

  ngOnInit() {
    this.IsresponseHDFC=true;
    this.password="hdfc123";
  }

  Goback() {
    $('.abc').removeClass('addremovecss');
    this.IsresponseHDFC=false;
  }
  
  HDFCChallan()
  {
    this.spinner.show();
    this.sellerService.VerificationChallan(this.HDFCChallanform.value).subscribe((data:any)=>{
      this.spinner.hide();
      if(data.data.status==404)
      {
        this.HDCFMsg =data.data.message;
        this.toastr.show(data.data.message);
      }
      else if (data.data.status==200) 
      {
        this.HDCFMsg =data.data.message;
        this.toastr.show(data.data.message);
      }
      else if (data.data.status==300) 
      {
      this.HDCFMsg = data.data.message;
      this.toastr.error(data.data.message);
      }
   else if (data.data.status==422) {
    this.HDCFMsg = data.data.message;
    this.toastr.error(data.data.message);
 }
     else
     {
      this.HDCFMsg =data.data.message;
      this.toastr.error(data.data.message);
     }
    }, error => {
    console.log(error)
    }
    );
  }

  HDFCAuthentication()
  {
    if(this.HDFCForm.value.Password==this.password)
    {
      this.IsresponseHDFC=false;
      this.toastr.show("Login Successfully");
    }
    else
    {
      this.toastr.error("Login failed");
    }
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SellerService } from '../Services/Seller/seller.service';
//import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';  
import { DatePipe } from '@angular/common';
declare var jsPDF: any;

@Component({
  selector: 'app-iitr-donation',
  templateUrl: './iitr-donation.component.html',
  styleUrls: ['./iitr-donation.component.scss']
})
export class IitrDonationComponent implements OnInit {

  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('form', {static: false}) form: ElementRef;
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  accessCode: any;
  merchant_id:any;
  encRequest : any;
  encdata:any;
  merchant_code:any;
  currency: string;
  SellerDbList: any[] = [];
  TaskDbList: any[] = [];
  IITRDonationForm:FormGroup;
  IITRDonationFormSubmit:boolean=false;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  IsresponseSBI: boolean;
  IsresponseHDFC:boolean;
  ApplicationSeq_No:any;
  CATRegistrationNos:any;
  applicationSeqNo:any = {};
  AmountStatus:any;
  ApplicantName: any;
  questionTypedeatils: any;
  RegistrationNo: any;
  submitted: boolean;
  e_receiptOpen:boolean=false;
  DonationData:any;
  amountinword: any;
  Online:any;
  constructor(private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  { 
    this.route.queryParams.subscribe(params => {
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
      this.ApplicationSeq_No=params['ApplicationSeq_No'];
      this.ApplicantName=params['ApplicantName'];
      this.RegistrationNo=params['RegistrationNo']
  });
  this.IITRDonationForm = this.formBuilder.group({
    Nameofdonar:[``, Validators.required],
    Phone:[``,Validators.required],
    Email:[``,Validators.required],
    PersonalDetails:[``,Validators.required],
    PurposeOfDonation:[``,Validators.required],
    Amount:[``,Validators.required],
    Remark:[``,Validators.required],
    Pan:[``,Validators.required],
    encdata:[''],
    merchant_code:[''],
        })
  }

  ngOnInit() 
  {
    if(this.order_status =="Success")
     {
      this.sellerService.DonationReceipt(this.order_id ).subscribe((data:any)=>{
        this.DonationData=data.data.data;
        this.amountinword=data.data.datamodel;
        console.log(this.DonationData);
        this.e_receiptOpen=true;
       },
       error => {
         console.log(error)
         }
       );
     }
     else if(this.order_status!=null) 
    {
      this.IsresponseHDFC=true;
    }
    this.currency='INR';
    this.accessCode = 'AVGO09IE49CI70OGIC';
  }

  SubmitDataHDFC()
  {
    this.IITRDonationFormSubmit=true;
    if(!this.IITRDonationForm.valid){
      return;
    }
    this.submitted = true;
      let parameter = "iitr_ref_no=34344333|amount=1";
      this.sellerService.PayDonationViaHDFC(this.IITRDonationForm.value).subscribe((data:any)=>{
     console.log('Hdfcencdata',data.data.data)
     this.encRequest = data.data.data; 
     console.log(this.accessCode,this.encRequest);
     if(data.data.data.amountStatus!="Success")
     {
     setTimeout(() => {
      this.form.nativeElement.submit()
    }, 1000);
    }
    else
    {
      this.toastr.info(data.data.message)
    }
    },
    error => {
      console.log(error)
      }
    );
  }
  Gobackereceipt()
  {
    $('.abc').removeClass('addremovecss');
   // window.location.href='http://localhost:4200/IITR_Donation';
    window.location.href='https://payment.iitr.ac.in/IITR_Donation';
   this.IsresponseHDFC=false;

  }
  Goback()
  {
    $('.abc').removeClass('addremovecss');
    //window.location.href='http://localhost:4200/IITR_Donation';
   window.location.href='https://payment.iitr.ac.in/IITR_Donation';
   this.e_receiptOpen=false;

  }

  public downloadBeena()
  {
    var data = document.getElementById('pdfTable');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295; 
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  

      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('DonationReceipt.pdf'); // Generated PDF  
    });  

  }
}

import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-offline-students',
  templateUrl: './offline-students.component.html',
  styleUrls: ['./offline-students.component.scss']
})
export class OfflineStudentsComponent implements OnInit {
  firstNme: string;
  lastname: string;
  name: string;
  uploadexcelForm:FormGroup;
  uploadexcelFormSubmit:boolean;
  Uploadexcelsheet: string;
  fileNameList: any = [];
  private httpClient: HttpClient;
  uploadexceldata: any;
  response1: any = {};
  user: { imgPath: ""; };

  constructor(public datepipe: DatePipe,private router:Router,private sellerService: SellerService,private formBuilder:FormBuilder,private spinner: NgxSpinnerService,private toastr: ToastrService,private httpBackend: HttpBackend) { 
    this.uploadexcelForm = this.formBuilder.group(
      { 
        fullname:[``,Validators.required],
        Descriptions:[``,Validators.required],
        Uploadexcelsheet: [''],
        excelcode:[``],
        startdate:[''],
        enddate:[''],
        filetype:['']
      }
    );
  }

  ngOnInit() {
    this.firstNme=localStorage.getItem('firstName');
    this.lastname=localStorage.getItem('lastname');
    this.name=this.firstNme +" " + this.lastname
  }
  downloadFile(){
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = '/assets/sampleOfflinestudentdata.xlsx';
    //link.download = path;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }



  uploadFileBrowse(fileInput, name) 
  {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowserExcelsheet(<File>fileInput.target.files, name);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }
  uploadFileBrowserExcelsheet(fileData, DocType) 
  {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName=this.uploadexcelForm.controls.Uploadexcelsheet.value +"-"+DocType;
      formData.append(fileName,"");
      this.fileNameList.push(fileData[i].DocType);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    this.uploadexceldata=this.uploadexcelForm.value.Uploadexcelsheet
    var url = environment.BaseUrl + `api/EbaySeller/UploadExcel`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.Uploadexcelsheet = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }



  UploadExcelSubmit()
  {
    this.uploadexcelFormSubmit=true;
    
      if(this.Uploadexcelsheet==""||this.Uploadexcelsheet==undefined)
      {
        this.toastr.error("Upload and check mandatory fields");
      }
      else{
      this.spinner.show();
       const data=
       {
      'fullname':this.uploadexcelForm.controls['fullname'].value,
      'Uploadexcelsheet':this.Uploadexcelsheet,
      'Descriptions':this.uploadexcelForm.controls['Descriptions'].value ,
      'excelcode':this.uploadexcelForm.controls['excelcode'].value ,
      'filetype':this.uploadexcelForm.controls['filetype'].value ,
      'Email':sessionStorage.getItem('Email'),
       }
        this.sellerService.SaveOfflineExcelData(data).subscribe(res=>{
          console.log(res);
          if(res["statusCode"]==404){
            // this.wrongCurrentPassword=true;
          }
          else{
        //  this.wrongCurrentPassword=false;
         alert(res["message"]);
          this.uploadexcelForm.reset();
          this.router.navigate(["Admin/Excel_Data"]);
          this.uploadexcelFormSubmit=false;
        }
        this.spinner.hide(); 
      },
      error=>{
        console.log(error);
        this.spinner.hide();
      });
      }
  }
}

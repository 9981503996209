import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';
declare var jsPDF: any;
import html2canvas from 'html2canvas'; 
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-married-hostel-bill',
  templateUrl: './married-hostel-bill.component.html',
  styleUrls: ['./married-hostel-bill.component.scss']
})
export class MarriedHostelBillComponent implements OnInit {
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;
  @ViewChild('pdfasTableHDFCChallan', {static: false}) pdfasTableHDFCChallan: ElementRef;
  @ViewChild('pdfasTableHDFC', {static: false}) pdfasTableHDFC: ElementRef;
  @ViewChild('htmlData', {static: false}) htmlData:ElementRef;
  @ViewChild('form', {static: false}) form: ElementRef;
  MarriedHostelBillForm:FormGroup;
  IsAmountButton:boolean;
  Total:any
  accessCode: any;
  merchant_id:any;
  encRequest : any;
  currency: string;
  merchant_code: string;
  enrollmentNo: any;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  trans_date: any;
  IsresponseHDFC: boolean;
  billing_name: any;

  fileNameList: any = [];
  private httpClient: HttpClient;
  response1: any = {};
  user: { imgPath: ""; };
  paymentSlip: string;
  paymentSlipFlag: boolean = true;
  Bill_No: any;
  Month: any;
  Address: any;
  HostelName: any;

  constructor(private httpBackend: HttpBackend,public datepipe: DatePipe,private spinner: NgxSpinnerService,private formbuilder:FormBuilder,private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  { 

    this.route.queryParams.subscribe(params => {
      this.enrollmentNo=params['enrollment_no']
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
      this.trans_date=params['trans_date'];
      this.billing_name=params['billing_name'];
      this.Bill_No=params['Bill_No'];
      this.Month=params['Month'];
      this.Address=params['Address'];
      this.HostelName=params['HostelName'];
  });

    this.MarriedHostelBillForm=this.formBuilder.group({
      Enrollment_Employment:[``, Validators.required],
      currency:[``, Validators.required],
      Name:[``, Validators.required], 
      AddressOfPayer:[``, Validators.required],
      BillRelated:[``, Validators.required],
      billno:[``, Validators.required],
      Date:[``, Validators.required],
      amounttobepaid:[``, Validators.required],
      HouseRent:[``, Validators.required],
      ElectricityCharges:[``, Validators.required],
      WaterChanges:[``, Validators.required],
      MeterCharges:[``, Validators.required],
      FurnitureCharges:[``],
      OtherCharges:[``],
      Arrear:[``],
      Total:[``],
      Months:[``, Validators.required],
      paymentreceipt: ['', Validators.required],
      hostelname:['', Validators.required]
     });
  }

  ngOnInit() 
  {
    this.accessCode = 'AVKR88GJ13AH16RKHA';
    this.merchant_id='234485';
    this.currency='INR';
     this.merchant_code='IIT_RK';

     if(this.order_status=="Success")
     {
       this.IsresponseHDFC = true;
     }
      else if(this.order_status=="Failure") 
     {
       this.IsresponseHDFC = true;
     }
     else if(this.order_status=="Aborted")
   {
     this.IsresponseHDFC = true;
   }
  }
  HouseRent(data: any)
  {
    this.MarriedHostelBillForm.value.Total=Number(data) + Number(this.MarriedHostelBillForm.value.ElectricityCharges)+ Number(this.MarriedHostelBillForm.value.WaterChanges) + Number(this.MarriedHostelBillForm.value.MeterCharges)+Number(this.MarriedHostelBillForm.value.FurnitureCharges)+Number(this.MarriedHostelBillForm.value.OtherCharges)+Number(this.MarriedHostelBillForm.value.Arrear); 
    this.Total=this.MarriedHostelBillForm.value.Total;
  }
  ElectricityRent(data:any)
  {
    this.MarriedHostelBillForm.value.Total=Number(data) +Number(this.MarriedHostelBillForm.value.HouseRent)+ Number(this.MarriedHostelBillForm.value.WaterChanges) + Number(this.MarriedHostelBillForm.value.MeterCharges)+Number(this.MarriedHostelBillForm.value.FurnitureCharges)+Number(this.MarriedHostelBillForm.value.OtherCharges)+Number(this.MarriedHostelBillForm.value.Arrear);
    this.Total=this.MarriedHostelBillForm.value.Total;
  }
  WaterRent(data:any)
  {
    this.MarriedHostelBillForm.value.Total=Number(data) +Number(this.MarriedHostelBillForm.value.HouseRent)+ Number(this.MarriedHostelBillForm.value.ElectricityCharges)+ Number(this.MarriedHostelBillForm.value.MeterCharges)+Number(this.MarriedHostelBillForm.value.FurnitureCharges)+Number(this.MarriedHostelBillForm.value.OtherCharges)+Number(this.MarriedHostelBillForm.value.Arrear);
    this.Total=this.MarriedHostelBillForm.value.Total;
  }
  MeterRent(data:any)
  {
    this.MarriedHostelBillForm.value.Total=Number(data)+Number(this.MarriedHostelBillForm.value.HouseRent)+ Number(this.MarriedHostelBillForm.value.ElectricityCharges)+ Number(this.MarriedHostelBillForm.value.WaterChanges) + Number(this.MarriedHostelBillForm.value.FurnitureCharges)+Number(this.MarriedHostelBillForm.value.OtherCharges)+Number(this.MarriedHostelBillForm.value.Arrear);
    this.Total=this.MarriedHostelBillForm.value.Total;
  }
  FurnitureRent(data:any)
  {
    this.MarriedHostelBillForm.value.Total=Number(data)+Number(this.MarriedHostelBillForm.value.HouseRent)+ Number(this.MarriedHostelBillForm.value.ElectricityCharges)+ Number(this.MarriedHostelBillForm.value.WaterChanges) + Number(this.MarriedHostelBillForm.value.MeterCharges)+Number(this.MarriedHostelBillForm.value.OtherCharges)+Number(this.MarriedHostelBillForm.value.Arrear); 
    this.Total=this.MarriedHostelBillForm.value.Total;
  }
  OtherRent(data:any)
  {
    this.MarriedHostelBillForm.value.Total=Number(data)+Number(this.MarriedHostelBillForm.value.HouseRent)+ Number(this.MarriedHostelBillForm.value.ElectricityCharges)+ Number(this.MarriedHostelBillForm.value.WaterChanges) + Number(this.MarriedHostelBillForm.value.MeterCharges)+Number(this.MarriedHostelBillForm.value.FurnitureCharges)+Number(this.MarriedHostelBillForm.value.Arrear);
    this.Total=this.MarriedHostelBillForm.value.Total;
  }
  ArrearRent(data:any)
  {
    this.MarriedHostelBillForm.value.Total=Number(data)+Number(this.MarriedHostelBillForm.value.HouseRent)+ Number(this.MarriedHostelBillForm.value.ElectricityCharges)+ Number(this.MarriedHostelBillForm.value.WaterChanges) + Number(this.MarriedHostelBillForm.value.MeterCharges)+Number(this.MarriedHostelBillForm.value.FurnitureCharges)+Number(this.MarriedHostelBillForm.value.OtherCharges);
    this.Total=this.MarriedHostelBillForm.value.Total;
  }

  PayFeesHDFC()
  {
    debugger;
    const data =
    {
      'Enrollment_Employment':this.MarriedHostelBillForm.controls['Enrollment_Employment'].value,
      'currency' : this.MarriedHostelBillForm.controls['currency'].value,
      'Name' : this.MarriedHostelBillForm.controls['Name'].value,
      'AddressOfPayer' : this.MarriedHostelBillForm.controls['AddressOfPayer'].value,
      'BillRelated':this.MarriedHostelBillForm.controls['BillRelated'].value,
      'billno':this.MarriedHostelBillForm.controls['billno'].value,
      'Date' :this.MarriedHostelBillForm.controls['Date'].value,
      'Total':this.MarriedHostelBillForm.controls['Total'].value,
      'amounttobepaid':this.MarriedHostelBillForm.controls['amounttobepaid'].value,
      'Months':this.MarriedHostelBillForm.controls['Months'].value,
      'paymentSlip' : this.paymentSlip,
      'hostelname':this.MarriedHostelBillForm.controls['hostelname'].value,
    }
    this.sellerService.ResponseHandlerOfHDFCMarriedHostelBill(data).subscribe((data:any)=>{
      console.log('---------------------', data.data.data)
      this.encRequest = data.data.data; 
      console.log(this.accessCode,this.encRequest);
      setTimeout(() => {
        this.form.nativeElement.submit()
      }, 1000);
      }, error => {
      console.log(error)
      });
  }

  public downloadPDFHDFC() 
  {
    var data = document.getElementById('pdfasTableHDFC');  //Id of the table
        html2canvas(data).then(canvas => {  
          // Few necessary setting options  
          let imgWidth = 208;   
          let pageHeight = 295;    
          let imgHeight = canvas.height * imgWidth / canvas.width;  
          let heightLeft = imgHeight;  
          const contentDataURL = canvas.toDataURL('image/png')  
          let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
          let position = 0;  
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
          pdf.save('BillReceiptofIITR.pdf'); // Generated PDF  
        });  
        
      }
      Goback()
      {
        this.IsresponseHDFC=false;
        $('.abc').removeClass('addremovecss');
        window.location.href='https://payment.iitr.ac.in/MarriedHostelBill';
      }

      UploadFile(fileInput) 
      {
        if (fileInput != null) 
        {
          this.uploadPaymentReceipt(<File>fileInput.target.files);
          fileInput.target.value = "";
        }
        else 
        {
          this.toastr.error("You could not upload document", "")
        }
      }

      uploadPaymentReceipt(fileData) 
  {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) 
    {
      formData.append('file[]', fileData[i]);
      var fileName = this.MarriedHostelBillForm.controls.paymentreceipt.value;
      formData.append(fileName, "");
      this.fileNameList.push(fileData[i].name);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    this.spinner.show();
    var url = environment.BaseUrl + `api/EbaySeller/uploadMarriedHostelBillDocuments`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.spinner.hide();
        this.response1 = res;
        this.user = 
        {
          imgPath: this.response1.renameFile,
        }
        this.MarriedHostelBillForm.controls.paymentreceipt.patchValue(this.user.imgPath);
        this.paymentSlip = this.user.imgPath;
        this.paymentSlipFlag = true;
      })
  }
}

import {HostListener, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-width-layout',
  templateUrl: './full-width-layout.component.html',
  styleUrls: ['./full-width-layout.component.scss']
})
export class FullWidthLayoutComponent implements OnInit {
  screenHeight:any;
  screenWidth:any;
  constructor() {
    this.getScreenSize();
   }

  ngOnInit() {
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        console.log(this.screenHeight, this.screenWidth);
  }
}

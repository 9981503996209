import { Component, OnInit } from '@angular/core';
import { SellerService } from '../Services/Seller/seller.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileService } from '../Services/profile/profile.service';
import { OrderService } from '../Services/Order/order-service';
// import { FormGroup } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { data } from 'jquery';
import {Subject,Observable,interval,Subscription} from 'rxjs';

// import { timeStamp } from 'console';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  Enrollment:string="";
  TaskDbList: any[] = [];
  StudentActiveData: any[] = [];
  StudentDictionarySortedData: any[] = [];
  TotalExcelStudent: any[] = [];
  DeleteSellerForm:FormGroup;
  userRole: any;
  UserName: string;
  ProductDbList: any;
  listcount: any;
  isLoader: boolean;
  ListOfUsers: any;
  userName: string;
  email: string;
  OrderDbList: any;
  OrderList: any;
  Datalengeth: any;
  messageoforderlist: string;
  eBayCount: any;
  OrderCountwithResponse: any;
  OrderCountwithoutResponse: any;
  ListOfSeller: any;
  ListOfCustomer: any;
  menuItemCustomer:any;
  menuItemOrder: any;
  menuItemSeller: any;
  menuItemProduct: any;
  menuItemQuestion: any;
  myProfile: any;
  menuItemDash: any;
  menuItemHome:any;
  FirstName: string;
  LastName: string;
  IsAnyIssue: boolean;
  DeleteSellerFormSubmit:boolean=false;
  SetUpSellerForm:any;
  DeleteSellerss:any;
  OtherFees: string;
  Total1187: any;
  Total1187SBICount: any;
  TotalSBITSum: any;
  Total1187HDFC: any;
  Total1187HDFCCount: any;
  TotalStudent1187: any;
  total2288: any;
  TotalSBITSum2288: any;
  totalamount2288: any;
  TotalOfHDFC2288: any;
  TotalOfHDFCtransaction2288: any;
  totalamountHDFC2288: any;
  totalstudentsof2288: any;
  userProfile: any;
  dataExist: boolean;
  enrollmentno: string;
  name:any;
  emailId: any;
  mobileNo: any;
  category: any;
  course: any;
  department: any;
  d_DN: any;
  status_year: any;
  ProfileImage: any;
  TotalAmount: any;
  TotalStudent2724: any;
  Successpaidstudent2724: any;
  SBITotalPaid2724: any;
  TotalPaidAmount2724: any;
  HDFCTotalPaid2724: any;
  Totalstudentsbi2724: any;
  TotalstudentHDFC2724: any;
  TotalAmount2724: any;
  TotalStudent566: any;
  Successpaidstudent566: any;
  SBITotalPaid566: any;
  TotalPaidAmount566: any;
  HDFCTotalPaid566: any;
  Totalstudentsbi566: any;
  TotalstudentHDFC566: any;
  TotalAmount566: any;
  TotalAmountReceived_606: any;
  Total_Success_606_student: any;
  Total_HDFC_SUCCESS_student: any;
  Total_SBI_SUCCESS_student: any;
  SUM_SBI_606: any;
  SUM_HDFC_606: any;
  Total_List_606: any;
  Total_Amount_of_606: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  IsStudentMaster: boolean;
  ActiveStudentCount: any;
  IsStudentLoginData: boolean=false;
  DBListDepartment: any;
  private updateSubscription: Subscription;
  MedicalCount: any;
  SumOfMedical: any;
  MarriedBillCount: any;
  SumOfMarriedBill: any;

  constructor(private _http: OrderService, private spinner: NgxSpinnerService,private formbuilder:FormBuilder, private sellerService: SellerService, private toastr: ToastrService, private profileService: ProfileService) 
  { 
    this.DeleteSellerForm = this.formbuilder.group(
      { 
        Email:[''],
        subject:[''],
        body:['']
      })

  }
  ngOnInit() 
  {
    this.userRole = localStorage.getItem('userRole');
    this.enrollmentno=localStorage.getItem('Enrollment');
    this.UserName = window.sessionStorage.getItem('UserName');
    this.FirstName= localStorage.getItem('firstName');
    this.LastName= localStorage.getItem('lastname');
   if (this.userRole == 'Student') 
   {
   this.profileService.GetStudentProfileDataFromEnrollment(this.enrollmentno).subscribe((data:any)=>{
     this.userProfile=data.data;
     this.name=this.userProfile.name;
     this.enrollmentno=this.userProfile.enrollmentno;
     this.emailId=this.userProfile.emailId;
     this.mobileNo=this.userProfile.mobileNo;
     this.category=this.userProfile.category;
     this.course=this.userProfile.course;
     this.department=this.userProfile.department;
     this.d_DN=this.userProfile.d_DN;
     this.status_year=this.userProfile.status_year;
     this.ProfileImage=this.userProfile.firstName;
     console.log(this.userProfile);
   })
  }

    if (this.userRole == 'Public') 
    {
      this.userRole = 'Customer';
      this.menuItemOrder='/Customer/orders';
      this.menuItemProduct='/Customer/Product';
      this.myProfile='/Customer/my-profile';
      this.menuItemHome='/Customer/home';
    }
    if(this.userRole=='Admin')
    {
      this.menuItemOrder='/Admin/orders';
      this.menuItemProduct='/Admin/Product';
      this.menuItemSeller='/Admin/Studentdetails'
      this.menuItemQuestion='/Admin/Questionnaire';
      this.myProfile='/Admin/my-profile';
      this.menuItemCustomer='/Admin/customer';
      this.menuItemHome='/Admin/home';
      this.OtherFees='/Admin/onsolidated'
      // this.menuItemHome='/Admin/setupseller';
    }
    if(this.userRole=='Seller')
    {
      this.menuItemOrder='/Seller/orders';
      this.menuItemProduct='/Seller/Product';
      this.menuItemQuestion='/Seller/Questionnaire';
      this.myProfile='/Seller/my-profile';
      this.menuItemCustomer='/Seller/customer';
      this.menuItemHome='/Seller/home';
    }
    if(this.userRole=='Customer'){
      this.menuItemOrder='/Customer/orders';
      this.menuItemProduct='/Customer/Product';
      this.myProfile='/Customer/my-profile';
      this.menuItemHome='/Customer/home';
    }
    if(this.userRole=='Student'){
      this.menuItemOrder='/Student/orders';
      this.menuItemProduct='/Student/Product';
      this.myProfile='/Student/my-profile';
      this.menuItemHome='/Student/home';
    }

    // this.profileService.GetSellerCount().subscribe((data: any) => {
    //   console.log(data.data, 'que');
    //   this.ListOfSeller = data.data.data;
    // },
    //   (err: HttpErrorResponse) => {
    //     this.isLoader = false;
    //   });
      if(this.userRole =="Admin")
      {
        this.profileService.GetStudentActive().subscribe((data: any) => {
          this.ActiveStudentCount = data.data;
        },
          (err: HttpErrorResponse) => {
            this.isLoader = false;
          });
      }

      if(this.userRole !="Student")
      {
      this.profileService.GetOtherFeesStudentCount(this.UserName).subscribe((data:any) =>
      {
      this.ListOfCustomer=data.data.data;
      },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });


     

      this.profileService.GetTotalofExcel1187().subscribe((data:any) =>
      {
      this.Total1187=data.data;
      this.Total1187SBICount=data.total;
      this.TotalSBITSum=data.statusCode;
      this.TotalStudent1187=data.message
      },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });

this.profileService.GetTotalofExcel2288().subscribe((data:any)=>
{
this.total2288=data.data;
this.totalstudentsof2288=data.total;
this.TotalSBITSum2288=data.statusCode;
this.TotalAmount=data.total1;
},
(err: HttpErrorResponse) => {
  this.isLoader = false;
});

// this.profileService.GetDataFrom2724().subscribe((data:any)=>
// {
//   this.TotalStudent2724=data.total;
//   this.Successpaidstudent2724=data.data;
//   this.SBITotalPaid2724=data.total3;
//   this.TotalPaidAmount2724= data.total1;
//   this.HDFCTotalPaid2724= data.total2;
//   this.Totalstudentsbi2724=data.transactionid; 
//   this.TotalstudentHDFC2724=data.total4;
//   this.TotalAmount2724=data.statusCode;
//   console.log(data.data);
// },
// (err: HttpErrorResponse) => {
//   this.isLoader = false;
// });

// this.profileService.GetDataFrom566().subscribe((data:any)=>
// {
//   this.TotalStudent566=data.total;
//   this.Successpaidstudent566=data.data;
//   this.SBITotalPaid566=data.total3;
//   this.TotalPaidAmount566= data.total1;
//   this.HDFCTotalPaid566= data.total2;
//   this.Totalstudentsbi566=data.transactionid; 
//   this.TotalstudentHDFC566=data.total4;
//   this.TotalAmount566=data.statusCode;
//   console.log(data.data);
// },
// (err: HttpErrorResponse) => {
//   this.isLoader = false;
// });

// this.profileService.GetTotalofHDFCExcel2288().subscribe((data:any)=>
// {
// this.TotalOfHDFCtransaction2288=data.data;
// this.totalamountHDFC2288=data.total;
// },
// (err: HttpErrorResponse) => {
//   this.isLoader = false;
// });
      this.profileService.GetTotalofExcel1187HDFC().subscribe((data:any) =>
      {
      this.Total1187HDFC=data.data;
      this.Total1187HDFCCount=data.total;
      },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });

      this.profileService.GetSumCountMedicalFund().subscribe((data:any) =>
      {
      this.MedicalCount=data.total1;
      this.SumOfMedical=data.data;
      },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
      this.profileService.GetSumOfMarriedHostelFunds().subscribe((data:any) =>
      {
      this.MarriedBillCount=data.total1;
      this.SumOfMarriedBill=data.data;
      },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
  // total of 606 //
  //  this.profileService.GetTotalOf606().subscribe((data:any) =>
  //  {
  //   this.TotalAmountReceived_606=data.total;
  //  this.Total_Success_606_student=data.total1;
  //  this.Total_HDFC_SUCCESS_student=data.total2;
  //  this.Total_SBI_SUCCESS_student=data.total3;
  //  this.SUM_SBI_606=data.total4;
  //  this.SUM_HDFC_606=data.data;
  //  this.Total_List_606=data.datamodel;
  //  this.Total_Amount_of_606=data.transactionid
  //  },
  //  (err: HttpErrorResponse) => {
  //    this.isLoader = false;
  //  });
  // **************@************//
  
    this.profileService.GetUserList(this.ListData).subscribe((data: any) => {
      this.ListOfUsers = data.data.data;
      this.OrderList = data.data.total
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
    this.sellerService.countofproduct(this.Productlist).subscribe((data: any) => {
      this.ProductDbList = data.data.data;
      this.listcount = data.data.data.length;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;});

    // this.sellerService.getOrderList(this.UserName).subscribe((data: any) => {
    // this.Datalengeth=data.data.data.length;
    //   this.Datalengeth=0;
    //   if(data.statusCode==200)
    //   {
    //     this.OrderDbList = data.data.data;
    //   }
    //   else{
    //   this.messageoforderlist="No data found";
    //   },
    //   (err: HttpErrorResponse) => {
    //     this.isLoader = false;
    //   });

        this.sellerService.eBayCount(this.UserName).subscribe((data:any) =>
        {
        this.eBayCount=data.data.data;
        },
        (err: HttpErrorResponse) => {
          this.isLoader = false;
        });
        this.sellerService.OrderCountwithResponse(this.UserName).subscribe((data:any) =>
        {
        this.OrderCountwithResponse=data.data.data;
        },
        (err: HttpErrorResponse) => {
          this.isLoader = false;
        });
        this.sellerService.OrderCountwithoutResponse(this.UserName).subscribe((data:any) =>
        {
        this.OrderCountwithoutResponse=data.data.data;
        },
        (err: HttpErrorResponse) => {
          this.isLoader = false;
        });
      }
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true
       
      };
      this.loadExcel();
      this.StudentDisplayOfDepartment();

    //   this.updateSubscription = interval(60000).subscribe(
    //     (val) => {
         
    //     }
    //  );
  }

  IsStudentLoginOpen()
  {
    this.IsStudentLoginData=true;
    this. StudentLogindata() ;
  }

  StudentDisplayOfDepartment()
  {
    this.sellerService.GetDictionarydataOfStudent().subscribe((data: any) => {
      this.StudentDictionarySortedData = data.data.data;
      this.TotalExcelStudent== data.data.datamodel;
      this.dtTrigger.next();

    },
      (err: HttpErrorResponse) => {
      });
  }
  
  StudentLogindata()
  {
    this.spinner.show();
    this.sellerService.ActivestudentData().subscribe((data: any) => {
    this.StudentActiveData = data.data;
    this.spinner.hide();
    },
    (err: HttpErrorResponse) => {
    });
  }

  GoBackStud()
  {
    this.IsStudentLoginData=false;
  }

  loadExcel() 
  {
    this.spinner.show();
    this.sellerService.Getexceldata().subscribe((data: any) => {
    this.TaskDbList = data.data.data;
    this.spinner.hide();
    },
    (err: HttpErrorResponse) => {
    });
  }

  StudentMasterPopup()
  {
    $('.abc').addClass('addremovecss');
    this.IsStudentMaster=true;
  }
  AnyIssue()
  {
    $('.abc').addClass('addremovecss');
    this.IsAnyIssue=true;
   // this.users=editdata;
    //this.email = window.sessionStorage.getItem("Email");
    //console.log('users', this.users);
  }
  GobackStudMaster()
  {
    $('.abc').addClass('addremovecss');
    this.IsStudentMaster=false;
  }
  Goback() {
    
    $('.abc').removeClass('addremovecss');
    this.IsAnyIssue=false;
  }
  

  setupemail()
  {
  this.sellerService.setupemail(this.DeleteSellerForm.value).subscribe((data:any)=>{
  this.toastr.success("Mail sent successfully");
  this.IsAnyIssue=false;
  this.ngOnInit();
  //   window.location.reload();
  },
  (err: HttpErrorResponse) => {
     this.isLoader = false;
 }); 
  }
  ListData(ListData: any) {
    throw new Error("Method not implemented.");
  }
  filterValue(filterValue: any) {
    throw new Error("Method not implemented.");
  }
  Productlist(Productlist: any) {
    throw new Error("Method not implemented.");
  }
  RefreshExcelStatus(con:any)
  {
    this.spinner.show();
    this.sellerService.RefreshExcelStatus(con.questionTypeId).subscribe((data: any) => {
      this.spinner.hide();
      this.toastr.success("data refresh successfully.");
      this.StudentDisplayOfDepartment();
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
  }
}

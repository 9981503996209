import { Component, OnInit } from '@angular/core';
import { CommonserviceService } from '../Services/CommonService/commonservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  Count:number=0;
  events: string[] = [];
  opened: boolean;
  isCollapsed = true;
  isHomeActive=true;
  searchtgl: boolean = false;
  title = 'ebayaftercare-app';
  loggedIn:boolean;
  UserName:string;
  userRole:any;
  menuItemCustomer:any;
  menuItemOrder: any;
  menuItemSeller: any;
  menuItemProduct: any;
  menuItemQuestion: any;
  myProfile: any;
  menuItemDash: any;
  menuItemHome:any;

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/];

  constructor(private commonService:CommonserviceService,private router : Router) 
  { }

  ngOnInit() {
    this.userRole= localStorage.getItem('userRole');
    this.commonService.loginObsrv.subscribe(data=>this.loggedIn=data)
    this.commonService.usernameObsrv.subscribe(data=>this.UserName=data);
    if(this.userRole=='Admin'){
      this.menuItemOrder='/Admin/orders';
      this.menuItemProduct='/Admin/Product';
      this.menuItemSeller='/Admin/sellers'
      this.menuItemQuestion='/Admin/Questionnaire';
      this.myProfile='/Admin/my-profile';
      this.menuItemCustomer='/Admin/customer';
      this.menuItemHome='/Admin/home';
      // this.menuItemHome='/Admin/setupseller';
    }
    if(this.router.url=='/Admin/home'|| this.router.url=='/Admin/Seller'||this.router.url=='/Admin/Product'||this.router.url=='/Admin/customer'||this.router.url=='/Admin/home'|| this.router.url=='/Admin/Questionnaire' || this.router.url=='/Admin/myProfile'){
      this.isHomeActive = false;
    }
   
    if(this.userRole=='Seller'){
      this.menuItemOrder='/Seller/orders';
      this.menuItemProduct='/Seller/Product';
      this.menuItemQuestion='/Seller/Questionnaire';
      this.myProfile='/Seller/my-profile';
      this.menuItemCustomer='/Seller/customer';
      this.menuItemHome='/Seller/home';
    }
    if(this.router.url=='/Seller/Product'||this.router.url=='/Seller/customer'||this.router.url=='/Seller/home'|| this.router.url=='/Seller/Questionnaire' || this.router.url=='/Seller/myProfile'){
      this.isHomeActive = false;
    }
    if(this.userRole=='Customer'){
      this.menuItemOrder='/Customer/orders';
      this.menuItemProduct='/Customer/Product';
      this.myProfile='/Customer/my-profile';
      this.menuItemHome='/Customer/home';
    }
    if(this.router.url=='/Customer/Product'||this.router.url=='/Customer/home'||this.router.url=='/Customer/orders'){
      this.isHomeActive = false;
    }
  }
  HideAndShowSideBar(){
      this.Count=this.Count+1;
      if(this.Count%2==0){
      document.getElementById('side_body').classList.add('body-sidebar-toggle');
      }
      else{
      document.getElementById('side_body').classList.remove('body-sidebar-toggle');
      }
      
      }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';

@Component({
  selector: 'app-auditorium-booking',
  templateUrl: './auditorium-booking.component.html',
  styleUrls: ['./auditorium-booking.component.scss']
})
export class AuditoriumBookingComponent implements OnInit {

  AuditoriumBookingForm:FormGroup;

  constructor(private sellerService:SellerService,private formBuilder:FormBuilder,private spinner: NgxSpinnerService,private router:Router,private toastr: ToastrService) 
  {
    this.AuditoriumBookingForm=this.formBuilder.group(
      {
        auditoriumname:[``,Validators.required],
        department:[``,Validators.required],
        Name:[``,Validators.required],
        category:[``,Validators.required],
        IITRMailId:[``,Validators.required],
        PhoneNo:[``,Validators.required],
        Date:[``,Validators.required],
        starttime:[``,Validators.required],
        endtime:[``,Validators.required],
       });
   }

  ngOnInit() {
  }

  AddBookingData()
  {
    const data =
    {
      'auditoriumname':this.AuditoriumBookingForm.controls['auditoriumname'].value,
      'department' : this.AuditoriumBookingForm.controls['department'].value,
      'Name' : this.AuditoriumBookingForm.controls['Name'].value,
      'category' : this.AuditoriumBookingForm.controls['category'].value,
      'IITRMailId':this.AuditoriumBookingForm.controls['IITRMailId'].value,
      'PhoneNo':this.AuditoriumBookingForm.controls['PhoneNo'].value,
      'Date' :this.AuditoriumBookingForm.controls['Date'].value,
      'starttime' :this.AuditoriumBookingForm.controls['starttime'].value,
      'endtime' :this.AuditoriumBookingForm.controls['endtime'].value,
    }

    this.sellerService.SaveAuditoriumBooking(data).subscribe((data:any)=>{
      this.spinner.hide();
      //this.IsStudentCompline=false;
      this.toastr.success("Thank you for booking auditorium");
    }, error => {
      console.log(error)
      });

  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';



@Component({
  selector: 'app-addquestionnaires',
  templateUrl: './addquestionnaires.component.html',
  styleUrls: ['./addquestionnaires.component.scss']
})
export class AddquestionnairesComponent implements OnInit {
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  accessCode: any;
  merchant_id:any;
  encRequest : any;
  encdata:any;
  merchant_code:any;
  currency: string;
  addquestionnairesform:FormGroup;
  submitted: boolean;
  isLoader: boolean;
  questionTypedeatils: any;
  radioSelected: any;

constructor(private sellerService:SellerService,private QuestionnaireService: QuestionnaireService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService) { 
this.addquestionnairesform = this.formBuilder.group({
Name:[``, Validators.required],
Department:[``,Validators.required],
Course:[``,Validators.required],
Year:[``,Validators.required],
Aadhar_No:[``],
DepositeAmountFor:[``,Validators.required],
MobileNo:[``,Validators.required],
Email:[``,Validators.required],
OtherFeeAmount:[``,Validators.required],
Remark:[``,Validators.required],
Enrollmentno:[``,Validators.required],
encdata:[''],
merchant_code:[''],
    })
  }

  ngOnInit() 
  {
    this.accessCode = 'AVKR88GJ13AH16RKHA';
    this.merchant_id='234485';
    this.currency='INR';
    this.merchant_code='IIT_RK';
  }
  AddQuestion()
  {
    this.submitted = true;
    const data =
      {
        'QuestionText': this.addquestionnairesform.value.QuestionText,
        'QuestionType': this.addquestionnairesform.value.QuestionType,
        'TagName': this.addquestionnairesform.value.TagName
      }
    this.QuestionnaireService.AddQuestionIndividual(data).subscribe((result :any)=>
    {
      console.log(result);
      this.toastr.success(result.data.message);
      this.router.navigate(["/Admin/Questionnaire"]);
    }, (err: HttpErrorResponse) => {}); 
  }
  PayFees()
  {
      this.submitted = true;
      let parameter = "iitr_ref_no=34344333|amount=1";
      this.sellerService.ChecksumOfOtherFEES(this.addquestionnairesform.value).subscribe((data:any)=>{
      console.log('sbiencdata',data.data.data)
      this.encdata=data.data.data;
      console.log(this.merchant_code,this.encdata);
      setTimeout(() => {
      this.formsbi.nativeElement.submit()
    }, 1000);},error => {console.log(error)});
  }
}

import { Component, OnInit, ElementRef, ViewChild, Injectable, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SellerService } from '../Services/Seller/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as jsPDF from 'jspdf';
import { IfStmt } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-student-admission',
  templateUrl: './student-admission.component.html',
  styleUrls: ['./student-admission.component.scss']
})
export class StudentAdmissionComponent implements OnInit {

  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();
  StudentAdmissionform:FormGroup;
  submitted: boolean;
  encdata: any;
  public imagePath;
  imgURL: any;
  checked:any;
  
  favoriteCartoon: string;
  cartoons: string[] = ['Tom and Jerry', 'Rick and Morty', 'Ben 10', 'Batman: The Animated Series'];
  StudentsDetailList: any;
  constructor(private httpClient: HttpClient,private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  { 
     this.StudentAdmissionform = this.formBuilder.group({
      jeeapplicationno:[``, Validators.required],
      fullname:[``,Validators.required],
      sclass:[``],
      departmentname:[``,Validators.required],
      specialization:[``],
      branch:[``,Validators.required],
      bhawan:[``],
      roomno:[``],
      category:[``,Validators.required],
      dob:[``,Validators.required],
      physicallydisabled:[``,Validators.required],
      bankname:[``,Validators.required],
      bankaccountno:[``,Validators.required],
      studentmobileno:[``,Validators.required],
      studentmailidiitr:[``],
      studentmailid:[``,Validators.required],
      nationality:[``,Validators.required],
      bloodgroup:[``],
      maritalstatus:[``],
      gender:[``],
      Fathername:[``,Validators.required],
      fdesignation:[``],
      fofficeaddress:[``],
      foccuptation:[``],
      fathermobile:[``],
      fatherhomemobileno:[``],
      fatheroffice:[``],
      mothername:[``],
      motheroccuptation:[``],
      motherdesignation:[``],
      motherhomeaddress:[``],
      motherofficeaddress:[``],
      motheremailid:[``],
      mothermobile:[``],
      motherhomemobileno:[``],
      motherofficephoneno:[``],
      permanenthomeaddress:[``],
      pin:[``,Validators.required],
      phoneno:[``,Validators.required],
      nearestrailwaystation:[``],
      guardianname:[``],
      guardiandesignation:[``],
      guardianaddress:[``],
      guardianoccuptation:[``],
      guardianmobileno:[``],
      guardianhomeno:[``],
      guardianofficeno:[``],     
      undertaking:[``,Validators.required],  
      createdBy:[``,Validators.required],  
      parameter2:[``],
      parameter3:[``],
      
      })
  }



  ngOnInit() {
    this.StudentAdmissionform.reset();


    this.StudentAdmissionform = this.formBuilder.group({
      jeeapplicationno:[``, Validators.required],
      fullname:[``,Validators.required],
      sclass:[``],
      departmentname:[``,Validators.required],
      specialization:[``],
      branch:[``,Validators.required],
      bhawan:[``],
      roomno:[``],
      category:[``,Validators.required],
      dob:[``,Validators.required],
      physicallydisabled:[``,Validators.required],
      bankname:[``,Validators.required],
      bankaccountno:[``,Validators.required],
      studentmobileno:[``,Validators.required],
      studentmailidiitr:[``],
      studentmailid:[``,Validators.required],
      nationality:[``,Validators.required],
      bloodgroup:[``],
      maritalstatus:[``],
      gender:[``],
      Fathername:[``,Validators.required],
      fdesignation:[``],
      fofficeaddress:[``],
      foccuptation:[``],
      fathermobile:[``],
      fatherhomemobileno:[``],
      fatheroffice:[``],
      mothername:[``],
      motheroccuptation:[``],
      motherdesignation:[``],
      motherhomeaddress:[``],
      motherofficeaddress:[``],
      motheremailid:[``],
      mothermobile:[``],
      motherhomemobileno:[``],
      motherofficephoneno:[``],
      permanenthomeaddress:[``],
      pin:[``,Validators.required],
      phoneno:[``,Validators.required],
      nearestrailwaystation:[``],
      guardianname:[``],
      guardiandesignation:[``],
      guardianaddress:[``],
      guardianoccuptation:[``],
      guardianmobileno:[``],
      guardianhomeno:[``],
      guardianofficeno:[``],     
      undertaking:[``,Validators.required],  
      createdBy:[``,Validators.required],  
      parameter2:[``],
      parameter3:[``],
      })
  }
 firstDropDownChanged(val: any) {
    console.log(val);
      this.sellerService.getStudentRegistrationDeatilas(val).subscribe((data: any) => {
        this.StudentsDetailList = data.data.data;
       console.log(this.StudentsDetailList);
        this.StudentAdmissionform.controls["fullname"].patchValue(this.StudentsDetailList.fullname);
            this.StudentAdmissionform.controls["departmentname"].patchValue(this.StudentsDetailList.departmentname);
        this.StudentAdmissionform.controls["dob"].patchValue(this.StudentsDetailList.dob);
         this.StudentAdmissionform.controls["createdBy"].patchValue(this.StudentsDetailList.createdBy);
         this.StudentAdmissionform.controls["specialization"].patchValue(this.StudentsDetailList.specialization);
         this.StudentAdmissionform.controls["branch"].patchValue(this.StudentsDetailList.branch);
         this.StudentAdmissionform.controls["bhawan"].patchValue(this.StudentsDetailList.bhawan);
         this.StudentAdmissionform.controls["roomno"].patchValue(this.StudentsDetailList.roomno);
         this.StudentAdmissionform.controls["category"].patchValue(this.StudentsDetailList.category);
         this.StudentAdmissionform.controls["physicallydisabled"].patchValue(this.StudentsDetailList.physicallydisabled);
         this.StudentAdmissionform.controls["bankname"].patchValue(this.StudentsDetailList.bankname);
        this.StudentAdmissionform.controls["bankaccountno"].patchValue(this.StudentsDetailList.bankaccountno);
        // console.log("CATDetailList",this.StudentsDetailList);
          this.StudentAdmissionform.controls["studentmobileno"].patchValue(this.StudentsDetailList.studentmobileno);
          this.StudentAdmissionform.controls["studentmailid"].patchValue(this.StudentsDetailList.studentmailid);     
           // c
           this.StudentAdmissionform.controls["studentmailidiitr"].patchValue(this.StudentsDetailList.studentmailidiitr);
           this.StudentAdmissionform.controls["motherofficeaddress"].patchValue(this.StudentsDetailList.motherofficeaddress);

           this.StudentAdmissionform.controls["bloodgroup"].patchValue(this.StudentsDetailList.bloodgroup);
           this.StudentAdmissionform.controls["nationality"].patchValue(this.StudentsDetailList.nationality); 

           this.StudentAdmissionform.controls["maritalstatus"].patchValue(this.StudentsDetailList.maritalstatus);

           this.StudentAdmissionform.controls["gender"].patchValue(this.StudentsDetailList.gender); 
           this.StudentAdmissionform.controls["Fathername"].patchValue(this.StudentsDetailList.Fathername);
           this.StudentAdmissionform.controls["fdesignation"].patchValue(this.StudentsDetailList.fdesignation);                                                               
           this.StudentAdmissionform.controls["foccuptation"].patchValue(this.StudentsDetailList.foccuptation);
           this.StudentAdmissionform.controls["fofficeaddress"].patchValue(this.StudentsDetailList.fofficeaddress); 
         
      

/////////////////////////////////////////////////////////////
           this.StudentAdmissionform.controls["fathermobile"].patchValue(this.StudentsDetailList.fathermobile);
          this.StudentAdmissionform.controls["fatherhomemobileno"].patchValue(this.StudentsDetailList.fatherhomemobileno);     
           // c
           this.StudentAdmissionform.controls["fatheroffice"].patchValue(this.StudentsDetailList.fatheroffice);
           this.StudentAdmissionform.controls["mothername"].patchValue(this.StudentsDetailList.mothername);

           this.StudentAdmissionform.controls["motheroccuptation"].patchValue(this.StudentsDetailList.motheroccuptation);
           this.StudentAdmissionform.controls["motherdesignation"].patchValue(this.StudentsDetailList.motherdesignation); 

           this.StudentAdmissionform.controls["motherhomeaddress"].patchValue(this.StudentsDetailList.motherhomeaddress);

           this.StudentAdmissionform.controls["motheremailid"].patchValue(this.StudentsDetailList.motheremailid); 
           this.StudentAdmissionform.controls["mothermobile"].patchValue(this.StudentsDetailList.mothermobile);
           this.StudentAdmissionform.controls["motherhomemobileno"].patchValue(this.StudentsDetailList.motherhomemobileno);                                                               
           this.StudentAdmissionform.controls["motherofficephoneno"].patchValue(this.StudentsDetailList.motherofficephoneno);
           this.StudentAdmissionform.controls["permanenthomeaddress"].patchValue(this.StudentsDetailList.permanenthomeaddress); 


           this.StudentAdmissionform.controls["pin"].patchValue(this.StudentsDetailList.pin); 
           this.StudentAdmissionform.controls["phoneno"].patchValue(this.StudentsDetailList.phoneno);
           this.StudentAdmissionform.controls["nearestrailwaystation"].patchValue(this.StudentsDetailList.nearestrailwaystation);                                                               
           this.StudentAdmissionform.controls["guardianname"].patchValue(this.StudentsDetailList.guardianname);
           this.StudentAdmissionform.controls["guardiandesignation"].patchValue(this.StudentsDetailList.guardiandesignation); 

           this.StudentAdmissionform.controls["guardianoccuptation"].patchValue(this.StudentsDetailList.guardianoccuptation); 
           this.StudentAdmissionform.controls["guardianmobileno"].patchValue(this.StudentsDetailList.guardianmobileno);
           this.StudentAdmissionform.controls["guardianofficeno"].patchValue(this.StudentsDetailList.guardianofficeno);                                                               
           this.StudentAdmissionform.controls["guardianaddress"].patchValue(this.StudentsDetailList.guardianaddress);
         //  this.StudentAdmissionform.controls["guardianhomeno"].patchValue(this.StudentsDetailList.guardianhomeno); 

           this.StudentAdmissionform.controls["undertaking"].patchValue(this.StudentsDetailList.undertaking); 
        
      })
    
  }
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/upload',formData, {reportProgress: true, observe: 'events'})
   // this.http.post('https://localhost:5001/api/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }


  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        let formData:FormData = new FormData();
        formData.append('uploadFile', file, file.name);
        let headers = new Headers();
        /** In Angular 5, including the header Content-Type can invalidate your request */
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.httpClient.post(environment.BaseUrl + '/api/EbaySeller/upload',formData, {reportProgress: true, observe: 'events'})
        // this.http.post('https://localhost:5001/api/upload', formData, {reportProgress: true, observe: 'events'})
           .subscribe(event => {
             if (event.type === HttpEventType.UploadProgress)
               this.progress = Math.round(100 * event.loaded / event.total);
             else if (event.type === HttpEventType.Response) {
               this.message = 'Upload success.';
               this.onUploadFinished.emit(event.body);
             }
           });
    }
}

  StudentAdmission()
  {
    this.submitted = true;
      let parameter = "iitr_ref_no=34344333|amount=1";
      this.sellerService.SaveStudentAdmissionDetails(this.StudentAdmissionform.value).subscribe((data:any)=>{
     console.log('studentdata',data.data.data)
     if(  data.statusCode==200)
     {
     this.toastr.success("Registration successfully completed!!!!!");
     this.StudentAdmissionform.reset();
     this.router.navigate(["/StudentAdmission"]);
    }
    else
    {
      this.toastr.error("something went wrong please try again");
      this.router.navigate(["/StudentAdmission"]);
    }
    },
    error => {
      console.log(error)
      }
    );
  }



  
  merchant_code(merchant_code: any, encdata: any) {
    throw new Error("Method not implemented.");
  }
}

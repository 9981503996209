import { Component, OnInit } from '@angular/core';
import * as jquery from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { SellerService } from '../Services/Seller/seller.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import {Observable} from 'rxjs';
interface EventTarget { result: any; }
const image = '';
declare var $: any;
declare var window: any;



@Component({
  selector: 'app-excel-list',
  templateUrl: './excel-list.component.html',
  styleUrls: ['./excel-list.component.scss']
})
export class ExcelListComponent implements OnInit {
  private httpClient: HttpClient;
  fileNameList: any = [];
  TaskDbList: any[] = [];
  IsDelete:boolean=false;
  IsEditForm:boolean=false;
  CourseDbList: any[] = [];
  ExcelDbList: any[] = [];
  IsWithSameExcel:boolean=false;
  IsChangesWithSameExcel:boolean=false;
  IsOTPVerify:boolean=false;
  loading = false;
  submitted = false;
  isLoader = true;
  users: any = {};
  IsEdit = false;
  excelFile= true;
  userType:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  // excelwithsamecode: any;
  firstNme: string;
  lastname: string;
  name: string;
  ExcelWithSameCode:FormGroup;
  ChangesInSameExcelCode:FormGroup;
  VerifyOTPsubmit:FormGroup;
  EditExcelform:FormGroup;
  excelwithsamecodedata: any;
  Chagesinsameexcelcode:any;
  uploadexceldata: any; 
  user: { imgPath: ""; };
  response1: any = {};
  Uploadexcelsheet:string;
  startdate1: string;
  enddate1: string;
  ipAddress: any;
  excelcode: any;
  AmountStatusList: any[] = [];
  ChangesInData: any;
  ProgessBarValue: number=0;
  order_level:any;
  width : any=0;

  constructor(public datepipe: DatePipe,private http: HttpClient,private sellerService:SellerService, private toastr: ToastrService,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService,private httpBackend: HttpBackend) 
  { 
    this.ExcelWithSameCode=this.formBuilder.group({
      questionTypeId:[``, Validators.required],
      name:[],
      Uploadexcelsheet:[``, Validators.required],
      startdate:[``, Validators.required],
      enddate:[``, Validators.required],
      descriptions:[``, Validators.required],
     });
     this.ChangesInSameExcelCode=this.formBuilder.group({
      questionTypeId:[``, Validators.required],
      name:[],
      Uploadexcelsheet:[``, Validators.required],
      startdate:[``, Validators.required],
      enddate:[``, Validators.required],
      descriptions:[``, Validators.required],
      endtime:[``, Validators.required],
      starttime:[``, Validators.required],
     });
     this.VerifyOTPsubmit= this.formBuilder.group({
      otp:[``, Validators.required],
      createdBy:['',Validators.required],
      questionId:['',Validators.required]
     });
     this.EditExcelform= this.formBuilder.group({
      tagName:[``, Validators.required],
      questionId:['',Validators.required],
      descriptions:['',Validators.required],
      enddate:['',Validators.required],
      startdate:['',Validators.required],
      questionTypeId:[``, Validators.required],
      starttime:['',Validators.required],
      endtime:['',Validators.required],
     });
  }

  ngOnInit() 
  {
    this.firstNme=localStorage.getItem('firstName');
    this.lastname=localStorage.getItem('lastname');
    this.name=this.firstNme +" " + this.lastname; 
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.loadExcel();
    this.getIPAddress();
 this.GetCourseData();
 this.GelExcelCodeData();
 
  }
  // LoadExcelData Getexceldata
  onDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.startdate1=finalDate;
   }

   onDateSelectend(event)
   {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.enddate1=finalDate;
   }


  loadExcel() {
    this.spinner.show();
    this.sellerService.Getexceldata().subscribe((data: any) => {
    console.log('new karan', data);
    this.TaskDbList = data.data.data;
    this.spinner.hide();
    this.dtTrigger.next();
    },
    (err: HttpErrorResponse) => {
    });
  }
GelExcelCodeData()
{
  this.spinner.show();
  this.sellerService.GetExcelCodedata().subscribe((data:any)=> { 
  this.ExcelDbList=data.data.data;
  this.spinner.hide();
},
 (err: HttpErrorResponse) => {
 });
}
  GetCourseData(){
  this.spinner.show();
  this.sellerService.GetDescriptiondata().subscribe((data:any)=> { 
  this.CourseDbList=data.data.data;
  this.spinner.hide();
},
 (err: HttpErrorResponse) => {
 });
}
getDataForExcel(seller: any)
{
  this.spinner.show();
  this.sellerService.getExcelCodeData(seller).subscribe((data: any) => {
  this.AmountStatusList = data.data;
  this.TaskDbList = this.AmountStatusList;
  this.dtTrigger.next();
  this.spinner.hide();})
}

getDataForCourse(seller: any)
{
  this.spinner.show();
  this.sellerService.getExcelDescriptionData(seller).subscribe((data: any) => {
  this.AmountStatusList = data.data;
  this.TaskDbList = this.AmountStatusList;
  this.dtTrigger.next();
  this.spinner.hide();})
}

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  StudentBifurcationExcelRun(con)
  {
    this.spinner.show();
    this.sellerService.StudentBifurcationExcelRun(con.questionId,con.questionText,con.createdBy).subscribe((data: any) => {
    this.spinner.hide();
    if(data.statusCode==200)
    {
    this.toastr.info("Excel Uploaed Successfully" )
    this.ngOnInit() ;}
    else if(data.statusCode==400)
    {
    this.toastr.warning(data.excelExits)
    }
    else
    {
    this.toastr.error("Please try again" )
    }
    console.log('new karan', data);
    var dd = data.data;
    },
    (err: HttpErrorResponse) => {
    });
  }
  
  UpdatesChangesOfSameExcel(con)
  {
    this.spinner.show();
    this.sellerService.UpdatesChangesOfSameExcel(con.questionId,con.questionText,con.questionTypeId,con.descriptions).subscribe((data: any) => {
    this.spinner.hide();
    if(data.statusCode==200)
    {
    this.toastr.info("Excel Uploaed Successfully" )
    this.ngOnInit() ;
    }
    else if(data.statusCode==400)
    {
    this.toastr.warning(data.excelExits)
    }
    else{
    this.toastr.error("Please try again" )
    }
    console.log('new karan', data);
    var dd = data.data;
    },
    (err: HttpErrorResponse) => {
    });
  }
  RunExeOffline(con)
  {
    this.spinner.show();
    this.sellerService.RunExeOffline(con.questionId,con.questionText,con.questionTypeId).subscribe((data: any) => {
    this.spinner.hide();
    if(data.statusCode==200)
    {
    this.toastr.info("Excel Uploaed Successfully" )
    this.ngOnInit() ;}
    else if(data.statusCode==400)
    {
    this.toastr.warning(data.excelExits)
    }
    else{
    this.toastr.error("Please try again" )
    }
    console.log('new karan', data);
    var dd = data.data;
    },
    (err: HttpErrorResponse) => {
    });
  }
  ActiveStatus(con)
  {
    if(con.fileType!="Loan Data")
    {
    this.spinner.show();
    this.sellerService.UpdateStatusExcel(con.questionId,con.questionTypeId).subscribe((data:any)=>{
      this.spinner.hide();
      if(data.statusCode==200)
      {
        this.toastr.info(data.data.data +" " + "Excel Active Successfully" )
       this.ngOnInit() ;
      }
        else{
          this.toastr.error("Please try again" )
        }
      },
        (err: HttpErrorResponse) => {
        });
      }
      else
      {
        this.toastr.error("Your are not allowed to active this excel." )
      }
  }
  DeactiveStatus(con)
  {
this.spinner.show();
this.sellerService.DeactiveStatus(con.questionId,con.questionTypeId).subscribe((data:any)=>{
  this.spinner.hide();
  if(data.statusCode==200)
  {
    this.toastr.info(data.data.data +" "+ "Excel Deactive Successfully" )
   this.ngOnInit() ;
  }
    else{
      this.toastr.error("Please try again" )
    }
  },
    (err: HttpErrorResponse) => {
    });

  }
  Runexc(con)
    {
      this.spinner.show();
      this.sellerService.RunExe(con.questionId,con.questionText,con.createdBy).subscribe((data: any) => {
        this.spinner.hide();
        if(data.statusCode==200)
        {
this.toastr.info("Excel Uploaed Successfully" )
this.ngOnInit() ;
        }
        else if(data.statusCode==400)
        {
          this.toastr.warning(data.excelExits)
        }
        else{
          this.toastr.error("Please try again" )
        }
        console.log('new karan', data);
        var dd = data.data;
      },
        (err: HttpErrorResponse) => {
        });
    }

    VerifyOTP(con)
    {
      this.IsOTPVerify=true;
      this.excelcode=con;
    }

    CloseOTP()
    {
      this.IsOTPVerify=false;
    }
    checkOTP()
    {
     
      this.sellerService.CheckOTPAPI(this.VerifyOTPsubmit).subscribe(res=>{
        console.log(res);
        if(res["statusCode"]==404){
         this.toastr.error(res["message"])
        }
        else{
       this.toastr.success(res["message"]);
       this.IsOTPVerify=false;
       this.ngOnInit();
      }
      this.spinner.hide(); 
    },
    error=>{
      console.log(error);
      this.spinner.hide();
    });
    }
    uploadFileBrowse(fileInput, name) 
    {
      //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
      if (fileInput != null) {
        // if (fileInput.target.value != "" && fileInput.target.value != null) {
        this.uploadFileBrowserExcelsheet(<File>fileInput.target.files, name);
        // }
        fileInput.target.value = "";
      }
      else {
        this.toastr.error("You could not upload document", "")
      }
      //}
    }
    getIPAddress()
    {
      this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
        // console.log (window.location.host);
        this.ipAddress = res.ip;
      });
    }

    uploadFileBrowserExcelsheet(fileData, DocType) 
    {
      const formData = new FormData();
      this.fileNameList = [];
      for (let i = 0; i < fileData.length; i++) {
        formData.append('file[]', fileData[i]);
        var fileName=this.ExcelWithSameCode.controls.Uploadexcelsheet.value +"-"+DocType;
        formData.append(fileName,"");
        this.fileNameList.push(fileData[i].DocType);
      }
      this.httpClient = new HttpClient(this.httpBackend);
      this.uploadexceldata=this.ExcelWithSameCode.value.Uploadexcelsheet
      var url = environment.BaseUrl + `api/EbaySeller/UploadExcel`;
      this.httpClient.post(url, formData)
        .subscribe(res => {
          this.response1 = res;
          this.user = {
            imgPath: this.response1.renameFile,
          }
          this.Uploadexcelsheet = this.user.imgPath;
          this.toastr.success("data upload successfully.");
        })
    }

    AddExcelWithSameExcelCode(con)
    { 
      this.excelwithsamecodedata=con;
      this.IsWithSameExcel=true;
    }

   GetDiffrence(con)
   {
    this.spinner.show();
    this.sellerService.GetFeesDiffrence(con.questionTypeId).subscribe((data: any) => {
      this.spinner.hide();
      this.toastr.success("Get Diffrence successfully.");
      this.ngOnInit();
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
   }
   GetFinalStatus(con)
   {
    this.spinner.show();
    this.sellerService.GetFinalStatus(con.questionTypeId).subscribe((data: any) => {
      this.spinner.hide();
      this.toastr.success("Get Final Status Successfully.");
      this.ngOnInit();
    },
      (err: HttpErrorResponse) => {
        this.isLoader = false;
      });
   }

    Refresh(con)
    { 
      this.spinner.show();
      this.sellerService.RefreshFeesStatus(con.questionTypeId).subscribe((data: any) => {
        this.spinner.hide();
        this.toastr.success("data refresh successfully.");
        this.ngOnInit();
      },
        (err: HttpErrorResponse) => {
          this.isLoader = false;
        });
    }

    RefreshSBI(con)
    {
      this.spinner.show();
      this.sellerService.RefreshFeesSBI(con.questionTypeId).subscribe((data: any) => {
        this.spinner.hide();
        this.toastr.success("data refresh successfully.");
        this.ngOnInit();
      },
        (err: HttpErrorResponse) => {
          this.isLoader = false;
        });
    }

    GoBack()
    {
      this.IsWithSameExcel=false;
    }

    Edit(con)
    {
      let ele=con;
      this.name=this.firstNme +" " + this.lastname; 
      var dateFormat = ele.startdate || 'yyyy-MM-dd';
      this.IsEditForm=true;
      this.users = con;
    }

    CloseEdit()
    {
      this.IsEditForm=false;
    }

    DeleteExcel(con) 
    {
    alert("are you sure want to delete?")
    this.sellerService.DeleteExcel(con.questionId).subscribe((data: any) => {
    this.isLoader = false;
    this.ngOnInit();
    },
    (err: HttpErrorResponse) => {
    this.isLoader = false;
    });
    }

  UpdateExcelData()
  {
    this.spinner.show();
    const data=
    {
   'questionId':this.EditExcelform.controls['questionId'].value,
   'descriptions':this.EditExcelform.controls['descriptions'].value ,
   'startdate':this.EditExcelform.controls['startdate'].value,
   'enddate':this.EditExcelform.controls['enddate'].value,
   'starttime':this.EditExcelform.controls['starttime'].value,
   'endtime':this.EditExcelform.controls['endtime'].value,
    }
    this.sellerService.UpdateExcelData(data).subscribe((data:any)=>{
      this.spinner.hide();
      if(data.statusCode==200)
      {
        this.IsEditForm=false;
        this.toastr.success("Data Updated Successfully")
        this.ngOnInit();
      }
      else
      {
        this.toastr.error("Please, try again");
      }
    },
    (err:HttpErrorResponse) =>{
    });
  }

  AddExcelDatawithsamecode() 
  {
    alert("are you sure want to add excel?")
    this.spinner.show();
  const data=
  {
  'name':this.name,
  'questionTypeId':this.ExcelWithSameCode.controls['questionTypeId'].value,
  'Uploadexcelsheet':this.Uploadexcelsheet,
  'startdate':this.ExcelWithSameCode.controls['startdate'].value,
  'enddate':this.ExcelWithSameCode.controls['enddate'].value,
  'descriptions':this.ExcelWithSameCode.controls['descriptions'].value,
  'Email':sessionStorage.getItem('Email'),
  }
    this.sellerService.SaveExcelDataWithSameExcelCode(data).subscribe(res=>{
      console.log(res);
      if(res["statusCode"]==404){
      }
      else{
     this.toastr.success(res["message"]);
     this.IsWithSameExcel=false;
     this.ngOnInit();
    }
    this.spinner.hide(); 
  },
  error=>{
    console.log(error);
    this.spinner.hide();
  });
  }

  OpenDeleteModal(deletedata) 
  {
    $('.abc').addClass('addremovecss');
    this.IsDelete=true;
    this.users = deletedata;
  }

  AddExcelForUpdateswithsameExcelcode(con)
  {
    this.Chagesinsameexcelcode=con;
    this.IsChangesWithSameExcel=true;
  }


UpdateSameExcelWithData() 
{
alert("are you sure want to add excel?")
this.spinner.show();
const data=
{
'name':this.name,
'questionTypeId':this.Chagesinsameexcelcode.questionTypeId,
'Uploadexcelsheet':this.Uploadexcelsheet,
'startdate':this.Chagesinsameexcelcode.startdate,
'enddate':this.Chagesinsameexcelcode.enddate,
'descriptions':this.Chagesinsameexcelcode.descriptions,
'Email':sessionStorage.getItem('Email'),
'starttime':this.Chagesinsameexcelcode.descriptions,
'endtime':this.Chagesinsameexcelcode.descriptions,
}
  this.sellerService.UpadteDataWithSameExcelCode(data).subscribe(res=>
    {
    console.log(res);
    if(res["statusCode"]==404)
    {
    }
    else
    {
     this.ChangesInData=res["data"]["data"];
     this.toastr.success(res["message"]);
     this.IsChangesWithSameExcel=false;
     this.ngOnInit();
    }
    this.spinner.hide(); 
    },
    error=>{
    console.log(error);
    this.spinner.hide();
    });
}

UploadDataForUpdatesExcel(con)
{
}

UpdateGoBack()
{
  this.IsChangesWithSameExcel=false;
}

ActiveExcel()
{
  this.spinner.show();
  this.sellerService.GetActiveExcel().subscribe((data: any) => {
  console.log('new karan', data);
  this.TaskDbList = data.data.data;
  this.spinner.hide();
  this.dtTrigger.next();
  },
  (err: HttpErrorResponse) => {
  }); 
}

RefreshExcelStatus(con)
{ 
  this.spinner.show();
  this.sellerService.RefreshExcelStatus(con.questionTypeId).subscribe((data: any) => {
    this.spinner.hide();
    this.toastr.success("data refresh successfully.");
    this.ngOnInit();
  },
    (err: HttpErrorResponse) => {
      this.isLoader = false;
    });
}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private modals: any[] = [];

  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string) {
    // open modal specified by id
    let modal: any = this.modals.filter(x => x.id === id)[0];
    modal.open();
  }

  close(id: string) {
    // close modal specified by id
    let modal: any = this.modals.filter(x => x.id === id)[0];
    modal.close();
  }


  constructor(private httpClient: HttpClient) { }

  getOrderDurationList() {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    return this.httpClient.get(environment.BaseUrl + '/api/EbaySeller/OrderDurations', HttpOptions);
  }

  // getOrders(values: any) {
  //   const HttpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
  //     })
  //   }

  //   return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/Orders?selectedDate=${values}`, HttpOptions)
  // }

  UpdateOrders(orderid: any) {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/UpdateOrders?orderid=${orderid}`)
  }
  getOrders(values:any,email:string,Username:string){
    
      let  headers= new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
         })   
      
   
      let param = new HttpParams();

      // Begin assigning parameters
      param = param.append('selectedDate', values);
      param = param.append('email', email);
      param = param.append('userName', Username);
      return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/Orders`
      ,{params:param,headers:headers})
  }
getOtherFeesList()
{
  return this.httpClient.get(environment.BaseUrl+`/api/EbaySeller/ListOfOtherFees`)
}
getStudentProfileList()
{
  return this.httpClient.get(environment.BaseUrl+`/api/EbaySeller/ListOfStudentProfile`)
}
  getseller() {
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/getsellerslist`)
  }

  getDataForSeller(sellerUserId:any){
    let param = new HttpParams();
    
    // Begin assigning parameters
    param = param.append('sellerUserId', sellerUserId);
    
    return this.httpClient.get(environment.BaseUrl + `/api/EbaySeller/GetOrderForSeller`
    ,{params:param,headers:new HttpHeaders({ 'Content-Type': 'application/json',
    'Authorization':`bearer ${window.sessionStorage.getItem("token")}`})})
    }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../Services/Order/order-service';
import { ProfileService } from '../Services/profile/profile.service';
import { SellerService } from '../Services/Seller/seller.service';

@Component({
  selector: 'app-student-home',
  templateUrl: './student-home.component.html',
  styleUrls: ['./student-home.component.scss']
})
export class StudentHomeComponent implements OnInit {
  userRole: string;
  enrollmentno: string;
  UserName: string;
  FirstName: string;
  LastName: string;
  userProfile: any;
  name: any;
  emailId: any;
  mobileNo: any;
  category: any;
  course: any;
  department: any;
  d_DN: any;
  status_year: any;
  ProfileImage: any;
  menuItemOrder: string;
  menuItemProduct: string;
  myProfile: string;
  menuItemHome: string;
  PageId: any;

  constructor(private _http: OrderService, private spinner: NgxSpinnerService,private formbuilder:FormBuilder, private sellerService: SellerService, private toastr: ToastrService, private profileService: ProfileService) { }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole');
    this.enrollmentno=localStorage.getItem('Enrollment');
    this.UserName = window.sessionStorage.getItem('UserName');
   this.FirstName= localStorage.getItem('firstName');
   this.LastName= localStorage.getItem('lastname');
   this.UserName= window.sessionStorage.getItem('UserName');

   this.profileService.GetStudentProfileDataFromEnrollmentStudentLoginPage(this.enrollmentno).subscribe((data:any)=>{
     this.userProfile=data.data;
     this.name=this.userProfile.name;
     this.enrollmentno=this.userProfile.enrollmentNo;
     this.emailId=this.userProfile.studentEmail;
    // this.mobileNo=this.userProfile.studentMobileNo;
     this.mobileNo='7385540780';
     this.category=this.userProfile.category;
     this.course=this.userProfile.course;
     this.department=this.userProfile.department;
     this.d_DN=this.userProfile.d_ND;
     this.status_year=this.userProfile.years;
     this.ProfileImage=this.userProfile.urL;
     this.PageId=this.userProfile.pageId;

     console.log(this.userProfile);
    //  this.dataExist=true;
   })
    if(this.userRole=='Student'){
      this.menuItemOrder='/Student/orders';
      this.menuItemProduct='/Student/Product';
      this.myProfile='/Student/my-profile';
      this.menuItemHome='/Student/home';
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ChangePasswordService } from '../Services/ChangePassword/change-password.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { async } from 'q';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  hideCurrentPassword:boolean=true;
  hideNewPassword:boolean=true;
  changePasswordForm:FormGroup;
  confirmPasswordError:boolean;
  changePasswordFormSubmit:boolean;
  wrongCurrentPassword:boolean;
  userRole:any;
  constructor(private formBuilder:FormBuilder,private spinner: NgxSpinnerService,
    private changePwSrv:ChangePasswordService, private router:Router,) {
      this.changePasswordForm = this.formBuilder.group(
        { 
          Password:[``,[Validators.required]],
          ConfirmPassword:[``,[Validators.required]],
          NewPassword:[``,Validators.required]
        }
      );
     }

  ngOnInit() {
  }
  ChangePasswordFormSubmit (){
    this.changePasswordFormSubmit=true;
    this.confirmPasswordError=false;
    if(this.changePasswordForm.value.NewPassword!=this.changePasswordForm.value.ConfirmPassword)
    {
      this.confirmPasswordError = true;
      return;
    }
    if(!this.changePasswordForm.valid){
      return;
    }
      this.confirmPasswordError = false;
      this.spinner.show();
     async;
      this.changePwSrv.PostChangePasswordForm(this.changePasswordForm.value).subscribe(res=>{
        console.log(res);
        if(res["statusCode"]==404){
          this.wrongCurrentPassword=true;
        }
        else{
       this.wrongCurrentPassword=false;
       localStorage.setItem('IsReset',res["data"]["createdBy"]);
       alert(res["message"]);
       if(this.userRole=='Admin')
       {
           this.router.navigate(["/Admin/home"]);
         }
         else if(this.userRole=='CCB')
         {
           this.router.navigate(["/CCB/home"]);
         }
         else{
           this.router.navigate(["/Customer/home"]);
         }
       this.changePasswordForm.reset();
       this.changePasswordFormSubmit=false;
      }
      this.spinner.hide(); 
    },
    error=>{
      console.log(error);
      this.spinner.hide();
    });
   }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SellerService } from '../Services/Seller/seller.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import * as jsPDF from 'jspdf';
declare var jsPDF: any;


interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-non-teachingpost',
  templateUrl: './non-teachingpost.component.html',
  styleUrls: ['./non-teachingpost.component.scss']
})
export class NonTeachingpostComponent implements OnInit {

  toppingList: string[] = ['Advertisement No. IITR/Establishment/2022/01'];
  PostList: string[] = ['Deputy Registrar','Assistat Registrar', 'General Duty Medical Officer'];
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  @ViewChild('formHDFC', {static: false}) formHDFC: ElementRef;
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;
  @ViewChild('pdfasTableHDFC', {static: false}) pdfasTableHDFC: ElementRef;

  NonTeachingpostForm:FormGroup;
  encdata:any;
  encRequest : any;
  merchant_code:any
  accessCode: any;
  merchant_id:any;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  IsresponseSBI: boolean;
  IsresponseHDFC:boolean;
  ApplicationSeq_No:any;
  CATRegistrationNos:any;
  applicationSeqNo:any = {};
  ApplicantName: any;
  RegistrationNo: any;
  currency: string;

  foods: Food[] = [
    {value: 'a', viewValue: '(a)'},
    {value: 'b', viewValue: '(b)'},
    {value: 'c', viewValue: '(c)'},
    {value: 'd', viewValue: '(d)'},
    {value: 'e', viewValue: '(e)'},
  ];
  NonTeachingpostFormSubmit: boolean;
  submitted: boolean;
  bankname: any;
  email: any;
  username: any;

  constructor(private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  { 
    this.route.queryParams.subscribe(params => {
    this.order_id = params['order_id'];
    this.tracking_id = params['tracking_id'];
    this.bank_ref_no = params['bank_ref_no'];
    this.order_status = params['order_status'];
    this.amount = params['amount'];
    this.billing_name = params['billing_name'];
    this.merchant_param2=params['merchant_param2'];
    this.delivery_address=params['delivery_address'];
    this.sbi_ref_no=params['sbi_ref_no'];
    this.iitr_ref_no=params['iitr_ref_no'];
    this.status=params['status'];
    this.desc=params['desc'];
    this.Transactiontime_date=params['Transactiontime_date'];
    this.ApplicationSeq_No=params['ApplicationSeq_No'];
    this.ApplicantName=params['ApplicantName'];
    this.RegistrationNo=params['RegistrationNo'];
    this.bankname=params['BankName'];
    this.email=params['EmailId'];
    this.username=params['UserName'];
});

    this.NonTeachingpostForm = this.formBuilder.group({
      AdvtNo:[``, Validators.required],
      PostNo:[``,Validators.required],
      PostName:[``,Validators.required],
      Username:[``,Validators.required],
      Name:[``,Validators.required],
      E_mailID:[``,Validators.required],
      MobileNo:[``,Validators.required],
      AadharNo:[``],
      TotalFee:[``,Validators.required],
          })
  }

  ngOnInit() {
    this.accessCode = 'AVKR88GJ13AH16RKHA';
    this.merchant_id='234485';
    this.currency='INR';
    this.merchant_code='IIT_RK';
    this.IsresponseSBI=false;
const data =
      {
        'tracking_id' : this.tracking_id,
        'bank_ref_no' : this.bank_ref_no,
        'order_status' : this.order_status,
        'amount' : this.amount,
        'billing_name' :this.billing_name,
        'merchant_param2':this.merchant_param2,
        'delivery_address':this.delivery_address,
        'sbi_ref_no':this.sbi_ref_no,
        'iitr_ref_no':this.iitr_ref_no,
        'status':this.status,
        'desc':this.desc,
        'Transactiontime_date': this.Transactiontime_date,
        'ApplicantName':this.ApplicantName,
        'RegistrationNo':this.RegistrationNo,
      }
      if(this.status=="Success")
      {
        this.IsresponseSBI = true;
       // this.downloadPDF();
      }
       else if(this.status=="Failure") 
      {
        this.IsresponseSBI = true;
       // this.downloadPDF();
      }
      else if(this.status=="Aborted")
    {
      this.IsresponseSBI = true;
     // this.downloadPDF();
    }

    if(this.order_status=="Success")
    {
      this.IsresponseHDFC = true;
    }
     else if(this.order_status=="Failure") 
    {
      this.IsresponseHDFC = true;
    }
    else if(this.order_status=="Aborted")
  {
    this.IsresponseHDFC = true;
  }
    this.NonTeachingpostForm.reset();
  }
  savenonteachingdata()
  {
    this.NonTeachingpostFormSubmit=true;
    if(!this.NonTeachingpostForm.valid){
      return;
    }
    this.submitted = true;
      let parameter = "iitr_ref_no=34344333|amount=1";
      this.sellerService.Non_TeachingFeesViaHDFC(this.NonTeachingpostForm.value).subscribe((data:any)=>{
     console.log('Hdfcencdata',data.data.data)
     this.encRequest = data.data.data; 
     console.log(this.accessCode,this.encRequest);
     if(data.data.data.amountStatus!="Success")
     {
     setTimeout(() => {
      this.formHDFC.nativeElement.submit()
    }, 1000);
    }
    else
    {
      this.toastr.info(data.data.message)
    }
    },
    error => {
      console.log(error)
      }
    );
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public downloadPDFHDFC() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTableHDFC = this.pdfasTableHDFC.nativeElement;
    doc.fromHTML(pdfasTableHDFC.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('PaymentReceiptofNewEntrants_Fee.pdf');
  }
  // savenonteachingdata()
  // {
  //   this.NonTeachingpostForm.reset();
  //   this.toastr.success("Data Submitted Sucessfully");
  // }
  Goback() {
    $('.abc').removeClass('addremovecss');
     window.location.href='https://iitrpaydemo.azurewebsites.net/NonTeachingpost';
    this.IsresponseHDFC=false;
  }


}

import { Component, OnInit } from '@angular/core';
import { SellerService } from '../../Services/Seller/seller.service';
import { from } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-setup-seller',
  templateUrl: './setup-seller.component.html',
  styleUrls: ['./setup-seller.component.scss']
})
export class SetupSellerComponent implements OnInit {
  SetUpSellerForm:any;
  SetUpSellerFormSubmit:boolean=false;
  data:any;

  submitted: boolean;
  constructor(private Sellerservices: SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService) 
  { 
    this.SetUpSellerForm = this.formBuilder.group({
      SellerEmail: ['', [Validators.required,Validators.email,Validators.minLength(3), Validators.maxLength(50)]],
      Token:['', [Validators.required,Validators.maxLength(1000)]],
      Userid: ['', [Validators.required,Validators.maxLength(30)]],
      FirstName:['',[Validators.required,Validators.maxLength(30)]],
      Lastname:['',[Validators.required,Validators.maxLength(30)]]
    })
  }
 addtoken()
 {
  this.SetUpSellerFormSubmit=true;
  if (this.SetUpSellerForm.invalid) {
    return;
}
  const data =
      {
        'SellerEmail': this.SetUpSellerForm.value.SellerEmail,
        'Token': this.SetUpSellerForm.value.Token,
        'Userid': this.SetUpSellerForm.value.Userid,
        'FirstName': this.SetUpSellerForm.value.FirstName,
        'Lastname':this.SetUpSellerForm.value.Lastname
      }
      this.Sellerservices.setupseller(data).subscribe((result:any) => {
        if(result.statusCode==200)
        {
          this.toastr.success(result.data.message);
          this.router.navigate(["/Admin/sellers"]);
        }
        else
        {
          this.toastr.error(result.data.message);
        }
      },
        (err: HttpErrorResponse) => {
        });
 }
 get f() { return this.SetUpSellerForm.controls; }
  ngOnInit() 
  {

  }

}

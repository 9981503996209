import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { SellerService } from '../Services/Seller/seller.service';
import { HttpErrorResponse } from '@angular/common/http';
import {MatSort} from '@angular/material'
import * as jsPDF from 'jspdf';
import { debug } from 'util';
import { ExcelService } from '../Services/excel.service';
import * as XLSX from 'xlsx'; 
import {ErrorStateMatcher} from '@angular/material/core';
import { Subject } from 'rxjs';


export interface PeriodicElement 
{
  ac_head:any;
  Receipts:any;
  Expenditure:any;
  Balance:any;
}

export class AccountDashbord implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-account-dashbord',
  templateUrl: './account-dashbord.component.html',
  styleUrls: ['./account-dashbord.component.scss']
})
export class AccountDashbordComponent implements OnInit {
  Is_master: boolean;
  Account_masterForm:FormGroup
  TaskDbListstudent: any[] = [];
  TaskDbList:any[]=[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dataResultList: PeriodicElement[] = [];
  StudentReportDB: any[] = [];
  EnableData=false;
  displayedColumns: string[] = ['select','ac_head','Receipts','Expenditure','Balance','chqno','Amount'];
   dataSource = new MatTableDataSource<PeriodicElement>(this.StudentReportDB);
  dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.StudentReportDB);
  selection = new SelectionModel<PeriodicElement>(true, []);
  StudentReportsDbList: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  OH31: any;
  OH35: any;
  OH36: any;

  constructor(private SpinnerService: NgxSpinnerService,private sellerService:SellerService, private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService, private route: ActivatedRoute) { 
    this.Account_masterForm = this.formBuilder.group({
      ac_head: ['', Validators.required],
      Receipts:['', Validators.required],
      Expenditure:['', Validators.required],
      Balance:['',Validators.required],
      nativeSelectFormControl:['',Validators.required]
    })
  }


  selected = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  selectFormControl = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  ac_head = new FormControl('valid', [
    Validators.required,
    Validators.pattern('valid'),
  ]);

  matcher = new AccountDashbord();

  ngOnInit() 
  {
this.loadExcel();
  }
  loadExcel() {
    this.SpinnerService.show();
    this.sellerService.GetAccountStatementbankdata().subscribe((data: any) => {
      console.log('new karan', data);
      this.TaskDbList = data.data.data;
      this.SpinnerService.hide();
      this.dtTrigger.next();
    },
      (err: HttpErrorResponse) => {

      });
  }
  Loadlist()
  {
    this.sellerService.ListExpenditure().subscribe((data:any) => {
    this.OH31=data.data.total;
    this.OH35=data.data.total1;
    this.OH36=data.data.total2;

      //this.spinner.show();
  this.StudentReportsDbList = data.data.data;
  console.log(this.StudentReportsDbList);
  // this.listcount= data.data.data.length;
 // this.toastr.info("We have"+" "+this.listcount+" "+"seller list");
  this.dataResultList = this.StudentReportsDbList;
  this.dataSource.data = this.dataResultList;
   this.dataSource.paginator = this.paginator;
   this.dataSource.sort = this.sort;
  // this.spinner.hide();
  },
  (err: HttpErrorResponse) => {
  });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  AddExpenditure()
  {
    this.sellerService.AddExpenditure(this.Account_masterForm.value).subscribe((data:any)=>{
      //this.dataExist = false;
      if(data.statusCode==200)
      {
        this.toastr.success("Data Added successfull")
        this.Is_master=false;
        this.Loadlist();
      }
else
{
  this.toastr.error("something went wrong, please try again");
}
    
      // this.toastr.success(data.data.message);
      });
  }

  AddMasterData()
  { 
    this.Is_master=true;
  }
  GoBack()
  {
    this.Is_master=false;
  }
  Runexc(data:any)
  {

  }
}

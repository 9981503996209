import {SelectionModel} from '@angular/cdk/collections';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { SellerService } from '../Services/Seller/seller.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {MatSort} from '@angular/material'
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
//import * as jsPDF from 'jspdf';
import { debug } from 'util';
import { ExcelService } from '../Services/excel.service';
import * as XLSX from 'xlsx'; 
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
declare var jsPDF: any;


export interface PeriodicElement {
  semester: string;
  EnrollmentNo: string;
  Name:string;
  Course:string;
  Department:string;
  Category: string;
  Status_Year:string;
  D_ND:string;
  InsituteFee:string;
  MessFee:string;
  OtherFee:string;
  TotalFees:string;
  PayStatus:string;
  Bank:string
  IITR_Ref_No:string;
  BankRefNo:string;
  transactiontime_date:string;
  stReg:string;
  Semester:string;
  stEmail: string;
  stMediFee: string;
  stMob: string;
  stName: string;
  description:string;
  filetype:string;
  PaidAmount:string;
  AmountDiff:string;
  TransactionId:string;
  Accommodation:string;
  Electricity:string;
  MessEsttb_Chag:string;
  ExcelCode:string;
  Mobile:string;
  Email:string;
  IFSCCode:string;
  AccountNo:string;
  Date:string;
  BeneficiaryAccountNo:string;
  RemitterName:string;
  UTR:string;
  MMID:string;
  Remark:string;
  Filetype:string;
  Action:string;
  enrollmentno:string;
  finalStatus:string;
  name:string;
  course:string;
  department:string;
  category:string;
  status_Year:string;
  d_ND:string;
  insituteFee:string;
  accommodation_Chag:string;
  electricity_Chag:string;
  messFee:string;
  messEsttb_Chag:string;
  otherFee:string;
  totalFees:string;
  paidAmount:string;
  amountStatus:string;
  p_type:string;
  iitR_Ref_No:string;
  bankRefNo:string;
  date:string;
  remark:string;
  beneficiaryAccountNo:string;
  utr:string;
  remitterName:string;
  amountDiff:string;
  semesterCharg:string;
  modeOfPayment:string;
  }
const SellerDbList1: PeriodicElement[] = [];
@Component({
  selector: 'app-sellers',
  templateUrl: './sellers.component.html',
  styleUrls: ['./sellers.component.scss']
})
export class SellersComponent implements OnInit {
  @ViewChild('formsbi', {static: false}) formsbi: ElementRef;
  @ViewChild('formsbidoubleverification', {static: false}) formsbidoubleverification: ElementRef;
  @ViewChild('form', {static: false}) form: ElementRef;
  @ViewChild('pdfasTable', {static: false}) pdfasTable: ElementRef;
  @ViewChild('pdfasTableFees',{static:false}) pdfasTableFees:ElementRef;
  @ViewChild('TABLE',{static:false}) table: ElementRef;

  websiteList: any = ['Yes','No']
  // selected = 'option2';
  isReadonly = false;
  accessCode: any;
  merchant_id:any;
  encRequest : any;
  encdata:any;
  merchant_code:any;
  order_level:any;
  allow:any;
  startDate:any;
  endDate:any;
  someDateVar: string;
  someDateVar2: string;
  order_no : any = 'qaz234567';
  testAmount : any = '10';
  selectedAddress : any = {
  name : 'testing',
  address : 'test address',
  city : 'test city',
  pincode : '23456',
  state : 'state test',
  phone : '1234567890'
  }
  SemesterDbList: any[] = [];
  CourseDbList: any[] = [];
  RemarkDbList: any[] = [];
  SellerDbList: any[] = [];
  dataResultList: PeriodicElement[] = [];
  EnableData=false;
  displayedColumns: string[] = ['select','EnrollmentNo','finalStatus','Name','Course','Department','Category','Status_Year','Semester','D_ND','InsituteFee','SemesterCharg','Accommodation','Electricity','MessFee','MessEsttb_Chag','OtherFee','TotalFees','paidAmount','amountDiff','PayStatus','description','Bank','IITR_Ref_No','BankRefNo','date','transactiontime_date','ExcelCode','stEmail','stMob','stName','stMediFee','remark','modeOfPayment','isAllow','endDate_Allow','filetype','Action'];
  displayedColumnsCustomer: string[] = ['select','semester','InsituteFee','MessFee','OtherFee','TotalFees','PayStatus','description','Bank','IITR_Ref_No','BankRefNo','transactiontime_date','stEmail','stMob','stName','stMediFee','Action'];
  displayedColumnsCustomersubdata: string[] = ['select','InsituteFee','MessFee','OtherFee','TotalFees','PayStatus','Action'];
  dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.SellerDbList);
  dataSource = new MatTableDataSource<PeriodicElement>(this.SellerDbList);
  AddStudentForm:FormGroup;
  DeleteSellerForm:FormGroup;
  DeleteSellerFormSubmit:boolean=false;
  updateSellerform:FormGroup;
  ccavenuForm:FormGroup;
  sbiForm:FormGroup;
  IsAllowData:FormGroup;
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  IsEdit = false;
  users: any = {};
  userRole: string;
  listcount: any;
  IsDelete: boolean;
  isLoader: boolean;
  IsAnyIssue:boolean;
  IsUpdate: boolean;
  getall:any=[];
  email: string;
  currency: string;
  UserName: string;
  order_id:string;
  tracking_id:string;
  bank_ref_no:string;
  order_status:string;
  amount:any;
  billing_name:string;
  Isresponse: boolean;
  IsresponseSBI:boolean;
  merchant_param2:string;
  delivery_address:string
  Enrollment: string;
  paymentstatus: any;
  cancel_url:"https://iitroorkeepaymentservices.azurewebsites.net/Admin/CCavenuResponse";
  redirect_url:"https://iitroorkeepaymentservices.azurewebsites.net/Admin/CCavenuResponse";
  language:"EN";
  SetUpSellerForm:any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  AmountStatusList: any;
  exelList:any = [];
  trans_date: any;
  StatusMessage: any;
  Semester: any;
  value:Number;
  value1:Number;
  SumData:any;
  Enrollmentno: any;
  IsAllowstu: boolean;
  AddStudent: boolean;
  ExcelDbList: any[] = [];
  Total: any;
  RemarkOfExcel: any;
  Isallow: any;

  /** Whether the number of selected elements matches the total number of rows. */
  constructor(private http: HttpClient,public datepipe: DatePipe,private excelService:ExcelService,private toastr: ToastrService,private sellerService:SellerService,private spinner: NgxSpinnerService,private formbuilder:FormBuilder,private router : Router,private route: ActivatedRoute) 
  {
    this.route.queryParams.subscribe(params => {
    this.order_id = params['order_id'];
    this.tracking_id = params['tracking_id'];
    this.bank_ref_no = params['bank_ref_no'];
    this.order_status = params['order_status'];
    this.amount = params['amount'];
    this.billing_name = params['billing_name'];
    this.merchant_param2=params['merchant_param2'];
    this.delivery_address=params['delivery_address'];
    this.sbi_ref_no=params['sbi_ref_no'];
    this.iitr_ref_no=params['iitr_ref_no'];
    this.status=params['status'];
    this.desc=params['desc'];
    this.Transactiontime_date=params['Transactiontime_date'];
    this.trans_date=params['trans_date'];
    this.StatusMessage=params['StatusMessage'];
    this.Semester=params['Semester'];
    this.Enrollmentno=params['Enrollmentno']
  });
    const dataResultList: PeriodicElement[] = [];
    for (let i = 1; i <= 15; i++) {
      //this.SellerDbList.push(this.createNewUser(i));
    }
    // Assign the data to the data source for the table to render
    this.dataSourceSimple = new MatTableDataSource(dataResultList);
    this.dataSource = new MatTableDataSource(dataResultList);
    this.IsAllowData= this.formbuilder.group(
    {
      yesnooption:[''],
      sellerId:[''],
      enddate:['']
    }
    )
    this.DeleteSellerForm = this.formbuilder.group(
      { 
      sellerId:[''],
      subject:[''],
      body:[''],
      id:['']
      })
      this.updateSellerform=this.formbuilder.group(
        {
        enrollmentno:[''],
        name:[''],
        course:[''],
        department:[''],
        category:[''],
        status_Year:[''],
        d_ND:[''],
        insituteFee:[''],
        messFee:[''],
        otherFee:[''],
        totalFees:[''],
        id:[''],
        accommodation_Chag:[''],
        messEsttb_Chag:[''],
        electricity_Chag:[''],
        semesterCharg:[''],
        amountStatus:[''],
        p_type:[''],
        modeOfPayment:[''],
        description:[''],
        paidAmount:[''],
        transactiontime_date:['']
        })
        this.AddStudentForm=this.formbuilder.group(
          {
          enrollmentno:[''],
          name:[''],
          course:[''],
          department:[''],
          category:[''],
          status_Year:[''],
          d_ND:[''],
          insituteFee:[''],
          messFee:[''],
          otherFee:[''],
          totalFees:[''],
          id:[''],
          accommodation_Chag:[''],
          messEsttb_Chag:[''],
          electricity_Chag:[''],
          semesterCharg:[''],
          amountStatus:[''],
          p_type:[''],
          modeOfPayment:[''],
          description:[''],
          paidAmount:[''],
          transactiontime_date:[''],
          stReg:[''],
          remark:[''],
          Semester:['']
          })
this.sbiForm=this.formbuilder.group
({
  encdata:[''],
  merchant_code:[''],
  order_id:[''],
  amount:[''],
  currency:[''],
  billing_name:[''],
  billing_address:[''],
  billing_city:[''],
  billing_state:[''],
  billing_zip:[''],
  billing_country:[''],
  billing_tel:[''],
  billing_email:[''],
  iitr_ref_no:[''],
  Enrollmentno:['']
})
        this.ccavenuForm=this.formbuilder.group(
          {
            encdata:[''],
            merchant_code:[''],
            merchant_id:[''],
            encRequest:[''],
            access_code:[''],
            order_id:[''],
            amount:[''],
            currency:[''],
            redirect_url:[''],
            cancel_url:[''],
            language:[''],
            billing_name:[''],
            billing_address:[''],
            billing_city:[''],
            billing_state:[''],
            billing_zip:[''],
            billing_country:[''],
            billing_tel:[''],
            billing_email:[''],
            delivery_name:[''],
            delivery_address:[''],
            delivery_city:[''],
            delivery_state:[''],
            delivery_zip:[''],
            delivery_country:[''],
            delivery_tel:[''],
            userRole:[''],
            Enrollmentno:['']
          })

          // this.getJSON().subscribe(data => {  
          //   data.forEach(row => {  
          //     this.excel.push(row);  
          //   });  
          //  });  
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
      return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
   /** The label for the checkbox on the passed row */
   checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.EnrollmentNo + 1}`;
  }
  excel=[];
  ngOnInit() 
  {
    this.IsresponseSBI=false;
const data =
      {
        'tracking_id' : this.tracking_id,
        'bank_ref_no' : this.bank_ref_no,
        'order_status' : this.order_status,
        'amount' : this.amount,
        'billing_name' :this.billing_name,
        'merchant_param2':this.merchant_param2,
        'delivery_address':this.delivery_address,
        'sbi_ref_no':this.sbi_ref_no,
        'iitr_ref_no':this.iitr_ref_no,
        'status':this.status,
        'desc':this.desc,
        'Transactiontime_date': this.Transactiontime_date,
        'order_id':this.order_id,
        'trans_date':this.trans_date,
        'StatusMessage':this.StatusMessage,
        'Semester':this.Semester,
        'Enrollmentno':this.Enrollmentno,
      }
      // this.iitr_ref_no = window.sessionStorage.setItem('iitr_ref_no',this.iitr_ref_no);
      // this.amount = window.sessionStorage.setItem('amount',this.amount);
      // this.iitr_ref_no = window.sessionStorage.getItem('iitr_ref_no');
      // this.amount=window.sessionStorage.getItem('amount');

      if(this.status=="Success")
      {
        this.IsresponseSBI = true;
      }
       else if(this.status=="Failure") 
      {
        this.IsresponseSBI = true;
      }
      else if(this.status=="Aborted")
      {
       this.IsresponseSBI = true;
      }
      // AVKR88GJ13AH16RKHA
    //this.accessCode = 'AVWP03GL00AX39PWXA';
    this.accessCode = 'AVKR88GJ13AH16RKHA';
    this.merchant_id='234485';
    this.currency='INR';
    this.merchant_code='IIT_RK';
    this.userRole= localStorage.getItem('userRole');
    this.Enrollment=localStorage.getItem('Enrollment')
    this.UserName = window.sessionStorage.getItem('UserName');
    this.dataSource.paginator = this.paginator;
  
    if(this.order_status=="Success")
    {
      this.Isresponse = true;
    }
     else if(this.order_status=="Failure") 
    {
      this.Isresponse = true;
    }
    else if(this.order_status=="Aborted")
    {
      this.Isresponse = true;
    }


 //serch---------------//
 this.dataSource.paginator = this.paginator;
 //search--------------//
 this.GetCourseData();
 this.GetSemesterData();
 this.GetRemarkData();
 this.GelExcelCodeData();
  }
//********searching */
ngAfterViewInit() {
this.dataSourceSimple.paginator = this.paginator;
this.dataSourceSimple.sort = this.sort;
this.dataSource.paginator = this.paginator;
this.dataSource.sort = this.sort;

return this.sellerService.getSellerList(this.UserName,this.Enrollment).subscribe(res => {
  this.dataSource.data =res['data'].data;
  this.spinner.hide ("Load all data.");
  setTimeout(() => {
    this.getAllData()
  }, 1000);
  
    this.toastr.success("Load all data.")
});
}

LoadStudentDetailsData()
 {
  this.sellerService.getSellerList(this.UserName,this.Enrollment)
  .subscribe((data:any) => {
  this.SellerDbList = data.data.data;
  this.listcount= data.data.data.length;
  this.dataResultList = this.SellerDbList;
  this.dataSource.data = this.dataResultList;
  this.spinner.hide();
  if(this.dataResultList.length>0)
  {
    this.EnableData;
  }
  else
  {
  this.EnableData=true;
  $('.table-wrap').hide()
  }
  console.log(this.SellerDbList);
},
(err: HttpErrorResponse) =>
{
});
 }
 GetSemesterData()
{
  this.spinner.show("Please Wait ..... It's Working");
  this.sellerService.Getsemesterdata().subscribe((data:any)=> { 
  this.SemesterDbList=data.data.data;
  this.spinner.hide();
},
 (err: HttpErrorResponse) => {
 });
}
GetCourseData()
{
  this.spinner.show("Please Wait ..... It's Working");
  this.sellerService.GetCoursedata().subscribe((data:any)=> { 
  this.CourseDbList=data.data.data;
  this.spinner.hide();
},
 (err: HttpErrorResponse) => {
 });
}
GetRemarkData()
{
  this.spinner.show();
  this.sellerService.GetRemarkdata().subscribe((data:any)=> { 
  this.RemarkDbList=data.data.data;
  this.spinner.hide();
},
 (err: HttpErrorResponse) => {
 });
}

public downloadPDF() {
  const doc = new jsPDF();
  const specialElementHandlers = {
    '#editor': function (element, renderer) {
      return true;
    }
  };
  const pdfasTable = this.pdfasTable.nativeElement;
  doc.fromHTML(pdfasTable.innerHTML, 30, 30, {
    width: 190,
    'elementHandlers': specialElementHandlers
  });
  doc.save('PaymentReceipt.pdf');
}

public downloadPDFFees() {
  const doc = new jsPDF();
  const specialElementHandlers = {
    '#editor': function (element, renderer) {
      return true;
    }
  };
  const pdfasTableFees = this.pdfasTableFees.nativeElement;
  doc.fromHTML(pdfasTableFees.innerHTML, 30, 30, {
    width: 190,
    'elementHandlers': specialElementHandlers
  });
  doc.save('PaymentReceipt.pdf');
}

updateStats()
{
  console.log('I am doing something every 10 second');
}
  // /** Builds and returns a new User. */
  getfilter(name){
    var email = "";
    this.dataSource.data=[];
    this.dataSource.data= this.getall;
    var myval = $.grep(this.dataSource.data, function (n, i) {
      if (name != '' && name != undefined) {
        email = n.Name;
    }
      return name.trim()==email.trim();
    });
    if(name!="Select Email"){
      this.dataSource.data=myval;
    }
}
checksum()
{
  let parameter = "iitr_ref_no=34344333|amount=1";
  this.sellerService.Checksumresponse(this.sbiForm.value).subscribe((data:any)=>{
  console.log('sbiencdata',data.data.data)
  this.encdata=data.data.data;
  console.log(this.merchant_code,this.encdata);
  setTimeout(() => {
  this.formsbi.nativeElement.submit()
}, 1000);
},
error => {
  console.log(error)
  }
);
}
checkout(){
    let redirect_url = 'http://localhost:4200/';
    let useremail = 'vishu.bakde@gmail.com';
    // let request = `merchant_id=234485&order_id=${this.order_no}&currency=INR&amount=${this.testAmount}&redirect_url=${redirect_url}&cancel_url=${redirect_url}&language=EN&billing_name=${this.selectedAddress.name}&billing_address=${this.selectedAddress.address}&billing_city=${this.selectedAddress.city}&billing_state=MH&billing_zip=${this.selectedAddress.pincode}&billing_country=India&billing_tel=${this.selectedAddress.phone}&delivery_name=${this.selectedAddress.name}&delivery_address=${this.selectedAddress.address}&delivery_city=${this.selectedAddress.city}&delivery_state=${this.selectedAddress.state}&delivery_zip=${this.selectedAddress.pincode}&delivery_country=India&delivery_tel=${this.selectedAddress.phone}&billing_email=${useremail}`
    this.sellerService.ResponseHandler(this.ccavenuForm.value).subscribe((data:any)=>{
    console.log('---------------------', data.data.data)
    this.encRequest = data.data.data; 
    console.log(this.accessCode,this.encRequest);
    // 
    setTimeout(() => {
      this.form.nativeElement.submit()
    }, 1000);
    }, error => {
    console.log(error)
    }
    );
}

  ViewOrder(editdata) 
  {
    debugger;
  this.paymentstatus= editdata.amountStatus;
  //   function randomString(length, chars) {
  //     var result = '';
  //     for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  //     return result;
  // }
  // var order_id = randomString(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    $('.abc').addClass('addremovecss');
    this.IsEdit = true;
    this.users = editdata;
    console.log('users', this.users);
  }
  AnyIssue(editdata)
  {
    $('.abc').addClass('addremovecss');
    this.IsAnyIssue=true;
    this.users=editdata;
    this.email = window.sessionStorage.getItem("Email");
    console.log('users', this.users);
  }
  DeleteSeller(editdata)
  {
    debugger;
    this.IsEdit=false;
    this.IsDelete=true
    this.users=editdata;
     }

  setupemail()
 {
  this.sellerService.setupemail(this.DeleteSellerForm.value).subscribe((data:any)=>{
    this.IsDelete = false;
    this.toastr.success("Mail sent successfully");
    this.IsAnyIssue=false;
    this.ngOnInit();
 //   window.location.reload();
  }, (err: HttpErrorResponse) => {
    this.isLoader = false;
    
});  
}
DeleteSellerss()
 {
  this.spinner.show("Working.... Please Wait!!!");
  this.sellerService.DeleteSeller(this.DeleteSellerForm.value).subscribe((data:any)=>{
  this.IsDelete = false;
  this.toastr.success("Data deleted successfully");
  this.SellerDbList = data.data.data;
  this.dataResultList = this.SellerDbList;
  this.dataSource.data = this.dataResultList;
  this.spinner.hide();
 //   window.location.reload();
  }, (err: HttpErrorResponse) => {
    this.isLoader = false;
}); 
}

UpdateSeller(editdata)
{
  this.IsUpdate = true;
  this.users = editdata;
  if(this.userRole=="DOSW")
  {
    this.isReadonly = true;
  }
  else
  {
    this.isReadonly = false;
  }
}

OpenStudentAddForm()
{
  this.AddStudent = true;
}


UpdateSellerForm()
{
  this.spinner.show();
  this.sellerService.UpdateSellers(this.updateSellerform.value).subscribe((data:any)=>{
  this.IsEdit = false;
  this.spinner.hide();
  this.toastr.success(data.message);
  this.IsUpdate=false;
  this.SellerDbList = data.data;
  this.dataResultList = this.SellerDbList;
  this.dataSource.data = this.dataResultList;
  }, (err: HttpErrorResponse) => {
    this.isLoader = false;
}); 
}


firstDropDownChanged(val: any)
{
  this.spinner.show("Please wait....");
  this.sellerService.getExcelCode(val).subscribe((data: any) => 
  {
  this.SellerDbList = data.data.data;
  this.listcount= data.data.data.length;
  this.dataResultList = this.SellerDbList;
  this.dataSource.data = this.dataResultList;
  this.dataSource.paginator = this.paginator;
  this.spinner.hide();
  })
}
// UpdateSellerForm()
// {
//   debugger;
//   this.sellerService.UpdateSellers(this.updateSellerform.value).subscribe((data:any)=>{
//     debugger;
//     this.IsEdit = false;
//     this.toastr.success("Update Seller successfully");
//     this.IsUpdate=false;
//     this.ngOnInit();
//  //   window.location.reload();
//   }, (err: HttpErrorResponse) => {
//     this.isLoader = false;
// }); 
// }

GobackView()
{
  $('.abc').removeClass('addremovecss');
  this.IsEdit = false;
}
GobackDelete()
{
  $('.abc').removeClass('addremovecss');
  this.IsDelete=false;
}
gobackstuupdate()
{
  this.IsUpdate=false;
}

  Goback() 
  {
    $('.abc').removeClass('addremovecss');
    this.userRole= localStorage.getItem('userRole');
    if(this.userRole=="Customer")
    {
      //window.location.href='http://localhost:4200/Customer/Studentdetails';
      window.location.href='https://iitrpaydemo.azurewebsites.net/Customer/Studentdetails'
    }
    else
    {
      window.location.href='https://iitrpaydemo.azurewebsites.net/Admin/Studentdetails';
    }
    // local
   // window.location.href='http://localhost:4200/Admin/Studentdetails';
    // sever
  // window.location.href='https://iitrwebapppayment.azurewebsites.net/Admin/Studentdetails';
    
    //http://localhost:4200
    //window.location.href='https://iitrwebapppayment.azurewebsites.net/Admin/Studentdetails';
    this.IsEdit = false;
    this.IsDelete=false;
    this.IsUpdate=false;
    this.IsAnyIssue=false;
    this.Isresponse=false;
    this.IsresponseSBI=false;
  }
  Suminsitute(data: any)
  {
   this.users.totalFees = Number(data) + Number(this.users.semesterCharg)+ Number(this.users.accommodation_Chag) + Number(this.users.electricity_Chag)+Number(this.users.messFee)+Number(this.users.messEsttb_Chag)+Number(this.users.otherFee); 
   this.Total=this.users.totalFees;
  }
  SumSemesterCharges(data: any)
  {
    this.users.totalFees=Number(data)+Number(this.users.insituteFee)+ Number(this.users.accommodation_Chag) + Number(this.users.electricity_Chag)+Number(this.users.messFee)+Number(this.users.messEsttb_Chag)+Number(this.users.otherFee); 
  }
  SumAccommodationFee(data:any)
  {
    this.users.totalFees = Number(data)+ Number(this.users.semesterCharg)+Number(this.users.insituteFee)+Number(this.users.electricity_Chag)+Number(this.users.messFee)+Number(this.users.messEsttb_Chag)+Number(this.users.otherFee);
  }
  SumelectricityFee(data:any)
  {
    this.users.totalFees = Number(data)+ Number(this.users.semesterCharg) + Number(this.users.insituteFee) + Number(this.users.accommodation_Chag) + Number(this.users.messFee) + Number(this.users.messEsttb_Chag) + Number(this.users.otherFee);
  }
  Summess(data: any)
  {
   this.users.totalFees = Number(data) + Number(this.users.semesterCharg)+ Number(this.users.accommodation_Chag) + Number(this.users.electricity_Chag) + Number(this.users.insituteFee)+ Number(this.users.messEsttb_Chag)+Number(this.users.otherFee); 
  }
  SumMessEstablishment(data: any)
  {
    this.users.totalFees =Number(data) + Number(this.users.semesterCharg)+ Number(this.users.insituteFee) + Number(this.users.accommodation_Chag) +Number(this.users.electricity_Chag)+ Number(this.users.messFee) + Number(this.users.otherFee)
  }
  SumOther(data: any)
  {
   this.users.totalFees = Number(data)+ Number(this.users.semesterCharg) + Number(this.users.insituteFee) + Number(this.users.accommodation_Chag) +Number(this.users.electricity_Chag) + Number(this.users.messFee) + Number(this.users.messEsttb_Chag); 
  }


  SumOfInsituteFee(data: any)
  {
    // Number(this.AddStudentForm.value.ElectricityCharges)
   this.Total = Number(data) + Number(this.AddStudentForm.value.semesterCharg)+ Number(this.AddStudentForm.value.accommodation_Chag) + Number(this.AddStudentForm.value.electricity_Chag)+Number(this.AddStudentForm.value.messFee)+Number(this.AddStudentForm.value.messEsttb_Chag)+Number(this.AddStudentForm.value.otherFee); 
  }
  SumOfSemesterFee(data: any)
  {
    this.Total=Number(data)+Number(this.AddStudentForm.value.insituteFee)+ Number(this.AddStudentForm.value.accommodation_Chag) + Number(this.AddStudentForm.value.electricity_Chag)+Number(this.AddStudentForm.value.messFee)+Number(this.AddStudentForm.value.messEsttb_Chag)+Number(this.AddStudentForm.value.otherFee); 
  }
  SumOfAccommodationFee(data:any)
  {
    this.Total = Number(data)+ Number(this.AddStudentForm.value.semesterCharg)+Number(this.AddStudentForm.value.insituteFee)+Number(this.AddStudentForm.value.electricity_Chag)+Number(this.AddStudentForm.value.messFee)+Number(this.AddStudentForm.value.messEsttb_Chag)+Number(this.AddStudentForm.value.otherFee);
  }
  SumOfElectricityFee(data:any)
  {
    this.Total = Number(data)+ Number(this.AddStudentForm.value.semesterCharg) + Number(this.AddStudentForm.value.insituteFee) + Number(this.AddStudentForm.value.accommodation_Chag) + Number(this.AddStudentForm.value.messFee) + Number(this.AddStudentForm.value.messEsttb_Chag) + Number(this.AddStudentForm.value.otherFee);
  }
  SumOfMess(data:any)
  {
    this.Total = Number(data) + Number(this.AddStudentForm.value.semesterCharg)+ Number(this.AddStudentForm.value.accommodation_Chag) + Number(this.AddStudentForm.value.electricity_Chag) + Number(this.AddStudentForm.value.insituteFee)+ Number(this.AddStudentForm.value.messEsttb_Chag)+Number(this.AddStudentForm.value.otherFee); 
   }
   SumOfMessEstablishmentFee(data: any)
  {
    this.Total =Number(data) + Number(this.AddStudentForm.value.semesterCharg)+ Number(this.AddStudentForm.value.insituteFee) + Number(this.AddStudentForm.value.accommodation_Chag) +Number(this.AddStudentForm.value.electricity_Chag)+ Number(this.AddStudentForm.value.messFee) + Number(this.AddStudentForm.value.otherFee)
  }
  SumOfOtherFee(data: any)
  {
   this.Total = Number(data)+ Number(this.AddStudentForm.value.semesterCharg) + Number(this.AddStudentForm.value.insituteFee) + Number(this.AddStudentForm.value.accommodation_Chag) +Number(this.AddStudentForm.value.electricity_Chag) + Number(this.AddStudentForm.value.messFee) + Number(this.AddStudentForm.value.messEsttb_Chag); 
  }
  getDataForSeller(seller: any)
  {
    this.spinner.show();
    this.sellerService.getStudentFeesAmountStatus(seller).subscribe((data: any) => {
    this.AmountStatusList = data.data;
    this.dataResultList = this.AmountStatusList;
    this.dataSource.data = this.dataResultList;
    this.spinner.hide();
  })
  }

  getDataForCourse(seller: any)
  {
    this.spinner.show();
    this.sellerService.getStudentCourse(seller).subscribe((data: any) => {
    this.AmountStatusList = data.data;
    this.dataResultList = this.AmountStatusList;
    this.dataSource.data = this.dataResultList;
    this.spinner.hide();
      })
  }

  getRemarkList(seller: any)
  {
    this.spinner.show();
    this.sellerService.GetRemarkList(seller).subscribe((data: any) => {
    this.AmountStatusList = data.data;
    this.dataResultList = this.AmountStatusList;
    this.dataSource.data = this.dataResultList;
    this.spinner.hide();})
  }

  getDataForSemester(seller: any)
  {
    this.spinner.show();
    // var Product = "";
    // this.AmountStatusList;
    // var myval = $.grep(this.dataSource.data, function (n, i) {
    //   if (seller != '' && seller != undefined) {
    //     Product = n.semester;
    //   }
    //   return seller.trim() == Product.trim();
    // });
    // if (myval.length !=0) {
    //   this.dataSource.data = myval;
    //   this.dataResultList=this.dataSource.data;
    //      this.spinner.hide();
    // }
    // else
    // {
    //   this.dataSource.data =[];
    //   this.spinner.hide();
    // }
    this.sellerService.getStudentSemester(seller).subscribe((data: any) => {
    this.AmountStatusList = data.data;
    this.dataResultList = this.AmountStatusList;
    this.dataSource.data = this.dataResultList;
    this.spinner.hide();})
  }
  getAllData()
  {
    let seller = "all"
    this.getDataForSeller(seller);
  }

  RefershAllData() {
    //  this.spinner.show();
    this.sellerService.RefreshAllStatusAPI().subscribe((data: any) => {
      console.log('new karan', data);
      this.toastr.show(data.message);
      this.sellerService.getSellerList(this.UserName,this.Enrollment)
  .subscribe((data:any) => {
  this.SellerDbList = data.data.data;
  this.listcount= data.data.data.length;
 // this.toastr.info("We have"+" "+this.listcount+" "+"seller list");
  this.dataResultList = this.SellerDbList;
  this.dataSource.data = this.dataResultList;
  if(this.dataResultList.length>0){
    this.EnableData;
    }
    else{
    this.EnableData=true;
    $('.table-wrap').hide()
    //$('.mat-paginator').hide();
    }
  console.log(this.SellerDbList);
},
  (err: HttpErrorResponse) => {
  });
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
      });
  }
  exportexcel(): void 
  {
     /* table id is passed over here */   
     
  }

  // ExportTOExcel()
  // {
  //   this.exelList=this.dataSource.data;
  //  // const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //  // XLSX.utils.book_append_sheet(this.exelList,'Sheet1');
  //   /* save to file */
  //   XLSX.writeFile(this.exelList, 'SheetJS.xlsx');
  // }
  // ExportTOExcel()
  // {
  //   console.log("export");
  //   // this.table.nativeElement.style.background = "red";
  //   const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
  //   const workSheet = XLSX.utils.json_to_sheet(this.dataSource.data);
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, workSheet, 'Sheet1');
  //   /* save to file */
  //   XLSX.writeFile(wb,'SheetJS.xlsx');
  //   console.log("exported");

  // }

  ExportTOExcel() 
  {
    this.dataSource.data = this.dataResultList;
    this.exelList=this.dataSource.data;
    let dataToExport = this.dataSource.filteredData.map(x => ({
    EnrollmentNo: x.enrollmentno,
    FinalStatus:x.finalStatus,
    Name: x.name,
    Course:x.course,
    Department:x.department,
    Category:x.category,
    Status_Year:x.status_Year,
    Semester:x.semester,
    D_ND:x.d_ND,
    InsituteFee:x.insituteFee,
    SemesterCharges:x.semesterCharg,
    AccommodationFee:x.accommodation_Chag,
    ElectricityFee:x.electricity_Chag,
    MessFee:x.messFee,
    MessEstablishmentFee:x.messEsttb_Chag,
    OtherFee:x.otherFee,
    TotalFees:x.totalFees,
    PaidAmount:x.paidAmount,
    Diffrence:x.amountDiff,
    AmountStatus:x.amountStatus,
    Description:x.description,
    Bank:x.p_type,
    IITRRefNo:x.iitR_Ref_No,
    BankRefNo:x.bankRefNo,
    Date:x.date,
    TransactionDate:x.transactiontime_date,
    ExcelCode:x.stReg,
    Mob:x.stMob,
    Email:x.stEmail,
    AccountNo:x.stName,
    IFSC:x.stMediFee,
    Remark:x.remark,
    BeneficiaryAccountNo:x.beneficiaryAccountNo,
    UTR:x.utr,
    RemitterName:x.remitterName,
    ModeOfPayment:x.modeOfPayment,
      }));
    let workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, <XLSX.Table2SheetOpts>{ sheet: 'Sheet 1' });
    let workBook: XLSX.WorkBook = XLSX.utils.book_new();
    // // Adjust column width
    // var wscols = [
    //   { wch: 50 },
    //   { wch: 50 },
    //   { wch: 30 }
    // ];
    // workSheet["!cols"] = wscols;
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
    XLSX.writeFile(workBook, `Student_fees.xlsx`);
  }

exportAsXLSX():void 
{
  this.dataSource.data = this.dataResultList;
  this.exelList=this.dataSource.data;
  this.excelService.exportAsExcelFile(this.exelList,'Student_fees');
}
// public getJSON(): Observable<any> {  
//   return this.http.get('https://api.myjson.com/bins/zg8of');  
// }
applyFilter(event: Event) 
{
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  if (this.dataSource.paginator) 
  {
    this.dataSource.paginator.firstPage();
  }
}

getDate(data:any)
{
this.startDate;
this.endDate;
this.someDateVar = this.datepipe.transform(this.startDate, "yyyy/dd/MM");
this.someDateVar2 = this.datepipe.transform(this.endDate, "yyyy/dd/MM");
this.sellerService.GetFilterDateDataStudentFees(this.someDateVar,this.someDateVar2).subscribe((data: any) => {
this.AmountStatusList = data.data.data;
this.dataResultList = this.AmountStatusList;
this.dataSource.data = this.dataResultList;
})
}

VerSendMailifyData(formData)
{    
  this.spinner.show();
  this.sellerService.sendTansactonMail(formData).subscribe((result: any) => {
  if (result.statusCode == 200) {       
  this.toastr.success("Mail has been send to user with certificate");   
  }
  else {
  this.toastr.error("something went wrong please try again");
  }
  this.spinner.hide();
  })
}

IsAllowStudent(editdata)
{
  this.IsAllowstu = true;
  this.users = editdata;
  this.Isallow=this.users.isAllow;
}

GobackIsAllow()
{
  this.IsAllowstu = false;
}

IsAllowStudentYesNo()
{
this.spinner.show();
this.sellerService.IsAllowStudentData(this.IsAllowData.value).subscribe((data:any)=>{
this.toastr.success("Data Updated");
this.SellerDbList = data.data.data;
this.dataResultList = this.SellerDbList;
this.dataSource.data = this.dataResultList;
this.IsAllowstu = false;
this.spinner.hide();
// let seller = "all"
// this.getDataForSeller(seller);
}, 
(err: HttpErrorResponse) => {
  this.isLoader = false;
}); 
}

gobackstudentadd()
{
  this.AddStudent=false;
}

ActiveExcel()
{
  this.spinner.show();
  this.sellerService.GetActiveExcel().subscribe((data: any) => {
  this.ExcelDbList = data.data.data;
  console.log('ExcelCode', this.ExcelDbList);
  },
  (err: HttpErrorResponse) => {
  }); 
}

GelExcelCodeData()
{
  this.spinner.show();
  this.sellerService.GetExcelCodedata().subscribe((data:any)=> { 
  this.ExcelDbList=data.data.data;
  console.log("ExcelCode",this.ExcelDbList)
},
 (err: HttpErrorResponse) => {
 });
}

Refresh(editdata) 
  {
   // this.IsEdit = true;
    this.users = editdata;
    this.spinner.show();
    this.sellerService.StudentPortalRefresh(this.users.id).subscribe((data: any) => {
      if(data.data != null)
      {
      this.AmountStatusList = data.data.data.data;
      this.SellerDbList=data.data.data;
      this.dataResultList = this.SellerDbList;
      this.dataSource.data = this.dataResultList;
      if(this.AmountStatusList=='Already Success')
      {
        this.toastr.show(this.AmountStatusList)
      }
      else
      {
      this.toastr.show(this.AmountStatusList)
      this.spinner.hide();
      }
     this.firstDropDownChanged(this.users.stReg)
     }
    else
    {
      this.toastr.show('No Status Updated.')
      this.spinner.hide();
    }
      //this.spinner.hide();
    })
  }
  getRemarkofExcel(seller: any)
  {
    debugger;
    this.spinner.show();
    this.sellerService.GetExcelRemark(seller).subscribe((data: any) => {
    this.RemarkOfExcel = data.data.data.descriptions;
    this.spinner.hide();})
  }
  AddStudentData()
  {
  this.spinner.show();
  this.sellerService.AddStudentData(this.AddStudentForm.value).subscribe((data:any)=>{
  this.IsEdit = false;
  this.spinner.hide();
  this.toastr.success(data.message);
  this.IsUpdate=false;
  this.SellerDbList = data.data;
  this.dataResultList = this.SellerDbList;
  this.dataSource.data = this.dataResultList;
  },
   (err: HttpErrorResponse) => {
    this.isLoader = false;
     }); 
  }

}


import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SignupService {

  constructor(private httpClient: HttpClient) { }
  PostSignUpForm(formData: any) {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    //GetSubscriptionMaster() { return this.http.get(this.API_ENDPOINT + '/api/SubscriptionMaster/ListOfSubscriptionsMaster') }
    //BaseUrl
    // return this.httpClient.post(`https://localhost:44349/api/users/Signup`,formData,HttpOptions);
    return this.httpClient.post(environment.BaseUrl + `api/users/Signup`, formData, HttpOptions);
  }
  Email;
  LogoutUser(Email) {
    this.Email = Email;
    return this.httpClient.get(environment.BaseUrl + '/api/Users/Logout?Email=' + this.Email);
  }
}

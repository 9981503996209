import { Component } from '@angular/core';
import { CommonserviceService } from '../Services/CommonService/commonservice.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SignupService } from '../Services/SignUp/signup.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  registerationForm:FormGroup;
  registerationFormSubmit:boolean=false;
  failedSignUp:boolean;
  suggestions:string[]=[];
  firstName:string;
  lastName:string;
  hide:boolean=true;
  constructor(private commonService:CommonserviceService, private formBuilder:FormBuilder,
    private signupService:SignupService, private router:Router,  private spinner: NgxSpinnerService) {
    this.registerationForm = this.formBuilder.group(
      { 
       // UserName:[``,[Validators.required, Validators.maxLength(50)]],
        FirstName:[``,[Validators.required]],
        LastName:[``,[Validators.required]],
        Email:[``,[Validators.required, Validators.email]],
        Password:[``,[Validators.required, Validators.minLength(1),Validators.maxLength(50)]],
        Enrollment:[``,[Validators.required, Validators.minLength(1),Validators.maxLength(50)]],
        Mobileno:[``,[Validators.required, Validators.minLength(1),Validators.maxLength(50)]]
      }
    );
   }
  //  CheckUserNameInput(value){
  //   if(value!=undefined)
  //     this.suggestions=[];
  //  }
  //  get Suggestions(){
  //   if(this.firstName!=undefined && this.lastName!=undefined){
  //   this.suggestions=[];
  //   var data = (Math.random() * 50 + 1).toString();
  //   for(var i=0; i<3;i++){
  //     if(!this.suggestions.includes(this.firstName+this.lastName+data[i]))
  //        this.suggestions.push(this.firstName+this.lastName+data[i]);
  //   }
  //   return this.suggestions;
  //   }
  //   else{
  //   this.suggestions=[];
  //   return null
  //  }
  // }
clickalert()
{
  if( alert("Please check the details properly before submission"),true)
  {
    this.SingupFormSubmit();
  }
else
{
  this.registerationForm.value();
}
}
   SingupFormSubmit(){
    this.registerationFormSubmit=true;
    if(!this.registerationForm.valid){
      return;
    }
    else{
      this.spinner.show();
      this.signupService.PostSignUpForm(this.registerationForm.value).subscribe(res=>{
        if(res){
          this.failedSignUp=false;
          alert("Signed Up Successfully")
          this.router.navigate(["/login"]);
        }
        else{
            this.failedSignUp=true;
      }
      this.spinner.hide(); 
    },
    error=>{this.failedSignUp=true;
      this.spinner.hide();
    });
   }
  }
Reset()
{
  this.registerationForm.reset();
}

}

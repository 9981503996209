import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private httpClient:HttpClient) { }
  
  PostForgotPasswordForm(formData:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
       })   
    }
    return this.httpClient.get(environment.BaseUrl+`api/users/GenerateOtp/?email=${formData.Email}`,formData);
  }
}

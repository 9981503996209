import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SellerService } from '../Services/Seller/seller.service';
//import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';  
import { DatePipe } from '@angular/common';
declare var jsPDF: any;


@Component({
  selector: 'app-studentpaymentdetails',
  templateUrl: './studentpaymentdetails.component.html',
  styleUrls: ['./studentpaymentdetails.component.scss']
})

export class StudentpaymentdetailsComponent implements OnInit {
  //@ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('form', {static: false}) form: ElementRef;
  SellerDbList: any[] = [];
  TaskDbList: any[] = [];
  LogDbList: any[] = [];
  TaskDbListstudent: any[] = [];
  IsDelete:boolean=false;
  loading = false;
  submitted = false;
  isLoader = true;
  users: any = {};
  IsEdit = false;
  excelFile= true;
  userType:any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  listcount: any;
  AmountStatusList: any;
  exelList:any = [];
  userRole: string;
  Enrollment: string;
  UserName: string;
  Studentpaydata: any;
  IsWithStudentPayData: boolean;
  StudentPayForm:FormGroup;
  AmountStatus: any;
  IsAmountButton: boolean;
  e_receiptOpen: boolean;
  Diffrence: any;
  encRequest: any;
  accessCode: any;
  merchant_id:any;
  encdata:any;
  merchant_code:any;
  currency: string;
  CATDetailList: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  CATRegistrationNo: any;
  trans_date: any;
  StatusMessage: any;
  Semester: any;
  Enrollmentno: any;
  IsresponseHDFC: boolean=false;
  enrollmentNo: any;
  order_id: any;
  ApplicationSeq_No: any;
  ApplicantName: any;
  currentDate = new Date();
  date: string;
  


  constructor(private datePipe: DatePipe,private http: HttpClient,private sellerService:SellerService, private toastr: ToastrService,private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,private spinner: NgxSpinnerService) 
  { 
    this.StudentPayForm=this.formBuilder.group({
      questionTypeId:[``, Validators.required],
      name:[],
      Uploadexcelsheet:[``, Validators.required],
      startdate:[``, Validators.required],
      enddate:[``, Validators.required],
      totalFees:[``, Validators.required]
     });

     this.route.queryParams.subscribe(params => {
      this.enrollmentNo=params['enrollment_no']
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
      this.ApplicationSeq_No=params['ApplicationSeq_No'];
      this.ApplicantName=params['ApplicantName'];
      this.CATRegistrationNo=params['RegistrationNo'];
      this.trans_date=params['trans_date'];
      this.StatusMessage=params['StatusMessage'];
      this.Semester=params['Semester'];
      this.Enrollmentno=params['Enrollmentno']
  });
  }

  ngOnInit() {
    const data =
          {
            'tracking_id' : this.tracking_id,
            'bank_ref_no' : this.bank_ref_no,
            'order_status' : this.order_status,
            'amount' : this.amount,
            'billing_name' :this.billing_name,
            'merchant_param2':this.merchant_param2,
            'delivery_address':this.delivery_address,
            'sbi_ref_no':this.sbi_ref_no,
            'iitr_ref_no':this.iitr_ref_no,
            'status':this.status,
            'desc':this.desc,
            'Transactiontime_date': this.Transactiontime_date,
            'RegistrationNo':this.CATRegistrationNo,
            'trans_date':this.trans_date,
            'StatusMessage':this.StatusMessage,
            'Semester':this.Semester,
            'Enrollmentno':this.Enrollmentno,
          }
        if(this.order_status=="Success")
        {
          this.IsresponseHDFC = true;
        }
         else if(this.order_status=="Failure") 
        {
          this.IsresponseHDFC = true;
        }
        else if(this.order_status=="Aborted")
      {
        this.IsresponseHDFC = true;
      }
      else if(this.order_status=="Awaited")
      {
        this.IsresponseHDFC=true;
      }

    this.accessCode = 'AVKR88GJ13AH16RKHA';
    this.merchant_id='234485';
    this.currency='INR';
    this.merchant_code='IIT_RK';
    this.userRole= localStorage.getItem('userRole');
    this.Enrollment=localStorage.getItem('Enrollment')
    this.UserName = window.sessionStorage.getItem('UserName');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };
    this.loadExcel();
  }
  loadExcel() {
   
    this.spinner.show();
    this.sellerService.getSellerList(this.UserName,this.Enrollment).subscribe((data: any) => {
    
      console.log('new karan', data);
      this.TaskDbList = data.data.data;
      this.LogDbList=data.data.datamodel;
      this.TaskDbListstudent=data.data.data;
      this.spinner.hide();
      this.dtTrigger.next();
    },
      (err: HttpErrorResponse) => {
      });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  OpenDeleteModal(deletedata) 
  {
   
    $('.abc').addClass('addremovecss');
    this.IsDelete=true;
    this.users = deletedata;
  }
  firstDropDownChanged(val: any){
 
    this.sellerService.GetstudentFeesData(val).subscribe((data: any) => {
    
      this.SellerDbList = data.data.data;
      this.listcount= data.data.data.length;
      this.TaskDbList= this.SellerDbList;
    },
      (err: HttpErrorResponse) => {
      });
  }

  Refresh(editdata) 
  {
    
   // this.IsEdit = true;
    this.users = editdata;
    this.spinner.show();
    this.sellerService.StudentPortalRefresh(this.users.id).subscribe((data: any) => {
     
      this.AmountStatusList = data.data.data.data;
      if(this.AmountStatusList=='Already Success'){
        this.toastr.show(this.AmountStatusList)
      }
      else
      {
      this.toastr.show(this.AmountStatusList)
      }

      this.sellerService.getSellerList(this.UserName,this.Enrollment).subscribe((data: any) => {
       
        console.log('new karan', data);
        this.TaskDbList = data.data.data;
        this.LogDbList=data.data.datamodel;
        this.TaskDbListstudent=data.data.data;
        this.spinner.hide();
      },
        (err: HttpErrorResponse) => {
        });
      this.spinner.hide();
      
    })
    
  }
  OpenPaymentReceipt(con)
  {
   
    this.Studentpaydata=con;
    this.date = this.datePipe.transform(this.currentDate, 'dd-MM-yyyy');
    if(this.Studentpaydata.amountStatus!="Success")
    {
      this.Studentpaydata.messPaidFee = 0;
      this.Studentpaydata.otherPaidFee = 0;
      this.Studentpaydata.insitutePaidFee = 0;
      this.Studentpaydata.paidAmount=0;
     // this.Studentpaydata.messEsttb_Chag = 0;
      this.Studentpaydata.accommodation_Chag = 0;
      this.Studentpaydata.electricity_Chag = 0;
    }
    else
    {
      this.Studentpaydata.messFee = Number(con.messFee)+ Number(con.messEsttb_Chag);
      this.Studentpaydata.insituteFee = Number(con.insituteFee)+ Number(con.semesterCharg)+ Number(con.accommodation_Chag)+ Number(con.electricity_Chag);
      this.Studentpaydata.messPaidFee = Number(con.messFee);
      this.Studentpaydata.otherPaidFee = con.otherFee;
      this.Studentpaydata.insitutePaidFee = con.insituteFee;
      this.Studentpaydata.messEsttb_Chag = con.messEsttb_Chag;
      this.Studentpaydata.accommodation_Chag = con.accommodation_Chag;
      this.Studentpaydata.electricity_Chag = con.electricity_Chag;

      if(this.Studentpaydata.amountStatus=="Success")
      {
        this.Studentpaydata.paidAmount=con.paidAmount;
      }
      else
      {
            this.Studentpaydata.paidAmount=Number(con.messFee) + Number(con.otherFee) +Number(con.insituteFee) + Number(con.accommodation_Chag) + Number(con.electricity_Chag);
      }
    }
    //By Beena
    if(this.Studentpaydata.amountStatus!="Success")
    {
      this.Studentpaydata.messFee = (Number(con.messFee)+ Number(con.messEsttb_Chag));
      this.Studentpaydata.insituteFee = Number(con.insituteFee)+ Number(con.semesterCharg)+ Number(con.accommodation_Chag)+ Number(con.electricity_Chag);
    }

    this.Studentpaydata.messFeeDiff = (Number(this.Studentpaydata.messFee)) - this.Studentpaydata.messPaidFee;

    
    this.Studentpaydata.otherFeeDiff = this.Studentpaydata.otherFee - this.Studentpaydata.otherPaidFee;

    
    this.Studentpaydata.insituteFeeDiff = this.Studentpaydata.insituteFee - this.Studentpaydata.insitutePaidFee;
     this.Diffrence = Number(this.Studentpaydata.totalFees) - Number(this.Studentpaydata.paidAmount);
    //this.Diffrence = Number(this.Studentpaydata.totalFees);
    this.e_receiptOpen=true;

  }

  AddExcelWithSameExcelCode(con)
  { 
   
    this.Studentpaydata=con;
    this.AmountStatus=this.Studentpaydata.amountStatus;
    if(this.AmountStatus!='Success')
    {
     this. IsAmountButton= true;
    }
    this.IsWithStudentPayData=true;
  }
  GoBack()
  {
    this.IsWithStudentPayData=false;
  }
  Gobackereceipt()
  {
    this.e_receiptOpen=false;
  }
  Gobackofpaymentpopup()
  {
    $('.abc').removeClass('addremovecss');
    window.location.href='https://iitrpaydemo.azurewebsites.net/Student/StudentFeesDetails';
    //window.location.href='http://localhost:4200/Customer/StudentFeesDetails';
    this.IsresponseHDFC=false;
    this.loadExcel();
  }

  public downloadBeena()
  {
    var data = document.getElementById('pdfTable');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 208;   
      let pageHeight = 295;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  

      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('PaymentReceiptofIITR_Fee.pdf'); // Generated PDF  
    });  

  }
  public captureScreen()  
  {  
    var data = document.getElementById('pdfasTableHDFCChallan');  
    html2canvas(data).then(canvas => {  
      var imgWidth =  300;   
      var pageHeight = 295;    
      // var imgHeight = canvas.height * imgWidth / canvas.width;  
      var imgHeight = 2000 * imgWidth / 1800;  
      var heightLeft = imgHeight;  
      var imgMargin=100;
  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF({
        orientation: "Portrait",
        unit: "cm",
        format: [27, 27]  //height and width
      });
     // let pdf = new jsPDF('p', 'cm', 'a4'); // A4 size page of PDF  
      var position = 0;  
      //pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.addImage(contentDataURL, 'PNG', 0, position, 35, 27)  
      pdf.save('MYPdf.pdf'); // Generated PDF   
    });  
  }  


  public downloadPDFHDFCChallan() {
    
    var margin = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    };
    const doc = new jsPDF();
    // const doc = new jsPDF({
    //   orientation: "Portrait",
    //   unit: "cm",
    //   format: [12, 12]  //height and width
    // });
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfasTableHDFCChallan = this.pdfTable.nativeElement;
    doc.fromHTML(pdfasTableHDFCChallan.innerHTML, 15, 15, {
      'width': 190,
      'elementHandlers': specialElementHandlers
    },
	function(bla){doc.save('PaymentReceiptofIITR_Fee.pdf');},
margin);
    
  }

  PayFeesHDFC(con)
  {
    
    this.sellerService.ResponseHandlerOfHDFCForStudents(this.Studentpaydata).subscribe((data:any)=>{
      
    console.log('---------------------', data.data.data)
    
    if(data.data.data != null)
    {
    this.encRequest = data.data.data; 
    console.log(this.accessCode,this.encRequest);

     if(data.data.data.amountStatus!="Success")
     {
    setTimeout(() => {
      this.form.nativeElement.submit()
    }, 1000);
  }
  else
  {
    this.toastr.info(data.data.message)
  }
}
else
{
  this.toastr.info(data.data.message)
}
    }, error => {
    console.log(error)
    }
    );
  }
}

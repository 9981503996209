import { HttpBackend } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';
import html2canvas from 'html2canvas';  
import { DatePipe } from '@angular/common';
declare var jsPDF: any;

@Component({
  selector: 'app-medi-contribution',
  templateUrl: './medi-contribution.component.html',
  styleUrls: ['./medi-contribution.component.scss']
})
export class MediContributionComponent implements OnInit {
  
  @ViewChild('form', {static: false}) form: ElementRef;
  // @ViewChild('pdfasTableHDFC', {static: false}) pdfasTableHDFC: ElementRef;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  toppingList: string[] = ['EMPLOYEE','PENSIONER', 'STUDENT', 'OTHERS'];
  MEDICALForm:FormGroup;
  accessCode : any;
  merchant_id:any;
  encRequest : any;
  encdata:any;
  merchant_code:any;
  currency: string;
  order_id: any;
  tracking_id: any;
  bank_ref_no: any;
  order_status: any;
  amount: any;
  billing_name: any;
  merchant_param2: any;
  delivery_address: any;
  sbi_ref_no: any;
  iitr_ref_no: any;
  status: any;
  desc: any;
  Transactiontime_date: any;
  ApplicationSeq_No: any;
  ApplicantName: any;
  RegistrationNo: any;
  bankname: any;
  email: any;
  username: any;
  IsresponseHDFC: boolean;
  PaymentRecept:boolean=false;
  CheckPaymentStatus:boolean=false;
  constructor(private spinner: NgxSpinnerService,private httpBackend: HttpBackend,private sellerService:SellerService,private formBuilder: FormBuilder,private router:Router,private toastr: ToastrService,private route: ActivatedRoute) 
  { 
    this.MEDICALForm = this.formBuilder.group({
      Name:[``, Validators.required],
      Category:[``, Validators.required],
      CategoryNo:[``, Validators.required],
      pan:[``, Validators.required],
      mobileno:[``, Validators.required],
      email:[``, Validators.required],
      ContributionNameorPurpuse:[``, Validators.required],
      amount:[``, Validators.required],
      Remarks:[``, Validators.required],
      currency:[``, Validators.required],
    });
   
    this.route.queryParams.subscribe(params => {
      this.order_id = params['order_id'];
      this.tracking_id = params['tracking_id'];
      this.bank_ref_no = params['bank_ref_no'];
      this.order_status = params['order_status'];
      this.amount = params['amount'];
      this.billing_name = params['billing_name'];
      this.merchant_param2=params['merchant_param2'];
      this.delivery_address=params['delivery_address'];
      this.sbi_ref_no=params['sbi_ref_no'];
      this.iitr_ref_no=params['iitr_ref_no'];
      this.status=params['status'];
      this.desc=params['desc'];
      this.Transactiontime_date=params['Transactiontime_date'];
      this.ApplicationSeq_No=params['ApplicationSeq_No'];
      this.ApplicantName=params['ApplicantName'];
      this.RegistrationNo=params['RegistrationNo'];
      this.bankname=params['BankName'];
      this.email=params['EmailId'];
      this.username=params['UserName'];
  });

 
  }

  ngOnInit() {
    if(this.order_status=="Success")
    {
      this.IsresponseHDFC = true;
    }
     else if(this.order_status=="Failure") 
    {
      this.IsresponseHDFC = true;
    }
    else if(this.order_status=="Aborted")
    {
    this.IsresponseHDFC = true;
    }

    this.currency='INR';
    this.accessCode = 'AVGO09IE49CI70OGIC';
    // this.accessCode = 'AVGO09IE49CI70OGIC';
    
  }
  CheckFormat()
  {
    this.IsresponseHDFC=true;
  }
  reset()
  {
    this.MEDICALForm.reset();
  }
  addsponserdata()
  {
    this.sellerService.MedicalContributionHDFCBank(this.MEDICALForm.value).subscribe((data:any)=>{
      console.log('Hdfcencdata',data.data.data)
      this.encRequest = data.data.data; 
      console.log(this.accessCode,this.encRequest);
      // if(data.data.data.amountStatus!="Success")
      // {
      setTimeout(() => {
       this.form.nativeElement.submit()
     }, 1000);
    //  }
    //  else
    //  {
    //    this.toastr.info(data.data.message)
    //  }
     },
     error => {
       console.log(error)
       }
     );
  }
  GobackMedical2()
  {
    $('.abc').removeClass('addremovecss');
    //window.location.href='http://localhost:4200/Medical_Contribution';
    window.location.href='https://payment.iitr.ac.in/Medical_Contribution';
    
   this.IsresponseHDFC=false;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
public DownloadBeena()
{
  var data = document.getElementById('pdfTable');  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 200;   
      let pageHeight = 200;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('IIT Roorkee medical contribution.pdf'); // Generated PDF  
    });  
}
  public downloadAsPDF() {
   const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const pdfTable = this.pdfTable.nativeElement;

    doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      
      width: 190,
      'elementHandlers': specialElementHandlers
    });

    doc.save('IIT Roorkee medical contribution.pdf');
  }

  CheckPaymentStatusPopup()
  {
    this.CheckPaymentStatus=true;
  }
  GobackMedical3()
  {
    this.CheckPaymentStatus=false;
  }
}

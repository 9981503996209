import { SelectionModel } from '@angular/cdk/collections';
// import { Component, Inject } from '@angular/core';
// import { ViewChild } from '@angular/core'
import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material';
import { OrderService } from '../Services/Order/order-service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfStmt } from '@angular/compiler';
import { SellerService } from '../Services/Seller/seller.service';
import { QuestionnaireService } from '../Services/Questionnaire/questionnaire.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { debug } from 'util';
 //import * as jsPDF from 'jspdf';
import { ExcelService } from '../Services/excel.service';
declare var jsPDF: any;
export interface PeriodicElement 
{
  enrollmentNo:any;
  name:any;
  amount:any;
  remarks:any;
  amountStatus:any;
  transactiontime_date:any;
  iITR_Ref_No:any;
  BankRefNo:any;
  description:any;
  tracking_id:any;
}
@Component({
  selector: 'app-ccb-list',
  templateUrl: './ccb-list.component.html',
  styleUrls: ['./ccb-list.component.scss']
})
export class CCBListComponent implements OnInit {
  IsEdit = false;
  dataResultList: PeriodicElement[] = [];
  StudentReportDB: any[] = [];
  EnableData=false;
  displayedColumns: string[] = ['select','enrollmentNo','name','amount','remarks','amountStatus','transactiontime_date','iITR_Ref_No','BankRefNo','description','tracking_id','Action'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.StudentReportDB);
  dataSourceSimple=new MatTableDataSource<PeriodicElement>(this.StudentReportDB);
  selection = new SelectionModel<PeriodicElement>(true, []);
  StudentReportsDbList: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  users: any;
  updateofficalform: FormGroup;
  dataExist: boolean;
  profileedit: boolean;
  UserName: any;
  userRole: any;
  order_level:any;
  AmountStatusList: any;
  exelList:any = [];
  constructor(private excelService:ExcelService,private toastr: ToastrService,private sellerService:SellerService,private spinner: NgxSpinnerService,private formbuilder:FormBuilder,private router : Router,private route: ActivatedRoute) 
  {const dataResultList: PeriodicElement[] = [];
    for (let i = 1; i <= 15; i++) {
      //this.SellerDbList.push(this.createNewUser(i));
    }
    // Assign the data to the data source for the table to render
    this.dataSourceSimple = new MatTableDataSource(dataResultList);
    this.dataSource = new MatTableDataSource(dataResultList); }

  ngOnInit() {
    
    this.userRole=window.localStorage.getItem('userRole');
    this.UserName = window.sessionStorage.getItem('UserName');
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // ********************************//
    this.sellerService.getCCBlist(this.UserName).subscribe((data:any) => {
    this.spinner.show();
    this.StudentReportsDbList = data.data.data;
    console.log(this.StudentReportsDbList);
  // this.listcount= data.data.data.length;
 // this.toastr.info("We have"+" "+this.listcount+" "+"seller list");
  this.dataResultList = this.StudentReportsDbList;
  this.dataSource.data = this.dataResultList;
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
  this.spinner.hide();
  },
  (err: HttpErrorResponse) => {
  });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  public downloadAsPDF() {
    const doc = new jsPDF();
    const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    const pdfTable = this.pdfTable.nativeElement;
    doc.fromHTML(pdfTable.innerHTML, 15, 15, {
      width: 190,
      'elementHandlers': specialElementHandlers
    });
    doc.save('StudentRecords.pdf');
  }
  editofficaldata(userProfile) 
  {
    this.IsEdit=false;
    this.profileedit= true;
    this.users = userProfile;
    console.log('users', this.users);
  //   this._sellerService.showQuestions(editdata.items.displayProductId).subscribe((data:any) => {
  //     console.log('questions',data.data);
  // },
  //   (err: HttpErrorResponse) => {
       
  //   });
  }
  ViewOrder(editdata) 
  {
    $('.abc').addClass('addremovecss');
    this.IsEdit = true;
    this.users = editdata;
  }
  Refresh(editdata) 
  {
   // this.IsEdit = true;
    this.users = editdata;
    this.spinner.show();
    this.sellerService.RefreshCCBDataAmountStatus(this.users.ccbId).subscribe((data: any) => {
      this.AmountStatusList = data.data;
      if(data.data=='Already Success'){
        this.toastr.show(this.AmountStatusList)
      }
      else{
      this.toastr.show(this.AmountStatusList.data)
    }
  //     this.dataResultList = this.AmountStatusList;
  //     this.dataSource.data = this.dataResultList;
  //     this.dataSource.paginator = this.paginator;
  // this.dataSource.sort = this.sort;
      this.spinner.hide();
      this.ngOnInit();
    })
  }
  


  Goback() {
    $('.abc').removeClass('addremovecss');
    this.IsEdit = false;
    this.profileedit=false;
  }
  getDataForSeller(seller: any)
  {
    this.sellerService.getCCBDataAmountStatus(seller).subscribe((data: any) => {
        this.AmountStatusList = data.data;
        this.dataResultList = this.AmountStatusList;
        this.dataSource.data = this.dataResultList;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
    
  }
  exportAsXLSX():void {
    this.dataSource.data = this.dataResultList;
    this.exelList=this.dataSource.data;
    //this.exelList.push(this.dataSource.data[0]);
    this.excelService.exportAsExcelFile(this.exelList,'CCB_NoDues');
}
}

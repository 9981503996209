import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-upload-expenditurereport',
  templateUrl: './upload-expenditurereport.component.html',
  styleUrls: ['./upload-expenditurereport.component.scss']
})
export class UploadExpenditurereportComponent implements OnInit {
  uploadexpenditureForm:FormGroup;
  uploadexpenditureFormSubmit:boolean;
 
  fileNameList: any = [];
  filecode:any;
  private httpClient: HttpClient;
  user: { imgPath: ""; };
  response1: any = {};
  uploadexceldata: any;
  Uploadexcelsheet: string;
  firstNme: string;
  lastname: string;
  name: string;
  downloads: any;
  TaskDbListstudent: any[] = [];
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};

  constructor(public datepipe: DatePipe,private router:Router,private sellerService: SellerService,private formBuilder:FormBuilder,private spinner: NgxSpinnerService,private toastr: ToastrService,private httpBackend: HttpBackend) 
  {
    this.uploadexpenditureForm = this.formBuilder.group(
      { 
        filename:[``,Validators.required],
        Uploadexcelsheet: [''],
        filecode:[''],
      }
    );
   }

  ngOnInit() {
    this.loadExpenditureExcel();
  }
  loadExpenditureExcel() {
    // this.spinner.show();
    this.sellerService.getExpenditureExcelList().subscribe((data: any) => {
      console.log('new karan', data);
      this.TaskDbListstudent=data.data;
      this.spinner.hide();
      this.dtTrigger.next();
    },
      (err: HttpErrorResponse) => {
      });
  }



  uploadFileBrowse(fileInput, name) 
  {
    //  if (localStorage.getItem("SessionPopUp") == null || localStorage.getItem("SessionPopUp") == undefined) {
    if (fileInput != null) {
      // if (fileInput.target.value != "" && fileInput.target.value != null) {
      this.uploadFileBrowserExcelsheet(<File>fileInput.target.files, name);
      // }
      fileInput.target.value = "";
    }
    else {
      this.toastr.error("You could not upload document", "")
    }
    //}
  }

  uploadFileBrowserExcelsheet(fileData, DocType) 
  {
    const formData = new FormData();
    this.fileNameList = [];
    for (let i = 0; i < fileData.length; i++) {
      formData.append('file[]', fileData[i]);
      var fileName=this.uploadexpenditureForm.controls.Uploadexcelsheet.value +"-"+DocType;
      formData.append(fileName,"");
      this.fileNameList.push(fileData[i].DocType);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    this.uploadexceldata=this.uploadexpenditureForm.value.Uploadexcelsheet
    var url = environment.BaseUrl + `api/EbaySeller/UploadExcelExpenditure`;
    this.httpClient.post(url, formData)
      .subscribe(res => {
        this.response1 = res;
        this.user = {
          imgPath: this.response1.renameFile,
        }
        this.Uploadexcelsheet = this.user.imgPath;
        this.toastr.success("data upload successfully.");
      })
  }

  UploadExcelSubmit()
  {
    this.uploadexpenditureFormSubmit=true;
    
      if(this.Uploadexcelsheet==""||this.Uploadexcelsheet==undefined)
      {
        this.toastr.error("Upload and check mandatory fields");
      }
      else{
    this.spinner.show();
   const data=
   {
  'filecode':"001",
  'Uploadexcelsheet':this.Uploadexcelsheet,
  'filename':this.uploadexpenditureForm.controls['filename'].value ,
   }
    this.sellerService.SaveExpenditureExcelData(data).subscribe(res=>{
      console.log(res);
      if(res["statusCode"]==404){
        // this.wrongCurrentPassword=true;
      }
      else{
    //  this.wrongCurrentPassword=false;
     alert(res["message"]);
      this.uploadexpenditureForm.reset();
      this.loadExpenditureExcel();
     // this.router.navigate(["Admin/Excel_Data"]);
      this.uploadexpenditureFormSubmit=false;
    }
    this.spinner.hide(); 
  },
  error=>{
    console.log(error);
    this.spinner.hide();
  });
  }
}


RunexcExpenditure(el)
{
  this.spinner.show();
  this.sellerService.Expenditurerunexcelexe(el.orderDurationId,el.reportid,el.reportName).subscribe((data: any) => {
    this.spinner.hide();
    if(data.statusCode==200)
    {
this.toastr.info("Excel Uploaed Successfully" )
this.ngOnInit() ;
    }
    else if(data.statusCode==400)
    {
      this.toastr.warning(data.excelExits)
    }
    else{
      this.toastr.error("Please try again" )
    }
    console.log('new karan', data);
    var dd = data.data;
  },
    (err: HttpErrorResponse) => {
    });
}
DeleteExcel(el)
{

}
}

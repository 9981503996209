import { Component} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { LoginService } from '../Services/Login/login.service';
import { Router } from '@angular/router';
import { CommonserviceService } from '../Services/CommonService/commonservice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent 
{
  loginForm:FormGroup;
  loginFormSubmit:boolean=false;
  rememberMe:boolean=false;
  hide:boolean = true;
  failedSignUp:boolean;
  responseError: boolean=false;
  userRole:any;
  errMessage:string;
  required:any;
  IsEmailBox: boolean=false;
  ErrorMessage: void;
  
  constructor(private formBuilder:FormBuilder, private serviceLogin:LoginService, private spinner: NgxSpinnerService,private router:Router, private commonService:CommonserviceService, private toastr: ToastrService)
    {
    this.loginForm = this.formBuilder.group({
      Email:[``,[Validators.required,Validators.minLength(3), Validators.maxLength(50)]],
      Password:[``,[Validators.required, Validators.minLength(1),Validators.maxLength(50)]],
      Enrollment:[``],
    });
    }
  LoginFormSubmit() 
  {
    this.loginFormSubmit=true;
    if(!this.loginForm.valid){
      return;
    }
    else{
      this.spinner.show();
      this.serviceLogin.PostLoginForm(this.loginForm.value).subscribe(res=>{
        console.log(res);
        console.log(res["data"]["token"]);
       if(res["data"]["email"]=="arcurriculum@iitr.ac.in") 
       {
         this.userRole="SubAdmin";
         localStorage.setItem('userRole', this.userRole)
       }
       else if(res["data"]["email"]=="adoswsw@iitr.ac.in") 
       {
         this.userRole="SubAdmin";
         localStorage.setItem('userRole', this.userRole)
       }
      else if(res["data"]["userRole"]=="Customer")
      {
        this.userRole="Student";
        localStorage.setItem('userRole', this.userRole)
      }
      else if(res["data"]["userRole"]=="Warden")
      {
        this.userRole="Warden";
        localStorage.setItem('userRole', this.userRole)
      }
       else
       {
        localStorage.setItem('userRole', res["data"]["userRole"])
       }
       
      localStorage.setItem('token', res["data"]["token"])
      localStorage.setItem('firstName',res["data"]["firstName"])
      localStorage.setItem('lastname',res["data"]["lastname"])
      localStorage.setItem('Enrollment',res["data"]["enrollment"])
      window.sessionStorage.setItem("UserName",`${res["data"]["email"]}`);
      if(res["statusCode"]==200 ){
          this.responseError=false;
          this.toastr.success("Login successfully.")
         // this.userRole=localStorage.getItem('userRole');
          window.sessionStorage.setItem("token",`${res["data"]["token"]}`);
          window.sessionStorage.setItem("UserName",`${res["data"]["email"]}`);
          window.sessionStorage.setItem("Email",`${res["data"]["email"]}`);
          this.commonService.username.next(`${res["data"]["userName"]}`);
          if(this.rememberMe){
              localStorage.setItem("token",`${res["data"]["token"]}`);
              window.localStorage.setItem("UserName",`${res["data"]["userName"]}`);
              window.localStorage.setItem("Email",`${res["data"]["email"]}`);
              localStorage.setItem('Enrollment',res["data"]["enrollment"]);
              localStorage.setItem('IsReset',res["data"]["isResetPass"]);
          }
            this.failedSignUp=false;
            this.commonService.login.next(true);
          this.userRole= localStorage.getItem('userRole');
          if(this.userRole=='Admin')
          {
              this.router.navigate(["/Admin/home"]);
            }
            else if(this.userRole=='CCB')
            {
              this.router.navigate(["/CCB/home"]);
            }
            else if(this.userRole=='SubAdmin')
            {
              this.router.navigate(["/SubAdmin/home"]);
            }
            else if(this.userRole=='DOSW')
            {
              this.router.navigate(["/DOSW/home"]);
            }
            else if(this.userRole=='Admin_ADCI')
            {
              this.router.navigate(["/ADCI/home"]);
            }
            else if(this.userRole=='Student')
            {
              this.router.navigate(["/Student/home"]);
            }
            else if(this.userRole=='Warden')
            {
              this.router.navigate(["/Warden/home"]);
            }
            else
            {
              if(res["data"]["isResetPass"]=="1")
              {
                this.router.navigate(["/Customer/home"]);
              }
              else
              {
             // this.router.navigate(["/Customer/ChangePassword"]);
              this.router.navigate(["/Customer/home"]);
            }
            }
           
            // this.router.navigate(["/Admin/home"]);
        }  
        else
        {
          this.responseError=true;
    }
    this.spinner.hide(); 
    },
    error=>{this.responseError=true;
      this.toastr.error(error.error.message);
      this.errMessage=error.error.message;
      this.ErrorMessage=error.error.message;
      console.log(this.errMessage);
      this.spinner.hide();
    });
   }
   
  }
  OpenEmailBox()
  {
this.IsEmailBox=true;
  }
  Reset()
  {
    this.loginForm.reset();
  }
  Goback()
  {
    this.IsEmailBox=false;
  }
}

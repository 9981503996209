import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionInputList, QuestionVM } from 'src/app/products/products.component';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  public headers: Headers;
  constructor(private httpClient: HttpClient,
    private route: Router) { }
    
  GetListOfQuestionnaires(UserName:any) {
    return this.httpClient.get(environment.BaseUrl + `/api/Questionnaires/ListOfQuestionnaires?UserName=${ window.sessionStorage.getItem('UserName')}`);
  }
  AddQuestion(quetionListData: any) 
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    console.log(window.sessionStorage.getItem("token"));
    const QuetionInputModel =
      {
         'UserEmail': window.sessionStorage.getItem('Email'),
         'UserName': window.sessionStorage.getItem('UserName'),
         'QuetionList': quetionListData,
         'ProductId': localStorage.getItem("product id")
      }
      console.log(QuetionInputModel);
    return this.httpClient.post(environment.BaseUrl + `api/Questionnaires/SaveQuestionnaires`, {
      QuetionInputModel
    }, HttpOptions);
  }

  AddMultiQuestion(quetionListData: any) 
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    const QuetionInputModel =
      {
         'UserEmail': window.sessionStorage.getItem('Email'),
         'UserName': window.sessionStorage.getItem('UserName'),
         'QuetionList': quetionListData['itemRows'].value,
         'ProductId': '',
         'TagName':quetionListData['TagName']
      }
      console.log(QuetionInputModel);
    return this.httpClient.post(environment.BaseUrl + `api/Questionnaires/AddMultipleQuestionnaires`, {
      QuetionInputModel
    }, HttpOptions);
  }

  getQuestionType() {
    return this.httpClient.get(environment.BaseUrl + '/api/Questionnaires/QuestionTypes');
  }
 
  AddQuestionIndividual(formData:any)
  {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':`bearer ${window.sessionStorage.getItem("token")}`
       })   
    }
    console.log(window.sessionStorage.getItem("token"));
    return this.httpClient.post(environment.BaseUrl+`api/Questionnaires/SaveQuestionnairesIndividual`,{
      'userEmail':window.sessionStorage.getItem('Email'),
      'questionAsked':formData.QuestionText,
      'itemId':formData.ItemId,
      'tagName':formData.TagName,
      'questionType':formData.QuestionType,
      'UserName': window.sessionStorage.getItem("UserName",)
    },HttpOptions);
  }


 
  UpdateQuestion(formData: any)   {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    return this.httpClient.post(environment.BaseUrl + `api/Questionnaires/UpdateQue`, {
      questionId: formData.questionId,
      UserEmail: window.sessionStorage.getItem('Email'),
      UserName: window.sessionStorage.getItem('UserName'),
      QuestionAsked: formData.QuestionText,
      TagName: formData.TagName,
      QuestionType: formData.QuestionType
    }, HttpOptions);
  }

  DeleteQuestionaries(formData: any) {
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `bearer ${window.sessionStorage.getItem("token")}`
      })
    }
    return this.httpClient.post(environment.BaseUrl + `api/Questionnaires/DeleteQuetionaries`, {
      questionId: formData.questionId,
      UserEmail: window.sessionStorage.getItem('Email'),
      UserName: window.sessionStorage.getItem('UserName'),
    }, HttpOptions);
  }
}


import { Component, OnInit } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import { ViewChild } from '@angular/core'
import {CustomerService} from '../Services/customer/customer.service'
import {MatOption} from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../Services/Seller/seller.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit 
{
  IsSendMailForm: FormGroup;
  RemarkDbList: any[] = [];
  // myselectedFoods: any[] = [];
  txt: string;
  userTypeFilters = [
    {
      key: 1, value: 'Value 1',
    },
    {
      key: 2, value: 'Value 2',
    },
    {
      key: 3, value: 'Value 3',
    },
    {
      key: 4, value: 'Value 4',
    }
  ];
  @ViewChild('allSelected',{static: false}) private allSelected: MatOption;
  

  constructor(private router:Router,private formBuilder:FormBuilder,private sellerService:SellerService,private spinner: NgxSpinnerService,private customerService:CustomerService,private toastr: ToastrService)
  {
  
    this.IsSendMailForm=this.formBuilder.group(
      {
      // Email:[``,Validators.required, Validators.email],
     Subject:[''],
     body:[''],
     toppings:[],
     order_level:[],
     EmailList:[],
    //  userType:[]
     });
  }
  
  /** Whether the number of selected elements matches the total number of rows. */

   /** Selects all rows if they are not all selected; otherwise clear selection. */

  ngOnInit() {
 this.GetEmailList();
 this.IsSendMailForm = this.formBuilder.group({
  userType: new FormControl(''),
  Subject:[''],
  body:[''],
});

  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.IsSendMailForm.controls.userType
        .patchValue([...this.RemarkDbList.map(item => item), 0]);
        // .patchValue([...this.userTypeFilters.map(item => item.key), 0]);
    } else {
      this.IsSendMailForm.controls.userType.patchValue([]);
    }
  }
  // setAll()
  // {
  //   this.EmailList.setValue(this.RemarkDbList);
  //   this.txt = this.EmailList.value;
  // }

  GetEmailList()
  {
    this.spinner.show();
    this.sellerService.EmailList().subscribe((data: any) => {
    this.RemarkDbList = data.data.data;
    this.spinner.hide();
    },(err: HttpErrorResponse) => {});
  }
  SendMail()
  {
    let emaillist=null;
    const data =
    {
      'userType':this.IsSendMailForm.controls['userType'].value,
      'EmailList' : emaillist,
      'Subject':this.IsSendMailForm.controls['Subject'].value,
      'body' : "test data",
    }
    this.sellerService.SendMailPensioner(data).subscribe((data: any) => {
      this.toastr.success(data.message);
      this.IsSendMailForm.reset();
    }, error => 
    {
      console.log(error)
      });
//SendEmail
  }

  Goback() {
    $('.abc').removeClass('addremovecss');
    this.router.navigate(["Admin/EmailMaster"]);
  }
}
  
